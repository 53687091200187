export default class BPHttpTask {
    constructor(args) {
      this.type = 0;
      this.header = '';
      this.content = '';
      this.serverUrl = '';
      this.props = {};
      this.bcookie = null;
      this.cookie = "";
      this.calllog = [];

      if ('type' in args) {
        this.type = args.type;
      }
      if ('header' in args) {
        this.header = args.header;
      }
      if ('content' in args) {
        this.content = args.content;
      }
      if ('serverUrl' in args) {
        this.serverUrl = args.serverUrl;
      }
      if ('props' in args) {
        this.props = args.props;
      }
      if ('bcookie' in args) {
        this.bcookie = args.bcookie;
      }      
      if ('calllog' in args) {
        this.calllog = args.calllog;
      }
      if ('cookie' in args) {
        this.cookie = args.cookie;
      }
    }
    appendCallLog(s) {
      this.calllog.push(s);
    }
    getHttpServerUrl() {
      return this.serverUrl;
    }
    setServerUrl(s) {
      this.serverUrl = s;
    }
    setProperty(sName, sValue) {  // GUID, C_UAOS, C_UAPIXELS, C_LATLON, C_B_COOKIE, C_BPIN, C_HOST
      this.props[sName] = sValue;
    }
    getProps() {
      return this.props;
    }
    getProperty(sName) {
      if (this.props[sName] != null) {
        return this.props[sName];
      } else {
        return "";
      }
    }
    getBcookie() {
      return this.bcookie;
    }
    setBcookie(bc) {
      this.bcookie = bc;
    }
    getCookie() {
      return this.cookie;
    }
    setCookie(c) {
      this.cookie = c;
    }
  }
  BPHttpTask.HTTP_TASK_SERVER = 1;
  BPHttpTask.HTTP_TASK_CLIENT = 2;
  