export default class BShape {
  constructor(iType) {
    this._maskBits = 0;
    this._type = 0;
    this._fillColor = 0;
    this._strokeColor = 0;
    this._inset = false;
    this._fill = null;
    this._stroke = null;

    this._type = iType;
    this._maskBits = 0;
  }
  clear() {
    this._fill = null;
    this._stroke = null;
  }
  getType() {
    return this._type;
  }
  setFill(pn) {
    this._fill = pn;
  }
  getFill() {
    return this._fill;
  }
  setStroke(pn) {
    this._stroke = pn;
  }
  getStroke() {
    return this._stroke;
  }
  setInset(b) {
    this._inset = b;
  }
  getInset() {
    return this._inset;
  }
  draw(ctx, g, pnt, dn, x, y, width, height) {
    switch (this.getType()) {
      case BShape.SHAPE_RECTANGLE:
        {
        let br = this;
        br.draw(ctx, g, pnt, dn, x, y, width, height);
        break;
        }
      case BShape.SHAPE_ROUND_RECTANGLE:
        {
        let br = this;
        br.draw(ctx, g, pnt, dn, x, y, width, height);
        break;
        }
      case BShape.SHAPE_OVAL_RECTANGLE:
        {
        let br = this;
        br.draw(ctx, g, pnt, dn, x, y, width, height);
        break;
        }
      case BShape.SHAPE_ELLIPSE:
      case BShape.SHAPE_CIRCLE:
        {
        let be = this;
        be.draw(ctx, g, pnt, dn, x, y, width, height);
        break;
        }
      case BShape.SHAPE_LINE:
        {
        let bl = this;
        bl.draw(ctx, g, pnt, dn, x, y, width, height);
        break;
        }
      case BShape.SHAPE_POLYGON:
        {
        let bp = this;
        bp.draw(ctx, g, pnt, dn, x, y, width, height);
        break;
        }
      case BShape.SHAPE_POLYLINE:
        {
        let bp = this;
        bp.draw(ctx, g, pnt, dn, x, y, width, height);
        break;
        }
    }
  }
}
BShape.C_RECT = 'rect';
BShape.C_RRECT = 'rrect';
BShape.C_ORECT = 'orect';
BShape.C_ELLIPSE = 'ellipse';
BShape.C_CIRCLE = 'circle';
BShape.C_LINE = 'line';
BShape.C_POLYGON = 'polygon';
BShape.C_POLYLINE = 'polyline';
BShape.AllShapes = [BShape.C_RECT, BShape.C_RRECT, BShape.C_ORECT, BShape.C_ELLIPSE, BShape.C_CIRCLE, BShape.C_LINE, BShape.C_POLYGON, BShape.C_POLYLINE];
BShape.SHAPE_INVALID = 0;
BShape.SHAPE_RECTANGLE = 1;
BShape.SHAPE_ROUND_RECTANGLE = 2;
BShape.SHAPE_OVAL_RECTANGLE = 3;
BShape.SHAPE_ELLIPSE = 4;
BShape.SHAPE_CIRCLE = 5;
BShape.SHAPE_LINE = 6;
BShape.SHAPE_POLYGON = 7;
BShape.SHAPE_POLYLINE = 8;
BShape.BITS_SET_FILL = 0x00000001;
BShape.BITS_SET_STROKE = 0x00000002;
