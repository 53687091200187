export default class BLinearGradient {
  constructor() {
    this._id = null;
    this._type = 0;
    this._colors = null;
    this._stops = null;
    this._alpha = 0;

    this._type = this.GRADIENT_INVALID;
    this._alpha = 255;
  }
  clear() {
    this._id = null;
    this._colors = null;
    this._type = this.GRADIENT_INVALID;
  }
  isValid() {
    if (this._type !== this.GRADIENT_INVALID && this._id !== null) {
      return true;
    } else {
      return false;
    }
  }
  setType(iType) {
    this._type = iType;
  }
  getType() {
    return this._type;
  }
  setId(sId) {
    this._id = sId;
  }
  getId() {
    return this._id;
  }
  setColorsAndStops(aColors, aStops, nColors) {
    if (nColors > 0) {
      this._colors = new Array(nColors);
      this._stops = new Array(nColors);
      for (let i = 0; i < nColors; i++) {
        this._colors[i] = aColors[i] & 0x00ffffff;
        if (aStops[i] === -1) {
          if (i === 0) {
            this._stops[i] = 0;
          } else {
            if (i === nColors - 1) {
              this._stops[i] = 100;
            } else {
              this._stops[i] = (i * 100) / (nColors - 1);
            }
          }
        } else {
          this._stops[i] = aStops[i];
        }
      }
    } else {
      this._colors = null;
    }
  }
  getColors() {
    return this._colors;
  }
  getStops() {
    return this._stops;
  }
  setAlpha(alpha) {
    if (alpha >= 0 && alpha <= 255) {
      this._alpha = alpha;
    }
  }
  getAlpha() {
    return this._alpha;
  }
}
BLinearGradient.GRADIENT_INVALID = 0;
BLinearGradient.GRADIENT_TB = 1;
BLinearGradient.GRADIENT_BT = 2;
BLinearGradient.GRADIENT_LR = 3;
BLinearGradient.GRADIENT_RL = 4;
