import * as Location from 'expo-location';

export default class BLocation {
    constructor(mid, resCode) {
      this._iCurLatitude = 0.0;
      this._iCurLongitude = 0.0;
      this._iLatitude = 0.0;
      this._iLongitude = 0.0;
      this._iMeters = 0;
      this._iTime = 0;
      this._interval = BLocation.GPS_REFRESH_RATE;
      this._midlet = null;
      this._activityResCode = 0;
      this.mEnabled = false;
      this._mAsked = false;

      this.mySubscription = null;
    }
    hasLatLonBeenSet() {
      if (this._iCurLatitude != 0.0 && this._iCurLongitude != 0.0) {
        return true;
      } else {
        return false;
      }
    }
    getFormattedLatLon() {
      return this._iCurLatitude.toFixed(6) + ';' + this._iCurLongitude.toFixed(6);
    }
    getCommaFormattedLatLon() {
      return this._iCurLatitude.toFixed(6) + ',' + this._iCurLongitude.toFixed(6);
    }
    getLat() {
      return this._iCurLatitude;
    }
    getLon() {
      return this._iCurLongitude;
    }
    getLatLon() {
        return this.getCommaFormattedLatLon();
    }
    getTime() {
      return this._iTime;
    }
    getEnabled() {
      return this.mEnabled;
    }
    setProviders() {
    }
    NotifyClientGPSUseRequestL(bUse, dMiles, iUpdateSeconds) {
      this._iMeters = dMiles * 1609.344;
      if (iUpdateSeconds === -1) {
        this._interval = BLocation.GPS_REFRESH_RATE;
      } else {
        this._interval = iUpdateSeconds;
      }
      if (bUse) {
        if (!this.mEnabled) {
          this.setProviders();
          this.doRequestPermission(true);
        }
      } else {
        if (this.mEnabled) {
            if (this.mySubscription !== null) {
                this.mySubscription.remove();
            }
        }
      }
      this.mEnabled = bUse;
    }
    async doRequestPermission(bDoLoc) {
        if (!this._mAsked) {
            this._mAsked = true;
            // if already granted (coarse or fine), then continue
//            (async () => {
                let { status } = await Location.getForegroundPermissionsAsync();
                if (status !== 'granted') {
                    let { status } = await Location.requestForegroundPermissionsAsync();
                    if (status !== 'granted') {
                        global.globalVars.appClass.showMessage("Location position refused");
                        return;
                    }
                }
                this.doRequestLocation(bDoLoc);
//            });
        } else {
            this.doRequestLocation(bDoLoc);
        }
    }
    async doRequestLocation(bDoLoc) {
        let loc = null;       // 

        this.mySubscription = await Location.watchPositionAsync(
            {
                accuracy: Location.Accuracy.High,
                timeInterval: this._interval * 1000,
                distanceInterval: (this._iMeters)
            },
            (loc) => {
                this.onLocationChanged(loc);
            }
        );
        if (bDoLoc) {
            loc = await Location.getLastKnownPositionAsync();
            if (loc === null) {
              loc = await Location.getCurrentPositionAsync();
            }
            if (loc !== null) {
                this.updateGPSLocation(loc.coords.latitude, loc.coords.longitude);
            }
        }
    }
    setEnabled(bEnable) {
      if (bEnable) {
        if (!this.mEnabled) {
          this.setProviders();
          this.doRequestPermission(false);
        }
      } else {
        if (this.mEnabled) {
            if (this.mySubscription !== null) {
                this.mySubscription.remove();
            }
        }
      }
      this.mEnabled = bEnable;
    }

    updateGPSLocation(latitude, longitude) {
      if (this._iLatitude === 0.0 && this._iLongitude === 0.0) {
        if (latitude !== 0.0 && longitude !== 0.0) {
          this._iLatitude = latitude;
          this._iLongitude = longitude;
          this._iCurLatitude = latitude;
          this._iCurLongitude = longitude;
          global.globalVars.setLatLon(latitude, longitude);
          global.globalVars.appClass.RefreshS();
        }
      } else {
        let distance = this.distanceBetween(this._iLatitude, this._iLongitude, latitude, longitude);
        this._iCurLatitude = latitude;
        this._iCurLongitude = longitude;
        if (distance >= this._iMeters) {
          this._iLatitude = latitude;
          this._iLongitude = longitude;
          global.globalVars.setLatLon(latitude, longitude);
          global.globalVars.appClass.RefreshS();
        }
      }
    }
    distanceBetween(lat1, lon1, lat2, lon2) {
       // The math module contains a function
        // named toRadians which converts from
        // degrees to radians.
        lon1 =  lon1 * Math.PI / 180;
        lon2 = lon2 * Math.PI / 180;
        lat1 = lat1 * Math.PI / 180;
        lat2 = lat2 * Math.PI / 180;
   
        // Haversine formula
        let dlon = lon2 - lon1;
        let dlat = lat2 - lat1;
        let a = Math.pow(Math.sin(dlat / 2), 2)
                 + Math.cos(lat1) * Math.cos(lat2)
                 * Math.pow(Math.sin(dlon / 2),2);
               
        let c = 2 * Math.asin(Math.sqrt(a));
   
        // Radius of earth in kilometers 6371. Use 3956
        // for miles.  for meters, use 6371000
        let r = 6371000;
   
        // calculate the result
        return(c * r);        
    }
    onLocationChanged(location) {
        this.updateGPSLocation(location.coords.latitude, location.coords.longitude);
        this._iTime = location.timestamp;
    }
    onProviderDisabled(provider) {}
    onProviderEnabled(provider) {}
    onStatusChanged(provider, status, extras) {}
  }
  BLocation.GPS_REFRESH_RATE = 15;
  