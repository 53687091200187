import BShape from './BShape';
import Fixed32 from './Fixed32';
import BStatus from './BStatus';

export default class BLine extends BShape {
  constructor(iType) {
    this.evaulated = false;

    this._maskSet = 0;
    this._x1 = 0;
    this._y1 = 0;
    this._x2 = 0;
    this._y2 = 0;

    super(iType);
  }
  isValid() {
    switch (super.getType()) {
      case BShape.SHAPE_LINE:
        return ((this._maskSet & BLine.MASK_LINE) === BLine.MASK_LINE);
    }
    return false;
  }
  setX1(x1) {
    this._x1 = x1;
    this._maskSet |= BLine.VAL_X1;
  }
  setY1(y1) {
    this._y1 = y1;
    this._maskSet |= BLine.VAL_Y1;
  }
  setX2(x2) {
    this._x2 = x2;
    this._maskSet |= BLine.VAL_X2;
  }
  setY2(y2) {
    this._y2 = y2;
    this._maskSet |= BLine.VAL_Y2;
  }
  draw(g, mPaint, dnBase, x, y, width, height) {
    if (!this.evaulated) {
      if (typeof this._x1 == 'object') {
        this._x1.pop();    // x1
        var fdv = Parser.evalTokens(this._x1, width, height);
        this._x1 = fdv;
      }
      if (typeof this._y1 == 'object') {
        this._y1.pop();
        var fdv = Parser.evalTokens(this._y1, width, height);
        this._y1 = fdv;
      }
      if (typeof this._x1 == 'object') {
        this._x2.pop();    // x2
        var fdv = Parser.evalTokens(this._x2, width, height);
        this._x2 = fdv;
      }
      if (typeof this._y2 == 'object') {
        this._y2.pop();
        var fdv = Parser.evalTokens(this._y2, width, height);
        this._y2 = fdv;
      }

      this.evaulated = true;
    }
    let x1 = 0;
    let y1 = 0;
    let x2 = 0;
    let y2 = 0;
    if (this.getInset()) {
      x1 = x + Fixed32.toInt(this._x1);
      y1 = y + Fixed32.toInt(this._y1);
      x2 = x + width - Fixed32.toInt(this._x2);
      y2 = y + height - Fixed32.toInt(this._y2);
    } else {
      x1 = x + Fixed32.toInt(this._x1);
      y1 = y + Fixed32.toInt(this._y1);
      x2 = x + Fixed32.toInt(this._x2);
      y2 = y + Fixed32.toInt(this._y2);
    }
    let pn = this.getStroke();
    if (pn !== null) {
      if (pn.TID().toLowerCase().equals(DecorNode.C_lowercase_c)) {
        let status = new BStatus(true);
        let iv = DecorNode.parseColorNode(pn, status);
        if (status.getStatus()) {
          let argbOld = mPaint.getColor();
          mPaint.setColor(iv);
          let path = new Path();
          path.moveTo(x1, y1);
          path.moveTo(x2, y2);
          mPaint.setStyle(Paint.Style.STROKE);
          g.drawPath(path, mPaint);
          mPaint.setColor(argbOld);
        }
      }
    }
  }
}
BLine.VAL_X1 = 0x00000001;
BLine.VAL_Y1 = 0x00000002;
BLine.VAL_X2 = 0x00000010;
BLine.VAL_Y2 = 0x00000020;
BLine.MASK_LINE = BLine.VAL_X1 | BLine.VAL_Y1 | BLine.VAL_X2 | BLine.VAL_Y2;
