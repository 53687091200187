import Vector from "./Vector";
import StringBuffer from './StringBuffer';

export default class ParenNode {
  constructor() {
    this._children = null;
    this._tid = null;
    this._iVal = 0;

    this._children = new Vector();
    this._tid = null;
    this._iVal = 0;
  }
  TID() {
    return this._tid;
  }
  getChildren() {
    return this._children;
  }
  firstChild() {
    if (this._children !== null && this._children.size() > 0) {
      return this._children.elementAt(0);
    }
    return null;
  }

  toString() {
    let sb = new StringBuffer();
    ParenNode.appendText(this, sb);
    return sb.toString();
  }
  toStringChildren() {
    let res = new StringBuffer();
    for (let i=0; i<this._children.size(); i++) {
      let dnChild = this._children.elementAt(i);
      ParenNode.appendText(dnChild, res);
    }
    return res.toString();
  }
};
ParenNode.appendText = (me, res) => {
  if (me == null) return;
  res.append(me.TID());

  if (me.getChildren().size() > 0) {
    res.append("(");
  }

  for (let i=0; i<me.getChildren().size(); i++) {
    let dnChild = me.getChildren().elementAt(i);
    ParenNode.appendText(dnChild, res);
  }
  if (me.getChildren().size() > 0) {
    res.append(")");
  }
}
ParenNode.scanToMatchingCloseParen = (sRest) => {
  let level = 0;
  let i = 0;
  let slen = sRest.length;
  let c = '';
  do {
    c = sRest.charAt(i);
    if (c === ')') {
      if (level === 0) {
        return i;
      } else {
        if (level > 0) {
          level--;
        } else {
          return -1;
        }
      }
    } else {
      if (c === '(') {
        level++;
      }
    }
    i++;
  } while (i < slen);
  return -1;
};
ParenNode.NodeFromString = (sParenString) => {
  let root = new ParenNode();
  let ilen = 0;
  let itemp = 0;
  if (sParenString === null) {
    return null;
  }
  let iTotalLen = sParenString.length;
  try {
    do {
      itemp = ParenNode.Parse(root, sParenString.substring(ilen));
      if (itemp === 0) {
        root = null;
        return root;
      } else {
        ilen += itemp;
      }
    } while (ilen < iTotalLen);
  } catch (ex) {
    root = null;
  }
  return root;
};
ParenNode.Parse = (rootNode, sParenString) => {
  let len = sParenString.length;
  let sb = new StringBuffer();
  let c = '';
  let i = 0;
  let myNode = new ParenNode();
  rootNode._children.addElement(myNode);
  if (i < len) {
    do {
      c = sParenString.charAt(i);
      if (c === '(') {
        if (sb.length() > 0) {
          myNode._tid = sb.toString();
          sb = new StringBuffer();
          let stidlen = myNode._tid.length;
          let k = 0;
          do {
            c = myNode._tid.charCodeAt(k);
            // a-z, A-Z, 0-9, .,  43=+, 45=0, 47=/, 42=*
            if (!((c >= 97 && c <= 122) || (c >= 65 && c <= 90) || (c >= 48 && c <= 57) || 
                   c === 46 || c == 43 || c == 45 || c == 42 || c == 47)) { // 2020-10-10
              return 0;
            } else {
              k++;
            }
          } while (k < stidlen);
          i++;
          if (i < len) {
            let pend = ParenNode.scanToMatchingCloseParen(sParenString.substring(i));
            if (pend === -1) {
              return 0;
            } else {
              if (pend === 0) {
                return i + 1;
              }
            }
            let sRest = sParenString.substring(i, i + pend + 1);
            let iiSubLen = 0;
            do {
              let subLen = ParenNode.Parse(myNode, sRest.substring(iiSubLen));
              if (subLen === 0) {
                return 0;
              } else {
                iiSubLen += subLen;
              }
            } while (iiSubLen < sRest.length);
            return i + iiSubLen;
          } else {
            return i;
          }
        } else {
          return 0;
        }
      } else {
        if (c === ')') {
          if (sb.length() > 0) {
            myNode._tid = sb.toString();
            sb = new StringBuffer();
          } else {
            rootNode._children.removeElement(myNode);
          }
          i++;
          return i;
        } else {
          if (!(c === ' ' && sb.length() === 0)) {
            if (c === ' ') {
              myNode._tid = sb.toString();
              sb = new StringBuffer();
              myNode = new ParenNode();
              rootNode._children.addElement(myNode);
            } else {
              sb.append(c);
            }
          }
          i++;
        }
      }
    } while (i < len);
    if (sb.length() > 0) {
      myNode._tid = sb.toString();
    }
  }
  return i;
};
