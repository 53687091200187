export default class XYRect {
    constructor$0() {}
    constructor$1(r) {
      this.x = r.x;
      this.y = r.y;
      this.width = r.width;
      this.height = r.height;
    }
    constructor$4(x, y, width, height) {
      this.x = this.x;
      this.y = this.y;
      this.width = this.width;
      this.height = this.height;
    }
    constructor(...args$) {
      switch (args$.length) {
        case 0:
          return this.constructor$0(...args$);
        case 1:
          return this.constructor$1(...args$);
        case 4:
          return this.constructor$4(...args$);
      }
    }
  }
  