import React from "react";
import FormLabel from '@mui/material/FormLabel';
import { TextField } from "@mui/material";

export default class EDefaultText extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            textType: (props.original != null) ? 'text' : 'default',
            text: (props.original == null) ? props.txtProps.default : props.original,   // could be null
            editingText: false,
        }

        this.savedText = '';
    }

    static getDerivedStateFromProps(props, state) {
        let stRet = {
            textType: (props.original != null) ? 'text' : 'default',
        }
        if (!state.editingText) {
            stRet.text = (props.original == null) ? props.txtProps.default : props.original   // could be null
        }
        return stRet;
    }

    typedFocus = (event) => {
        this.savedText = this.state.text;
    }
    typedBlur = (event) => {
        let val = event.currentTarget.value;

        if (val.length > 0) {
            this.state.text = val;
        } else {
            this.state.text = this.savedText;
        }
        this.state.editingText = false;

        event.currentTarget.style.background = null;

        this.propChange();
    }

    typedChanged = (event) => {
        let val = event.currentTarget.value;
        if (val.length > 0) {
            this.setState({
                text: val,
                editingText: true
            });

            event.currentTarget.style.background = null;
        } else {
            this.setState({
                text: val,
                editingText: true
            });
            event.currentTarget.style.background = 'yellow';
        }
    }

    propChange = (obj) => {
        let sval = this.state.text;

        this.props.setProp(sval)
    }

    render() {
        return (
            <>
                <FormLabel component="legend">{this.props.name}</FormLabel>
                <TextField
                    value={this.state.text}
                    variant="standard"
                    onFocus={this.typedFocus}
                    onBlur={this.typedBlur}
                    onChange={this.typedChanged}
                    style={{
                        marginLeft: "5px"
                    }}
                />
            </>

        )
    }
}