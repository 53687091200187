import BPColor from './BPColor';
import BShape from './BShape';
import Fixed32 from './Fixed32';
import BStatus from './BStatus';
import Parser from './shunt';

export default class BPolyline extends BShape {
  constructor(iType) {
    super(iType);
    this.evaluated = false;

    this._xValues = null;
    this._yValues = null;

  }
  clear() {
    super.clear();
    this._xValues = null;
    this._yValues = null;
  }
  isValid() {
    switch (super.getType()) {
      case BShape.SHAPE_POLYLINE:
        if (this._xValues !== null && this._xValues.length > 1) {
          return true;
        } else {
          return false;
        };
    }
    return false;
  }
  addPoint(fx, fy) {
    if (this._xValues === null) {
      this._xValues = new Array();
      this._yValues = new Array();
    }
    this._xValues.push(fx);
    this._yValues.push(fy);
  }
  draw(ctx, g, mPaint, dnBase, xpos, ypos, width, height) {
    if (width <= 0 || height <= 0 || this._xValues.length === 0) {
      return undefined;
    }
    if (!this.evaluated) {
      let len = this._xValues.length;
      for (var i=0; i<len; i++) {
        if (typeof this._xValues[i] == 'object') {
          var fdv = Parser.evalTokens(this._xValues[i], width, height);
          this._xValues[i] = fdv;
        }
        if (typeof this._yValues[i] == 'object') {
          var fdv = Parser.evalTokens(this._yValues[i], width, height);
          this._yValues[i] = fdv;
        }
      }

      this.evaluated = true;
    }

    let pn = this.getStroke();
    if (pn !== null) {
      if (pn.TID().toLowerCase().equals(DecorNode.C_lowercase_c)) {
        let status = new BStatus(true);
        let iv = DecorNode.parseColorNode(pn, status);
        if (status.getStatus()) {

          let rgbOld = g.strokeStyle;
          g.strokeStyle = BPColor.intToHexRGBA(iv);

          g.beginPath();
          let len = this._xValues.length;
          if (len > 0) {
            g.moveTo(Fixed32.toInt(this._xValues[0]) + xpos, Fixed32.toInt(this._yValues[0]) + ypos);
            for (let i = 1; i < len; i++) {
              g.lineTo(Fixed32.toInt(this._xValues[i]) + xpos, Fixed32.toInt(this._yValues[i]) + ypos);
            }
            g.stroke();
          }

          g.strokeStyle = rgbOld;
        }
      }
    }
  }
}
