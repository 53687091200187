import React from "react";
import { Text, View } from 'react-native';
import DecorNode from "../graphics/DecorNode";

export default class CTitleView extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
    }
    componentDidMount() {
    }
    componentWillUnmount() {
    }
    /*
    setText(s) {
        this.setState({message: s});
    }
    */
    render() {
/*
        let dn = DecorNode.FromAttributes(this.props.decorString, DecorNode.DECORNODE_TYPE_NAVBAR);
        let pnFont = null;
        if (dn !== null) {
            pnFont = dn.findChildNode(DecorNode.C_lowercase_font);
        }

        this.myFontSettings = DecorNode.getFontSettings(pnFont, {
            fontFactor: 1.43,       // 20pt
            fontWeight: 400,
        });
        style... below
                    ...this.myFontSettings,
*/

        return (
            <Text style={
                {
                    color: this.props.color,
                    width: '75%',
                    textAlign: 'center',
                    ...this.props.style,
                }
            }
            >
                {this.props.title}

            </Text>
        )
    }
}
