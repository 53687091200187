import React from "react";
import DecorBackground from "../graphics/DecorBackground";
import DecorNode from "../graphics/DecorNode";

export default class CSeparatorView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            decorNodeString: props.decorNodeString ? props.decorNodeString : CSeparatorView.DEFAULT_DECOR_NODE_STRING,
            iheight: 1,
        }
    }
    refresh() {
        this.setState({
            decorNodeString: this.state.decorNodeString
          })    
    }
    setDecorNodeString(s) {
        if (typeof s !== 'undefined' && s != null) {
            this.setState({
                decorNodeString: s
            });
        } else {
            this.setState({
                decorNodeString: CSeparatorView.DEFAULT_DECOR_NODE_STRING
            });
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.decorNodeString != prevProps.decorNodeString) {
            this.setState({
                decorNodeString: this.props.decorNodeString
            })

            let dn = DecorNode.FromAttributes(this.props.decorNodeString, DecorNode.DECORNODE_TYPE_SEPARATOR);
            let iheight = 1;
            if (dn != null) {
                let iVal = dn.getChildNodeIntValue(DecorNode.C_lowercase_height);
                if (iVal !== Number.MAX_SAFE_INTEGER) {
                    iheight = iVal;
                }
            } else {
                iheight = 1;
            }
            this.setState({ iheight: iheight });
        }
    }
    componentDidMount() {
    }
    componentWillUnmount() {
    }
    render() {
        if (this.state.visible && this.state.iheight > 0) {
            return (
                <DecorBackground style={[{
                    height: this.state.iheight,
                    width: '100%',
                }, this.props.style]}
                decorNodeString={this.state.decorNodeString} dnType={DecorNode.DECORNODE_TYPE_SEPARATOR}/>
            )
        } else {
            return null;
        }
    }
}

CSeparatorView.DIVIDER_COLOR = 0x0FFe0e0e0;
CSeparatorView.DEFAULT_DECOR_NODE_STRING = "bg(c(#FFe0e0e0)) height(1)";
