
import ImageRow from "./ImageRow";
import XYRect from "./XYRect";

export default class HTMLRow extends ImageRow {
    constructor(sUrl, r, minHeight, vAlign, hAlign, itemNo) {
        super(sUrl, r, minHeight, vAlign, hAlign, itemNo);
        this.mState = '';
        this._wv = null;
        this.heightSet = false;
        this.clone = null;
    }
    update(imgUrl, newRect, minHeight, vAlign, hAlign) {
        this._imgUrl = imgUrl;
        this._rect = new XYRect(newRect);
        this._minHeight = minHeight;
        this._valign = vAlign;
        this.halign = hAlign;
        /*
        if (this.clone === null && this._wv !== null) {            
            this.clone = this._wv.current.cloneNode();
        }
        */
    }
    getClone() {
        return this.clone;
    }
    saveState(sState) {
        this.mState = sState;
    }
    restoreState() {
        return this.mState;
    }
    setWebView(wv) {
        this._wv = wv;
    }
    getWebView() {
        return this._wv;
    }
}
