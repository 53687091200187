export default class ArrayList {
    constructor(args) {
        if (typeof args !== 'undefined') {
            this._arr = new Array(args);
        } else {
            this._arr = new Array();
        }
    }
    clear = () => {
        this._arr = new Array();
    }
    addObj = (v) => {
        this._arr.push(v);
    }
    add = (i, v) => {
        this._arr[i] = v;
    }
    get = (ix) => {
        return this._arr[ix];
    }
    set = (ix,v) => {
        this._arr[ix] = v;
    }
    size = () => {
        if (this._arr == null) {
            return 0;
        } else {
            return this._arr.length;
        }
    }
    remove = (ix) => {
        this._arr.splice(ix, 1);
    }
    map(callback /*, args */) {
        var T, A, k;
        var len = this.size();
        if (typeof callback !== 'function') {
            throw new TypeError(callback + ' is not a funtion');
        }

        var O = Object(this);

        // 4. If IsCallable(callback) is false, throw a TypeError exception.
        // See: https://es5.github.com/#x9.11
        if (typeof callback !== 'function') {
            throw new TypeError(callback + ' is not a function');
        }

        // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 1) {
            T = arguments[1];
        }

        // 6. Let A be a new array created as if by the expression new Array(len)
        //    where Array is the standard built-in constructor with that name and
        //    len is the value of len.
        A = new Array(len);

        // 7. Let k be 0
        k = 0;

        // 8. Repeat, while k < len
        while (k < len) {

            var kValue, mappedValue;

            kValue = O.get(k);

            mappedValue = callback.call(T, kValue, k, O);

            A[k] = mappedValue;

            k++;
        }

        // 9. return A
        return A;
    }
}
ArrayList.ofArrayLists = (n) => {
    let myArrayList = new ArrayList(n);
    for (let i=0; i<n; i++) {
        myArrayList.set(i, new ArrayList());
    }
    return myArrayList;
}