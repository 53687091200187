export default class BPTask {
    constructor(iType, oD) {
      this.iTaskType = iType;
      this.oData = oD;
      this.bWaitingResponse = false;
    }
  }
  BPTask.TASK_SENDFILE = 1;
  BPTask.TASK_PLAYFILE = 2;
  BPTask.TASK_HTTPTASK = 3;
  BPTask.TASK_HTTPEDITTASK = 4;
