class LatLng {
    constructor(lat, lng) {        
        this.lat = lat;
        this.lng = lng;
    }
    getLat() {
        return this.lat;
    }
    getLon() {
        return this.lng;
    }
}

//arMapItems.add(new TropareMapItem(latLng, sDescription, cColor, imgUrl, sClickAction));
class TropareMapItem {
    constructor(latLng, sDescription, cColor, imgUrl, sClickAction) {
        this.latLng = latLng;
        this.sDescription = sDescription;
        this.cColor = cColor;
        this.imgUrl = imgUrl;
        this.sClickAction = sClickAction;
    }
    getLat() {
        return this.latLng.getLat();
    }
    getLon() {
        return this.latLng.getLon();
    }
    getDescription() {
        if (typeof this.sDescription === 'undefined' || this.sDescription === null) {
            return "";
        } else {
            return this.sDescription;
        }
    }
    getColor() {
        return this.cColor;
    }
    getImgUrl() {
        return this.imgUrl;
    }
    getClickAction() {
        return this.sClickAction;
    }
}

export {LatLng, TropareMapItem}
