import BBUtils from "./BBUtils";
export default class DynMenuList {
    constructor(arg) {
  
      if (arg.hasOwnProperty('_alist')) {   // arg is DynMenuList
        this._alist = [];
        for (let i=0; i<arg._alist.length; i++) {
          this._alist.push(arg._alist[i].repeat(1));
        }
      } else {                              // arg is string
        this._alist = BBUtils.split(arg, ';');
        for (let i=0; i<this._alist.length; i++) {
          this._alist[i] = this._alist[i].trim();
        }
      }
    }
    getText() {
      if (this._alist !== null && this._alist.length > 1) {
        return this._alist[1];
      } else {
        return BBUtils.C_EMPTY_STRING;
      }
    }
    getAction() {
      if (this._alist !== null && this._alist.length > 2) {
        return this._alist[2];
      } else {
        return BBUtils.C_EMPTY_STRING;
      }
    }
    getButton() {
      if (this._alist !== null && this._alist.length > 3) {
        return this._alist[3];
      } else {
        return BBUtils.C_EMPTY_STRING;
      }
    }
    isRightSlider() {
      if (this._alist !== null && this._alist.length > 3) {
        return this._alist[3].toUpperCase().equals('SR');
      }
      return false;
    }
    isRightButtonSingleAction() {
      if (this._alist !== null && this._alist.length > 3) {
        return this._alist[3].toUpperCase().equals('RB');
      }
      return false;
    }
    getFirstExtra() {
      if (this._alist !== null && this._alist.length > 4) {
        return this._alist[4];
      } else {
        return BBUtils.C_EMPTY_STRING;
      }
    }
    getSecondExtra() {
      if (this._alist !== null && this._alist.length > 5) {
        return this._alist[5];
      } else {
        return BBUtils.C_EMPTY_STRING;
      }
    }
    getImageUrl() {
      if (this._alist !== null && this._alist.length > 5) {
        return this._alist[5];
      } else {
        return BBUtils.C_EMPTY_STRING;
      }
    }
    getThirdExtra() {
      if (this._alist !== null && this._alist.length > 6) {
        return this._alist[6];
      } else {
        return BBUtils.C_EMPTY_STRING;
      }
    }
    getAlternateButton() {
      return this.getThirdExtra();
    }
    getOthers() {
      if (this._alist !== null && this._alist.length > 6) {
        let ilen = this._alist.length - 6;
        let aret = [];
        for (let i = 0; i < ilen; i++) {
          this.push(this._alist[i + 6]);
        }
        return aret;
      } else {
        return null;
      }
    }
    getSubType(ix) {
      if (this._alist !== null && this._alist.length > 0) {
        if (this._alist[0].length > ix) {
          return this._alist[0].charAt(ix);
        }
      }
      return 32;
    }
    getContext() {
      return this.getSubType(0);
    }
    getProcessingType() {
      return this.getSubType(1);
    }
    getNextStep() {
      return this.getSubType(2);
    }
  }
  