import React from "react";
import { RgbStringColorPicker } from 'react-colorful';
import { Container, Box, TextField, Paper } from "@mui/material";
import {colord} from 'colord';
import BGradientStop from "./BGradientStop";
import {ArrowBack, ArrowForward, ArrowDownward, ArrowUpward} from '@mui/icons-material';

import Draggable from "react-draggable";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import BColorSwatch from "./BColorSwatch";
import BPColor from "../graphics/BPColor";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import {LensBlur, Circle}  from '@mui/icons-material';


function BpIconDirection(props) {
    return(
        <Box
            sx={{
                width: "24px",
                height: "24px"
            }}
        >
            {props.mi}
        </Box>
    )
}

function BpCheckedIconDirection(props) {
    return(
        <Box
            sx={{
                backgroundColor: '#aaaaaa',
                width: "24px",
                height: "24px"
            }}
        >
            {props.mi}
        </Box>
    )
}
// Inspired by blueprintjs
function BpRadioDirection(props) {
    return (
      <Radio
        sx={{
          '&:hover': {
            bgcolor: 'transparent',
          },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIconDirection mi={props.mi} />}
        icon={<BpIconDirection mi={props.mi}/>}
        {...props}
      />
    );
  }

export default class BGradientPicker extends React.Component {
    static defaultProps = {
        delayTimeout: 100,
    }

    constructor(props) {
        // gref = refernce to outer gradient
        super(props);

        this.onChange = this.props.onChange ? this.props.onChange : function(pal) {};

        this.state = {
            activeDrags: 0,
            initialColor: "#00f",
            textColor: '#00ffff',

            genGradient: "linear-gradient(90deg, #020024 0%, #090979, 35%, #00d4ff 100%)",
            genGradientImage: "linear-gradient(90deg, rgb(2, 0, 36) 0%, rgb(2, 156, 218) 51%, rgb(0, 212, 255) 100%)",
            selectedIndex: 0,

            gradientDegrees: 90,
            gradientAlpha: 255,
            gradientPalette: [],

            editingPaletteItem: false,
            editingTextColor: false,
        }

        this.timerId = null;

        this.isDragging = false;

        this.savedColor = '#000';
        this.listSavedColor = '#000';
        this.listSavedPerc = '0';

        this.gradientBarRef = React.createRef();

        this.addStop = this.addStop.bind(this);
        this.colorChange = this.colorChange.bind(this);
        this.onDrag = this.onDrag.bind(this);
        this.typedColorBlur = this.typedColorBlur.bind(this);
        this.typedColorOnFocus = this.typedColorOnFocus.bind(this);

    }

    static getDerivedStateFromProps(props, state) {


        // Copy this.props.gradient to state
        let gradient = {
            gradientDegrees: props.gradient.degrees,
            gradientAlpha: props.gradient.alpha,
            gradientPalette: []
            };


        for (let i=0; i<props.gradient.palette.length; i++) {
            let myPal = {
                offset: props.gradient.palette[i].offset,
                color: props.gradient.palette[i].color
            };
            gradient.gradientPalette.push(myPal);
        }

        let myState = {};

        if (state.selectedIndex >= gradient.gradientPalette.length) {
            myState.selectedIndex = gradient.gradientPalette.length - 1;
        } else {
            myState.selectedIndex= state.selectedIndex;
        } 
 
        if (!state.editingTextColor) {
            myState.initialColor = gradient.gradientPalette[myState.selectedIndex ].color;
            myState.textColor = myState.initialColor;
        }
        
        let stateGradient = {
            gradientAlpha: state.gradientAlpha,
            gradientDegrees: state.gradientDegrees,
            gradientPalette: state.gradientPalette,
        }

        if (!BGradientPicker.compareGradient(stateGradient, gradient)) {
            if (!state.editingPaletteItem) {
                myState.gradientAlpha = gradient.gradientAlpha;
                myState.gradientDegrees = gradient.gradientDegrees;
                myState.gradientPalette = gradient.gradientPalette;
            }
        }

        return myState;
    }

    alphaChange = (event, newValue) => {
        let val = (newValue * 255 / 100).toFixed();
        this.setState( {gradientAlpha: val});
        this.state.gradientAlpha = val;
        this.propChange();
    }

    propChange(oChange) {
        if (typeof this.props.onChange === 'function') {
            let gradient;
            if (typeof oChange === 'undefined') {
                gradient = {
                    degrees: this.state.gradientDegrees,
                    alpha: this.state.gradientAlpha,
                    palette: this.state.gradientPalette    
                }
            } else {
                gradient = {
                    degrees: oChange.hasOwnProperty('degrees') ? oChange.degrees : this.state.gradientDegrees,
                    alpha: oChange.hasOwnProperty('alpha') ? oChange.alpha : this.state.gradientAlpha,
                    palette: oChange.hasOwnProperty('palette') ? oChange.palette : this.state.gradientPalette    
                }
            }
            this.props.onChange(gradient);
        }
    }
    generateGradientFromPalette = (pal) => {
        let gradient = {
            degrees: this.state.gradientDegrees,
            alpha: this.state.gradientAlpha,
            palette: (typeof pal !== 'undefined') ? pal : this.state.gradientPalette
        }

        let gProps = BPColor.getCSSGradient(gradient);

        if (this.props.gref.current !== null) {
            this.props.gref.current.style.background = gProps.background;
            this.props.gref.current.style.backgroundImage = gProps.backgroundImage;
        }
        this.setState({
            genGradient: gProps.background,
            genGradientImage: gProps.backgroundImage,
        })
    }
    componentWillUnmount() {

    }
    componentDidMount() {
        this.generateGradientFromPalette();
    }

    // When actual RGBAColorPicker changes
    colorChange = (c) => {
        let cVal;
        if (this.state.textColor.startsWith('#')) {
            cVal = colord(c).toHex();
            this.setState({textColor: cVal})
        } else {
            cVal = c;
            this.setState({textColor: cVal});
        }
        if (this.state.selectedIndex < this.state.gradientPalette.length) {
            let pal = this.replacePaletteEntryColor(this.state.selectedIndex, cVal);
            this.generateGradientFromPalette(pal);
            this.propChange({palette: pal});
        }
    }
    onStart = (event, ui, index) => {
        this.isDragging = true;
        event.stopPropagation();
        event.preventDefault();

        this.startOffsetValue = this.state.gradientPalette[index].offset;

        this.setState({
             activeDrags: ++this.state.activeDrags,
             editingPaletteItem: true
            });
    };

    onStop = (event, ui) => {
        setTimeout(() => (this.isDragging = false), 0);

        event.stopPropagation();
        event.preventDefault();
        this.setState({
             activeDrags: --this.state.activeDrags,
            editingPaletteItem: false });
    };

    doDrag = (dragVals) => {
        if (dragVals.x != 0) {
            let dn = this.gradientBarRef.current;

            let percChange = Math.round(100 * dragVals.x / dn.offsetWidth);
            if (percChange != 0) {

                let ival = /*parseInt(this.startOffsetValue) + */ percChange;
                if (ival < 0) {
                    ival = 0;
                } else if (ival > 100) {
                    ival = 100;
                }

                let pal = this.replacePaletteEntryOffset(dragVals.index, ival);
                this.setState({
                    selectedIndex: dragVals.index,
                    gradientPalette: pal,
                });

                this.generateGradientFromPalette(pal);
                this.propChange({ palette: pal });
            }
        }

    }

    notify = dVals => {    
        this.doDrag(dVals);
      };

    resetTimer() {
        clearTimeout(this.timerId);
      }

    runTimeoutUpdate = dVals => {
        const { delayTimeout } = this.props;
    
        this.resetTimer();
        this.timerId = setTimeout(() => this.notify(dVals), delayTimeout);
      };

    onDrag = (e, ui, index) => {
        let dragVals = {x: ui.x, index: index};
        this.runTimeoutUpdate(dragVals);
    }

    typedColorOnFocus = () => {
        this.savedColor = this.state.textColor;
    }
    typedColorBlur = (event) => {
        let val = event.currentTarget.value;
        if (!colord(val).isValid()) {
            val = this.savedColor;
        }

        event.currentTarget.style.background = null;


        let pal = this.replacePaletteEntryColor(this.state.selectedIndex, val);
        this.setState({
            textColor: val,
            initialColor: val,
            editingTextColor: false,
            gradientPalette: pal
            });

        this.generateGradientFromPalette(pal);
        
        this.propChange({palette: pal});
    }
    typedColorChanged = (event) => {
        let val = event.currentTarget.value;
        if (colord(val).isValid()) {
            event.currentTarget.style.background = null;
        } else {
            event.currentTarget.style.background = 'yellow';
        }

        this.setState({
            textColor: val,
            editingTextColor: true
        })
    }

    handleListItemClick = (event, index) => {

        this.setState({
            selectedIndex: index,
            textColor: this.state.gradientPalette[index].color,
            initialColor: this.state.gradientPalette[index].color,
        });
    }

    replacePaletteEntryColor = (index, sVal) => {
        let pal = [...this.state.gradientPalette];
        let item = {...pal[index]};
        item.color = sVal;
        pal[index] = item;
        return pal;
    }

    replacePaletteEntryOffset = (index, sVal, nosort) => {
        let pal = [...this.state.gradientPalette];
        let item = {...pal[index]};
        item.offset = sVal;
        pal[index] = item;
        if (typeof nosort === 'undefined') {
            pal.sort(function(a,b) {
                return a.offset - b.offset;
            })
        }
        return pal;
    }

    typedListTextFocus = (event, index) => {
        this.listSavedColor = this.state.gradientPalette[index].color;
    }

    typedListTextBlur = (event, index) => {
        let val = event.currentTarget.value;
        if (!colord(val).isValid()) {
            val = this.listSavedColor;
        }

        event.currentTarget.style.background = null;


        let pal = this.replacePaletteEntryColor(index, val);
        // BUGBUG: is this needed?  or, does propChange take care of this?
        this.setState({
            textColor: val,
            initialColor: val,
            gradientPalette: pal,
            editingPaletteItem: false
            });

        this.generateGradientFromPalette(pal);        
        this.propChange({palette: pal});

    }
    typedListTextChanged = (event, index) => {
        let val = event.currentTarget.value;
        let pal;
        if (colord(val).isValid()) {
            pal = this.replacePaletteEntryColor(index, val);
            event.currentTarget.style.background = null;
        } else {
            pal = this.replacePaletteEntryColor(index, val);
            event.currentTarget.style.background = 'yellow';
        }        
        this.setState({
            gradientPalette: pal,
            editingPaletteItem: true
        })
    }

    isBetween0and100 = val => {
        try {
            let ival = parseInt(val);
            if (ival >= 0 && ival <= 100) {
                return true;
            } else {
                return false;
            }
        } catch (ignore) {
            return false;
        }
    }

    typedListPercFocus = (event, index) => {
        this.listSavedPerc = this.state.gradientPalette[index].offset;
    }

    typedListPercBlur = (event, index) => {
        let val = event.currentTarget.value;

        if (!this.isBetween0and100(val)) {
            val = this.listSavedPerc;
        }

        event.currentTarget.style.background = null;

        let pal = this.replacePaletteEntryOffset(index, val);

        // BUGBUG: is this needed?  or, does propChange take care of this?
        this.setState({
            gradientPalette: pal,
            editingPaletteItem: false
            });

        this.generateGradientFromPalette(pal);        
        this.propChange({palette: pal});

    }
    typedListPercChanged = (event, index) => {
        let val = event.currentTarget.value;
        if (this.isBetween0and100(val)) {
            event.currentTarget.style.background = null;
        } else {
            event.currentTarget.style.background = 'yellow';
        }

        let pal = this.replacePaletteEntryOffset(index, val, true);

        this.setState({
            gradientPalette: pal,
            editingPaletteItem: true
            });
    }

    addStop = (event) => {
        if (this.isDragging) return;

        let perc = (100 * event.nativeEvent.offsetX / this.gradientBarRef.current.offsetWidth).toFixed();
        let ctx = global.globalVars.getCanvas().getContext('2d');
        var gradient = ctx.createLinearGradient(0,0,256,2);
        for (let i=0; i<this.state.gradientPalette.length; i++) {
            let pval = this.state.gradientPalette[i];
            gradient.addColorStop(pval.offset/100, pval.color);
        }
        ctx.fillStyle = gradient;
        ctx.fillRect(0,0,256,2);
        let pixel = ctx.getImageData(((perc/100)*256).toFixed(),1,1,1).data;
        // array of 4 - rgba
        let color = '#' + BPColor.decimalToHex(pixel[0],2) + 
            BPColor.decimalToHex(pixel[1],2) + 
            BPColor.decimalToHex(pixel[2],2);
//alpha            BPColor.decimalToHex(pixel[3],2);

        let myPalE = {offset: perc, color: color};
        let newArr = [...this.state.gradientPalette];
        newArr.push(myPalE);
        newArr.sort(function(a,b) {
            return a.offset - b.offset;
        })

        let myIx = newArr.findIndex( elem => elem.offset === myPalE.offset && elem.color === myPalE.color );
        this.setState({
            selectedIndex: myIx
        })

//        this.state.gradientPalette = newArr;    // so propChange picks it up...
//        this.setState({gradientPalette: newArr});

        this.generateGradientFromPalette(newArr);

        this.propChange({palette: newArr});
    }
    
    rowDelete = (event, index) => {
        event.stopPropagation();
        if (this.state.gradientPalette.length > 1) {
            this.state.gradientPalette.splice(index,1);
            this.generateGradientFromPalette();
            if (this.state.selectedIndex >= this.state.gradientPalette.length) {
                this.state.selectedIndex = this.state.gradientPalette.length-1;
            }
            this.propChange();
        } else {
            global.globalVars.appClass.showMessage('You must have at least one stop');
        }

    }
    handleDirectionChange = (event) => {
        this.setState({gradientDegrees: event.target.value});
        this.propChange({degrees: event.target.value});
    }

    // convert (xxdeg  to (90deg
    make90 = (grad) => {
        return grad.replace(/(\d+deg)/, "90deg");

    }
    computeBounds = (offset, index) => {
        if (this.gradientBarRef.current !== null) {
            let dn = this.gradientBarRef.current;
            let x = dn.offsetWidth * parseInt(offset) / 100;
//            return {left: -x, right: dn.offsetWidth - x};
            return {left: 0, right: dn.offsetWidth};
        } else {
            return "parent";
        }
    }
    computePosition = (offset) => {
        if (this.gradientBarRef.current !== null) {
            let dn = this.gradientBarRef.current;
            let xpos = Math.round(dn.offsetWidth * parseInt(offset) / 100);
            return {x: xpos, y:0};
        } else {
            return {x: 0, y:0};
        }        
    }
    render() {
        const dragHandlers = {onStart: this.onStart, onDrag: this.onDrag, onStop: this.onStop};
        let that = this;
        return (
            <Container>
                <Box
                    sx={{
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        border: "1px solid #dfe1e6",
                        position: "relative",
                        height: "56px",
                        padding: "14px 16px",
                        background: "#fff",
                        display: 'block'
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: "22px",
                            border: "2px solid #1F2667",
                            padding: "2px",
                            borderRadius: "6px",
                            boxSizing: "border-box",
                            display: 'block'
                        }}
                    >
                        <Box
                            onClick={this.addStop}
                            sx={{
                                backgroundImage: this.make90(this.state.genGradientImage),
                                width: "100%",
                                height: "100%",
                                borderRadius: "2px",
                                background: this.make90(this.state.genGradient),
                                boxSizing: "border-box",
                                display: "block"
                            }}
                        >
                            <Box
                                ref={this.gradientBarRef}
                                sx={{
                                    display: 'flex',
                                    position: "absolute",
                                    top: "9px",
                                    left: "10px",
                                    right: "28px",
                                    height: "31px",
                                    cursor: "copy",
                                    boxSizing: "border-box"
                                }}
                            >
                                <Box
                                id="myparent"
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    position: 'relative',
                                }}
                                >
                                {this.state.gradientPalette.map((item, index) => {
                                    return (
                                        <Draggable
                                            key={index}
                                            axis="x"
                                            position={this.computePosition(item.offset)}
                                            bounds={this.computeBounds(item.offset, index)}
                                            onStart={(event, ui) => this.onStart(event, ui, index)}
                                            onDrag={(event, ui) => this.onDrag(event, ui, index)}
                                            onStop={this.onStop}
                                        >
                                            <div
                                                style={{
                                                    position: 'relative'
                                                }}>
                                                <BGradientStop
                                                    data-index={index}
                                                    pref={that.gradientBarRef}
                                                    perc={item.offset}
                                                    color={item.color} />
                                            </div>
                                        </Draggable>
                                    )

                                })}
                                </Box>
                            </Box>
                        </Box>

                    </Box>

                </Box>
                <Box
                    sx={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        display: 'flex',
                    }}
                >
                    <Box
                    >
                        <RgbStringColorPicker
                            name="gradientcolor"
                            color={colord(this.state.initialColor).toRgbString()}
                            style={{
                                padding: "16px",
                                borderRadius: "12px",
                                background: "#33333a",
                                boxShadow: "0 6px 12px #999",
                            }}
                            onChange={this.colorChange} />
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <LensBlur />
                            <Slider area-label="alpha" value={((this.state.gradientAlpha / 255) * 100).toFixed()} onChange={this.alphaChange} />
                            <Circle />
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            display: "inline-block",
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <TextField
                                label="HEX"
                                width="10ch"
                                value={this.state.textColor}
                                variant="outlined"
                                onBlur={this.typedColorBlur}
                                onFocus={this.typedColorOnFocus}
                                onChange={this.typedColorChanged}
                                style={{
                                    marginLeft: "5px",
                                    display: 'flex'

                                }}
                            />
                            <RadioGroup ara-label="direction"
                                onChange={this.handleDirectionChange}
                                value={this.state.gradientDegrees}
                                name="rb_dir" row>
                                <BpRadioDirection value="90" mi={<ArrowForward />} />
                                <BpRadioDirection value="270" mi={<ArrowBack />} />
                                <BpRadioDirection value="180" mi={<ArrowDownward />} />
                                <BpRadioDirection value="0" mi={<ArrowUpward />} />
                            </RadioGroup>
                        </Box>

                        <Paper style={{
                            maxHeight: 220,
                            overflow: 'auto'
                        }}
                        >
                            <List dense={true}>
                                {this.state.gradientPalette.map((item, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            selected={this.state.selectedIndex === index}
                                            onClick={(event) => this.handleListItemClick(event, index)}
                                            secondaryAction={
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={(event) => this.rowDelete(event, index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <BColorSwatch
                                                    id={"cs" + index.toString()}
                                                    color={this.state.gradientPalette[index].color}
                                                    handleClick={(event) => this.handleListItemClick(event, index)}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText>
                                                <TextField
                                                    style={{ maxWidth: "13ch" }}
                                                    inputProps={{ maxLength: 9 }}
                                                    variant="outlined"
                                                    onChange={(event) => this.typedListTextChanged(event, index)}
                                                    onBlur={(event) => this.typedListTextBlur(event, index)}
                                                    onFocus={(event) => this.typedListTextFocus(event, index)}
                                                    value={this.state.gradientPalette[index].color}
                                                />
                                                <Box
                                                    sx={{
                                                        display: 'inline-block',
                                                        width: "3ch",
                                                    }}
                                                />
                                                <TextField
                                                    style={{ maxWidth: "8ch" }}
                                                    inputProps={{ maxLength: 3 }}
                                                    variant="outlined"
                                                    onChange={(event) => this.typedListPercChanged(event, index)}
                                                    onBlur={(event) => this.typedListPercBlur(event, index)}
                                                    onFocus={(event) => this.typedListPercFocus(event, index)}
                                                    value={this.state.gradientPalette[index].offset}
                                                />
                                            </ListItemText>
                                        </ListItem>
                                    )
                                }
                                )}
                            </List>
                        </Paper>
                    </Box>

                </Box>
            </Container>
        )
    }
}

BGradientPicker.compareGradient = (ga, gb) => {
    if (ga.gradientAlpha != gb.gradientAlpha) {
        return false;
    }
    if (ga.gradientDegrees != gb.gradientDegrees) {
        return false;
    }
    if (ga.gradientPalette.length != gb.gradientPalette.length) {
        return false;
    }
    for (let i=0; i<ga.gradientPalette.length; i++) {
        if (ga.gradientPalette[i].offset != gb.gradientPalette[i].offset) {
            return false;
        }
        if (ga.gradientPalette[i].color != gb.gradientPalette[i].color) {
            return false;
        }
    }
    return true;
}
