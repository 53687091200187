import BBUtils from "./BBUtils";
import Vector from "../graphics/Vector";
import StringBuffer from "../graphics/StringBuffer";
import AsyncPostImage from "./AsyncPostImage";
import AsyncImage from "./AsyncImage";
import ImageFactory from "./ImageFactory";

export default class AsyncFullSizeImageCache {
    constructor(ctx) {
      this.m_imageCache = null;
      this.m_urlsNeeded = null;
      this.m_fetchImages = null;
      this.asyncPostImage = null;
      this.images = null;
      this.mContext = null;
      this.mPostChecksum = null;
      this.mPostImageFails = 0;
  
      this.mContext = ctx;
      this.m_imageCache = new Vector();
//sync      this.imagesLock = new Object();
    }
    isImageCached(sUrl) {
      return AsyncFullSizeImageCache.isImageCachedGeneric(this.m_imageCache, sUrl);
    }
    addToImageCache(asyncImage) {
        AsyncFullSizeImageCache.addToGenericCache(this.m_imageCache, asyncImage);
    }
    addToCache(sUrl, img) {
      let aimg = new AsyncImage(sUrl, img);
      this.addToImageCache(aimg);
      //was synchronized(this.imagesLock)
      if (this.images !== null) {
        let bFound = false;
        let len = this.images.size();
        for (let i = 0; !bFound && i < len; i++) {
          aimg = this.images.elementAt(i);
          if (aimg.containsUrl(sUrl)) {
            bFound = true;
          }
        }
        if (!bFound) {
          this.images.add(aimg);
        }
      }
    }
    asyncPostImageReadyImage(sUrl, img) {
        //was synchronized(this)
        let ixTrim = sUrl.indexOf(AsyncFullSizeImageCache.C_100PERCENT);
        let urlNormal = null;
        if (ixTrim !== -1) {
          urlNormal = sUrl.substring(ixTrim + AsyncFullSizeImageCache.C_100PERCENT.length);
        } else {
          urlNormal = sUrl;
        }
        this.addToCache(urlNormal, img);
        let cbs = this.m_fetchImages[urlNormal];
        delete this.m_fetchImages[urlNormal];
        let cbsNeeded = this.m_urlsNeeded[urlNormal];
        if (typeof cbs !== 'undefined') {
          let len = cbs.size();
          for (let i = 0; i < len; i++) {
            let icb = cbs.elementAt(i);
            if (typeof cbsNeeded !== 'undefined' && cbsNeeded.contains(icb)) {
              cbsNeeded.removeElement(icb);
            }
            // Global...
            global.globalVars.refreshViewType(icb);
          }
          if (typeof cbsNeeded !== 'undefined') {
            len = cbsNeeded.size();
            for (let i = 0; i < len; i++) {
              let icb = (cbsNeeded.elementAt(i));
              global.globalVars.refreshViewType(icb);
            }
            delete this.m_urlsNeeded[urlNormal];
          }
        }
      }
    asyncPostImageReady(sUrl, sRedirect) {
      let ixTrim = sRedirect.indexOf(AsyncFullSizeImageCache.C_100PERCENT);
      if (ixTrim !== -1) {
        let redir = sRedirect.substring(ixTrim + AsyncFullSizeImageCache.C_100PERCENT.length);
        let aimg = this.isImageCached(redir);
        if (aimg !== null) {
          this.asyncPostImageReadyImage(sUrl, aimg.image);
        }
      }
    }
    asyncPostFinished(bStatus) {
      if (bStatus) {
        //was synchronized(this)
        let sb = new StringBuffer();
        sb.append(BBUtils.C_IMG_EQUAL);
        for (const sImageUrl in this.m_fetchImages) {
            let sbUrl = new StringBuffer();
            sbUrl.append(BBUtils.C_GEOMETRY_PLAIN);
            sbUrl.append(AsyncFullSizeImageCache.C_100PERCENT);
            sbUrl.append(sImageUrl);
            sbUrl.append("\n");
            sb.append(BBUtils.UrlEncode(sbUrl.toString()));
        }
            
        for (const sImageUrl in this.m_urlsNeeded) {
            let cbs = this.m_urlsNeeded[sImageUrl];
            if (this.m_fetchImages[sImageUrl]) {
                let cbAlready = this.m_fetchImages[sImageUrl];
                let len = cbs.size();
                for (let z = 0; z < len; z++) {
                if (!cbAlready.contains(cbs.elementAt(z))) {
                    cbAlready.add(cbs.elementAt(z));
                }
                }
            } else {
                let sbUrl = new StringBuffer();
                sbUrl.append(BBUtils.C_GEOMETRY_PLAIN);
                sbUrl.append(AsyncFullSizeImageCache.C_100PERCENT);
                sbUrl.append(sImageUrl);
                this.m_fetchImages[sImageUrl] = cbs;
                sbUrl.append("\n");
                sb.append(BBUtils.UrlEncode(sbUrl.toString()));
            }
        }
        this.m_urlsNeeded = {};

        this.asyncPostImage = null;
        if (sb.length() > BBUtils.C_IMG_EQUAL.length) {
          let spdata = sb.toString();
          let myv = crc32(spdata);
          let lastv = this.mPostChecksum;
          if (myv === lastv) {
            this.mPostImageFails++;
          }
          if (myv !== lastv || this.mPostImageFails < 2) {
            this.mPostChecksum = myv;

            this.asyncPostImage = new AsyncPostImage(global.globalVars.getBatchService(), spdata, AsyncPostImage.AsyncFullSizeCacheType);
            global.globalVars.getImageLoader().send(this.asyncPostImage)
              .then((data) => ({data}))
              .catch((error) => ({error}))
              .then(({data, error}) => {
                  if (data != null) {
                    this.handlePostReturn(data);
                  }
              });
          }
        }
        sb = null;;
      }
    }
    async handlePostReturn(data) {
      // data.buffer
      var arImages = data.images;
      for (let i=0; i<arImages.length; i++) {
          if (arImages[i].hasOwnProperty('redirect')) {
              this.asyncPostImageReady(arImages[i].url, arImages[i].redirect);
          } else if (arImages[i].hasOwnProperty('slice')) {   // ixStart, ixEnd
              var slice_buff = new Uint8Array(data.buffer.slice(arImages[i].slice.ixStart, arImages[i].slice.ixEnd));
              var base64str = '';
              var len = slice_buff.byteLength;
              for (let i=0; i<len; i++) {
                  if (slice_buff[i] != 10) {
                      base64str += String.fromCharCode(slice_buff[i]);
                  }
              }              
              await ImageFactory.fromBase64(base64str)
                .then((img) => ({img}))
                .catch((error) => ({error}))
                .then(({img, error}) => {
                    if (img != null) {
                      this.asyncPostImageReadyImage(arImages[i].url, img);
                    }
                });
  
          }
      }
      this.asyncPostFinished(true);
    }

    clearImages() {
      //was synchronized(this.imagesLock)
      if (this.images !== null) {
        this.images.removeAllElements();
      }
      this.images = new Vector();;
    }
    getImageForUrl(sUrl) {
      let retVal = null;
      //was synchronized(this.imagesLock)
      if (this.images !== null) {
        let sz = this.images.size();
        for (let i = 0; i < sz; i++) {
          let img = this.images.elementAt(i);
          if (img.containsUrl(sUrl)) {
            retVal = img;
            break;
          }
        }
      }
      return retVal;
    }
    addUrl(sUrl, uiType) {
      let vUrls = new Vector();
      vUrls.add(sUrl);
      this.loadAllImages(vUrls, uiType);
    }
    loadAllImages(imageUrls, uiType) {
      //was synchronized(this)
      if (this.m_urlsNeeded === null) {
        this.m_urlsNeeded = {};     // new Hashtable<String, Vector<Integer>>()
      }
      let len = imageUrls.size();
      for (let i = 0; i < len; i++) {
        let sImageUrl = imageUrls.elementAt(i);
        let cbs = this.m_urlsNeeded[sImageUrl];
        if (typeof cbs !== 'undefined') {
          if (!cbs.contains(uiType)) {
            cbs.add(uiType);
          }
        } else {
          cbs = new Vector();
          cbs.add(uiType);
          this.m_urlsNeeded[sImageUrl] = cbs;
        }
      }
      if (this.asyncPostImage === null) {
        this.m_fetchImages = {};    // new Hashtable<String, Vector<Integer>>()
        let sb = new StringBuffer();
        sb.append(BBUtils.C_IMG_EQUAL);
        for (const sImageUrl in this.m_urlsNeeded) {
          let cbs = this.m_urlsNeeded[sImageUrl];
          if (this.isImageCached(sImageUrl) !== null) {
            if (typeof cbs !== 'undefined') {
              len = cbs.size();
              for (let i = 0; i < len; i++) {
                global.globalVars.refreshViewType(cbs.elementAt(i));
              }
            }
          } else {
            if (this.m_fetchImages[sImageUrl]) {
              let cbAlready = this.m_fetchImages[sImageUrl];
              len = cbs.size();
              for (let z = 0; z < len; z++) {
                if (!cbAlready.contains(cbs.elementAt(z))) {
                  cbAlready.add(cbs.elementAt(z));
                }
              }
            } else {
                let sbUrl = new StringBuffer();
                sbUrl.append(BBUtils.C_GEOMETRY_PLAIN);
                sbUrl.append(AsyncFullSizeImageCache.C_100PERCENT);
                sbUrl.append(sImageUrl);
                this.m_fetchImages[sImageUrl] = cbs;
                sbUrl.append("\n");
                sb.append(BBUtils.UrlEncode(sbUrl.toString()));
            }
          }
        }
        this.m_urlsNeeded = {};
        this.asyncPostImage = null;
        this.mPostImageFails = 0;
        if (sb.length() > BBUtils.C_IMG_EQUAL.length) {
          let spdata = sb.toString();
          this.mPostChecksum = crc32(spdata);
          this.asyncPostImage = new AsyncPostImage(global.globalVars.getBatchService(), spdata, AsyncPostImage.AsyncFullSizeCacheType);
          global.globalVars.getImageLoader().send(this.asyncPostImage)
            .then((data) => ({data}))
            .catch((error) => ({error}))
            .then(({data, error}) => {
                if (data != null) {
                  this.handlePostReturn(data);
                }
            });
        }
        sb = null;
      }
    }
  }
  var makeCRCTable = function(){
    var c;
    var crcTable = [];
    for(var n =0; n < 256; n++){
        c = n;
        for(var k =0; k < 8; k++){
            c = ((c&1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
        }
        crcTable[n] = c;
    }
    return crcTable;
}

var crc32 = function(str) {
    var crcTable = window.crcTable || (window.crcTable = makeCRCTable());
    var crc = 0 ^ (-1);

    for (var i = 0; i < str.length; i++ ) {
        crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xFF];
    }

    return (crc ^ (-1)) >>> 0;
};

  AsyncFullSizeImageCache.C_MAX_PER_CACHE = 50;
  AsyncFullSizeImageCache.C_100PERCENT = '100%/';
  AsyncFullSizeImageCache.isImageCachedGeneric = (cache, sUrl) => {
    let img = null;
    let len = cache.size();
    for (let i = 0; i < len; i++) {
      img = cache.elementAt(i);
      if (img.containsUrl(sUrl)) {
        return img;
      }
    }
    return null;
  };
  AsyncFullSizeImageCache.isImageCachedGenericAnySize = (cache, sUrl) => {
    let img = null;
    let len = cache.size();
    for (let i = 0; i < len; i++) {
      img = cache.elementAt(i);
      if (img.containsAnySize(sUrl)) {
        return img;
      }
    }
    return null;
  };
  AsyncFullSizeImageCache.addToGenericCache = (cache, asyncImage) => {
    let img = null;
    let len = cache.size();
    for (let i = 0; i < len; i++) {
      img = cache.elementAt(i);
      if (img.contains(asyncImage)) {
        if (i !== 0) {
          cache.removeElementAt(i);
          cache.insertElementAt(img, 0);
        }
        return undefined;
      }
    }
    if (cache.size() < AsyncFullSizeImageCache.C_MAX_PER_CACHE) {
      cache.insertElementAt(asyncImage, 0);
    } else {
      cache.removeElementAt(cache.size() - 1);
      cache.insertElementAt(asyncImage, 0);
    }
  };
  