export default class AsyncPostImage {
    constructor(url,data, kind) {
      this.sourceUrl = url;
      this.sourceData = data;
      this.sourceKind = kind;
    }
    toString() {
      return JSON.stringify({url: this.sourceUrl, data: this.sourceData, kind: this.sourceKind});
    }
    // abortDownload() - this function is not implementable given the Promise chain used
  }

  AsyncPostImage.AsyncFullSizeCacheType = 0;
  AsyncPostImage.AsyncRegularType = 1;