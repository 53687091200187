import BPSocketConst from "./BPSocketConst";
import DecorNode from "../graphics/DecorNode";
import DynMenuList from "./DynMenuList";
import Vector from "../graphics/Vector";
import BBUtils from "./BBUtils";
import BNumberUtils from "../graphics/BNumberUtils";
import BStatus from "../graphics/BStatus";
import HashMap from "../util/HashMap";
import TBItem from './TBItem';
import BImageSpec from "../graphics/BImageSpec";
import Character from "../graphics/Character";
import StringBuffer from "../graphics/StringBuffer";
import BBMaps from "./BBMaps";
import ArrayList from '../util/ArrayList';
import MenuSection from "./MenuSection";
import { Linking } from "react-native";
import CCallLog from "./CCallLog";
import {LatLng, TropareMapItem } from './TropareMaps';
import CMenuAdapterUtils from "../util/CMenuAdapterUtils";
import BPHttpTask from "./BPHttpTask";
import CCookieRepository from "./CCookieRepository";

const R = {
    string: {
        C_NOTHING_TO_MAP: "Nothing to map, insufficient information",
        C_YOU_LOCATION_LABEL: "You",
        C_START_LOCATION_LABEL: "Start",
        C_ERROR_PLACING_CALL: "Unable to place a call.",
        C_ERROR_SENDING_EMAIL: "Unable to compose email.",
        C_ERROR_SENDING_SMS: "Unable to compose SMS.",
        type_here: "type here",
        C_ERROR_UNABLE_URL: "Don't know how to open: ",
        CMD_GOTO_HOME: "Home",
        C_NO_MAPS: "Unable to start mapping",
    },
    drawable: {
        menu_home: "home_nav_000000.png",
    }
}
export default class CMenuAdapter {
    constructor(ctx, app) {
        this.ctx = ctx;
        this.appClass = app;
        this.m_arKeyPhrases = null;
        this.m_arColumns = null;
        this.m_iListLen = 0;
        this.m_iSelected = -1;
        this.menuGuard = 0;
        this.images = null;
        this._dynamicMenuList = [];

        this.m_decorNodeMisc = null;

        this.m_mapImageId = Number.MAX_SAFE_INTEGER;
        this.m_mapImageDefaultUrl = null;
        this.m_tintedImages = null;
        this.m_mapTintDefaultFormat = null;

        this.m_numMenuItems = 0;

        this._imgUrlRightButton = null;
        this._imgUrlLeftButton = null;      //2022-08-22 attempt at image for back button
        this._imgUrlTitleSearchButton = null;
        // 2022-08-17 this._rightButton = null;
        this._rightButtonType = CMenuAdapter.B_RIGHTBUTTON_REGULAR;
        this.rbType = 0;
        this.lbType = 0;

        this._tbItems = null;
        this._toolbarEnabled = false;
        this._ixClick = -1;

        this.rowHints = null;

        this._searchCenterLabel = null;
        this._searchCenter = null;

//        this.m_arTextRows = new ArrayList();
//        this.m_arImageRows = new ArrayList();
        this.m_arHTMLRows = new ArrayList();
        this.m_arHScrollRows = new ArrayList();
        this.m_decorNodeItems = []
        this.m_decorNodeItemsString = []
//        this.m_decorNodeItemString = null;
        // Not Used on Web
        this._returnToAppUrl = null;
        this._returnToAppMenu = null;

        this.m_backButtonText = 'Back';

        this._origRowHintsText = '';
        this._origDecorNodeItem = '';
        this._origDecorNodeScreen = '';
        this._origDecorNodeSeparator = '';
        this._origDecorNodeTitle = '';
        this._origDecorNodeMisc = '';

    }
    getString(s) {
        return s;
    }
    getIcon = (s) => {
        // img... + s;
        return global.globalVars.getRemoteImagePath() + s;
    }
    showErrorMessage(s) {
        this.appClass.showErrorMessage(s);
    }
    showMessage(sMsg) {
        this.appClass.showMessage(sMsg);
    }
    processMisc() {
        let bRemove = false;
        let bSet = false;
        this.m_mapImageId = Number.MAX_SAFE_INTEGER;
        this.m_mapImageDefaultUrl = null;
        this.m_tintedImages = null;
        this.m_mapTintDefaultFormat = null;
        if (this.m_decorNodeMisc !== null) {
            let pn = this.m_decorNodeMisc.findChildNode(DecorNode.C_lowercase_title);
            if (pn !== null) {
                pn = pn.firstChild();
                if (pn !== null && pn.TID().toLowerCase().equals('none')) {
                    bRemove = true;
                }
            }
            pn = this.m_decorNodeMisc.findChildNode(DecorNode.C_lowercase_rburl);
            if (pn !== null) {
                pn = pn.firstChild();
                if (pn !== null) {
                    this.rbType = DecorNode.DECORNODE_TYPE_RIGHTBUTTON;   // 2022-08-18 DecorNode.getFreeType();
                    this.setRightImageButton(pn.TID(), this.rbType);
                }
            }

            bSet = false;
            pn = this.m_decorNodeMisc.findChildNode(DecorNode.C_lowercase_lburl);
            if (pn !== null) {
                pn = pn.firstChild();
                if (pn !== null) {
                    this.lbType = DecorNode.DECORNODE_TYPE_LEFTBUTTON;   // 2022-08-18 DecorNode.getFreeType();
                    this.setLeftImageButton(pn.TID(), this.lbType);
                    bSet = true;
                }
            }
            if (!bSet) {
                this.setLeftImageButton(null);
            }

            bSet = false;
            pn = this.m_decorNodeMisc.findChildNode(DecorNode.C_lowercase_titlesearch);
            if (pn !== null) {
                pn = pn.firstChild();
                if (pn !== null) {
                    this.setTitleSearchImageButton(pn.TID(), DecorNode.DECORNODE_TYPE_TITLESEARCHBUTTON);
                    bSet = true;
                }
            }
            if (!bSet) {
                this.setTitleSearchImageButton(null);
            }

            pn = this.m_decorNodeMisc.findChildNode(DecorNode.C_lowercase_map);
            if (pn !== null) {
                let pnImage = DecorNode.findChildNode(pn, DecorNode.C_lowercase_image);
                if (pnImage !== null) {
                    pn = DecorNode.findChildNode(pnImage, DecorNode.C_lowercase_id);
                    if (pn !== null) {
                        let status = new BStatus(true);
                        let tval = DecorNode.parseIntChild(pn, status);
                        if (status.getStatus()) {
                            this.m_mapImageId = tval;
                        }
                    }
                    pn = DecorNode.findChildNode(pnImage, DecorNode.C_lowercase_default);
                    if (pn !== null) {
                        pn = DecorNode.findChildNode(pn, DecorNode.C_lowercase_url);
                        if (pn !== null) {
                            let sId = DecorNode.parseUrlNode(pn);
                            if (sId.startsWith('r:') || sId.startsWith('http')) {
                                this.m_mapImageDefaultUrl = sId;
                            }
                        }
                    }
                    pn = DecorNode.findChildNode(pnImage, DecorNode.C_lowercase_tint);
                    if (pn !== null) {
                        let pnt = DecorNode.findChildNode(pn, DecorNode.C_lowercase_c);
                        if (pnt !== null) {
                            if (pnt.firstChild() !== null) {
                                this.m_mapTintDefaultFormat = pnt.firstChild().TID();
                            }
                        }
                    }
                }
            }
            pn = this.m_decorNodeMisc.findChildNode(DecorNode.C_lowercase_tint);
            if (pn !== null) {
                let kids = pn.getChildren();
                for (let i = 0; i < kids.size(); i++) {
                    let pnChild = kids.elementAt(i);
                    if (pnChild.TID().toLowerCase().equals('image')) {
                        let imgId = Number.MAX_SAFE_INTEGER;
                        let icolor = 0;
                        let pnt = DecorNode.findChildNode(pnChild, DecorNode.C_lowercase_id);
                        if (pnt !== null) {
                            let status = new BStatus(true);
                            let tval = DecorNode.parseIntChild(pnt, status);
                            if (status.getStatus()) {
                                imgId = tval;
                            }
                        }
                        pnt = DecorNode.findChildNode(pnChild, DecorNode.C_lowercase_c);
                        if (pnt !== null) {
                            let status = new BStatus(true);
                            let iv = DecorNode.parseColorNode(pnt, status);
                            if (status.getStatus()) {
                                icolor = iv;
                                if (imgId !== Number.MAX_SAFE_INTEGER) {
                                    if (this.m_tintedImages === null) {
                                        this.m_tintedImages = new HashMap();
                                    }
                                    this.m_tintedImages.put(imgId, icolor);
                                }
                            }
                        }
                    }
                }
            }
        }
        if (bRemove) {
            if (this.appClass.state.hfieldVisible) {
                this.appClass.setHFieldVisible(bRemove);
            }
        } else {
            if (!this.appClass.state.hfieldVisible) {
                this.appClass.setHFieldVisible(bRemove);
            }
        }
    }

    addMenuButton() {
        this.appClass.resetRightButtonImageUrl();
        this._rightButtonType = CMenuAdapter.B_RIGHTBUTTON_REGULAR;
        /*
        if (this._rightButton !== null) {
            let bimg = new BImageSpec(this.ctx, "r:/img/white_nav_menu.png", null, DecorNode.DECORNODE_TYPE_RIGHTBUTTON);
            if (bimg.getImage() !== null) {    
                this._rightButton.setImageResource(R.drawable.white_nav_menu);  // r:/img/white_nav_menu.png
            }
            this._rightButtonType = CMenuAdapter.B_RIGHTBUTTON_REGULAR;
        }
        */
    }
    setLeftImageButton(imgUrl, iType) {
        this._imgUrlLeftButton = imgUrl;
        if (imgUrl === null) {
            this.appClass.setLeftButtonImageUrl(null);
            return undefined;
        }
        if (imgUrl.startsWith(BBUtils.C_RESOURCE_PROTOCOL)) {
            imgUrl = BImageSpec.resourceNameToRemote(this.ctx, imgUrl);
        }
        this.appClass.setLeftButtonImageUrl(imgUrl);
    }

    setTitleSearchImageButton(imgUrl, iType) {
        this._imgUrlTitleSearchButton = imgUrl;
        if (imgUrl === null) {
            this.appClass.setTitleSearchButtonImageUrl(null);
            return undefined;
        }
        if (imgUrl.startsWith(BBUtils.C_RESOURCE_PROTOCOL)) {
            imgUrl = BImageSpec.resourceNameToRemote(this.ctx, imgUrl);
        }

        if (this.appClass.lastTitleSearchChannel == null) {
            this.appClass.setTitleSearchButtonImageUrl(imgUrl);
        }
    }

    localSetRightImageButton(imgUrl, iType) {
        this._imgUrlRightButton = imgUrl;
        if (imgUrl === null) {
            return undefined;
        }
        if (imgUrl.startsWith(BBUtils.C_RESOURCE_PROTOCOL)) {
            imgUrl = BImageSpec.resourceNameToRemote(this.ctx, imgUrl);
        }
        this.appClass.setRightButtonImageUrl(imgUrl);
        
        /* old relying on refresh of image control
        let bimg = new BImageSpec(this.ctx, imgUrl, null, iType);
        if (bimg.getImage() !== null) {
            if (this._rightButton !== null) {   // _rightButton is a 'View' TODO:
                this._rightButton.setImageBitmap(bimg.getImage());
            }
        }
        */
    }

    setRightImageButton(imgUrl, iType) {
        this.localSetRightImageButton(imgUrl, iType);
    }
    addRightButtonSingleAction(imgUrl, iType) {
        this.setRightImageButton(imgUrl, iType);
        this._rightButtonType = CMenuAdapter.B_RIGHTBUTTON_SINGLE;
    }
    refreshRightbuttonSingleAction() {
        this.localSetRightImageButton(this._imgUrlRightButton, this.rbType);
    }
    menuClicked = () => {
        if (this._rightButtonType == CMenuAdapter.B_RIGHTBUTTON_SINGLE) {
            for (let ix=0; ix<this._dynamicMenuList.length; ix++) {
                if (this._dynamicMenuList[ix].isRightButtonSingleAction()) {
                    this.dynSelectList(ix);
                    break;
                }
            }
        } else {
            this.openOptionsMenuManually(true);
        }
    }
    refreshRowHints() {
        this.rowHints = CMenuAdapterUtils.setupRowHints(this._origRowHintsText);

        this.appClass.setDelayRender(true);
        this.setupCalcLines(false);
        let dindexes = [];
        for (let i=0; i<this.m_iListLen; i++) {
            dindexes.push({key:i, index:i});
        }
        this.appClass.setDataIndexes(dindexes);
        this.appClass.setDelayRender(false);
    }

    changeRowHints(sRowHintsText) {
        this.rowHints = CMenuAdapterUtils.setupRowHints(sRowHintsText);
    }
    changeDecorNodeItem(sdnItem) {
        this.appClass.setDecorNodeItemString(sdnItem);
//        this.m_decorNodeItemString = sdnItem;
    }
    changeDecorNodeTitle(sdnTitle) {
        this.appClass.setDecorNodeTitleString(sdnTitle);
    }
    changeDecorNodeScreen(sdnScreen) {
        this.appClass.setDecorNodeScreenString(sdnScreen);
    }
    changeDecorNodeSeparator(sdnSeparator) {
        this.appClass.setDecorNodeSeparatorString(sdnSeparator);
    }
    changeDecorNodeMisc(sdnMisc) {
        if (this.m_decorNodeMisc !== null) {
            this.m_decorNodeMisc.clear();
        }
        this.m_decorNodeMisc = null;
        this.m_decorNodeMisc = DecorNode.FromAttributes(sdnMisc, DecorNode.DECORNODE_TYPE_UNDEFINED);
    }
    processToolbarUI() {
        if (this._tbItems.size() > 0) {
            this.appClass.setToolbarItems(this._tbItems);
            this._toolbarEnabled = true;
        } else {
            this.appClass.setToolbarItems(null);
            this._toolbarEnabled = false;
        }
    }
    checkAction(ix, arColumns) {
        let bok = true;
        let sAction = this._dynamicMenuList[ix].getAction();
        if (sAction.length > 0) {
            let ch = '';
            let i = 0;
            while (bok && i < sAction.length) {
                ch = sAction.charAt(i);
                if (ch === '$') {
                    let bOptional = false;
                    let bDontAllowParam = false;
                    i++;
                    if (i < sAction.length && sAction.charAt(i) === '?') {
                        bOptional = true;
                        i++;
                    } else {
                        if (i < sAction.length && sAction.charAt(i) === '!') {
                            bDontAllowParam = true;
                            i++;
                        }
                    }
                    if (i < sAction.length && sAction.charAt(i) === '%') {
                        i++;
                    }
                    if (i < sAction.length && Character.isDigit(sAction.charAt(i))) {
                        let iarg = 0;
                        while (i < sAction.length && Character.isDigit(sAction.charAt(i))) {
                            ch = sAction.charCodeAt(i);
                            iarg *= 10;
                            iarg += ch - '0'.charCodeAt(0);
                            i++;
                        }
                        if (!bOptional) {
                            if (iarg < arColumns.length) {
                                if (arColumns[iarg] === null || arColumns[iarg].length === 0) {
                                    if (!bDontAllowParam) {
                                        bok = false;
                                    }
                                } else {
                                    if (bDontAllowParam) {
                                        bok = false;
                                    }
                                }
                            } else {
                                if (!bDontAllowParam) {
                                    bok = false;
                                }
                            }
                        }
                    } else {
                        if (i < sAction.length && sAction.charAt(i) === '@') {
                            if (!bOptional) {
                                if (this.appClass.getText().length === 0) {
                                    if (!bDontAllowParam) {
                                        bok = false;
                                    }
                                } else {
                                    if (bDontAllowParam) {
                                        bok = false;
                                    }
                                }
                            }
                            i++;
                        } else {
                            if (i < sAction.length && sAction.charAt(i) === 'p') {
                                if (!bOptional) {
                                    let lat = 0;
                                    let lon = 0;
                                    lat = global.globalVars.getLat();
                                    lon = global.globalVars.getLon();
                                    if (lat === 0.0 && lon === 0.0) {
                                        if (!bDontAllowParam) {
                                            bok = false;
                                        }
                                    } else {
                                        if (bDontAllowParam) {
                                            bok = false;
                                        }
                                    }
                                }
                                i++;
                            } else {
                                if (i < sAction.length && sAction.charAt(i) === 'f') {
                                    if (!bOptional) {
                                        if (global.globalVars.getBPIN().length === 0) {
                                            if (!bDontAllowParam) {
                                                bok = false;
                                            }
                                        } else {
                                            if (bDontAllowParam) {
                                                bok = false;
                                            }
                                        }
                                    }
                                    i++;
                                } else {
                                    if (i < sAction.length && (sAction.charAt(i) === 'w' || sAction.charAt(i) === 'h')) {
                                        i++;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    i++;
                }
            }
        }
        return bok;
    }

    parseDollarArgsOffset(arColumns, sFormat, bMakingUrl, cOffset) {
        let sb = new StringBuffer();
        let bok = true;
        if (sFormat.length > 0) {
            if (arColumns !== null) {
                let ch = '';
                let i = 0;
                while (bok && i < sFormat.length) {
                    ch = sFormat.charAt(i);
                    if (ch === '$') {
                        let bOptional = false;
                        let bDontAllowParam = false;
                        let bUrlEncode = false;
                        let bHavePerc = false;
                        if (bMakingUrl) {
                            bUrlEncode = true;
                        }
                        i++;
                        if (i < sFormat.length && sFormat.charAt(i) === '?') {
                            bOptional = true;
                            i++;
                        } else {
                            if (i < sFormat.length && sFormat.charAt(i) === '!') {
                                bDontAllowParam = true;
                                i++;
                            }
                        }
                        if (i < sFormat.length && sFormat.charAt(i) === '%') {
                            bHavePerc = true;
                            bUrlEncode = false;
                            i++;
                        }
                        if (i < sFormat.length && Character.isDigit(sFormat.charAt(i))) {
                            let iarg = 0;
                            while (i < sFormat.length && Character.isDigit(sFormat.charAt(i))) {
                                ch = sFormat.charCodeAt(i);
                                iarg *= 10;
                                iarg += ch - '0'.charCodeAt(0);
                                i++;
                            }
                            iarg -= cOffset;
                            if (iarg < arColumns.length && arColumns[iarg] !== null && arColumns[iarg].length !== 0) {
                                if (bDontAllowParam) {
                                    bok = false;
                                } else {
                                    if (bUrlEncode) {
                                        sb.append(BBUtils.UrlEncode(BBUtils.Utf8Encode(arColumns[iarg])));
                                    } else {
                                        if (bMakingUrl) {
                                            sb.append(BBUtils.Utf8Encode(arColumns[iarg]));
                                        } else {
                                            sb.append(arColumns[iarg]);
                                        }
                                    }
                                }
                                if (bok && bOptional) {
                                    if (i < sFormat.length && sFormat.charAt(i) === '?') {
                                        bok = false;
                                        i++;
                                        if (i < sFormat.length && sFormat.charAt(i) === '$') {
                                            i++;
                                            while (i < sFormat.length && sFormat.charAt(i) !== '$') {
                                                i++;
                                            }
                                            if (i < sFormat.length) {
                                                i++;
                                                bok = true;
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (bOptional) {
                                    if (i < sFormat.length && sFormat.charAt(i) === '?') {
                                        bok = false;
                                        i++;
                                        if (i < sFormat.length && sFormat.charAt(i) === '$') {
                                            i++;
                                            let ixStart = i;
                                            while (i < sFormat.length && sFormat.charAt(i) !== '$') {
                                                i++;
                                            }
                                            if (i < sFormat.length) {
                                                sb.append(sFormat.substring(ixStart, i));
                                                i++;
                                                bok = true;
                                            }
                                        }
                                    }
                                } else {
                                    if (!bOptional && !bDontAllowParam) {
                                        bok = false;
                                    }
                                }
                            }
                        } else {
                            if (i < sFormat.length && sFormat.charAt(i) === '@') {
                                if (this.appClass.getText().length > 0) {
                                    if (bDontAllowParam) {
                                        bok = false;
                                    } else {
                                        if (bUrlEncode) {
                                            sb.append(BBUtils.UrlEncode(BBUtils.Utf8Encode(this.appClass.getText())));
                                        } else {
                                            if (bMakingUrl) {
                                                sb.append(BBUtils.Utf8Encode(this.appClass.getText()));
                                            } else {
                                                sb.append(this._cEditText.getText());
                                            }
                                        }
                                    }
                                } else {
                                    if (!bOptional && !bDontAllowParam) {
                                        bok = false;
                                    }
                                }
                                i++;
                            } else {
                                if (i < sFormat.length && sFormat.charAt(i) === 'p') {
                                    let lat = 0;
                                    let lon = 0;
                                    lat = global.globalVars.getLat();
                                    lon = global.globalVars.getLon();
                                    if ((!(lat === 0.0 && lon === 0.0))) {
                                        if (!bDontAllowParam) {
                                            sb.append(lat + ';' + lon);
                                            bok = true;
                                        }
                                    } else {
                                        if (!bOptional && !bDontAllowParam) {
                                            bok = false;
                                        }
                                    }
                                    i++;
                                } else {
                                    if (i < sFormat.length && sFormat.charAt(i) === 'f') {
                                        if (global.globalVars.getBPIN().length !== 0) {
                                            if (!bDontAllowParam) {
                                                sb.append(global.globalVars.getBPIN());
                                                bok = true;
                                            }
                                        } else {
                                            if (!bOptional && !bDontAllowParam) {
                                                bok = false;
                                            }
                                        }
                                        i++;
                                    } else {
                                        if (i < sFormat.length && sFormat.charAt(i) === '$') {
                                            sb.append('$');
                                            i++;
                                        } else {
                                            if (i < sFormat.length && sFormat.charAt(i) === 'w') {
                                                sb.append(this.appClass.getDisplayWidth());
                                                i++;
                                            } else {
                                                if (i < sFormat.length && sFormat.charAt(i) === 'h') {
                                                    sb.append(this.appClass.getDisplayHeight());
                                                    i++;
                                                } else {
                                                    sb.append('$');
                                                    if (bOptional) {
                                                        sb.append('?');
                                                    }
                                                    if (bDontAllowParam) {
                                                        sb.append('!');
                                                    }
                                                    if (bHavePerc) {
                                                        sb.append('%');
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        sb.append(ch);
                        i++;
                    }
                }
            }
        }
        if (!bok) {
            return BBUtils.C_EMPTY_STRING;
        } else {
            return sb.toString();
        }
    }
    parseDollarArgs(arColumns, sFormat, bMakingUrl) {
        return this.parseDollarArgsOffset(arColumns, sFormat, bMakingUrl, 0);
    }

        // Go thru: _dynamicMenuList and pull out any toolbar tabs
	// look for: getButton()="TB", then use the getText() for the Tooltip
	// we only allow non-item B-Menu-Entry values (e.g. 'C') for toolbar buttons
    processToolbar(sToolbar) {
        let ix;
        let sText;
        let bok;

        // because you have to fiddle with the elements in the UI thread, we
        // squirrel them away and take action on them
        // in the UI thread. Mainly, we have to do this because if we are in a
        // channel, and someone types something, we are int
        // the same channel. The items would 'add' on top of each other, so we
        // need to delete the previous ones
        // to do this without flashing the UI, we batch all changes
        if (this._tbItems != null) {
            this._tbItems.removeAllElements();
            this._tbItems = null;
        }
        this._tbItems = new Vector();

        this.appClass.setDecorNodeToolbarString(sToolbar);

        let arColumns = [];
        arColumns.push(this.appClass.getIncrementalBaseUrl());

        // Now, check if we have any B-Menu-Engtry: header items here. If so,
        // add the context menu
        for (let ix = 0; ix < this._dynamicMenuList.length; ix++) {
            bok = false;
            let sButton = this._dynamicMenuList[ix].getButton();
            if (sButton != null && sButton.equals("TB")) // "TB"
            {
                sText = this._dynamicMenuList[ix].getText();
                if (sText.length > 0) {
                    bok = this.checkAction(ix, arColumns);

                    if (bok) {
                        sText = this.parseDollarArgs(arColumns, sText, false);

                        if (sText.length > 0) {
                            if (sText.equals("nil"))
                                sText = "";

                            // ix = selected menu, sText = tooltip
                            // on select, call: dynSelect(ix);
                            let bEnabled = true;
                            let sEnabled = this._dynamicMenuList[ix].getFirstExtra();
                            if (sEnabled != null && sEnabled.length > 0) {
                                if (sEnabled.toLowerCase().equals("disable"))
                                    bEnabled = false;
                            }

                            let imgSrc = this._dynamicMenuList[ix].getSecondExtra();

                            let tbi = new TBItem(imgSrc, sText, ix, bEnabled);
                            if (sEnabled != null && sEnabled.length > 0) {
                                if (sEnabled.toLowerCase().equals("active"))
                                    tbi.setActive(true);
                            }

                            this._tbItems.addElement(tbi);
                        }
                    }
                }
            }
        }

        this.processToolbarUI();
    }
    clearImages() {
        if (this.images !== null) {
            this.images.removeAllElements();
        }
        this.images = null;
        this.images = new Vector();
    }

    Clear() {
        this.m_arKeyPhrases = null;
        this.m_arColumns = null;
        this.m_iListLen = 0;
    }
    resetBackButtonToDefault() {
        this.m_backButtonText = "Back";
     }
     updateBackButtonText = () => {
        this.appClass.setBackButtonText(this.m_backButtonText);
     }

    resetTextEditToDefault() {
        this.appClass.setTitleVisible(false);
        this.appClass.setInputVisible(true);
    }
    resetTextEditPlaceholder() {
        this.appClass.setHintText(this.getString(R.string.type_here));
    }
    SetRowColorInfo(iRow) {
        let ix = 0;
        let aLen = 0;
        let ch = '';
        if (this.m_arKeyPhrases[iRow] === null) {
          return undefined;
        }
        aLen = this.m_arKeyPhrases[iRow].length;
        if (aLen === 0) {
          return undefined;
        }
        ch = this.m_arKeyPhrases[iRow].charAt(ix);
        if (ch === '\\') {
          ix++;
          if (ix < aLen) {
            ch = this.m_arKeyPhrases[iRow].charAt(ix);
            if (ch === '\\') {
              this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(1);
            } else {
              if (ch === '#') {
                this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(1);
              }
            }
          }
        } else {
          if (ch === '#') {
            ix++;
            let ixStart = ix;
            let a = 0;
            let r = 0;
            let g = 0;
            let b = 0;
            let bOk = true;
            let i = 0;
            let okLoop = false;
            if (ix >= aLen) {
              return undefined;
            }
            okLoop = true;
            while (ix < aLen && okLoop && i < 8) {
              ch = this.m_arKeyPhrases[iRow].charAt(ix);
              if (!DecorNode.isHex(ch)) {
                okLoop = false;
              } else {
                ix++;
                i++;
              }
            }
            if (i === 3) {
              r = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart));
              g = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 1));
              b = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 2));
              r = (r * 255) / 15;
              g = (g * 255) / 15;
              b = (b * 255) / 15;
              this.m_htForeColor[iRow] = (0x0FF000000 | (r << 16) | (g << 8) | b) >>> 0;
              this.SetHasForeColor(iRow);
              this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(4);
            } else {
              if (i === 4) {
                a = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart));
                r = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 1));
                g = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 2));
                b = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 3));
                a = (a * 255) / 15;
                r = (r * 255) / 15;
                g = (g * 255) / 15;
                b = (b * 255) / 15;
                this.m_htForeColor[iRow] = ((a << 24) | (r << 16) | (g << 8) | b) >>> 0;
                this.SetHasForeColor(iRow);
                this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(5);
              } else {
                if (i === 6) {
                  r = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart)) * 16;
                  r += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 1));
                  g = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 2)) * 16;
                  g += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 3));
                  b = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 4)) * 16;
                  b += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 5));
                  this.m_htForeColor[iRow] = (0x0FF000000 | (r << 16) | (g << 8) | b) >>> 0;
                  this.SetHasForeColor(iRow);
                  this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(7);
                } else {
                  if (i === 8) {
                    a = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart)) * 16;
                    a += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 1));
                    r = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 2)) * 16;
                    r += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 3));
                    g = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 4)) * 16;
                    g += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 5));
                    b = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 6)) * 16;
                    b += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 7));
                    this.m_htForeColor[iRow] = ((a << 24) | (r << 16) | (g << 8) | b) >>> 0;
                    this.SetHasForeColor(iRow);
                    this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(9);
                  } else {
                    bOk = false;
                  }
                }
              }
            }
            if (bOk) {
              aLen = this.m_arKeyPhrases[iRow].length;
              ix = 0;
              if (ix < aLen) {
                ch = this.m_arKeyPhrases[iRow].charAt(ix);
                if (ch === ' ') {
                    this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(1);
                } else {
                  if (ch === '\\') {
                    ix++;
                    if (ix < aLen) {
                      ch = this.m_arKeyPhrases[iRow].charAt(ix);
                      if (ch === '\\') {
                        this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(1);
                      } else {
                        if (ch === '#') {
                            this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(1);
                        }
                      }
                    }
                  } else {
                    if (ch === '#') {
                        ix++;
                        ixStart = ix;
                        bOk = true;
                        i = 0;
                        if (ix >= aLen) {
                          return undefined;
                        }
                        okLoop = true;
                        while (ix < aLen && okLoop && i < 8) {
                          ch = this.m_arKeyPhrases[iRow].charAt(ix);
                          if (!DecorNode.isHex(ch)) {
                            okLoop = false;
                          } else {
                            ix++;
                            i++;
                          }
                        }
                        if (i === 3) {
                          r = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart));
                          g = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 1));
                          b = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 2));
                          r = (r * 255) / 15;
                          g = (g * 255) / 15;
                          b = (b * 255) / 15;
                          this.m_htBackColor[iRow] = (CMenuAdapter.CI_BG_COLOR_ALPHA | (r << 16) | (g << 8) | b) >>> 0;
                          this.SetHasBackColor(iRow);
                          this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(4);
                        } else {
                          if (i === 4) {
                            a = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart));
                            r = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 1));
                            g = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 2));
                            b = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 3));
                            a = (a * 255) / 15;
                            r = (r * 255) / 15;
                            g = (g * 255) / 15;
                            b = (b * 255) / 15;
                            this.m_htBackColor[iRow] = ((a << 24) | (r << 16) | (g << 8) | b) >>> 0;
                            this.SetHasBackColor(iRow);
                            this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(5);
                          } else {
                            if (i === 6) {
                              r = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart)) * 16;
                              r += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 1));
                              g = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 2)) * 16;
                              g += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 3));
                              b = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 4)) * 16;
                              b += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 5));
                              this.m_htBackColor[iRow] =  (CMenuAdapter.CI_BG_COLOR_ALPHA | (r << 16) | (g << 8) | b) >>> 0;
                              this.SetHasBackColor(iRow);
                              this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(7);
                            } else {
                              if (i === 8) {
                                a = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart)) * 16;
                                a += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 1));
                                r = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 2)) * 16;
                                r += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 3));
                                g = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 4)) * 16;
                                g += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 5));
                                b = DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 6)) * 16;
                                b += DecorNode.cvtHex(this.m_arKeyPhrases[iRow].charAt(ixStart + 7));
                                this.m_htBackColor[iRow] = ((a << 24) | (r << 16) | (g << 8) | b) >>> 0;
                                this.SetHasBackColor(iRow);
                                this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(9);
                              } else {
                                bOk = false;
                            }
                        }
                      }
                    }
                    if (bOk) {
                      aLen = this.m_arKeyPhrases[iRow].length;
                      ix = 0;
                      if (ix >= aLen) {
                        return undefined;
                      }
                      ch = this.m_arKeyPhrases[iRow].charAt(ix);
                      if (ch === ' ') {
                        this.m_arKeyPhrases[iRow] = this.m_arKeyPhrases[iRow].substring(1);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }    
    }  
    // Accessory
    ClearAccessory(i) {
        this.m_arFlags[i] &= ~CMenuAdapter.MLI_ACCESSORY_MASK;
    }
    HasAccessory(i) {
        if ((this.m_arFlags[i] & CMenuAdapter.MLI_ACCESSORY_MASK) !== 0) {
            if (this.appClass.getSuppressAccessoryColumn() !== -1 && this.m_arColumns[i].length > this.appClass.getSuppressAccessoryColumn() && this.m_arColumns[i][this.appClass.getSuppressAccessoryColumn()].length > 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    SetAccessoryChevron(i) {
        this.m_arFlags[i] |= CMenuAdapter.MLI_HAS_CHEVRON;
    }
    HasChevron(i) {
        if ((this.m_arFlags[i] & CMenuAdapter.MLI_HAS_CHEVRON) !== 0) {
            if (this.appClass.getSuppressAccessoryColumn() !== -1 && this.m_arColumns[i].length > this.appClass.getSuppressAccessoryColumn() && this.m_arColumns[i][this.appClass.getSuppressAccessoryColumn()].length > 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    SetAccessoryBlueChevron(i) {
        this.m_arFlags[i] |= CMenuAdapter.MLI_HAS_BLUECHEVRON;
    }
    HasBlueChevron(i) {
        if ((this.m_arFlags[i] & CMenuAdapter.MLI_HAS_BLUECHEVRON) !== 0) {
            return true;
        } else {
            return false;
        }
    }
    // ForeColor
    SetHasForeColor(i) {
        this.m_arFlags[i] |= CMenuAdapter.MLI_HAS_FORECOLOR;
    }
    HasForeColor(i) {
        if ((this.m_arFlags[i] & CMenuAdapter.MLI_HAS_FORECOLOR) !== 0) {
            return true;
        } else {
            return false;
        }
    }
    // BackColor
    SetHasBackColor(i) {
        this.m_arFlags[i] |= CMenuAdapter.MLI_HAS_BACKCOLOR;
    }
    HasBackColor(i) {
        if ((this.m_arFlags[i] & CMenuAdapter.MLI_HAS_BACKCOLOR) !== 0) {
            return true;
        } else {
            return false;
        }
    
    }
    GetForeColor(i) {
        return this.m_htForeColor[i];
    } 
    GetBackColor(i) {
        return this.m_htBackColor[i];
    }

    SetHasPipe(i) {
        this.m_arFlags[i] |= CMenuAdapter.MLI_HAS_PIPE;
    }
    ClearHasPipe(i) {
        this.m_arFlags[i] &= ~CMenuAdapter.MLI_HAS_PIPE;
    }
    hasClick() {
        return this._ixClick !== -1;
    }
    getClick() {
        return this._ixClick;
    }
    setupRowInfoFrom_arColumns(i) {
        if (this.m_arColumns[i].length === 0) {
            this.m_arColumns[i] = new Array(1);
            this.m_arColumns[i][0] = BBUtils.C_EMPTY_STRING;
        }
        this.m_arFlags[i] = 0;
        this.m_arKeyPhrases[i] = this.m_arColumns[i][CMenuAdapter.IX_KEYPHRASE];
        this.SetRowColorInfo(i);
        this.m_arColumns[i][0] = this.m_arKeyPhrases[i];
        if (this.m_arKeyPhrases[i].indexOf('|') !== -1) {
            this.SetHasPipe(i);
        } else {
            this.ClearHasPipe(i);
        }
    }
    FillMenuSections(msl, arColumns, bListActive, bBlockBoth) {
        let ix = 0;
        let sText = null;
        let context = '';
        let bok = false;
        for (ix = 0; ix < this._dynamicMenuList.length; ix++) {
            bok = false;
            context = this._dynamicMenuList[ix].getContext();
            let sButton = this._dynamicMenuList[ix].getButton();
            switch (context) {
                case 'C':
                    if (!bListActive) {
                        bok = true;
                    } else {
                        if (sButton !== null) {
                            if (sButton.equals(CMenuAdapter.C_TB)) {
                                bok = true;
                            }
                        }
                    };
                    break;
                case 'I':
                    if (bListActive) {
                        bok = true;
                    };
                    break;
                case 'B':
                    if (!bBlockBoth) {
                        bok = true;
                    };
                    break;
            }
            if (sButton !== null) {
                if (sButton.equals(CMenuAdapter.C_IMAGE)) {
                    if (!global.globalVars.HideImages()) {
                        bok = false;
                    }
                } else if (sButton.equals(CMenuAdapter.C_HTML) || 
                            sButton.equals(BPSocketConst.C_CLICK) || 
                            sButton.equals(BPSocketConst.C_TB) || 
                            sButton.equals(CMenuAdapter.C_OVERLAY)) {
                        bok = false;
                }
            }
            if (bok) {
                sText = this._dynamicMenuList[ix].getText();
                if (sText.length > 0) {
                    bok = this.checkAction(ix, arColumns);
                    if (bok) {
                        sText = this.parseDollarArgs(arColumns, sText, false);
                        if (sText.length > 0) {
                            let ms = new MenuSection(sText, ix);
                            msl.addElement(ms);
                        }
                    }
                }
            }
        }
    }
    getTBItems() {
        return this._tbItems;
    }
    clickValid(arColumns) {
        let ix = 0;
        let sText = null;
        let context = '';
        let bok = false;
        for (ix = 0; ix < this._dynamicMenuList.length; ix++) {
            bok = false;
            context = this._dynamicMenuList[ix].getContext();
            let sButton = this._dynamicMenuList[ix].getButton();
            if (context === 'I' && sButton !== null && sButton.equals(BPSocketConst.C_CLICK)) {
                sText = this._dynamicMenuList[ix].getText();
                if (sText.length > 0) {
                    bok = this.checkAction(ix, arColumns);
                    if (bok) {
                        sText = this.parseDollarArgs(arColumns, sText, false);
                        if (sText.length > 0) {
                            bok = true;
                        } else {
                            bok = false;
                        }
                    }
                }
                return bok;
            }
        }
        return false;
    }
    getClickActionForRow(arColumns) {
        let ix = 0;
        let sText = null;
        let context = '';
        let bok = false;
        for (ix = 0; ix < this._dynamicMenuList.length; ix++) {
            bok = false;
            context = this._dynamicMenuList[ix].getContext();
            let sButton = this._dynamicMenuList[ix].getButton();
            if (context === 'I' && sButton !== null && sButton.equals(BPSocketConst.C_CLICK.C_CLICK)) {
                sText = this._dynamicMenuList[ix].getText();
                if (sText.length > 0) {
                    bok = this.checkAction(ix, arColumns);
                    if (bok) {
                        sText = this.parseDollarArgs(arColumns, sText, false);
                        if (sText.length > 0) {
                            return sText;
                        }
                    }
                }
            }
        }
        return null;
    }
    setAccessoryType(i) {
        let iMenuItems = 0;
        let arColumns = new Array(this.m_arColumns[i].length + 1);
        arColumns[0] = this.appClass.getIncrementalBaseUrl();
        for (let j = 0; j < this.m_arColumns[i].length; j++) {
            arColumns[j + 1] = this.m_arColumns[i][j];
        }
        let msl = new Vector();
        this.FillMenuSections(msl, arColumns, true, true);
        let bClickValid = this.clickValid(arColumns);
        arColumns = null;
        if (msl.size() > 0) {
            iMenuItems += msl.size();
            msl.removeAllElements();
        }
        msl = null;
        let iMenuToDo = 0;
        if (bClickValid || (this.m_arColumns[i].length > CMenuAdapter.IX_URL && this.m_arColumns[i][CMenuAdapter.IX_URL].length > 0)) {
            if (iMenuItems === 0) {
                iMenuToDo = 1;
            } else {
                if (iMenuItems > 0) {
                    iMenuToDo = 2;
                }
            }
        } else {
            if (!this.hasClick() && iMenuItems === 1) {
                iMenuToDo = 1;
            } else {
                if (iMenuItems > 0) {
                    iMenuToDo = 2;
                }
            }
        }
        if (iMenuToDo === 0) {
            this.ClearAccessory(i);
        } else {
            if (iMenuToDo === 1) {
                this.SetAccessoryChevron(i);
            } else {
                this.SetAccessoryBlueChevron(i);
            }
        }
    }
    setupCalcLines(bAllNewData) {
        this.m_arLines = new Array(this.m_arKeyPhrases.length);
        this.m_arSubLines = null;
        this.m_arSubLineWidths = null;
        this.m_arMarginsTop = null;
        this.m_arMarginsBottom = null;
        this.m_arVAlign = null;
        this.m_arRowHintsTotalColumns = 0;
        this.m_decorNodeItems = new Array(this.m_arKeyPhrases.length);
        BBUtils.setAllArray(this.m_decorNodeItems, null);
        this.m_decorNodeItemsString = new Array(this.m_arKeyPhrases.length);
        BBUtils.setAllArray(this.m_decorNodeItemsString, null);
        if (this.rowHints !== null && !global.globalVars.HideImages()) {
            this.m_arSubLines = new Array(this.m_arKeyPhrases.length * this.rowHints.length);
            for (let iRow = 0; iRow < this.rowHints.length; iRow++) {
                this.m_arRowHintsTotalColumns += this.rowHints[iRow].columns.length;
            }
            this.m_arSubLineWidths = new Array(this.m_arKeyPhrases.length * this.m_arRowHintsTotalColumns);
            this.m_arMarginsTop = new Array(this.m_arRowHintsTotalColumns);
            this.m_arMarginsBottom = new Array(this.m_arRowHintsTotalColumns);
            this.m_arVAlign = new Array(this.m_arRowHintsTotalColumns);
        } else {
            this.m_arMarginsTop = new Array(1);
            this.m_arMarginsBottom = new Array(1);
            this.m_arVAlign = new Array(1);
        }
        this.m_arRowWidthMultiplier = new Array(this.m_arKeyPhrases.length);
        this.clearRows(bAllNewData);
//        this.m_arTextRows = new ArrayList(this.m_arKeyPhrases.length);
//        this.m_arImageRows = new ArrayList(this.m_arKeyPhrases.length);
        if (bAllNewData) {
            this.m_arHTMLRows = new ArrayList(this.m_arKeyPhrases.length);
        } else {
            for (let ixRow = 0; ixRow < this.m_arHTMLRows.size(); ixRow++) {
                let v = this.m_arHTMLRows.get(ixRow);
                if (v !== null) {
                    for (let i = 0; i < v.size(); i++) {
                        let hr = v.get(i);
                        if (hr.getWebView() !== null) {
                            //TODO: clone hr.getWebView().cloneNode(), change ID
                            // setWebView(clone);
                            /*
                            if (hr.getWebView().getParent() !== null) {
                                (hr.getWebView().getParent()).removeView(hr.getWebView());
                            }
                            */
                        }
                    }
                }
            }
        }
        if (bAllNewData) {
            this.m_arHScrollRows = new ArrayList(this.m_arKeyPhrases.length);
        } else {
            for (let ixRow = 0; ixRow < this.m_arHScrollRows.size(); ixRow++) {
                let v = this.m_arHScrollRows.get(ixRow);
                if (v !== null) {
                    for (let i = 0; i < v.size(); i++) {
                        let hr = v.get(i);
                        if (hr.getHScrollView() !== null) {
                            //TODO: clone hr.getWebView().cloneNode(), change ID
                        }
                    }
                }
            }
        }
//        this.m_arLinesOffset = new Array(this.m_arKeyPhrases.length);
        this.m_bPipes = false;
        for (let i = 0; i < this.m_arKeyPhrases.length; i++) {
//            this.m_arTextRows.add(i, new ArrayList());
//            this.m_arImageRows.add(i, new ArrayList());
            if (bAllNewData) {
                this.m_arHTMLRows.add(i, new ArrayList());
                this.m_arHScrollRows.add(i, new ArrayList());
            }
            if (this.m_arKeyPhrases[i].indexOf('|') !== -1) {
                this.SetHasPipe(i);
                this.m_bPipes = true;
            } else {
                this.ClearHasPipe(i);
            }
            this.setAccessoryType(i);
        }
        this.m_loadedImages = false;
    }

    clearRows(bAllNewData) {
        /*
        if (this.m_arTextRows !== null) {
            for (let i = 0; i < this.m_arTextRows.size(); i++) {
                let v = this.m_arTextRows.get(i);
                if (v !== null) {
                    v.clear();
                }
                this.m_arTextRows.set(i, null);
            }
            this.m_arTextRows = null;
        }
        if (this.m_arImageRows !== null) {
            for (let i = 0; i < this.m_arImageRows.size(); i++) {
                let v = this.m_arImageRows.get(i);
                if (v !== null) {
                    v.clear();
                }
                this.m_arImageRows.set(i, null);
            }
            this.m_arImageRows = null;
        }
        */
        if (bAllNewData) {
            if (this.m_arHTMLRows !== null) {
                for (let i = 0; i < this.m_arHTMLRows.size(); i++) {
                    let v = this.m_arHTMLRows.get(i);
                    if (v !== null) {
                        for (let j = 0; j < v.size(); j++) {
                            let hr = v.get(j);
                            if (hr !== null) {
                                if (hr.getWebView() !== null) {
                                    //TODO: ? what
                                    //(hr.getWebView().getParent()).removeView(hr.getWebView());
                                }
                                hr.setWebView(null);
                            }
                        }
                        v.clear();
                    }
                    this.m_arHTMLRows.set(i, null);
                }
                this.m_arHTMLRows = null;
            }
            if (this.m_arHScrollRows !== null) {
                for (let i = 0; i < this.m_arHScrollRows.size(); i++) {
                    let v = this.m_arHScrollRows.get(i);
                    if (v !== null) {
                        for (let j = 0; j < v.size(); j++) {
                            let hr = v.get(j);
                            if (hr !== null) {
                                if (hr.getHScrollView() !== null) {
                                    //TODO: ? what
                                    //(hr.getWebView().getParent()).removeView(hr.getWebView());
                                }
                                hr.setHScrollView(null);
                            }
                        }
                        v.clear();
                    }
                    this.m_arHScrollRows.set(i, null);
                }
                this.m_arHScrollRows = null;
            }

        }
    }
    updateWithNewContent(sContent) {
        this.m_arKeyPhrases = BBUtils.splitCheckEnd(sContent, "\n", true);
        if (this.m_arKeyPhrases.length > 0) {
            this.m_arColumns = new Array(this.m_arKeyPhrases.length);
            this.m_htForeColor = {};    // new Hashtable();
            this.m_htBackColor = {};    // new Hashtable();
            this.m_arFlags = new Array(this.m_arKeyPhrases.length);
            for (let i = 0; i < this.m_arKeyPhrases.length; i++) {
                this.m_arColumns[i] = BBUtils.split(this.m_arKeyPhrases[i], '\t');
                this.setupRowInfoFrom_arColumns(i);
            }
            this.setupCalcLines(true);
        }
        this.m_iListLen = this.m_arKeyPhrases.length;
    }

    setListData(arBMenu,
        sContent,
        sRowHintsText,
        sFont,
        sdnItem,
        sdnScreen,
        sdnTitle,
        sdnMisc,
        sdnSeparator,
        sToolBar,
    ) {
        let dml = [];
        this._ixClick = -1;
        this.rbType = 0;
        this._imgUrlRightButton = null;
        if (arBMenu != null && arBMenu.length > 0) {
            let ixc = 0;
            for (let i = 0; i < arBMenu.length; i++) {
                ixc = arBMenu[i].indexOf(':');
                dml[i] = new DynMenuList(arBMenu[i].substring(ixc + 1));
                if (dml[i].getButton().equals(BPSocketConst.C_CLICK)) {
                    this._ixClick = i;
                } else {
                    if (dml[i].isRightButtonSingleAction()) {
                        let imgSrc = dml[i].getImageUrl();
                        if (imgSrc.length > 0) {
                            this.rbType = DecorNode.DECORNODE_TYPE_RIGHTBUTTON; // 2022-08-18  DecorNode.getFreeType();
                            this.addRightButtonSingleAction(imgSrc, this.rbType);
                        }
                    }
                }
            }
        }

        this.m_iSelected = -1;

        global.globalVars.getGlobalImageCache().clearImages();

        this._dynamicMenuList = dml;

        // 'getLastTitle' - this really should return the CTitleHistory from *this* request, so we should
        // get it from the CMDP_ADD_TITLE_HISTORY - which was processed just before this Cmd. So, just get it from
        // the 'top' by calling _cHistory.getLastTitle();
        let lastTitle = this.appClass._cHistory.getLastTitle();

        let sLastTitleBackButton = null;
        let sLastTitleTitle = null;
        let bLastTitleFind = false;
        if (lastTitle != null) {
            sLastTitleBackButton = lastTitle.backText;
            sLastTitleTitle = lastTitle.title;
            bLastTitleFind = lastTitle.find;
        }

        this.appClass.setDelayRender(true);

        this._origRowHintsText = sRowHintsText;
        this._origDecorNodeItem = sdnItem;
        this._origDecorNodeScreen = sdnScreen;
        this._origDecorNodeSeparator = sdnSeparator;
        this._origDecorNodeTitle = sdnTitle;
        this._origDecorNodeMisc = sdnMisc;

        this.Clear();
        this.clearImages();
        this.changeRowHints(sRowHintsText);
        this.changeDecorNodeItem(sdnItem);
        this.changeDecorNodeScreen(sdnScreen);
        this.changeDecorNodeSeparator(sdnSeparator);
        this.changeDecorNodeTitle(sdnTitle);
        this.changeDecorNodeMisc(sdnMisc);
        this.processMisc();
        if (this._imgUrlRightButton === null) {
            this.addMenuButton();
        }

        this.processToolbar(sToolBar);

        this.m_backButtonText = null;
        if (this.appClass._cHistory !== null) {
            this.m_backButtonText = this.appClass._cHistory.getLastBackButton();
        }
        if ((sLastTitleBackButton === null && sLastTitleTitle === null) || this.appClass.lastTitleSearchChannel != null) {
            this.resetBackButtonToDefault();
            this.resetTextEditToDefault();
            this.resetTextEditPlaceholder();
        } else {
            if (this.m_backButtonText === null) {
                this.resetBackButtonToDefault();
            } else {
                if (this.m_backButtonText !== null) {
                    ;   // already set
                }
            }
            if (bLastTitleFind) {
                this.resetTextEditToDefault();
                if (sLastTitleTitle !== null) {
                    this.appClass.setHintText(sLastTitleTitle);
                }
            } else {
                if (sLastTitleTitle !== null) {
                    this.appClass.setInputVisible(false);
                    this.appClass.setTitleVisible(true);
                    this.appClass.setTitleText(sLastTitleTitle);
                } else {
                    this.resetTextEditToDefault();
                    this.resetTextEditPlaceholder();
                }
            }
        }

        this.updateBackButtonText();

        // This code sets the 'selected' background color of the full cell
        // TODO
        /*
        if (this.midlet._cMenu !== null) {

            let dnI = DecorNode.FromAttributes(sdnItem, DecorNode.DECORNODE_TYPE_CELL);
            if (this.mPaintCellBackground !== null && this.mPaintCellBackground.canDraw(dnI)) {
                this.mPaintCellBackground.setDecorNode(dnI);
                this.midlet._cMenu.setSelector(this.mPaintCellBackground);
            } else {
                this.midlet._cMenu.setSelector(this.mSelectorDrawable);
            }
        }
        */

        //this.mHandler.sendMessage(this.mHandler.obtainMessage(this.MSG_THREAD_INVALIDATE_DECORS));
        this.updateWithNewContent(sContent);
        this.appClass.checkIfThisWasBack();
        this.appClass.checkIfRestoreScroll();
        // Now, send reference to VirtualList. - which will call <DrawView item={ix}>
        let dindexes = [];
        for (let i=0; i<this.m_iListLen; i++) {
            dindexes.push({key:i.toString(), index:i});
        }
        this.appClass.setDataIndexes(dindexes);
        this.appClass.refreshHorizontalScrollers();

        this.appClass.setDelayRender(false);
    }

    dynSelectBaseWithMenu(dynMenu, arColumns, dynType) {
        let sAction = null;
        let sButton = null;
        let processingType = '';
        let nextStep = '';
        let context = '';
        let bok = false;
        let bHandled = false;
        let bDidAction = false;
        sButton = dynMenu.getButton();
        bok = false;
        context = dynMenu.getContext();
        switch (context) {
            case 'C':
                if (dynType === CMenuAdapter.DYNMENU_TEXTBOX || dynType === CMenuAdapter.DYNMENU_CMENU) {
                    bok = true;
                } else {
                    if (sButton !== null) {
                        if (sButton.equals(CMenuAdapter.C_TB)) {
                            bok = true;
                        }
                    }
                };
                break;
            case 'I':
                if (dynType === CMenuAdapter.DYNMENU_CMENU) {
                    bok = true;
                };
                break;
            case 'B':
                bok = true;
                break;
        }
        if (!bok) {
            return false;
        }
        bHandled = false;
        sAction = dynMenu.getAction();
        if (sButton.equals(CMenuAdapter.C_TB)) {
            sButton = dynMenu.getAlternateButton();
        }
        processingType = dynMenu.getProcessingType();
        nextStep = dynMenu.getNextStep();
        if (global.globalVars.UseBBMaps()) {
            if (sButton.length > 0 && sButton.equals(CMenuAdapter.C_MAP) && BBMaps.Supported()) {
                let bMapIt = false;
                let sLabel = '';
                let sLatLon = '';
                let sImgUrl = '';
                if (arColumns.length > 3) {
                    sLatLon = arColumns[3];
                }
                if (arColumns.length > 1) {
                    sLabel = arColumns[1];
                }
                if (sLatLon.length === 0 || !BBUtils.validLatLon(sLatLon)) {
                    if (sAction.length > 0) {
                        sAction = this.parseDollarArgs(arColumns, sAction, true);
                        let uri = new URL(sAction);
                        let params = uri.searchParams;
                        sLatLon = params.get('latlon');
                        sLabel = params.get('label');
                        sImgUrl = params.get('imgurl');
                        if (sLabel === null) {
                            sLabel = '';
                        }
                        if (BBUtils.validLatLon(sLatLon)) {
                            bMapIt = true;
                        }
                    }
                } else {
                    bMapIt = true;
                }
                if (bMapIt) {
                    CCallLog.LogViewOnMap(global.globalVars.getBPIN(), sLatLon);
                    let lat = 0;
                    let lon = 0;
                    let ixc = sLatLon.indexOf(',');
                    lat = BNumberUtils.parseDouble(sLatLon.substring(0, ixc));
                    lon = BNumberUtils.parseDouble(sLatLon.substring(ixc + 1));
                    let sLabelSearchCenter = BBUtils.C_EMPTY_STRING;
                    let dLatSearchCenter = Number.MAX_VALUE;
                    let dLonSearchCenter = Number.MAX_VALUE;
                    if (this._searchCenterLabel !== null && this._searchCenterLabel.length > 0) {
                        sLabelSearchCenter = this._searchCenterLabel;
                    }
                    if (this._searchCenter !== null && this._searchCenter.length > 0) {
                        ixc = this._searchCenter.indexOf(',');
                        if (ixc !== -1) {
                            dLatSearchCenter = BNumberUtils.parseDouble(this._searchCenter.substring(0, ixc));
                            dLonSearchCenter = BNumberUtils.parseDouble(this._searchCenter.substring(ixc + 1));
                        }
                    }
                    if (sImgUrl === null || sImgUrl.length === 0) {
                        if (this.m_mapImageId !== Number.MAX_SAFE_INTEGER) {
                            sImgUrl = this.getImageUrlNoAdd('Image', this.m_mapImageId, arColumns);
                            if (this.radarType === 0) {
                                this.radarType = DecorNode.getFreeType();
                            }
                            if (sImgUrl === null) {
                                sImgUrl = this.m_mapImageDefaultUrl;
                            }
                        }
                        if (sImgUrl === null) {
                            sImgUrl = CMenuAdapter.TROP_DEFAULT_MARKER_IMAGE_URL;
                        }
                    }
                    let cColor = CMenuAdapter.TROP_DEFAULT_MAKER_COLOR;
                    if (this.m_mapTintDefaultFormat !== null) {
                        let sTintFormat = this.parseDollarArgsOffset(arColumns, this.m_mapTintDefaultFormat, false, 0);
                        if (sTintFormat !== null) {
                            let status = new BStatus(true);
                            let cResult = DecorNode.parseColorString(sTintFormat, status);
                            if (status.getStatus()) {
                                cColor = cResult;
                            }
                        }
                    }
                    bHandled = this.MapLocation(lat, lon, sLabel, dLatSearchCenter, dLonSearchCenter, sLabelSearchCenter, sImgUrl, cColor);
                }
                if (!bHandled) {
                    this.showMessage(this.getString(R.string.C_NOTHING_TO_MAP));
                    bHandled = true;
                }
            } else {
                if (sButton.length > 0 && sButton.startsWith(CMenuAdapter.C_MAP_COLON) && BBMaps.Supported()) {
                    let sLatLon = null;
                    let sUrl = null;
                    if (this._searchCenter !== null && this._searchCenter.length > 0) {
                        sLatLon = this._searchCenter;
                    } else {
                        sLatLon = global.globalVars.getLatLon();
                    }
                    let sPrevBase = this.appClass._cHistory.getPreviousBase();
                    let sPrevChar = this.appClass._cHistory.getPreviousChars();
                    sUrl = sPrevBase + '@' + BBUtils.UrlEncode(BBUtils.Utf8Encode(sPrevChar));
                    let sRest = sButton.substring(CMenuAdapter.C_MAP_COLON.length).trim();
                    CCallLog.LogMapRange(sUrl, sLatLon, CMenuAdapter.C_RANGE_EQUAL + sRest);
                    try {
                        let ixc = 0;
                        let sLabelSearchCenter = BBUtils.C_EMPTY_STRING;
                        let dLatSearchCenter = Number.MAX_VALUE;
                        let dLonSearchCenter = Number.MAX_VALUE;
                        if (this._searchCenterLabel !== null && this._searchCenterLabel.length > 0) {
                            sLabelSearchCenter = this._searchCenterLabel;
                        }
                        if (this._searchCenter !== null && this._searchCenter.length > 0) {
                            ixc = this._searchCenter.indexOf(',');
                            if (ixc !== -1) {
                                dLatSearchCenter = BNumberUtils.parseDouble(this._searchCenter.substring(0, ixc));
                                dLonSearchCenter = BNumberUtils.parseDouble(this._searchCenter.substring(ixc + 1));
                            }
                        }
                        ixc = sRest.indexOf('-');
                        if (ixc !== -1) {
                            let iStart = 0;
                            let iEnd = 0;
                            iStart = BNumberUtils.parseInt(sRest.substring(0, ixc));
                            iEnd = BNumberUtils.parseInt(sRest.substring(ixc + 1));
                            if (iStart <= iEnd) {
                                let arRange = new Array(iEnd - iStart + 1);
                                let ii = 0;
                                for (ii = iStart; ii <= iEnd; ii++) {
                                    arRange[ii - iStart] = ii;
                                }
                                bHandled = this.MapLocations(arRange, this.m_arColumns, dLatSearchCenter, dLonSearchCenter, sLabelSearchCenter);
                            }
                        } else {
                            let arSplit = BBUtils.split(sRest, ',');
                            if (arSplit.length > 0) {
                                let arRange = new Array(arSplit.length);
                                let ii = 0;
                                for (ii = 0; ii < arSplit.length; ii++) {
                                    arRange[ii] = Integer.parseInt(arSplit[ii]);
                                }
                                bHandled = this.MapLocations(arRange, this.m_arColumns, dLatSearchCenter, dLonSearchCenter, sLabelSearchCenter);
                            }
                        }
                        if (!bHandled) {
                            this.showMessage(this.getString(R.string.C_NOTHING_TO_MAP));
                            bHandled = true;
                        }
                    } catch (ignored) { }
                } else {
                    if (sButton.length > 0 && sButton.equals(CMenuAdapter.C_DIRECTIONS) && BBMaps.Supported()) {
                        try {
                            let sLabel = null;
                            let sLatLon = arColumns[3];
                            let slat = 0;
                            let slon = 0;
                            let dlat = 0;
                            let dlon = 0;
                            let ixc = sLatLon.indexOf(',');
                            dlat = BNumberUtils.parseDouble(sLatLon.substring(0, ixc));
                            dlon = BNumberUtils.parseDouble(sLatLon.substring(ixc + 1));
                            sLabel = this.getString(R.string.C_START_LOCATION_LABEL);
                            if (this._searchCenterLabel !== null && this._searchCenterLabel.length > 0) {
                                sLabel = this._searchCenterLabel;
                            }
                            if (this._searchCenter !== null && this._searchCenter.length > 0) {
                                CCallLog.LogDirections(global.globalVars.getBPIN(), sLatLon, this._searchCenter);
                                ixc = this._searchCenter.indexOf(',');
                                slat = BNumberUtils.parseDouble(this._searchCenter.substring(0, ixc));
                                slon = BNumberUtils.parseDouble(this._searchCenter.substring(ixc + 1));
                            } else {
                                CCallLog.LogGPSDirections(global.globalVars.getBPIN(), sLatLon, global.globalVars.getLatLon());
                                sLabel = this.getString(R.string.C_YOU_LOCATION_LABEL);
                                slat = global.globalVars.getLat();
                                slon = global.globalVars.getLon();
                            }
                            if (slat !== 0.0 && slon !== 0.0 && dlat !== 0.0 && dlon !== 0.0) {
                                bHandled = BBMaps.MapRoute(this, slat, slon, sLabel, BBUtils.C_EMPTY_STRING, dlat, dlon, sLabel, BBUtils.C_EMPTY_STRING);
                                //TODO: fail to Google Maps - alternatively, we could use Mapquest.js
                                /*
                                if (!bHandled) {
                                    this.showMessage(this.getString(R.string.C_NOTHING_TO_MAP));
                                    bHandled = true;
                                }
                                */
                            }
                        } catch (ex) { }
                    }
                }
            }
        }
        if (sButton.length > 0 && sButton.equals(CMenuAdapter.C_VCARD)) {
            let vCard = this.parseDollarArgs(arColumns, sAction, true);
            let ixQ = vCard.indexOf('?');
            if (ixQ !== -1 && ixQ < vCard.length - 1) {
                bHandled = this.AddVCard(vCard.substring(ixQ + 1));
            }
        } else {
            if (sButton.length > 0 && sButton.equals(CMenuAdapter.C_ICAL)) {
                let iCal = this.parseDollarArgs(arColumns, sAction, true);
                let ixQ = iCal.indexOf('?');
                if (ixQ !== -1 && ixQ < iCal.length - 1) {
                    bHandled = this.AddICal(iCal.substring(ixQ + 1));
                }
            } else {
                if (sButton.length > 0 && sButton.equals(CMenuAdapter.C_PHOTO)) {
                    let sPhoto = this.parseDollarArgs(arColumns, sAction, true);
                    bHandled = this.AddPhoto(sPhoto);
                } else {
                    if (sButton.length > 0 && sButton.equals(CMenuAdapter.C_PICVIEWER)) {
                        let sPicture = this.parseDollarArgs(arColumns, sAction, true);
                        bHandled = this.ViewPicture(sPicture);
                    } else {
                        if (sButton.length > 0 && sButton.equals(CMenuAdapter.C_VIDEOVIEWER)) {
                            let sVideo = this.parseDollarArgs(arColumns, sAction, true);
                            bHandled = this.PlayVideo(sVideo);
                        } else {
                            if (sButton.length > 0 && sButton.equals(CMenuAdapter.C_XBROWSER)) {
                                let sUrl = this.parseDollarArgs(arColumns, sAction, true);
                                this.XBrowser(sUrl);
                                bHandled = true;
                            }
                        }
                    }
                }
            }
        }
        if (bHandled) {
            bDidAction = true;
        }
        if (sAction.length > 0 && !bHandled) {
            sAction = this.parseDollarArgs(arColumns, sAction, true);
            if (sAction.length > 0) {
                bDidAction = true;
                if (processingType === 'I') {
                    this.processAction(sAction, false);
                } else {
                    if (processingType === 'O') {
                        this.processAction(sAction, true);
                    } else {
                        if (processingType === 'V') {
                            this.processExtendedAction(sAction, CMenuAdapter.GGCACTION_EXTERNAL_WNAV);
                        }
                    }
                }
            }
        }
        if (processingType === 'I' || processingType === 'O' || processingType === 'V') {
            if (nextStep === 'R') {
                this.RefreshR();
            } else {
                if (nextStep === 'S') {
                    this.RefreshS();
                }
            }
            if (dynType === CMenuAdapter.DYNMENU_CMENU) {
                if (nextStep === 'F') {
                    this.ForwardItem();
                }
            }
        }
        return bDidAction;
    }
    accessoryTapped(ix, bFromItem, bHasClick) {
        let arColumns = new Array(this.m_arColumns[ix].length + 1);
        arColumns[0] = this.appClass.getIncrementalBaseUrl();
        for (let j = 0; j < this.m_arColumns[ix].length; j++) {
            arColumns[j + 1] = this.m_arColumns[ix][j];
        }
        let msl = new Vector();
        this.FillMenuSections(msl, arColumns, true, true);
        arColumns = null;
        if (bFromItem) {
            if (msl.size() === 0) {
                msl = null;
                return undefined;
            } else {
                if (msl.size() === 1 && !bHasClick) {
                    let ms = msl.elementAt(0);
                    let ixMenu = ms.getIndex();
                    this.dynSelectList(ixMenu);
                    msl = null;
                    return undefined;
                }
            }
        }
        msl = null;
        this.m_iSelected = ix;
        if (!this.isFocused()) {
            this.requestFocus();
        }
        this.setSelection(this.m_iSelected);
        this.menuGuard = this.menuGuard + 1;
        this.openOptionsMenuManually(false);
        this.menuGuard = this.menuGuard - 1;
    }
    fillContextMenu2(arColumns, bListActive) {
        let msl = new Vector();
        this.FillMenuSections(msl, arColumns, bListActive, false);
        this.m_numMenuItems = this._dynamicMenuList.length;
        return msl;
    }
    makeContextMenu2(position) {
        if (this.menuGuard === 0) {
            this.m_iSelected = position;
        }
        if (this.m_iSelected >= 0 && this.m_iSelected < this.m_iListLen) {
            let arColumns = new Array(this.m_arColumns[this.m_iSelected].length + 1);
            arColumns[0] = this.appClass.getIncrementalBaseUrl();
            for (let i = 0; i < this.m_arColumns[this.m_iSelected].length; i++) {
                arColumns[i + 1] = this.m_arColumns[this.m_iSelected][i];
            }
            return this.fillContextMenu2(arColumns, true);
        } else {
            return null;
        }
    }
    openOptionsMenuManually(bAddHome) {
        let msl = null;
        let mslIx = -1; // cMenu
        if (this.appClass._cEditTextIsFocused()) {
            let ar = new Array(1);
            ar[0] = this.appClass.getIncrementalBaseUrl();
            msl = this.fillContextMenu2(ar, false);
        } else {
            mslIx = this.getSelectedItemPosition();
            msl = this.makeContextMenu2(mslIx);
            if (msl === null) {
                if (!(!this.appClass.getEditTextVisible() || !this.appClass.getHFieldVisible())) {
                    this.appClass._cEditTextRequestFocus();
                }
                let ar = new Array(1);
                ar[0] = this.appClass.getIncrementalBaseUrl();
                msl = this.fillContextMenu2(ar, false);
            }
        }
        if (msl !== null) {
            let ms = null;
            if (bAddHome) {
                ms = new MenuSection(this.getString(R.string.CMD_GOTO_HOME), CMenuAdapter.SYS_MENU_FLAG + CMenuAdapter.MENU_HOME);
                ms.setIcon(this.getIcon(R.drawable.menu_home));
                msl.insertElementAt(ms, 0);
            }
            if (this._returnToAppMenu !== null) {
                let cnt = this._returnToAppMenu.size();
                for (let i = 0; i < cnt; i++) {
                    ms = new MenuSection(this._returnToAppMenu.elementAt(i), CMenuAdapter.SYS_MENU_FLAG + CMenuAdapter.MENU_RMENU + i);
                    msl.insertElementAt(ms, i);
                }
            }
            //let v = this.appClass.getListObject();


            this.appClass.setMenuList(mslIx, msl);
        }
    }
    XBrowser(sUrl) {
        CCallLog.LogExternalUrl(global.globalVars.getBPIN(), sUrl);
        Linking.canOpenURL(sUrl).then(supported => {
            if (supported) {
                Linking.openURL(sUrl);
            } else {
                this.showMessage(this.getString(R.string.C_ERROR_UNABLE_URL) + sUrl);
            }
        })
    }
    isFocused() {
        return true;
    }
    requestFocus() {

    }
    setSelection(ix) {
        // 
    }

    getSelectedItemPosition = () => {
        return this.m_iSelected;
    }
    selectMenuItem = (menuId) => {
        if ((menuId & CMenuAdapter.SYS_MENU_FLAG) === CMenuAdapter.SYS_MENU_FLAG) {
            menuId = menuId & ~CMenuAdapter.SYS_MENU_FLAG;
            switch (menuId) {
                case CMenuAdapter.MENU_HOME:
                    this.appClass.gotoHome();
                    break;
                case CMenuAdapter.MENU_TEST:
                    break;
                case CMenuAdapter.MENU_SETTINGS:
                    break;
                case CMenuAdapter.MENU_EXIT:
                    finish();
                    break;
                default:
                    if (menuId >= CMenuAdapter.MENU_RMENU && this._returnToAppMenu !== null && menuId < CMenuAdapter.MENU_RMENU + this._returnToAppMenu.size()) {
                        this.doRMenu(menuId - CMenuAdapter.MENU_RMENU);
                    };
                    break;
            }
        } else {
            if (this.appClass._cEditTextIsFocused()) {
                this.dynSelect(menuId);
            } else {
                this.dynSelectList(menuId);
            }
        }
    }
    SelectItem(ix) {
        this.m_iSelected = ix;
        if (this.m_iSelected >= 0) {
            let bNotClick = true;
            if (this.getClick() !== -1) {
                bNotClick = !this.dynSelectList(this.getClick());
            }
            if (bNotClick) {
                if (this.m_arColumns[this.m_iSelected].length > CMenuAdapter.IX_URL && this.m_arColumns[this.m_iSelected][CMenuAdapter.IX_URL].length > 0) {
                    this.selectItem(this.m_arKeyPhrases[this.m_iSelected], this.m_arColumns[this.m_iSelected]);
                } else {
                    this.accessoryTapped(this.m_iSelected, true, false);
                }
            }
        }
    }
    selectItem(sKey, arColulmns) {
        if (arColulmns.length > CMenuAdapter.IX_URL) {
            this.processAction(arColulmns[CMenuAdapter.IX_URL], true);
        }
    }
    processAction(sSelected, bExternal) {
        if (bExternal) {
            this.processExtendedAction(sSelected, CMenuAdapter.GGCACTION_EXTERNAL);
        } else {
            this.processExtendedAction(sSelected, CMenuAdapter.GGCACTION_INTERNAL);
        }
    }
    processExtendedAction(sSelected, act) {
        let sUrl = null;
        let sb = null;
        if (sSelected.length === 0) { } else {
            if (sSelected.length >= 2 && sSelected.substring(0, 2).equals(CMenuAdapter.C_I_COLON)) {
                sUrl = BBUtils.CombineUrl(this.appClass.getIncrementalBaseUrl(), sSelected.substring(2));
                this.appClass.search(sUrl);
            } else {
                if (sSelected.length >= CMenuAdapter.C_TEL_COLON.length && sSelected.substring(0, CMenuAdapter.C_TEL_COLON.length).equals(CMenuAdapter.C_TEL_COLON)) {
                    sUrl = sSelected;
                    if (sUrl.length > 4) {
                        let sPhoneNum = sUrl.substring(4);
                        CCallLog.LogCall(global.globalVars.getBPIN(), sPhoneNum);
                        try {
                            Linking.openURL( CMenuAdapter.C_TEL_COLON + sPhoneNum);
                        } catch (ex) {
                            this.ShowErrorMessage(this.getString(R.string.C_ERROR_PLACING_CALL));
                        }
                    }
                } else {
                    if (sSelected.length >= CMenuAdapter.C_MAILTO_COLON.length && sSelected.substring(0, CMenuAdapter.C_MAILTO_COLON.length).equals(CMenuAdapter.C_MAILTO_COLON)) {
                        let sTo = null;
                        let sFixedUrl = sSelected;
                        sTo = BBUtils.C_EMPTY_STRING;
                        let ixQMark = sSelected.indexOf('?');
                        if (ixQMark !== -1) {
                            if (ixQMark !== 7) {
                                sTo = sSelected.substring(7, ixQMark);
                                sFixedUrl = 'mailto:' + sTo.replace('+', '') + sSelected.substring(ixQMark);
                            }
                        } else {
                            sTo = sSelected.substring(7);
                            sFixedUrl = sSelected.replace('+', '');
                        }
                        sTo = BBUtils.URLdecode(sTo);
                        sTo = BBUtils.Utf8Decode(sTo);
                        CCallLog.LogMailTo(global.globalVars.getBPIN(), sTo);
                        try {
                            Linking.openURL( sFixedUrl );
                        } catch (ex) {
                            this.ShowErrorMessage(this.getString(R.string.C_ERROR_SENDING_EMAIL));
                        }
                    } else {
                        if (sSelected.length >= CMenuAdapter.C_SMS_COLON.length && sSelected.substring(0, CMenuAdapter.C_SMS_COLON.length).equals(CMenuAdapter.C_SMS_COLON)) {
                            sUrl = sSelected;
                            if (sUrl.length > 4) {
                                let sPhoneNum = sUrl.substring(4);
                                CCallLog.LogSMS(global.globalVars.getBPIN(), sPhoneNum);
                                try {
                                    Linking.openURL( sUrl );
                                } catch (ex) {
                                    this.ShowErrorMessage(getString(R.string.C_ERROR_SENDING_SMS));
                                }
                            }
                        } else {
                            sUrl = BBUtils.CombineUrl(this.appClass.getIncrementalBaseUrl(), sSelected);
                            try {
                                sUrl = this.checkAndAddUid(sUrl);
                                if ((act === CMenuAdapter.GGCACTION_EXTERNAL || act === CMenuAdapter.GGCACTION_EXTERNAL_WNAV) || !(sUrl.startsWith(this.C_HTTP_COLON) || sUrl.startsWith(BBUtils.C_HTTPS_COLON))) {
                                    CCallLog.LogInternalUrl(global.globalVars.getBPIN(), sUrl);
                                    if (act === CMenuAdapter.GGCACTION_EXTERNAL) {
                                        this.gotoUrl(sUrl);
                                    } else {
                                        this.gotoUrl(sUrl);
                                    }
                                } else {
                                    CCallLog.LogCurlUrl(global.globalVars.getBPIN(), sUrl);
                                    sb = new StringBuffer();
                                    sb.append(CMenuAdapter.C_CURL_QMARK_U_EQUAL);
                                    sb.append(BBUtils.UrlEncode(sUrl));
                                    this.appClass.bpSocket.addHttpTask(BPHttpTask.HTTP_TASK_CLIENT, sb.toString(), BBUtils.C_EMPTY_STRING);
                                }
                            } catch (ex) { 
                                console.log(ex.toString());
                            }
                        }
                    }
                }
            }
        }
    }

    AddPhoto(url) {
        this.appClass.addPhoto(url);
    }

    correctFlavorSyndicatorUrls(sMofi) {
        let sBPIN = null;
        let bIgnoreBPIN = false;
        let sProtocol = null;
        let sArgs = BBUtils.split(sMofi, '&');
        for (let i = 0; i < sArgs.length; i++) {
          if (sArgs[i].startsWith(CMenuAdapter.C_pin_EQUAL) || sArgs[i].startsWith(CMenuAdapter.C_PIN_EQUAL)) {
            bIgnoreBPIN = true;
          }
        }
        let sUrl = '';
        for (let i = 0; i < sArgs.length; i++) {
            if (!bIgnoreBPIN && (sArgs[i].startsWith(CMenuAdapter.C_pin_EQUAL) || sArgs[i].startsWith(CMenuAdapter.C_PIN_EQUAL))) {
                if (sArgs[i].length > CMenuAdapter.C_pin_EQUAL.length) {
                    sBPIN = sArgs[i].substring(CMenuAdapter.C_pin_EQUAL.length);
                } else {
                    sBPIN = '';
                }
            } else if (sArgs[i].startsWith(CMenuAdapter.C_pin_EQUAL) || sArgs[i].startsWith(CMenuAdapter.C_PIN_EQUAL)) {
                if (sArgs[i].length > CMenuAdapter.C_pin_EQUAL.length) {
                    sBPIN = sArgs[i].substring(CMenuAdapter.C_pin_EQUAL.length);
                } else {
                    sBPIN = '';
                }
            } else if (sArgs[i].startsWith(CMenuAdapter.C_C_EQUAL) && sArgs[i].length > CMenuAdapter.C_C_EQUAL.length) {
                sUrl = sArgs[i].substring(CMenuAdapter.C_C_EQUAL.length);
            }
        }
        if (sUrl.startsWith(BBUtils.C_HTTP_COLON)) {
          sProtocol = BBUtils.C_HTTP_COLON;
        } else {
          if (sUrl.startsWith(BBUtils.C_HTTPS_COLON)) {
            sProtocol = BBUtils.C_HTTPS_COLON;
          } else {
            sProtocol = '';
          }
        }
        global.globalVars.setupFlavorSyndicatorUrls(sProtocol, sBPIN);
    }

    setReturnValueUI(s) {
        let sUrl = null;
        let sKeys = null;
        let bExternal = false;
        let bHome = false;
        let bIgnoreBPIN = false;
        let sProtocol = null;
        let sOI = null;
        let sBPIN = '';
        let sOldBPIN = global.globalVars.getBPIN();
        let returnMenu = null;
        let returnUrl = null;
        let sMsg = null;
        let sArgs = BBUtils.split(s, '&');
        for (let i = 0; i < sArgs.length; i++) {
            if (sArgs[i].startsWith(CMenuAdapter.C_pin_EQUAL) || sArgs[i].startsWith(CMenuAdapter.C_PIN_EQUAL)) {
                bIgnoreBPIN = true;
            }
        }
        for (let i = 0; i < sArgs.length; i++) {
            if (sArgs[i].startsWith(CMenuAdapter.C_C_EQUAL) && sArgs[i].length > CMenuAdapter.C_C_EQUAL.length) {
                sUrl = sArgs[i].substring(CMenuAdapter.C_C_EQUAL.length);
            } else if (sArgs[i].startsWith(CMenuAdapter.C_K_EQUAL) && sArgs[i].length > CMenuAdapter.C_K_EQUAL.length) {
                sKeys = BBUtils.URLdecode(sArgs[i].substring(CMenuAdapter.C_K_EQUAL.length));
            } else if (sArgs[i].startsWith(CMenuAdapter.C_O_EQUAL) && sArgs[i].length > CMenuAdapter.C_O_EQUAL.length) {
                sOI = sArgs[i].substring(CMenuAdapter.C_O_EQUAL.length);
                bExternal = true;
            } else if (sArgs[i].startsWith(CMenuAdapter.C_I_EQUAL) && sArgs[i].length > CMenuAdapter.C_I_EQUAL.length) {
                sOI = sArgs[i].substring(CMenuAdapter.C_I_EQUAL.length);
                bExternal = false;
            } else if (!bIgnoreBPIN && ((sArgs[i].startsWith(CMenuAdapter.C_bpin_EQUAL) || sArgs[i].startsWith(CMenuAdapter.C_BPIN_EQUAL)) && sArgs[i].length > CMenuAdapter.C_bpin_EQUAL.length)) {
                sBPIN = sArgs[i].substring(CMenuAdapter.C_bpin_EQUAL.length);
            } else if ((sArgs[i].startsWith(CMenuAdapter.C_pin_EQUAL) || sArgs[i].startsWith(CMenuAdapter.C_PIN_EQUAL)) && sArgs[i].length > CMenuAdapter.C_pin_EQUAL.length) {
                sBPIN = sArgs[i].substring(CMenuAdapter.C_pin_EQUAL.length);
            } else if ((sArgs[i].startsWith(CMenuAdapter.C_msg_EQUAL) || sArgs[i].startsWith(CMenuAdapter.C_msg_EQUAL)) && sArgs[i].length >= CMenuAdapter.C_msg_EQUAL.length) {
                if (sArgs[i].length > CMenuAdapter.C_msg_EQUAL.length) {
                    sMsg = sArgs[i].substring(CMenuAdapter.C_msg_EQUAL.length);
                } else {
                    sMsg = '';
                }
            } else if ((sArgs[i].startsWith(CMenuAdapter.C_RMENU_EQUAL) || sArgs[i].startsWith(CMenuAdapter.C_RMENU_EQUAL)) && sArgs[i].length > CMenuAdapter.C_RMENU_EQUAL.length) {
                returnMenu = BBUtils.Utf8Decode(BBUtils.URLdecode(sArgs[i].substring(CMenuAdapter.C_RMENU_EQUAL.length)));
            } else if ((sArgs[i].startsWith(CMenuAdapter.C_RURL_EQUAL) || sArgs[i].startsWith(CMenuAdapter.C_RURL_EQUAL)) && sArgs[i].length > CMenuAdapter.C_RURL_EQUAL.length) {
                returnUrl = BBUtils.URLdecode(sArgs[i].substring(CMenuAdapter.C_RURL_EQUAL.length));
            } else if (sArgs[i].indexOf('=') === -1) {
                if (sArgs[i].indexOf('refreshs') === 0) {
                    global.globalVars.appClass.RefreshS();
                } else if (sArgs[i].indexOf('refresh') === 0) {
                    global.globalVars.appClass.RefreshR();
                }
            }
        }
        
        if (sMsg !== null) {
            this.setListData(null, sMsg, '', '', '', '', '', '', '', '');
        }
        // BUGBUG: not used on web...
        if (!(returnUrl === null || returnUrl.length === 0 || returnMenu === null || returnMenu.length === 0)) {
            if (this._returnToAppUrl === null) {
                this._returnToAppUrl = new Vector();
            }
            if (this._returnToAppMenu === null) {
                this._returnToAppMenu = new Vector();
            }
            let bSame = false;
            for (let i = 0; i < this._returnToAppMenu.size(); i++) {
                if (this._returnToAppMenu.elementAt(i).equals(returnMenu) && this._returnToAppUrl.elementAt(i).equals(returnUrl)) {
                    bSame = true;
                }
            }
            if (!bSame) {
                this._returnToAppUrl.addElement(returnUrl);
                this._returnToAppMenu.addElement(returnMenu);
            }
        }
        if (sUrl !== null && sUrl.startsWith(BBUtils.C_HTTP_COLON)) {
            sProtocol = BBUtils.C_HTTP_COLON;
        } else {
            if (sUrl !== null && sUrl.startsWith(BBUtils.C_HTTPS_COLON)) {
                sProtocol = BBUtils.C_HTTPS_COLON;
            } else {
                sProtocol = '';
            }
        }

        if (sOldBPIN.length >= 0 && sBPIN.length === 0) {
            global.globalVars.setupFlavorSyndicatorUrls(sProtocol, sOldBPIN);
        } else {
            if (sBPIN.length > 0) {
                global.globalVars.setupFlavorSyndicatorUrls(sProtocol, sBPIN);
            }
        }
        if (sOldBPIN.length > 0 && sBPIN.length > 0) {
            let cRepo = CCookieRepository.sharedRepository();
            cRepo.reset();
            /* TODO
            this.deleteWebCookiesForDomain(this, CConfigParams.sharedParams().getSocketServerUrl(), true);
            this.deleteWebCookiesForDomain(this, 'tropare-users-login.prospectsforme.com', true);
            */
        }
        if (sBPIN.length > 0) {
            global.globalVars.setbPIN(sBPIN);
        } else {
            global.globalVars.setbPIN(sOldBPIN);
            if (sOldBPIN.length > 0) {
                //TODO: setTitle(getText(R.string.app_name));
            }
        }
        if (!sOldBPIN.equals(global.globalVars.getBPIN())) {
            bHome = true;
        } else {
            if (sOldBPIN.length === 0) {
                bHome = true;
            }
        }
        if (sUrl !== null && sUrl.length > 0) {
            bHome = false;
            let uri = null;
            try {
                uri = new URL(sUrl);
            } catch (ignore) {}

            if (uri === null || uri.hostname === null) {
                if (sUrl.startsWith('/')) {
                    sUrl = BBUtils.CombineUrl(global.globalVars.getBaseServer() + BBUtils.C_SLASH_I_SLASH, sUrl);
                } else {
                    sUrl = global.globalVars.getBaseServer() + BBUtils.C_SLASH_I_SLASH + sUrl + BBUtils.C_SLASH;
                }
            }
            if (sKeys !== null && sKeys.length > 0) {
                let iat = sUrl.indexOf('@');
                if (iat !== -1) {
                    sUrl = sUrl.substring(0, iat);
                }
                if (sKeys.indexOf('@') === 0) {
                    global.globalVars.appClass.search(sUrl +  sKeys);       // 2022-03-09 BBUtils.UrlEncode(BBUtils.Utf8Encode(sKeys)));
                } else {
                    global.globalVars.appClass.search(sUrl + "@" +  sKeys);     // 2022-03-09 BBUtils.UrlEncode(BBUtils.Utf8Encode(sKeys)));
                }
            } else {
                global.globalVars.appClass.searchNoFocus(sUrl);
            }
        }
        if (sOI !== null) {
            this.processAction(sOI, bExternal);
        }
        if (bHome) {
            global.globalVars.appClass.gotoHome();
        }
    }

    setMofiString(s) {
//        this.correctFlavorSyndicatorUrls(s);

        this.setReturnValueUI(s);
    }

    gotoUrl(url) {
        if (BBUtils.isBoopsieDomain(url)) {
            let uri = new URL(url);
            let path = uri.pathname;
            if (path !== null) {
                if (path.equals('/service/photo/')) {
                    this.AddPhoto(url);
                    return undefined;
                } else if (path.equals('/service/vcard/')) {
                    this.AddVCard(uri.getQuery());
                    return undefined;
                } else if (url.indexOf('/srvc/vcard.aspx') !== -1) {
                    let vcardParams = uri.search.substring(1);
                    if (vcardParams.indexOf('type=qrcode') === -1) {
                        this.AddVCard(vcardParams);    // javscript skip qmark ?
                        return undefined;
                    }
                } else if (path.equals('/service/mofi/')) {
                    let mofiString = uri.search.substring(1);
                    this.setMofiString(mofiString);
                    return undefined;
                } else if (path.equals('/service/edit/')) {
                    this.appClass.setEditModeTrue();
                    return undefined;
                } else if (path.equals('/service/map/')) {
                    try {
                        let arSplit = BBUtils.split(uri.search.substring(1), '&');
                        let arLabels = null;
                        let arLatLons = null;
                        for (let i = 0; i < arSplit.length; i++) {
                            let keyv = arSplit[i];
                            let ixEq = keyv.indexOf('=');
                            if (ixEq !== -1 && ixEq < keyv.length - 1) {
                                let decoded = BBUtils.Utf8Decode(BBUtils.URLdecode(keyv.substring(ixEq + 1)));
                                keyv = keyv.substring(0, ixEq + 1).toLowerCase();
                                if (keyv.equals('latlon=')) {
                                    arLatLons = BBUtils.split(decoded, ',');
                                } else {
                                    if (keyv.equals('labels=')) {
                                        arLabels = BBUtils.split(decoded, ',');
                                    }
                                }
                            }
                        }
                        if (arLabels !== null && arLatLons !== null && arLabels.length * 2 === arLatLons.length) {
                            let arRange = new Array(arLabels.length);
                            let arColumns = new Array(arLabels.length);
                            let ii = 0;
                            for (ii = 0; ii < arLabels.length; ii++) {
                                arRange[ii] = ii;
                                arColumns[0].push(arLabels[ii]);
                                arColumns[1].push(null);
                                arColumns[2].push(arLatLons[ii * 2] + ',' + arLatLons[ii * 2 + 1]);
                            }
                            let ixc = 0;
                            let sLabelSearchCenter = BBUtils.C_EMPTY_STRING;
                            let dLatSearchCenter = Number.MAX_VALUE;
                            let dLonSearchCenter = Number.MAX_VALUE;
                            if (this._searchCenterLabel !== null && this._searchCenterLabel.length > 0) {
                                sLabelSearchCenter = this._searchCenterLabel;
                            }
                            if (this._searchCenter !== null && this._searchCenter.length > 0) {
                                ixc = this._searchCenter.indexOf(',');
                                if (ixc !== -1) {
                                    dLatSearchCenter = BNumberUtils.parseDouble(this._searchCenter.substring(0, ixc));
                                    dLonSearchCenter = BNumberUtils.parseDouble(this._searchCenter.substring(ixc + 1));
                                }
                            }
                            this.MapLocations(arRange, arColumns, dLatSearchCenter, dLonSearchCenter, sLabelSearchCenter);
                        }
                    } catch (ex) { }
                    return undefined;
                }
            }
        }
        try {
            //TODO:
            this.appClass.openWebView(url);
        } catch (ex) { }
    }

    checkAndAddUid(sUrl) {
        let ixds = sUrl.indexOf(BBUtils.C_SLASHSLASH);
        let bBoopsieDomain = false;
        let sb = new StringBuffer();
        sb.append(sUrl);
        if (ixds !== -1) {
            if (sUrl.length > ixds + 2) {
                let ixs = sUrl.indexOf('/', ixds + 2);
                if (ixs !== -1) {
                    let ixb = sUrl.indexOf(BBUtils.C_BOOPSIE_DOT_COM);
                    if (ixb !== -1 && ixb === ixs - BBUtils.C_BOOPSIE_DOT_COM.length) {
                        if (sUrl.charAt(ixb - 1) === '.') {
                            bBoopsieDomain = true;
                        } else {
                            if (ixds === ixb - 2) {
                                bBoopsieDomain = true;
                            }
                        }
                    } else {
                        ixb = -1;
                    }
                    if (ixb === -1) {
                        ixb = sUrl.indexOf(BBUtils.C_BOOPSIE_DOT_MOBI);
                        if (ixb !== -1 && ixb === ixs - BBUtils.C_BOOPSIE_DOT_MOBI.length) {
                            if (sUrl.charAt(ixb - 1) === '.') {
                                bBoopsieDomain = true;
                            } else {
                                if (ixds === ixb - 2) {
                                    bBoopsieDomain = true;
                                }
                            }
                        } else {
                            ixb = -1;
                        }
                        if (ixb === -1) {
                            ixb = sUrl.indexOf(global.globalVars.murl_syndicatorDomain);
                            if (ixb !== -1 && ixb === ixs - global.globalVars.murl_syndicatorDomain.length) {
                                if (sUrl.charAt(ixb - 1) === '.') {
                                    bBoopsieDomain = true;
                                } else {
                                    if (ixds === ixb - 2) {
                                        bBoopsieDomain = true;
                                    }
                                }
                            }
                        }
                    }
                    let bsid = this.appClass.get_BSID();
                    if (typeof(bsid) !== 'undefined' && bsid.length > 0) {
                        if (sUrl.indexOf('?') !== -1) {
                            sb.append('&');
                        } else {
                            sb.append('?');
                        }
                        sb.append(CMenuAdapter.C_MOFIID_EQUAL);
                        sb.append(this.appClass.get_BSID());
                        if (global.globalVars.getBPIN().length > 0) {
                            sb.append(CMenuAdapter.C_AMP_BPIN_EQUAL);
                            sb.append(global.globalVars.getBPIN());
                        }
                    } else {
                        if (bBoopsieDomain) {
                            if (sUrl.indexOf('?') !== -1) {
                                sb.append('&');
                            } else {
                                sb.append('?');
                            }
                            sb.append(CMenuAdapter.C_UID_EQUAL);
                            sb.append(this.appClass.getGUID());
                            if (global.globalVars.getBPIN().length > 0) {
                                sb.append(CMenuAdapter.C_AMP_BPIN_EQUAL);
                                sb.append(global.globalVars.getBPIN());
                            }
                            if (global.globalVars.getBlocation().getLat() !== 0.0 && global.globalVars.getBlocation().getLon() !== 0.0) {
                                sb.append(CMenuAdapter.C_AMP_BGPS_EQUAL);
                                sb.append(global.globalVars.getBlocation().getCommaFormattedLatLon());
                            }
                        }
                    }
                }
            }
        }
        return sb.toString();
    }

    RefreshR() {
        this.appClass.lastHistoryRequest = "force";   // anything here...
        this.appClass.search(this.appClass.getIncrementalBaseUrl())
    }
    RefreshS() {
        let sText = BBUtils.UrlEncode(BBUtils.Utf8Encode(this.appClass.getText()));
        let sIncrementalBaseUrl = this.appClass.getIncrementalBaseUrl();

        let ix = sIncrementalBaseUrl.indexOf("@");
        if (ix != -1) {
            sIncrementalBaseUrl = sIncrementalBaseUrl.substring(0, ix + 1)
                + sText;
        } else {
            sIncrementalBaseUrl = sIncrementalBaseUrl + "@" + sText;
        }
        this.appClass.setIncrementalBaseUrl(sIncrementalBaseUrl)
        this.RefreshR();
    }

    forwardItem(sKey, arColumns) {
        if (arColumns.length > CMenuAdapter.IX_URL)
            this.processAction(arColumns[CMenuAdapter.IX_URL], true);
    }

    ForwardItem() {
        if (this.m_iSelected >= 0) {
            if (this.m_arColumns[this.m_iSelected].length > CMenuAdapter.IX_URL
                && this.m_arColumns[this.m_iSelected][CMenuAdapter.IX_URL].length > 0)
                this.forwardItem(this.m_arKeyPhrases[this.m_iSelected],
                    this.m_arColumns[this.m_iSelected]);
        }
    }

    dynSelectBase(ix, arColumns, dynType) {
        let bDidAction = false;
        if (ix < this._dynamicMenuList.length) {
            let dynMenu = new DynMenuList(this._dynamicMenuList[ix]);
            bDidAction = this.dynSelectBaseWithMenu(dynMenu, arColumns, dynType);
        }
        return bDidAction;
    }

    dynSelect(ix) {
        let ar = [];
        ar.push(this.appClass.getIncrementalBaseUrl());
        this.dynSelectBase(ix, ar, CMenuAdapter.DYNMENU_TEXTBOX);
    }
    dynSelectList(ix) {
        let bRet = false;
        if (this.m_iSelected >= 0 && this.m_iSelected < this.m_iListLen) {
            let arColumns = new Array(this.m_arColumns[this.m_iSelected].length + 1);
            arColumns[0] = this.appClass.getIncrementalBaseUrl().repeat(1);
            for (let i = 0; i < this.m_arColumns[this.m_iSelected].length; i++) {
                arColumns[i + 1] = this.m_arColumns[this.m_iSelected][i].repeat(1);
            }
            bRet = this.dynSelectBase(ix, arColumns, CMenuAdapter.DYNMENU_CMENU);
        } else {
            let ar = new Array(1);
            ar[0] = this.appClass.getIncrementalBaseUrl().repeat(1);
            bRet = this.dynSelectBase(ix, ar, CMenuAdapter.DYNMENU_CMENU);
        }
        return bRet;
    }
    getImageUrlNoAdd(sTagType, ixIH, _arColumns) {
        for (let ix = 0; ix < this._dynamicMenuList.length; ix++) {
            let sButton = this._dynamicMenuList[ix].getButton().trim();
            if (sButton !== null && sButton.length > 0 && sButton.startsWith(sTagType)) {
                let sAction = null;
                let firstExtra = this._dynamicMenuList[ix].getFirstExtra();
                if (firstExtra !== null && firstExtra.length > 0) {
                    let iImageId = BNumberUtils.parseInt(firstExtra);
                    if (ixIH === iImageId) {
                        sAction = this._dynamicMenuList[ix].getAction();
                        sAction = this.parseDollarArgs(_arColumns, sAction, true);
                        if (sAction !== null && sAction.length > 0) {
                            return sAction;
                        } else {
                            return null;
                        }
                    }
                } else {
                    if (ixIH === 0) {
                        sAction = this._dynamicMenuList[ix].getAction();
                        sAction = this.parseDollarArgs(_arColumns, sAction, true);
                        if (sAction !== null && sAction.length > 0) {
                            return sAction;
                        } else {
                            return null;
                        }
                    }
                }
            }
        }
        return null;
    }
    getIHUrl(sTagType, ixIH, _arColumns) {

        for (let ix = 0; ix < this._dynamicMenuList.length; ix++) {
            let sButton = this._dynamicMenuList[ix].getButton().trim();
            if (sButton !== null && sButton.length > 0 && sButton.startsWith(sTagType)) {
                let sAction = null;
                let firstExtra = this._dynamicMenuList[ix].getFirstExtra();
                if (firstExtra !== null && firstExtra.length > 0) {
                    let iImageId = BNumberUtils.parseInt(firstExtra);
                    if (ixIH === iImageId) {
                        sAction = this._dynamicMenuList[ix].getAction();
                        let arColumns = new Array(_arColumns.length + 1);
                        arColumns[0] = global.globalVars.appClass.getIncrementalBaseUrl();
                        let cnt = _arColumns.length;
                        for (let i = 0; i < cnt; i++) {
                            arColumns[i + 1] = _arColumns[i];
                        }
                        sAction = this.parseDollarArgs(arColumns, sAction, true);
                        if (sAction !== null && sAction.length > 0) {
                            return sAction;
                        } else {
                            return null;
                        }
                    }
                } else {
                    if (ixIH === 0) {
                        sAction = this._dynamicMenuList[ix].getAction();
                        let arColumns = new Array(_arColumns.length + 1);
                        arColumns[0] = global.globalVars.appClass.getIncrementalBaseUrl();
                        let cnt = _arColumns.length;
                        for (let i = 0; i < cnt; i++) {
                            arColumns[i + 1] = _arColumns[i];
                        }
                        sAction = this.parseDollarArgs(arColumns, sAction, true);
                        if (sAction !== null && sAction.length > 0) {
                            return sAction;
                        } else {
                            return null;
                        }
                    }
                }
            }
        }
        return null;
    }
    //   ...  Overlay; 1; 0[; noscale]
    // passing a 3rd extra of noscale will pull the image at its original size and use it
    getOverlayUrl_internal(sTagType, ixIH, _arColumns, ix2ndExtra, width, height, retObj) {

        for (let ix = 0; ix < this._dynamicMenuList.length; ix++) {
            let sButton = this._dynamicMenuList[ix].getButton().trim();
            if (sButton !== null && sButton.length > 0 && sButton.startsWith(sTagType)) {
                let sAction = null;
                let firstExtra = this._dynamicMenuList[ix].getFirstExtra();
                let secondExtra = this._dynamicMenuList[ix].getSecondExtra();
                if (firstExtra !== null && firstExtra.length > 0 && secondExtra != null && secondExtra.length > 0) {
                    let iImageId = BNumberUtils.parseInt(firstExtra);
                    let iOverlayId = BNumberUtils.parseInt(secondExtra);
                    if (ixIH === iImageId && ix2ndExtra === iOverlayId) {
                        sAction = this._dynamicMenuList[ix].getAction();
                        let arColumns = new Array(_arColumns.length + 1);
                        arColumns[0] = global.globalVars.appClass.getIncrementalBaseUrl();
                        let cnt = _arColumns.length;
                        for (let i = 0; i < cnt; i++) {
                            arColumns[i + 1] = _arColumns[i];
                        }
                        sAction = this.parseDollarArgs(arColumns, sAction, true);
                        if (sAction !== null && sAction.length > 0) {
                            sAction = BImageSpec.getRemoteImageUrl(sAction);
                            var sScale = this._dynamicMenuList[ix].getThirdExtra();
                            var bScale = true;
                            if (sScale != null && sScale.length > 0) {
                                if (sScale.toLowerCase().equals('noscale')) {
                                    bScale = false;
                                }
                            }

                            if (bScale) {
                                sAction = global.globalVars.getConvertUrl(sAction, width, height);
                            }
                            if (typeof retObj != 'undefined') {
                                retObj.scale = bScale;
                            }

                            return sAction;
                        } else {
                            return null;
                        }
                    }
                }
            }
        }
        return null;
    }

    getImageUrl(ixImage, _arColumns) {
        return this.getIHUrl(CMenuAdapter.C_IMAGE, ixImage, _arColumns);
    }
    getOverlayUrl(ixImage, _arColumns, ixOverlay, width, height, retObj) {
        return this.getOverlayUrl_internal(CMenuAdapter.C_OVERLAY, ixImage, _arColumns, ixOverlay, width, height, retObj);
    }
    getHTMLUrl(ixHTML, _arColumns) {
        return this.getIHUrl(CMenuAdapter.C_HTML, ixHTML, _arColumns);
    }
    getHScrollDecor(ixHScroll, _arColumns) {
        let sDecor = this.appClass.getHSList(ixHScroll);
        if (sDecor != null) {
            let arColumns = new Array(_arColumns.length + 1);
            arColumns[0] = global.globalVars.appClass.getIncrementalBaseUrl();
            let cnt = _arColumns.length;
            for (let i = 0; i < cnt; i++) {
                arColumns[i + 1] = _arColumns[i];
            }
            sDecor = this.parseDollarArgs(arColumns, sDecor, true);
            return sDecor;
        }
        return null;
    }

    MapLocation(lat, lon, sDescription, dLatSearchCenter, dLonSearchCenter, sLabelSearchCenter, imgUrl, cColor) {
        let arMapItems = new Array();
        let latLng = new LatLng(BBUtils.roundMapPrecision(lat), BBUtils.roundMapPrecision(lon));

        arMapItems.push(new TropareMapItem(latLng, sDescription, cColor, imgUrl, null));

        this.appClass.mapLocations(dLatSearchCenter, dLonSearchCenter,
            sLabelSearchCenter, this.radarType,
            arMapItems);

        return true;
    }

    MapLocations(arItems, arColumns, dLatSearchCenter, dLonSearchCenter, sLabelSearchCenter) {
        let bHandled = false;
        let arMapItems = new Array();
        let ix = 0;
        let iCount = 0;
        for (ix = 0; ix < arItems.length; ix++) {
            let i = 0;
            i = arItems[ix];
            if (i >= 0 && arColumns.length > i) {
                if (arColumns[i].length > 2) {
                    let sLatLon = arColumns[i][2];
                    if (BBUtils.validLatLon(sLatLon)) {
                        iCount++;
                    }
                }
            }
        }
        if (iCount === 0) {
            return false;
        }
        try {
            iCount = 0;
            for (ix = 0; ix < arItems.length; ix++) {
                let i = 0;
                i = arItems[ix];
                if (i >= 0 && arColumns.length > i) {
                    if (arColumns[i].length > 2) {
                        let sLatLon = arColumns[i][2];
                        if (BBUtils.validLatLon(sLatLon)) {
                            let sDescription = null;
                            let latLng = null;
                            let cColor = CMenuAdapter.TROP_DEFAULT_MAKER_COLOR;
                            let lat = 0;
                            let lon = 0;
                            let colorSet = false;
                            let ixc = sLatLon.indexOf(',');
                            lat = BNumberUtils.parseDouble(sLatLon.substring(0, ixc));
                            lon = BNumberUtils.parseDouble(sLatLon.substring(ixc + 1));
                            sDescription = arColumns[i][0];
                            latLng = new LatLng(BBUtils.roundMapPrecision(lat), BBUtils.roundMapPrecision(lon));
                            if (this.m_mapTintDefaultFormat !== null) {
                                let sTintFormat = this.parseDollarArgsOffset(arColumns[i], this.m_mapTintDefaultFormat, false, 1);
                                if (sTintFormat !== null) {
                                    let status = new BStatus(true);
                                    let cResult = DecorNode.parseColorString(sTintFormat, status);
                                    if (status.getStatus()) {
                                        cColor = cResult;
                                        colorSet = true;
                                    }
                                }
                            }
                            if (!colorSet) {
                                if (this.HasBackColor(i)) {
                                    cColor = this.GetBackColor(i);
                                }
                            }
                            let imgUrl = null;
                            if (this.m_mapImageId !== Number.MAX_SAFE_INTEGER) {
                                imgUrl = this.getImageUrl(this.m_mapImageId, arColumns[i]);
                                if (this.radarType === 0) {
                                    this.radarType = DecorNode.getFreeType();
                                }
                                if (imgUrl === null) {
                                    imgUrl = this.m_mapImageDefaultUrl;
                                }
                            }
                            if (imgUrl === null) {
                                imgUrl = CMenuAdapter.TROP_DEFAULT_MARKER_IMAGE_URL;
                            }
                            let sClickAction = null;
                            sClickAction = this.getClickActionForRow(arColumns[i]);
                            if (sClickAction === null) {
                                sClickAction = arColumns[i][1];
                            }
                            arMapItems.push(new TropareMapItem(latLng, sDescription, cColor, imgUrl, sClickAction));
                            iCount++;
                        }
                    }
                }
            }
            try {

                this.appClass.mapLocations(dLatSearchCenter, dLonSearchCenter,
                    sLabelSearchCenter, this.radarType,
                    arMapItems);
            } catch (ex) {
                this.showMessage(this.getString(R.string.C_NO_MAPS));
            }
            bHandled = true;
        } catch (ex) {
            console.log('MapLocations: ', ex.toString());
        }
        return bHandled;
    }
    findHTMLItemNo(ihRow, itemNo) {
        const sz = ihRow.size();
        for (let i=0; i<sz; i++) {
            let ir = ihRow.get(i);
            if (ir.getItemNo() === itemNo) {
                return ir;
            }
        }
        return null;
    }
    findHScrollItemNo(ihRow, itemNo) {
        const sz = ihRow.size();
        for (let i=0; i<sz; i++) {
            let ir = ihRow.get(i);
            if (ir.getItemNo() === itemNo) {
                return ir;
            }
        }
        return null;
    }

    deleteDataRow(ixRow) {
        let bRet = false;
        if (ixRow >= 0 && ixRow < this.m_arKeyPhrases.length) {
            if (this.m_arColumns.length === 1) { 

            } else {
                let k = 0;
                let arKeyPhrases = new Array(this.m_arKeyPhrases.length - 1);
                let arTempC = new Array(this.m_arColumns.length - 1);
                let arFlags = new Array(this.m_arColumns.length - 1);
                k = 0;
                for (let j = 0; j < this.m_arColumns.length; j++) {
                    if (j !== ixRow) {
                        arTempC[k] = this.m_arColumns[j];
                        arKeyPhrases[k] = this.m_arKeyPhrases[j];
                        arFlags[k] = this.m_arFlags[j];
                        k++;
                    }
                }
                this.m_arKeyPhrases = arKeyPhrases;
                this.m_arColumns = arTempC;
                this.m_arFlags = arFlags;
                this.m_iListLen = this.m_arKeyPhrases.length;
                let v = this.m_arHTMLRows.get(ixRow);
                if (v !== null) {
                    for (let j = 0; j < v.size(); j++) {
                        let hr = v.get(j);
                        if (hr !== null) {
                            if (hr.getWebView() !== null) {
//TODO
/*
                                if (hr.getWebView().getParent() !== null) {
                                    (hr.getWebView().getParent()).removeView(hr.getWebView());
                                }
*/

                            }
                            hr.setWebView(null);
                        }
                    }
                    v.clear();
                }
                this.m_arHScrollRows.remove(ixRow);
                v = this.m_arHScrollRows.get(ixRow);
                if (v !== null) {
                    for (let j = 0; j < v.size(); j++) {
                        let hr = v.get(j);
                        if (hr !== null) {
                            if (hr.getHScrollView() !== null) {
//TODO
/*
                                if (hr.getWebView().getParent() !== null) {
                                    (hr.getWebView().getParent()).removeView(hr.getWebView());
                                }
*/

                            }
                            hr.setHScrollView(null);
                        }
                    }
                    v.clear();
                }
                this.m_arHScrollRows.remove(ixRow);

            }
            if (typeof this.m_htForeColor[ixRow] !== 'undefined') {
                delete this.m_htForeColor[ixRow];
            }
            if (typeof this.m_htBackColor[ixRow] !== 'undefined') {
                delete this.m_htBackColor[ixRow];
            }
            this.setupRowInfoFrom_arColumns(ixRow);
            bRet = true;
        }
        return bRet;
    }

    // TropareCommandCallback
    commandGetRowCount(params) {
        return { 'data': this.m_arKeyPhrases.length.toString() };
    }
    commandGetDataAll(params) {
        let dRet = {};
        try {
            let jaRows = [];
            for (let ixRow = 0; ixRow < this.m_arColumns.length; ixRow++) {
                let jaCols = [];
                let asResult = this.m_arColumns[ixRow];
                for (let j = 0; j < asResult.length; j++) {
                    jaCols.push(asResult[j]);
                }
                jaRows.push(jaCols);
            }
            dRet['data'] = jaRows;
        } catch (ignore) { }
        return dRet;
    }
    
    commandGetDataRow(params) {
        let dRet = {};
        let ixRow = 0;
        try {
            let bOk = true;
            if (params !== null) {
                if (params.hasOwnProperty('row')) {
                    ixRow = parseInt(params['row']);
                } else {
                    dRet['error'] = 1;
                    dRet['message'] = 'No \'row\' specified';
                    bOk = false;
                }
            }
            if (bOk) {
                if (ixRow >= 0 && ixRow < this.m_arColumns.length) {
                    let jaRow = [];
                    let asResult = this.m_arColumns[ixRow];
                    for (let j = 0; j < asResult.length; j++) {
                        jaRow.push(asResult[j]);
                    }
                    dRet['data'] = jaRow;
                } else {
                    dRet['error'] = 1;
                    dRet['message'] = '\'row\' out of range';
                }
            }
        } catch (ignore) { }
        return dRet;
    }
      commandGetDataCell(params) {
        let dRet = {};
        let ixRow = 0;
        let ixCol = 0;
        try {
          let bOk = true;
          if (params !== null) {
            if (params.hasOwnProperty('row') && params.hasOwnProperty('column')) {
              ixRow = parseInt(params['row']);
              ixCol = parseInt(params['column']);
            } else {
              dRet.put('error', 1);
              dRet.put('message', 'Must specify \'row\' and \'column\'');
              bOk = false;
            }
          }
          if (bOk) {
            if (ixRow >= 0 && ixRow < this.m_arColumns.length && ixCol >= 0 && ixCol < this.m_arColumns[ixRow].length) {
              dRet['data'] = this.m_arColumns[ixRow][ixCol];
            } else {
              dRet['error'] =1;
              dRet['message'] = '\'row\'/\'column\' out of range';
            }
          }
        } catch (ignore) {
            console.log(ignore.toString());
        }
        return dRet;
      }
      commandGetHeader(params) {
        let dRet = {};
        try {
          let bOk = true;
          let sName = null;
          if (params !== null && params.hasOwnProperty('name')) {
            sName = params['name'];
          } else {
            dRet['error'] = 1;
            dRet['message'] = 'No \'name\' specified';
            bOk = false;
          }
          if (bOk) {
            let sResult = '';
            if (this.midlet._httpHeader !== null && sName !== null) {
//TODO              sResult = this.midlet._httpHeader.getHeaderField(sName);
              if (sResult === null) {
                sResult = '';
              }
            }
            dRet['name'] = sName;
            dRet['value'] = sResult;
          }
        } catch (ignore) {}
        return dRet;
      }
      commandGetHeaderAll(params) {
        let bOk = false;
        let dRet = {};
        try {
            dRet['data'] = this.appClass.getAllHeaders();
            bOk = true;
          }
        catch (ignore) {}
        try {
          if (!bOk) {
            dRet['error'] =  1;
            dRet['message'] = 'Unable to get headers';
          }
        } catch (ignore) {}
        return dRet;
      }
    commandGetHeaderStartsWith(params) {
        let bOk = false;
        let dRet = {};
        let sMsg = 'Unable to get headers';
        try {
            if (params !== null && params.hasOwnProperty('name')) {
                let sName = params['name'];
                dRet['data'] = this.appClass.getAllHeadersStartsWith(sName.toLowerCase());
                bOk = true;
            } else {
                sMsg = 'Must specify \'name\'';
            }
        } catch (ignore) {
            console.log(ignore.toString())
         }
        try {
            if (!bOk) {
                dRet['error'] = 1;
                dRet['message'] = sMsg;
            }
        } catch (ignore) { }
        return dRet;
    }
    commandGetLocation(params) {
        let dRet = {};
        try {
            let sLatLon = global.globalVars.getFormattedLatLon();
            let ll = sLatLon.split(';');
            dRet['latitude'] = ll[0];
            dRet['longitude'] = ll[1];
        } catch (ignore) { }
        return dRet;
    }
    commandSetDataAll(params) {
        let dRet = {};
        try {
            this.m_arColumns = null;
            let jaRows = params['value'];
            this.m_arColumns = [];
            for (let j = 0; j < jaRows.length; j++) {
                this.m_arColumns.push([]);
                let jaCols = jaRows[j];
                for (let k = 0; k < jaCols.length; k++) {
                    this.m_arColumns[j].push(jaCols[k])
                }
            }
            dRet['error'] = 0;
        } catch (ex) {
            try {
                dRet['error'] = 1;
                dRet['message'] = 'Error replacing data';
            } catch (ignore) { }
        }
        return dRet;
    }
    commandSetDataRow(params) {
        let dRet = {};
        let ixRow = 0;
        try {
            let bOk = true;
            if (params !== null && params.hasOwnProperty('row')) {
                ixRow = parseInt(params['row']);
            } else {
                dRet['error'] = 1;
                dRet['message'] = 'No \'row\' specified';
                bOk = false;
            }
            if (bOk) {
                if (ixRow >= 0 && ixRow < this.m_arColumns.length) {
                    let ja = params['value'];
                    for (let j = 0; j < ja.length; j++) {
                        if (j < this.m_arColumns[ixRow].length) {
                            this.m_arColumns[ixRow][j] = ja[j];
                        }
                    }
                    dRet['error'] = 0;
                } else {
                    dRet['error'] = 1;
                    dRet['message'] = '\'row\' out of range';
                }
            }
        } catch (ignore) { }
        return dRet;
    }
    commandSetDataCell(params) {
        let ixRow = 0;
        let ixCol = 0;
        let sVal = '';
        let dRet = {};
        try {
            let bOk = true;
            if (params !== null) {
                if (params.hasOwnProperty('row') && params.hasOwnProperty('column') && params.hasOwnProperty('value')) {
                    ixRow = parseInt(params['row']);
                    ixCol = parseInt(params['column']);
                    sVal = params['value'];
                } else {
                    dRet['error'] = 1;
                    dRet['message'] = 'Must specify \'row\', \'column\', and \'value\'';
                    bOk = false;
                }
            }
            if (bOk) {
                if (ixRow >= 0 && ixRow < this.m_arColumns.length && ixCol >= 0 && ixCol < this.m_arColumns[ixRow].length) {
                    this.m_arColumns[ixRow][ixCol] = sVal;
                    dRet['error'] = 0;
                } else {
                    dRet['error'] = 1;
                    dRet['message'] = '\'row\'/\'column\' out of range';
                }
            }
        } catch (ignore) { }
        return dRet;
    }
    commandSetHeader(params) {
        return null;
    }
    commandSetHeaderAll(params) {
        let asHeader = null;
        return null;
    }
    commandRepaint(params) {
        let dRet = {};
        let bRefresh = true;
        try {
            let bOk = true;
            if (params !== null) {
                if (params.hasOwnProperty('refresh')) {
                    if (parseInt(params['refresh']) === 0) {
                        bRefresh = false;
                    };
                }
            }
            dRet['error'] = 0;
        } catch (ignore) { }


        // Refresh, but no data change
        this.appClass.setDelayRender(true);
        this.setupCalcLines(bRefresh);
        let dindexes = [];
        for (let i=0; i<this.m_iListLen; i++) {
            dindexes.push({key:i, index:i});
        }
        this.appClass.setDataIndexes(dindexes);
        this.appClass.setDelayRender(false);

        return dRet;
    }
    commandRefreshR(params) {
        this.RefreshR();
        return null;
    }
    commandRefreshS(params) {
        this.RefreshS();
        return null;
    }
    commandDeleteDataRow(params) {
        let dRet = {};
        let ixRow = 0;
        try {
            let bOk = true;
            if (params !== null) {
                if (params.hasOwnProperty('row')) {
                    ixRow = parseInt(params['row']);
                } else {
                    dRet['error'] = 1;
                    dRet['message'] = 'No \'row\' specified';
                    bOk = false;
                }
            }
            if (bOk) {
                if (ixRow >= 0 && ixRow < this.m_arColumns.length) {
                    this.deleteDataRow(ixRow);
//                    this.appClass.deleteRow(ixRow);
                    dRet['error'] = 0;
                } else {
                    dRet['error'] = 1;
                    dRet['message'] = '\'row\' out of range';
                }
            }
        } catch (ignore) { 
            console.log(ignore.toString());
        }
        return dRet;
    }
    findTropareWebView(tWebView) {
        if (this.m_arHTMLRows !== null) {
            for (let i = 0; i < this.m_arHTMLRows.size(); i++) {
                let v = this.m_arHTMLRows.get(i);
                if (v !== null) {
                    for (let j = 0; j < v.size(); j++) {
                        let hr = v.get(j);
                        if (hr !== null) {
                            var iframeMe = document.getElementById('biframe_' + hr.getId()).contentWindow;
                            if (iframeMe === tWebView) {
                                return i;
                            }
                        }
                    }
                }
            }
        }
        return -1;
    }
    /*
    findTropareHScrollView(tHScrollView) {
        if (this.m_arHScrollRows !== null) {
            for (let i = 0; i < this.m_arHScrollRows.size(); i++) {
                let v = this.m_arHScrollRows.get(i);
                if (v !== null) {
                    for (let j = 0; j < v.size(); j++) {
                        let hr = v.get(j);
                        if (hr !== null) {
                            var iframeMe = document.getElementById('biframe_' + hr.getId()).contentWindow;
                            if (iframeMe === tHScrollView) {
                                return i;
                            }
                        }
                    }
                }
            }
        }
        return -1;
    }
    */

    commandSetMyHeight(params, tWebView) {
        let dRet = {};
        let hRow = 0;
        try {
            let bOk = true;
            if (params !== null) {
                if (params.hasOwnProperty('height')) {
                    hRow = parseFloat(params['height']); // * this.mContext.getResources().getDisplayMetrics().density;
                } else {
                    if (params.hasOwnProperty('fitscreen')) {
                        hRow = this.appClass.getListHeight();
                    } else {
                        dRet['error'] = 1;
                        dRet['message'] = 'No \'height\' specified';
                        bOk = false;
                    }
                }
            }
            if (bOk) {
                if (hRow >= 0.0) {
                    let ixRow = this.findTropareWebView(tWebView);
                    if (ixRow !== -1) {
                        let bChanged = false;
                        let v = this.m_arHTMLRows.get(ixRow);
                        if (v !== null) {
                            for (let j = 0; j < v.size(); j++) {
                                let hr = v.get(j);
                                if (hr !== null) {
                                    var iframeMe = document.getElementById('biframe_' + hr.getId()).contentWindow;
                                    if (iframeMe === tWebView) {
                                        hr.heightSet = true;
                                        hr._rect.height = hRow;
                                        bChanged = true;
                                        break;
                                    }
                                }
                            }
                        }
                        if (bChanged) {
                            this.setupCalcLines(false);
                            //TODO: need to update/redraw row ixRow
                            this.appClass.updateRow(ixRow);
                            //notifyDataSetChanged
                        }
                    }
                    dRet['error'] = 0;
                } else {
                    dRet['error'] = 1;
                    dRet['message'] = '\'row\' out of range';
                }
            }
        } catch (ignore) {
            console.log(ignore.toString());
         }
        return dRet;
    }
    commandSetTextAreas(params, tWebView) {
        let dRet = [];
        let rects = null;
        try {
            let bOk = true;
            if (params !== null && params.hasOwnProperty('values')) {
                rects = params['values'];
            } else {
                dRet['error'] = 1;
                dRet['message'] = 'No \'height\' specified';
                bOk = false;
            }
            if (bOk) {
                if (rects !== null) {
                    tWebView.setTextAreas(rects);
                    dRet['error'] = 0;
                } else {
                    dRet['error'] = 1;
                    dRet['message'] = '\'row\' out of range';
                }
            }
        } catch (ignore) { }
        return dRet;
    }
    commandClearCookies(params) {
        let dRet = [];
        try {
            this.midlet.removeAllCookies();
            dRet['error'] = 0;
        } catch (ignore) { }
        return dRet;
    }
    commandMofi(params) {
        let dRet = [];
        try {
            let bOk = true;
            if (params !== null) {
                if (params.hasOwnProperty('url')) {
                    let mofiString = params.url;
                    this.setMofiString(mofiString);
                    dRet['error'] = 0;
                    bOk = true;
                }
            }
            if (!bOk) {
                dRet['error'] = 1;
                dRet['message'] = 'must pass url parameter';
            }
        } catch (ignore) {}

        return dRet;
    }

    // Interface to embedded Web iFrame
    returnCommand(mWebView, sCallback, sCmd, deRet) {
        if (sCallback === null || deRet === null) {
            return undefined;
        }
        try {
            let retCmd = null;
            if (deRet !== null) {
                retCmd = deRet;
            } else {
                retCmd = {};
            }
            retCmd['command'] = sCmd;

            //TODO: mWebView was the ref to the iFrame for us...perhaps save the ID in
            // the HTMLRow...
            mWebView.postMessage(retCmd, '*');
//            mWebView[sCallback](retCmd);
        } catch (ex) {
            console.log('tropareif', ex.toString());
        }
    }
    saveState(webMessage) {
        const sSaveData = webMessage;
        if (this.m_arHTMLRows.size() > this.mHTMLPosition) {
            let v = this.m_arHTMLRows.get(this.mHTMLPosition);
            if (v !== null) {
                if (this.mHTMLSubPosition < v.size()) {
                    let hr = v.get(this.mHTMLSubPosition);
                    hr.saveState(sSaveData);
                }
            }
        }
    }
    restoreState() {
        let sResult = '';
        if (this.m_arHTMLRows.size() > this.mHTMLPosition) {
            let v = this.m_arHTMLRows.get(this.mHTMLPosition);
            if (v !== null) {
                if (this.mHTMLSubPosition < v.size()) {
                    let hr = v.get(this.mHTMLSubPosition);
                    sResult = hr.restoreState();
                }
            }
        }
        return sResult;
    }
    processWebMessage(event) {
        if (typeof event.data !== 'object') return;

        let origCmd = event.data;
        let mWebView = event.source;

        try {
            let arCmds = null;
            if (origCmd.hasOwnProperty('commands')) {
                arCmds = origCmd['commands'];
            } else if (origCmd.hasOwnProperty('command')) {
                arCmds = [];
                arCmds.push(origCmd);
            } else {
                return;
            }
            for (let j = 0; j < arCmds.length; j++) {
                let isError = false;
                let errorMsg = null;
                let callBack = null;
                let params = null;
                let deRet = null;
                let mCommandCallback = this;

                try {
                    let cmd = arCmds[j];
                    callBack = cmd['callback'];
                    if (cmd.hasOwnProperty('parameters')) {
                        params = cmd['parameters'];
                    }
                    let sCmd = cmd['command'];
                    if (sCmd.equals('getRowCount')) {
                        deRet = mCommandCallback.commandGetRowCount(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('getDataAll')) {
                        deRet = mCommandCallback.commandGetDataAll(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('getDataRow')) {
                        deRet = mCommandCallback.commandGetDataRow(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('getDataCell')) {
                        deRet = mCommandCallback.commandGetDataCell(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('getHeader')) {
                        deRet = mCommandCallback.commandGetHeader(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('getHeaderStartsWith')) {
                        deRet = mCommandCallback.commandGetHeaderStartsWith(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('getHeaderAll')) {
                        deRet = mCommandCallback.commandGetHeaderAll(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('getLocation')) {
                        deRet = mCommandCallback.commandGetLocation(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('setDataAll')) {
                        deRet = mCommandCallback.commandSetDataAll(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('setDataRow')) {
                        deRet = mCommandCallback.commandSetDataRow(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('setDataCell')) {
                        deRet = mCommandCallback.commandSetDataCell(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('setHeader')) {
                        deRet = mCommandCallback.commandSetHeader(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('setHeaderAll')) {
                        deRet = mCommandCallback.commandSetHeaderAll(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('repaint')) {
                        deRet = mCommandCallback.commandRepaint(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('refreshr')) {
                        deRet = mCommandCallback.commandRefreshR(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('refreshs')) {
                        deRet = mCommandCallback.commandRefreshS(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('deleteDataRow')) {
                        deRet = mCommandCallback.commandDeleteDataRow(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('setMyHeight')) {
                        deRet = mCommandCallback.commandSetMyHeight(params, mWebView);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('setTextAreas')) {
                        deRet = mCommandCallback.commandSetTextAreas(params, mWebView);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('clearCookies')) {
                        deRet = mCommandCallback.commandClearCookies(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);
                    } else if (sCmd.equals('mofi')) {
                        deRet = mCommandCallback.commandMofi(params);
                        this.returnCommand(mWebView, callBack, sCmd, deRet);

                    }
                } catch (ex) {
                    console.log('tropareif', ex.toString());
                }
                if (isError && callBack !== null) {
                    try {
                        let retError = {};
                        retError['error'] = 1;
                        if (errorMsg !== null) {
                            retError['message'] = errorMsg;
                        }
                        let sRet = JSON.stringify(retError);
                        let sUrl = 'javascript:' + callBack + '(\'' + BBUtils.escapeEcmaScript(sRet) + '\')';
                        this.mWebView.post(new Runnable());
                    } catch (ex) { }
                }
            }
        } catch (ignore) { }

    }
}

CMenuAdapter.B_RIGHTBUTTON_REGULAR = 0;
CMenuAdapter.B_RIGHTBUTTON_SLIDEOUT = 1;
CMenuAdapter.DYNMENU_TEXTBOX = 0;
CMenuAdapter.DYNMENU_CMENU = 2;
CMenuAdapter.C_TB = "TB";
CMenuAdapter.C_MAP = "Map";
CMenuAdapter.C_MAP_COLON = "Map:";
CMenuAdapter.TROP_DEFAULT_MAKER_COLOR = 0x0ff2f4388;
CMenuAdapter.TROP_DEFAULT_MARKER_IMAGE_URL = "r:img/sic2_.png";
CMenuAdapter.C_RANGE_EQUAL = "range=";
CMenuAdapter.C_DIRECTIONS = "Directions";
CMenuAdapter.C_VCARD = "VCard";
CMenuAdapter.C_ICAL = "ICal";
CMenuAdapter.C_PHOTO = "Photo";
CMenuAdapter.C_PICVIEWER = "PicViewer";
CMenuAdapter.C_VIDEOVIEWER = "VideoViewer";
CMenuAdapter.C_XBROWSER = "XBrowser";
CMenuAdapter.C_HTML = "HTML";
CMenuAdapter.C_IMAGE = "Image";
CMenuAdapter.C_OVERLAY = "Overlay";

CMenuAdapter.GGCACTION_INTERNAL = 0;
CMenuAdapter.GGCACTION_EXTERNAL = 1;
CMenuAdapter.GGCACTION_EXTERNAL_WNAV = 2;

// Positions (need these in CMenu.java and BoopsieMF.java too)
CMenuAdapter.IX_KEYPHRASE = 0;
CMenuAdapter.IX_URL = 1;
CMenuAdapter.IX_LATLON = 2;
CMenuAdapter.IX_TEL = 3;

CMenuAdapter.C_I_COLON = "i:";
CMenuAdapter.C_TEL_COLON = "tel:";
CMenuAdapter.C_MAILTO_COLON = "mailto:";
CMenuAdapter.C_SMS_COLON = "sms:";

CMenuAdapter.C_CURL_QMARK_U_EQUAL = "/curl?u=";
CMenuAdapter.DIVIDER_COLOR = 0x0FFe0e0e0;

	// Bitmsks
CMenuAdapter.MLI_HAS_PIPE           = 0x0001;
CMenuAdapter.MLI_HAS_FORECOLOR      = 0x0002;
CMenuAdapter.MLI_HAS_BACKCOLOR      = 0x0004;
CMenuAdapter.MLI_ACCESSORY_MASK     = 0x0018;	// OR of CHEVRON | BLUECHEVRON
CMenuAdapter.MLI_HAS_CHEVRON        = 0x0008;
CMenuAdapter.MLI_HAS_BLUECHEVRON    = 0x0010;

CMenuAdapter.CI_BG_COLOR_ALPHA = 0x0FF000000;

CMenuAdapter.SYS_MENU_FLAG = 0x8000;
CMenuAdapter.MENU_HOME = 1;
CMenuAdapter.MENU_TEST = 2;
CMenuAdapter.MENU_SETTINGS = 3;
CMenuAdapter.MENU_EXIT = 4;
CMenuAdapter.MENU_RMENU = 5;

CMenuAdapter.C_AMP_BPIN_EQUAL = "&B-PIN=";
CMenuAdapter.C_MOFIID_EQUAL = "mofiid=";
CMenuAdapter.C_UID_EQUAL = "uid=";
CMenuAdapter.C_AMP_BGPS_EQUAL = "&B-GPS=";

CMenuAdapter.C_pin_EQUAL = "pin=";
CMenuAdapter.C_PIN_EQUAL = "PIN=";
CMenuAdapter.C_C_EQUAL = "c=";
CMenuAdapter.C_O_EQUAL = "o=";
CMenuAdapter.C_K_EQUAL = "k=";
CMenuAdapter.C_I_EQUAL = "i=";
CMenuAdapter.C_BPIN_EQUAL = "B-PIN=";
CMenuAdapter.C_bpin_EQUAL = "b-pin=";
CMenuAdapter.C_msg_EQUAL = "msg=";
CMenuAdapter.C_RULR_EQUAL = "rurl=";
CMenuAdapter.C_RMENU_EQUAL = "rmenu=";
