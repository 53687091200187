import BShape from './BShape';
import Fixed32 from './Fixed32';
import BStatus from './BStatus';
import BImageSpec from './BImageSpec';
import DecorNode from './DecorNode';
import BPColor from './BPColor';
import BGraphics from './BGraphics';
import BBUtils from '../view/BBUtils';
import Parser from './shunt';

export default class BRect extends BShape {
  constructor(iType) {
    super(iType);

    this.evaluated = false;  // 2020-10-10

    this._maskSet = 0;
    this._x = 0;
    this._y = 0;
    this._width = 0;
    this._height = 0;
    this._rx = 0;
    this._ry = 0;
  }
  isValid() {
    switch (super.getType()) {
      case BShape.SHAPE_RECTANGLE:
        return ((this._maskSet & BRect.MASK_RECTANGLE) === BRect.MASK_RECTANGLE);
      case BShape.SHAPE_ROUND_RECTANGLE:
        return ((this._maskSet & BRect.MASK_RECTANGLE) === BRect.MASK_RECTANGLE) || ((this._maskSet & BRect.MASK_RECTANGLE) === BRect.MASK_ROUND_RECTANGLE);
      case BShape.SHAPE_OVAL_RECTANGLE:
        return ((this._maskSet & BRect.MASK_RECTANGLE) === BRect.MASK_RECTANGLE);
    }
    return false;
  }
  setX(x) {
    this._x = x;
    this._maskSet |= BRect.VAL_X;
  }
  setY(y) {
    this._y = y;
    this._maskSet |= BRect.VAL_Y;
  }
  setWidth(width) {
    this._width = width;
    this._maskSet |= BRect.VAL_WIDTH;
  }
  setHeight(height) {
    this._height = height;
    this._maskSet |= BRect.VAL_HEIGHT;
  }
  setRX(rx) {
    this._rx = rx;
    this._maskSet |= BRect.VAL_RX;
  }
  setRY(ry) {
    this._ry = ry;
    this._maskSet |= BRect.VAL_RY;
  }
  draw(ctx, g, mPaint, dnBase, x, y, width, height) {
    // 2020-10-10 compute dimensions if typeof _x is not number
    if (!this.evaluated) {
      if (typeof this._x == 'object') {
        this._x.pop();    // x
        var fdv = Parser.evalTokens(this._x, width, height);
        this._x = fdv;
      }
      if (typeof this._y == 'object') {
        this._y.pop();    // y
        var fdv = Parser.evalTokens(this._y, width, height);
        this._y = fdv;
      }
      if (typeof this._width == 'object') {
        this._width.pop();    // width
        var fdv = Parser.evalTokens(this._width, width, height);
        this._width = fdv;
      }
      if (typeof this._height == 'object') {
        this._height.pop();    // width
        var fdv = Parser.evalTokens(this._height, width, height);
        this._height = fdv;
      }
      if (typeof this._rx == 'object') {
        this._rx.pop();    // width
        var fdv = Parser.evalTokens(this._rx, width, height);
        this._rx = fdv;
      }
      if (typeof this._ry == 'object') {
        this._ry.pop();    // width
        var fdv = Parser.evalTokens(this._ry, width, height);
        this._ry = fdv;
      }

      this.evaluated = true;
    }

    let myx = 0;
    let myy = 0;
    let mywidth = 0;
    let myheight = 0;
    if (this.getInset()) {
      let xlocal = Fixed32.toInt(this._x);
      let ylocal = Fixed32.toInt(this._y);
      myx = x + xlocal;
      myy = y + ylocal;
      mywidth = width - (Fixed32.toInt(this._width) + xlocal);
      myheight = height - (Fixed32.toInt(this._height) + ylocal);
    } else {
      myx = Fixed32.toInt(this._x);
      myy = Fixed32.toInt(this._y);
      mywidth = Fixed32.toInt(this._width);
      myheight = Fixed32.toInt(this._height);
    }
    if (mywidth <= 0 || myheight <= 0) {
      return undefined;
    }
    let cx = Fixed32.toInt(this._rx);
    let cy = Fixed32.toInt(this._ry);
    g.save();
//    g.clipRect(x, y, x + width, y + height);
    g.beginPath();
    if (cx == 0 && cy == 0) {
      g.rect(myx, myy, mywidth, myheight);
    } else {
      g.moveTo(myx + cx, myy);
      g.lineTo(myx + mywidth - cx, myy);
      g.quadraticCurveTo(myx + mywidth, myy, myx + mywidth, myy + cy);
      g.lineTo(myx + mywidth, myy + myheight - cy);
      g.quadraticCurveTo(myx + mywidth, myy + myheight, myx + mywidth - cx, myy + myheight);
      g.lineTo(myx + cx, myy + myheight);
      g.quadraticCurveTo(myx, myy + myheight, myx, myy + myheight - cy);
      g.lineTo(myx, myy + cy);
      g.quadraticCurveTo(myx, myy, myx + cx, myy);
    }
    g.clip();
  
    let pnFill = this.getFill();
    if (pnFill !== null) {
      let pnChild = null;
      pnChild = DecorNode.findChildNode$2(pnFill, DecorNode.C_lowercase_c);
      if (pnChild !== null) {
        let status = new BStatus(true);
        let iv = DecorNode.parseColorNode(pnChild, status);
        if (status.getStatus()) {
          let rgbOld = g.fillStyle;
          g.fillStyle = BPColor.intToHexRGBA(iv);

          if (this.getType() === BShape.SHAPE_RECTANGLE) {
            g.fillRect(myx, myy, myx + mywidth, myy + myheight);
          } else if (this.getType() === BShape.SHAPE_ROUND_RECTANGLE) {
            if (cx > mywidth / 2) {
              cx = mywidth / 2;
            }
            if (cy > myheight / 2) {
              cy = myheight / 2;
            }
            if (cx > 0 && cy > 0) {
              BGraphics.roundRect(g, myx, myy, mywidth, myheight, cx, cy, true, false);
            }
          } else if (this.getType() === BShape.SHAPE_OVAL_RECTANGLE) {
            if (mywidth > myheight) {
              cx = myheight;
            } else {
              cx = mywidth;
            }
            cy = cx;
            if (cx > 0 && cy > 0) {
              BGraphics.roundRect(g, myx, myy, mywidth, myheight, cx, cy, true, false);
            }
          }
          g.fillStyle = rgbOld;
        }
      } else {
        pnChild = DecorNode.findChildNode$2(pnFill, DecorNode.C_lowercase_url);
        if (pnChild !== null) {
          let pn = pnChild.firstChild();
          if (pn !== null) {
            let satt = pn.TID().toLowerCase();
            if (satt !== null) {
              if (satt.startsWith('#')) {
                let bg = dnBase.hasGradientId(satt);
                if (bg !== null) {
                  if (this.getType() === BShape.SHAPE_RECTANGLE) {
                    BGraphics.fillRectGradient(g, mPaint, bg, 0, myx, myy, mywidth, myheight);
                  } else {
                    if (this.getType() === BShape.SHAPE_ROUND_RECTANGLE) {
                      if (cx > mywidth / 2) {
                        cx = mywidth / 2;
                      }
                      if (cy > myheight / 2) {
                        cy = myheight / 2;
                      }
                      if (cx > 0 && cy > 0) {
                        BGraphics.fillRoundRectGradient(g, mPaint, bg, 0, myx, myy, mywidth, myheight, cx, cy);
                      }
                    } else {
                      if (this.getType() === BShape.SHAPE_OVAL_RECTANGLE) {
                        if (mywidth > myheight) {
                          cx = myheight / 2;
                        } else {
                          cx = mywidth / 2;
                        }
                        cy = cx;
                        if (cx > 0 && cy > 0) {
                          BGraphics.fillRoundRectGradient(g, mPaint, bg, 0, myx, myy, mywidth, myheight, cx, cy);
                        }
                      }
                    }
                  }
                }
              } else {
                if (satt.startsWith(BBUtils.C_RESOURCE_PROTOCOL) || satt.startsWith(BBUtils.C_HTTP_COLON_WHACK_WHACK)) {
                  satt = BImageSpec.getRemoteImageUrl(satt);
                  let bimage = new BImageSpec(ctx, satt, pnFill, dnBase !== null ? dnBase._uiType : DecorNode.DECORNODE_TYPE_UNDEFINED);
                  let img = bimage.getImage();
                  if (img !== null) {
                    if (this.getType() === BShape.SHAPE_RECTANGLE) {
                      BGraphics.fillRectWithImage(g, mPaint, img, bimage.getAlpha(), pnFill, myx, myy, mywidth, myheight);
                    } else {
                      if (this.getType() === BShape.SHAPE_ROUND_RECTANGLE) {
                        if (cx > mywidth / 2) {
                          cx = mywidth / 2;
                        }
                        if (cy > myheight / 2) {
                          cy = myheight / 2;
                        }
                        if (cx > 0 && cy > 0) {
                          BGraphics.fillRoundRectImage(g, mPaint, img, bimage.getAlpha(), pnFill, myx, myy, mywidth, myheight, cx, cy);
                        }
                      } else {
                        if (this.getType() === BShape.SHAPE_OVAL_RECTANGLE) {
                          if (mywidth > myheight) {
                            cx = myheight / 2;
                          } else {
                            cx = mywidth / 2;
                          }
                          cy = cx;
                          if (cx > 0 && cy > 0) {
                            BGraphics.fillRoundRectImage(g, mPaint, img, bimage.getAlpha(), pnFill, myx, myy, mywidth, myheight, cx, cy);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    let pn = this.getStroke();
    if (pn !== null) {
      if (pn.TID().toLowerCase().equals(DecorNode.C_lowercase_c)) {
        let status = new BStatus(true);
        let iv = DecorNode.parseColorNode(pn, status);
        if (status.getStatus()) {
          let rgbOld = g.strokeStyle;
          g.strokeStyle = BPColor.intToHexRGBA(iv);
          if (this.getType() === BShape.SHAPE_RECTANGLE) {
            g.rect(myx, myy, myx + mywidth, myy + myheight, mPaint);
          } else {
            if (this.getType() === BShape.SHAPE_ROUND_RECTANGLE) {
              if (cx > mywidth / 2) {
                cx = mywidth / 2;
              }
              if (cy > myheight / 2) {
                cy = myheight / 2;
              }
              if (cx > 0 && cy > 0) {
                BGraphics.roundRect(g,myx, myy, mywidth, myheight, cx, cy, false, true);
              }
            } else {
              if (this.getType() === BShape.SHAPE_OVAL_RECTANGLE) {
                if (mywidth > myheight) {
                  cx = myheight;
                } else {
                  cx = mywidth;
                }
                cy = cx;
                if (cx > 0 && cy > 0) {
                  BGraphics.roundRect(g,myx, myy, mywidth, myheight, cx, cy, false, true);
                }
              }
            }
          }
          g.strokeStyle = rgbOld;
        }
      }
    }
    g.restore();
  }
}
BRect.VAL_X = 0x00000001;
BRect.VAL_Y = 0x00000002;
BRect.VAL_WIDTH = 0x00000004;
BRect.VAL_HEIGHT = 0x00000008;
BRect.VAL_RX = 0x00000010;
BRect.VAL_RY = 0x00000020;
BRect.MASK_RECTANGLE = BRect.VAL_X | BRect.VAL_Y | BRect.VAL_WIDTH | BRect.AL_HEIGHT;
BRect.MASK_ROUND_RECTANGLE = BRect.VAL_X | BRect.VAL_Y | BRect.AL_WIDTH | BRect.VAL_HEIGHT | BRect.VAL_RX | BRect.VAL_RY;
