import React from 'react';
import { ScrollView, Text } from 'react-native';

import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
    renderers,
  } from 'react-native-popup-menu';

  const {ContextMenu} = renderers;

export default class ShowMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { opened: true};

        this.onSelect = this.onSelect.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.msl != prevProps.msl) {
            this.setState({opened: this.state.opened});
        }
    }
    componentDidMount() {
    }
    componentWillUnmount() {
    }

    onTriggerPress() {
        this.setState({ opened: true });
      }

    onNothingSelected = () => {
        this.setState({ opened: false });
    }

    onClose = () => {
        global.globalVars.appClass.setMenuList(null);
    }
    onSelect = (oval) => {
        let selIx = this.props.msl.elementAt(oval).getIndex();
        this.setState({ opened: false });
        global.globalVars.selectMenuItem(selIx);   
        return true;
    }
    render() {
        if (this.props.msl === null) {
            return null;
        } else {
            return (
                <Menu
                    renderer={ContextMenu}
                    renderProps={{ top: 0, right: 0 }}
                    opened={this.state.opened}
                    onClose={this.onClose}
                    onSelect={this.onSelect}
                    onBackdropPress={this.onNothingSelected}
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}

                >
                    <MenuTrigger />
                    <MenuOptions customStyles={{
                        optionsContainer: {
                            backgroundColor: '#ddd',
                            padding: 5,
                        }
                    }
                    }>
                        <ScrollView style={{ maxHeight: 200 }}>
                            {this.props.msl.map((item, ix) => {
                                let sLabel = item.getText();
                                let arText = [];
                                if (sLabel.startsWith('|') && sLabel.length > 1) {
                                    sLabel = sLabel.substring(1);
                                }
                                arText = sLabel.split('|');

                                return (
                                    <MenuOption value={ix} key={ix}>
                                        {arText.map((txt, ixt) => {
                                            return (
                                                <Text key={ixt}>{txt}</Text>
                                            );
                                        })}
                                    </MenuOption>
                                );
                            })}
                        </ScrollView>
                    </MenuOptions>
                </Menu>
            )
        }
    }
}
