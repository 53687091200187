import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TableRowsIcon from '@mui/icons-material/TableRows';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import { Box } from "@mui/material";
import { View } from 'react-native';

function ELeftIconNav(props) {
    return(
        <Box
            sx={{
                width: "24px",
                height: "24px"
            }}
        >
            {props.mi}
        </Box>
    )
}

function ELeftCheckedIconNav(props) {
    return(
        <Box
            sx={{
                backgroundColor: '#aaaaaa',
                width: "24px",
                height: "24px"
            }}
        >
            {props.mi}
        </Box>
    )
}
// Inspired by blueprintjs
function ELeftRadioDirection(props) {
    return (
      <Radio
        sx={{
          '&:hover': {
            bgcolor: 'transparent',
          },
        }}
        disableRipple
        color="default"
        checkedIcon={<ELeftCheckedIconNav mi={props.mi} />}
        icon={<ELeftIconNav mi={props.mi}/>}
        {...props}
      />
    );
  }

export default class ELeftNav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            leftStyle: 'style',
        }
    }

    handleLeftChange = (event) => {
        this.setState({leftStyle: event.target.value});
        this.propChange(event.target.value);
    }

    propChange = (val) => {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(val);
        }
    }

    render() {
        return (
            <View style={
                this.props.style
            }
            >
                <RadioGroup ara-label="direction"
                    onChange={this.handleLeftChange}
                    value={this.state.leftStyle}
                    name="rb_dast"
                >
                    <ELeftRadioDirection value="data" mi={<TableRowsIcon 
                                        />} />
                    <ELeftRadioDirection value="style" mi={<FormatPaintIcon 
                                        />} />
                </RadioGroup>
            </View>
        )
    }
}
