import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import { RgbaStringColorPicker } from 'react-colorful';
import { Popover, Box, TextField } from "@mui/material";
import {colord} from 'colord';
import BGradientPicker from "./BGradientPicker";
import BColorSwatch from "./BColorSwatch";
import DecorNode from "../graphics/DecorNode";
import BPColor from "../graphics/BPColor";
import BLinearGradient from "../graphics/BLinearGradient";
import BImageSpec from "../graphics/BImageSpec";
import {LensBlur, Circle}  from '@mui/icons-material';
import { color } from "@mui/material/node_modules/@mui/system";

//import useClickOutside from "./useClickOutside";

function BpIcon(props) {
    return(
        <Box
            sx={{
                backgroundImage: "url(" + props.bgi + ")",
                backgroundSize: "contain",
                width: "32px",
                height: "32px"
            }}
        >
        </Box>
    )
}

function BpCheckedIcon(props) {
    return(
        <Box
            sx={{
                backgroundImage: "url(" + props.bgi + ")",
                backgroundSize: "contain",
                backgroundColor: '#aaaaaa',
                width: "32px",
                height: "32px"
            }}
        >
        </Box>
    )
}

/*
styled('span')(({ theme }) => ({
    width: 32,
    height: 32,
    backgroundImage: "url(https://img.prospectsforme.com/troparesheets-dev.boopsie/i_tile.png)",
    backgroundSize: "contain",
    'input:hover ~ &': {
        backgroundColor: '#cccccc',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#cccccc',
  });
  */
  
  // Inspired by blueprintjs
  function BpRadio(props) {
    return (
      <Radio
        sx={{
          '&:hover': {
            bgcolor: 'transparent',
          },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon bgi={props.bgi} />}
        icon={<BpIcon bgi={props.bgi}/>}
        {...props}
      />
    );
  }

export default class BDecorBG extends React.Component {
    // original = b-decor-xxx: <value here>
    // e.g.  bg(c(#fff))   bg(url(r:/img.png) tile)
    // DEFS(LG(id(#grad1) GD(tb) Alpha(255) stop(off(0) C(#deb887)) stop(off(40) C(#fff)) stop(off(60) C(#fff)) stop(off(100) c(#deb887)))) BG(url(#grad1))
    // BG(rrect(inset x(fw(100)) y(fh(100)) width(fw(100)) height(fh(100)) rx(20) ry(20) fill(url(r:img/ss005.png) tile)))
    // enableFG - if need to enable selecting FG color (object..)
    constructor(props) {
        super(props);
        this.state = {
            colorBase: 'none', // or 'gradient' - 'image' - could be others..
            fgBase: 'none',

            initialBGColor: '#777777ff',
            bgTypedColor: '#777777ff',

            initialFGColor: '#000000ff',
            fgTypedColor: '#000000ff',

            anchorEl: null,
            anchorElGrad: null,
            anchorFGEl: null,

            genGradient: null,
            genGradientImage: null,

            imageUrl: "",
            imageTreatment: 'fill',      // tile, scale, fill
            imageAlpha: 255,

            gradient: null,
        }

        this.bgSavedColor = "";
        this.fgSavedColor = "";

        this.gradientRef = React.createRef();

        this.colorChange = this.colorChange.bind(this);
        this.colorFGChange = this.colorFGChange.bind(this);
        this.gradientChange = this.gradientChange.bind(this);

    }
    
    static getDerivedStateFromProps(props, state) {
        
        let bNone = false;
        let setVals = {};

        if (typeof props.original !== 'undefined' && props.original !== null &&
            props.original != state.original) {
            try {
                let dn = DecorNode.FromAttributes(props.original, props.dnType);
                if (dn !== null) {
                    if (dn.hasBGColor()) {
                        let rgbaHex = BPColor.intToHexRGBA(dn.getBGColor());    // int, convert to hex
                        setVals['initialBGColor'] = rgbaHex;
                        setVals['bgTypedColor'] = rgbaHex;
                        setVals['colorBase'] = 'color';
                    } else {
                        let bg = dn.hasGradient(DecorNode.C_lowercase_bg);
                        if (bg !== null) {
                            // has bg gradient
                            let gradient = BDecorBG.getGradient(bg);
                            if (typeof gradient !== 'undefined') {
                                let gradientStyles = BPColor.getCSSGradient(gradient);
                                setVals['gradient'] = gradient;
                                setVals['genGradient'] = gradientStyles.background;
                                setVals['genGradientImage'] = gradientStyles.backgroundImage;
                                setVals['colorBase'] = 'gradient';
                            }
                        } else {
                            //    try background 'Resource' image
                            let specs = BImageSpec.getImageSpecs(dn, null, DecorNode.C_lowercase_bg);
                            if (specs !== null) {
                                setVals['colorBase'] = 'image';
                                setVals['imageUrl'] = specs.url;
                                setVals['imageTreatment'] = specs.treatment;
                                setVals['imageAlpha'] = specs.alpha;
                            } else {
                                bNone = true;
                            }
                        }

                        // if hasShape, drawshapes
                    }

                    if (typeof props.enableFG === 'object') {
                        if (dn[props.enableFG.dnHasFx]()) {
                            setVals['fgBase'] = 'color';
                            setVals['initialFGColor'] = BPColor.intToHexRGBA(dn[props.enableFG.dnGetFx]());
                            setVals['fgTypedColor'] = BPColor.intToHexRGBA(dn[props.enableFG.dnGetFx]());
                        } else {
                            if (typeof props.enableFG.default === 'string') {
                                setVals['initialFGColor'] = props.enableFG.default;
                                setVals['fgTypedColor'] = props.enableFG.default;
                            }
                        }
                    }
                } else {
                    bNone = true;
                }
            } catch (ignore) {
                console.log(ignore.toString());
            }

            setVals['original'] = props.original;

            if (bNone) {
                setVals['colorBase'] = 'none';
            }
            return setVals;
        } else {
            return null;
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // original, name, dnType
        /*
        if (prevProps.original != this.props.original || prevProps.name != this.props.name || prevProps.dnType != this.props.dnType) {
            this.setInitialState(true);
        }
        */
    }
    componentDidMount() {
    }

    handleFGRadioChange = (event) => {
        //event.target.value
        this.setState({fgBase: event.target.value});
        this.propChange({fg: event.target.value});
    }

    handleBaseColorChange = (event) => {
        //event.target.value
        this.setState({colorBase: event.target.value});

        this.propChange({bg: event.target.value});
    }
    handleTreatmentChange = (event) => {
        this.setState({imageTreatment: event.target.value});
        this.state.imageTreatment = event.target.value; // doesn't wait for state...
        this.propChange();
    }
    handleColorClick = (event) => {
        this.setState({anchorEl : event.currentTarget});
    }
    handleFGColorClick = (event) => {
        this.setState({anchorFGEl : event.currentTarget});
    }
    handleGradientClick = (event) => {
        this.setState({anchorElGrad : event.currentTarget});
    }

    handleColorClose = () => {
        this.setState({anchorEl: null});
    }
    handleFGColorClose = () => {
        this.setState({anchorFGEl: null});
    }
    handleGradientClose = () => {
        this.setState({anchorElGrad: null});
    }

    typedColorFocus = (event) => {
        this.bgSavedColor = this.state.bgTypedColor;
    }
    typedColorBlur = (event) => {
        let val = event.currentTarget.value;
        if (!colord(val).isValid()) {
            val = this.bgSavedColor;
        }

        event.currentTarget.style.background = null;

        this.setState({
            bgTypedColor: val,
            initialBGColor: val
            });

        event.currentTarget.style.background = null;

        this.propChange();

    }
    typedColorChanged = (event) => {
        let val = event.currentTarget.value;
        if (colord(val).isValid()) {
            this.setState({bgTypedColor: val});

            event.currentTarget.style.background = null;
        } else {
            this.setState({bgTypedColor: val});
            event.currentTarget.style.background = 'yellow';
        }
    }
    typedFGColorFocus = (event) => {
        this.fgSavedColor = this.state.fgTypedColor;
    }
    typedFGColorBlur = (event) => {
        let val = event.currentTarget.value;
        if (!colord(val).isValid()) {
            val = this.fgSavedColor;
        }

        event.currentTarget.style.background = null;

        this.setState({
            fgTypedColor: val,
            initialFGColor: val
            });

        event.currentTarget.style.background = null;

        this.propChange();
    }

    typedFGColorChanged = (event) => {
        let val = event.currentTarget.value;
        if (colord(val).isValid()) {
            this.setState({fgTypedColor: val});

            event.currentTarget.style.background = null;
        } else {
            this.setState({fgTypedColor: val});
            event.currentTarget.style.background = 'yellow';
        }
    }

    colorChange = (c) => {


        if (this.state.bgTypedColor.startsWith('#')) {
            let cval = colord(c).toHex();
            this.state.bgTypedColor = cval;
            this.setState({
                bgTypedColor: cval,
                initialBGColor: cval
            })
        } else {
            this.state.bgTypedColor = c;
            this.setState({
                bgTypedColor: c,
                initialBGColor: c
            });
        }

        this.propChange();
    }

    colorFGChange = (c) => {

        let cval = colord(c).toHex();       // TODO: lets propChange work...

        if (cval.startsWith('#')) {
            this.state.fgTypedColor = cval;
            this.state.initialFGColor = cval;
            /*
            this.setState({
                fgTypedColor: cval,
                initialFGColor: cval
            })
            */
        } else {
            this.state.fgTypedColor = c;
            this.state.initialFGColor = c;
            /*
            this.setState({
                fgTypedColor: c,
                initialFGColor: c
            });
            */
        }

        this.propChange();
    }

    fromRGBAtoARGB = (colr) => {
        // assume starts with #
        if (colr.length == 4) {     //#rgb
            return colr;
        } else if (colr.length == 5) { // #rgba
            return colr[0] + colr[4] + colr[1] + colr[2] + colr[3];
        } else if (colr.length == 7) { // #rrggbb
            return colr;
        } else if (colr.length == 9) {  // #rrggbbaa
            return colr[0] + colr[7] + colr[8] + colr[1] + colr[2] + colr[3] + colr[4] + colr[5] + colr[6];
        } else {
            return colr;
        }

    }
    createStartupGradient = () => {
        let myGradient = {
            alpha: 255,
            degrees: 90,
            palette: [],
        }
        myGradient.palette.push( {offset: 0, color: '#020024'} );
        myGradient.palette.push( {offset: 35, color: '#090979'} );
        myGradient.palette.push( {offset: 100, color: '#00d4ff'} );

        this.state.gradient = myGradient;
    }

    propChange = (pobj) => {
        // generate setProp from whatever the current settings are
        let switchVal = this.state.colorBase;
        if (typeof pobj !== 'undefined') {
            if (pobj.hasOwnProperty('bg')) {
                switchVal = pobj.bg;
            }
        }

        let pval = null;

        //NOTE: we are aarrggbb  colord is rrggbba
        switch (switchVal) {
            case 'none':
                pval = null;
                break;
            case 'color':
                pval = "bg(c(" + this.fromRGBAtoARGB(colord(this.state.bgTypedColor).toHex()) + "))";
                break;
            case 'gradient':
                if (this.state.gradient == null) {
                    this.createStartupGradient();
                }
                pval = this.generateGradient(this.state.gradient);
                break;
            case 'image':
                pval = "bg(url(" + this.state.imageUrl + ") " + this.state.imageTreatment + " alpha(" + this.state.imageAlpha+ "))";
                break;
        }

        if (typeof this.props.enableFG === 'object') {
            let fgSwitch = this.state.fgBase;
            if (typeof pobj !== 'undefined' && pobj.hasOwnProperty('fg')) {
                fgSwitch = pobj.fg;
            }
            switch (fgSwitch) {
                case 'none':
                    break;
                case 'color':
                    let fgval = 'fg(c(' + this.fromRGBAtoARGB(colord(this.state.fgTypedColor).toHex()) + "))";
                    if (typeof this.props.enableFG.wrap === 'string') {
                        fgval = this.props.enableFG.wrap + "(" + fgval + ")";
                    }
                    if (pval !== null) {
                        pval = fgval + ' ' + pval;
                    } else {
                        pval = fgval;
                    }
                    break;
            }
        }

        this.props.setProp(pval);

    }
    generateGradient = (gradient) => {
        // this.gradient (degrees, palette: [{offset: 0-100, color: <>}])
        // DEFS(LG(id(#grad1) GD(tb) Alpha(255) stop(off(0) C(#deb887)) stop(off(40) C(#fff)) stop(off(60) C(#fff)) stop(off(100) c(#deb887)))) BG(url(#grad1))
        let dir;
        switch (gradient.degrees) {
            case '0':
            case 0:
                dir = "bt";
                break;
            case '180':
            case 180:
                dir = "tb";
                break;
            case '270':
            case 270:
                dir = "rl"
                break;
            default:
                dir = 'lr'
                break;
        }
        let lgString = "DEFS(LG(id(#grad1) GD(" + dir + ") Alpha(" + gradient.alpha + ")";
        for (let i=0; i<gradient.palette.length; i++) {
            let pval = gradient.palette[i];
            lgString += " stop(off(" + pval.offset + ") C(" + colord(pval.color).toHex() + "))";
        }
        lgString += ")) BG(url(#grad1))";

//        this.gradient = gradient;  already set to this

        let gradientStyles = BPColor.getCSSGradient(gradient);
        this.setState( {
            genGradient: gradientStyles.background,
            genGradientImage: gradientStyles.backgroundImage
        })

        return lgString;
    }
    
    gradientChange = (gradient) => {
        this.state.gradient = gradient;
        this.propChange();
    }

    typedUrlFocus = (event) => {

    }

    typedUrlBlur = (event) => {
        // bg(url(<url here>) [alpha(0..255) tile|fill|scale])
        let url = event.currentTarget.value;
        let that = this;

        if (url.startsWith("data:image")) {
            global.globalVars.appClass.showMessage("Uploading image...please wait");

            let sUrl = global.globalVars.getSavePhotoUrl();
            let sdata = {
                "image-data" : url,
                "uid": global.globalVars.appClass.getGUID(),
                "B-PIN": global.globalVars.getBPIN()
            }
            global.globalVars.getEditInfo().post(sUrl, JSON.stringify(sdata), 
                function(xhr) {
                    let iurl = xhr.response;
                    that.state.imageUrl = iurl;      // so reads correctlin in propChange...
                    that.setState({imageUrl: iurl});
                    that.propChange();
                }, function (err) {
                    console.log(err);

            })

        } else {
            this.state.imageUrl = url;      // so reads correctlin in propChange...
            this.propChange();
        }

    }

    typedUrlChanged = (event) => {
        this.setState({imageUrl: event.currentTarget.value});
    }

    alphaChange = (event, newValue) => {
        let val = (newValue * 255 / 100).toFixed();
        this.setState( {imageAlpha: val});
        this.state.imageAlpha = val;        // TODO: so reds correct in propchange
        this.propChange();
    }

    render() {
        let openColorPopover = Boolean(this.state.anchorEl);
        let idCP = openColorPopover ? 'simple-color-popover' : undefined;
        let openGradientPopover = Boolean(this.state.anchorElGrad);
        let idGP = openGradientPopover ? 'simple-gradient-popover' : undefined;
        let openFGColorPopover = Boolean(this.state.anchorFGEl);
        let idFGCP = openFGColorPopover ? 'simple-fgcolor-popover' : undefined;

        return (
            <>
                {typeof this.props.enableFG === 'object' &&
                <>
                <FormLabel component="legend">{this.props.enableFG.label}</FormLabel>
                    <RadioGroup aria-label="foreground"
                        onChange={this.handleFGRadioChange}
                        value={this.state.fgBase}
                        name="rbg_fg"
                    >
                        <FormControlLabel value="none" control={<Radio />} label="Default" />
                        <FormControlLabel value="color" control={<Radio />} label="Color" />
                        {this.state.fgBase == 'color' &&
                            <div>
                                <BColorSwatch
                                    id={idFGCP}
                                    color={this.state.initialFGColor}
                                    handleColorClick={this.handleFGColorClick}
                                />
                                <TextField
                                    value={this.state.fgTypedColor}
                                    variant="standard"
                                    onFocus={this.typedFGColorFocus}
                                    onBlur={this.typedFGColorBlur}
                                    onChange={this.typedFGColorChanged}
                                    style={{
                                        marginLeft: "5px"
                                    }}
                                />
                            </div>
                        }
                        {this.state.fgBase == 'color' &&
                            <Popover
                                id={idFGCP}
                                open={openFGColorPopover}
                                anchorEl={this.state.anchorFGEl}
                                onClose={this.handleFGColorClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <RgbaStringColorPicker
                                    name="solidcolorfg"
                                    color={colord(this.state.initialFGColor).toRgbString()}
                                    style={{
                                        padding: "16px",
                                        borderRadius: "12px",
                                        background: "#33333a",
                                        boxShadow: "0 6px 12px #999",
                                    }}
                                    onChange={this.colorFGChange} />
                            </Popover>
                        }

                    </RadioGroup>
                </>
            }
                <FormLabel component="legend">{this.props.name}</FormLabel>
                <RadioGroup aria-label={this.props.name}
                    onChange={this.handleBaseColorChange}
                    value={this.state.colorBase}
                    name="rbg">
                    <FormControlLabel value="none" control={<Radio />} label="None" />
                    <FormControlLabel value="color" control={<Radio />} label="Color" />
                    {this.state.colorBase == 'color' &&
                        <div>
                            <BColorSwatch
                                id={idCP}
                                color={this.state.initialBGColor}
                                handleColorClick={this.handleColorClick}
                            />
                            <TextField
                                value={this.state.bgTypedColor}
                                variant="standard"
                                onFocus={this.typedColorFocus}
                                onBlur={this.typedColorBlur}
                                onChange={this.typedColorChanged}
                                style={{
                                    marginLeft: "5px"
                                }}
                            />
                        </div>
                    }
                    {this.state.colorBase == 'color' &&
                        <Popover
                            id={idCP}
                            open={openColorPopover}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleColorClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <RgbaStringColorPicker
                                name="solidcolor"
                                color={colord(this.state.initialBGColor).toRgbString()}
                                style={{
                                    padding: "16px",
                                    borderRadius: "12px",
                                    background: "#33333a",
                                    boxShadow: "0 6px 12px #999",
                                }}
                                onChange={this.colorChange} />
                        </Popover>
                    }

                    <FormControlLabel value="gradient" control={<Radio />} label="Gradient" />
                    {this.state.colorBase == 'gradient' &&
                        <div>
                            <div
                                ref={this.gradientRef}
                                aria-describedby={idGP}
                                style={{
                                    display: 'inline-block',
                                    background: this.state.genGradient,
                                    backgroundImage: this.state.genGradientImage,
                                    width: "182px",
                                    height: "28px",
                                    borderRadius: "8px",
                                    border: "3px solid #fff",
                                    boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)",
                                    cursor: "pointer",
                                }}
                                onClick={this.handleGradientClick}
                            />
                        </div>
                    }
                    {this.state.colorBase == 'gradient' &&
                        <Popover
                            id={idGP}
                            open={openGradientPopover}
                            anchorEl={this.state.anchorElGrad}
                            onClose={this.handleGradientClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                horizontal: 'right',
                                vertical: 'top',
                            }}
                        >
                            <BGradientPicker 
                                gref={this.gradientRef} 
                                gradient={this.state.gradient} 
                                onChange={this.gradientChange}
                            />
                        </Popover>
                    }
                    <FormControlLabel value="image" control={<Radio />} label="Image" />
                    {this.state.colorBase == 'image' &&
                        <>
                            <TextField
                                value={this.state.imageUrl}
                                variant="standard"
                                placeholder="Enter URL"
                                onFocus={this.typedUrlFocus}
                                onBlur={this.typedUrlBlur}
                                onChange={this.typedUrlChanged}
                                style={{
                                    marginLeft: "5px"
                                }}
                            />
                            <Stack spacing={2} direction="row" sx={{mb: 1}} alignItems="center">
                                <LensBlur />
                                <Slider area-label="alpha" value={((this.state.imageAlpha/255)*100).toFixed()} onChange={this.alphaChange} />
                                <Circle />
                            </Stack>
                            <RadioGroup aria-label={this.props.name}
                                onChange={this.handleTreatmentChange}
                                value={this.state.imageTreatment}
                                name="rb_fts" row>
                                <FormControlLabel value="fill" control={<BpRadio bgi="https://img.prospectsforme.com/troparesheets.boopsie/i_stretch.png" />} label="" />
                                <FormControlLabel value="tile" control={<BpRadio bgi="https://img.prospectsforme.com/troparesheets.boopsie/i_tile.png" />} label="" />
                                <FormControlLabel value="scale" control={<BpRadio bgi="https://img.prospectsforme.com/troparesheets.boopsie/i_scale.png" />} label="" />
                            </RadioGroup>
                        </>
                    }

                </RadioGroup>
            </>
        )
    }
}

BDecorBG.getGradient = (bg) => {
    let gColors = bg.getColors();
    let stops = bg.getStops();
    if (gColors === null) {
        return undefined;
    }
    if ((gColors.length !== stops.length) || gColors.length === 0) {
      return undefined;
    }

    let gradient = {
        degrees: 0,
        alpha: 255,
        palette: []
    };

    switch (bg.getType()) {
        case BLinearGradient.GRADIENT_TB:
            gradient.degrees = 180;
          break;
        case BLinearGradient.GRADIENT_LR:
            gradient.degrees = 90;
          break;
        case BLinearGradient.GRADIENT_RL:
            gradient.degrees = 270;
          break;
        case BLinearGradient.GRADIENT_BT:
        case undefined:
            gradient.degrees = 0;
          break;
      }

    let alen = gColors.length;
    if (alen === 1) {
      let alpha = bg.getAlpha();
      gradient.alpha = alpha;
      gradient.palette.push({offset: 0, color: BPColor.intToHexRGB(((alpha << 24) | (gColors[0] & 0xffffff)) >>> 0)});      
    } else {
        // TODO: fix alpha - this isnt' wright...
      let alpha = bg.getAlpha();
      gradient.alpha = alpha;
      let ptcolors = new Array(alen);
      for (let i = 0; i < alen; i++) {
        ptcolors[i] = ((alpha << 24) | (gColors[i] & 0xffffff)) >>> 0;
      }

      for (let i=0; i<alen; i++) {
          gradient.palette.push({offset: stops[i], color: BPColor.intToHexRGB(ptcolors[i])});
      }      
    }
    return gradient;
}
