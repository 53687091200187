
export default class ImageFactory {
    constructor() {

    }
}
// ctx.imageSmoothingEnabled
// 
ImageFactory.fromBase64 = async (sBase64) => {
    return new Promise((resolve, reject) => {
        var newImage = new Image();
        var canvas = global.globalVars.getCanvas();

        var g = canvas.getContext('2d');
        newImage.onerror = function() {
            reject('load base64');
        };
        newImage.onload = function() {
            //canvas.width = this.width;
            //canvas.height = this.height;
            //g.drawImage(newImage, 0, 0);

            //var bm = g.getImageData(0, 0, this.width, this.height);
            var img = {height: this.height, width: this.width, image: newImage };
            resolve(img);
        }
        newImage.src = sBase64;
    });
}