export default class RowHints {
    constructor(_height, _nColumns) {
      this.columns = null;
      this.minHeight = 0;
      this._maxColumns = 0;
      this._ix = 0;
  
      this.minHeight = _height;
      this.columns = new Array(_nColumns);
      this._maxColumns = _nColumns;
      this._ix = 0;
    }
    addColumn(ch) {
      if (this._ix < this._maxColumns) {
        this.columns[this._ix] = ch;
        this._ix++;
      }
    }
  }
  