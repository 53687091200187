import React from 'react';
import {StyleSheet, View} from 'react-native';
import BLinearGradient from "../graphics/BLinearGradient";
import TBView from './TBView';
import DecorNode from '../graphics/DecorNode';
import DecorBackground from '../graphics/DecorBackground';

export default class ToolbarManager extends React.Component {
    constructor(props) {
      // ctx={ctx} refresh={bool} listRender={function<>} visible={bool} items={_tbitems} />
      // decorNodeString=<decornode string>
      super(props);

      const {ctx} = props;
      this.state = {
        buttonType: ToolbarManager.BTOOLBAR_BUTTON_TYPE_LABEL,
      }
      this.btnType = ToolbarManager.BTOOLBAR_BUTTON_TYPE_LABEL;

      this.mContext = ctx;

      this._fit = true;
      

      this.mDecorNode = null;
      this.defGradient = new BLinearGradient();
      this.defGradient.setType(BLinearGradient.GRADIENT_TB);
      this.defGradient.setAlpha(255);
      let aColors = new Array(4);
      let aStops = new Array(4);
      aColors[0] = 0xff636d73;
      aColors[1] = 0xff42454a;
      aColors[2] = 0xff393c42;
      aColors[3] = 0xff202020;
      aStops[0] = 0;
      aStops[1] = 49;
      aStops[2] = 50;
      aStops[3] = 100;
      this.defGradient.setColorsAndStops(aColors, aStops, 4);

      let dn = DecorNode.FromAttributes(props.decorNodeString, DecorNode.DECORNODE_TYPE_TOOLBAR);
      this.setDecorNode(dn);
    }
    getButtonType() {
      return this.state.buttonType;
    }
    setFit(bFit) {
      this._fit = bFit;
    }
    getDecorNode() {
      return this.mDecorNode;
    }
    setDecorNode(dn) {
      this.mDecorNode = null;
      this.mDecorNode = dn;
      let btnType = ToolbarManager.BTOOLBAR_BUTTON_TYPE_LABEL;
      if (this.mDecorNode !== null) {
        let pn = this.mDecorNode.findChildNode(DecorNode.C_lowercase_type);
        if (pn !== null) {
          pn = pn.firstChild();
          if (pn !== null && pn.TID() !== null) {
            let satt = pn.TID().toLowerCase();
            if (satt.equals(DecorNode.C_lowercase_label)) {
              btnType = ToolbarManager.BTOOLBAR_BUTTON_TYPE_LABEL;
            } else {
              if (satt.equals(DecorNode.C_lowercase_image)) {
                btnType = ToolbarManager.BTOOLBAR_BUTTON_TYPE_IMAGE;
              } else {
                if (satt.equals(DecorNode.C_lowercase_combo)) {
                  btnType = ToolbarManager.BTOOLBAR_BUTTON_TYPE_COMBO;
                }
              }
            }
          }
        }
      }
      if (btnType != this.state.buttonType) {
  this.btnType = btnType;

        this.setState( {
          buttonType: btnType,
        })
      }
    }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Called when props change, so we need to update our props
    if (typeof this.props.decorNodeString !== 'undefined' && this.props.decorNodeString !== null) {
      if (!this.props.decorNodeString.equals(prevProps.decorNodeString)) {
        let dn = DecorNode.FromAttributes(this.props.decorNodeString, DecorNode.DECORNODE_TYPE_TOOLBAR);
        this.setDecorNode(dn);
      }  
    } else {
      this.setDecorNode(null);
    }

  }

  componentDidMount() {
  }

  render() {
    if (this.props.items !== null) {
      let that = this;
      return (
        <DecorBackground
          style={[styles.container,
          {
            height: global.globalVars.getTabBarHeight(),
          }
          ]}
          refresh={this.props.refresh}
          dnType={DecorNode.DECORNODE_TYPE_TOOLBAR} decorNodeString={this.props.decorNodeString}>

          {this.props.items.map((tbItem, index) => (
            <TBView key={index}
              style={styles.content}
              btnType={that.btnType}
              tbi={tbItem}
              decorToolbarString={this.props.decorNodeString} 
              refresh={this.props.refresh}
              />
          ))}
        </DecorBackground>
      )
    } else {
      return null;
    }
  }
}
ToolbarManager.BTOOLBAR_BUTTON_TYPE_LABEL = 1;
ToolbarManager.BTOOLBAR_BUTTON_TYPE_IMAGE = 2;
ToolbarManager.BTOOLBAR_BUTTON_TYPE_COMBO = 3;
ToolbarManager.DEFAULT_TABBAR_HEIGHT = 49;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    bottom: 0,
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  }
});

