import StringBuffer from "../graphics/StringBuffer";
import utf8 from 'utf8';
import BNumberUtils from "../graphics/BNumberUtils";

export default class BBUtils {
    constructor() {}
  }
  BBUtils.C_REFRESH = 'refresh';
  BBUtils.C_REFRESHS = 'refreshs';
  BBUtils.C_UAOS = 'UAOS';
  BBUtils.C_UAPIXELS = 'UAPIXELS';
  BBUtils.C_LATLON = 'LATLON';
  BBUtils.C_SLASHSLASH = '//';
  BBUtils.C_SLASH = '/';
  BBUtils.C_SLASH_I_SLASH = '/i/';
  BBUtils.C_HexChars = '0123456789abcdef';
  BBUtils.C_SOCKET = 'Socket';
  BBUtils.C_UTF8 = 'UTF8';
  BBUtils.C_EMPTY_STRING = '';
  BBUtils.C_INTERNAL = 'Internal';
  BBUtils.C_TRUE = 'true';
  BBUtils.C_FALSE = 'false';
  BBUtils.C_HTTP_1_DOT_1 = 'HTTP/1.1 ';
  BBUtils.C_HTTP_1_DOT_0 = 'HTTP/1.0 ';
  BBUtils.C_BOOPSIESERVER = 'BoopsieServer';
  BBUtils.C_BOOPSIESOCKETSERVER = 'BoopsieSocketServer';
  BBUtils.C_HTTP = 'http';
  BBUtils.C_HTTPS = 'https';
  BBUtils.C_HTTP_COLON = 'http:';
  BBUtils.C_HTTPS_COLON = 'https:';
  BBUtils.C_CRLF = '\r\n';
  BBUtils.C_HTTP_MOFI_PATTERN = 'https?://[^\\.]*\\.[^\\.]*\\.tredir\\.com/i/';
  BBUtils.C_GEOMETRY = '-geometry%20';
  BBUtils.C_GEOMETRY_PLAIN = '-geometry ';
  BBUtils.C_BOOPSIE_DOT_COM = 'prospectsforme.com';
  BBUtils.C_BOOPSIE_DOT_MOBI = 'prospectsforme.mobi';
  BBUtils.C_BOOPSIE_DOT_BREDIR_DOT_COM = 'boopsie.tredir.com';
  BBUtils.C_RESOURCE_PROTOCOL = 'r:';
  BBUtils.C_HTTP_COLON_WHACK_WHACK = 'http://';
  BBUtils.C_IMG_EQUAL = 'img=';
  BBUtils.C_SPACE = ' ';
  BBUtils.C_COMMA = ',';
  BBUtils.C_DOTSLASH = './';
  BBUtils.C_DOTDOTSLASH = '../';
  BBUtils.m_setUAOS = false;
  BBUtils.m_UAOS = '';
  BBUtils.patLatLon = /[\+-]*\d+\.\d+,[\+-]*\d+\.\d+/;
  BBUtils.getProperty = (sName) => {
    return this.getPropDefault(sName);
  };
  BBUtils.validLatLon = (sLatLon) => {
    if (sLatLon === null || sLatLon.length === 0) {
      return false;
    }
    let m = sLatLon.match(BBUtils.patLatLon);
    if (m !== null) {
      return true;
    } else {
      return false;
    }
  };
  BBUtils.getPropDefault = (sName) => {
    if (sName.equals(this.C_UAOS)) {
      if (!this.m_setUAOS) {
        this.m_UAOS = 'iPhone/Java - Version (' + System.getProperty('os.version') + '); Carrier (none); ';
        this.m_setUAOS = true;
      }
      return this.m_UAOS;
    } else {
      return this.C_EMPTY_STRING;
    }
  };
  // Paint p, String s
  BBUtils.stringWidth = (p, s) => {
    let nChars = 0;
    let widths = new Array(s.length);
    let fRes = 0;
    nChars = p.getTextWidths(s, widths);
    for (let i = 0; i < nChars; i++) {
      fRes = fRes + widths[i];
    }
    return fRes;
  };
  BBUtils.CombineUrl = (sBaseIn, sRelIn) => {
    let ix = 0;
    let ixEndDomain = 0;
    let sBase = null;
    let sRel = sRelIn;
    let bDone = false;
    ix = sRelIn.indexOf(':');
    if (ix !== -1) {
      if (sRelIn.substring(0, ix) !== BBUtils.C_HTTP && sRelIn.substring(0, ix) !== BBUtils.C_HTTPS) {
        return sRelIn;
      }
    }
    ix = sBaseIn.indexOf('{');
    if (ix !== -1) {
      sBaseIn = sBaseIn.substring(0,ix);
    }
    
    ix = sBaseIn.lastIndexOf('/');
    if (sBaseIn.charAt(ix - 1) === '/') {
      if (sBaseIn.length > ix + 1) {
        sBase = sBaseIn + '/';
      } else {
        sBase = sBaseIn;
      }
    } else {
      sBase = sBaseIn.substring(0, ix + 1);
    }
    ixEndDomain = sBase.indexOf(BBUtils.C_SLASHSLASH);
    ixEndDomain += 2;
    ixEndDomain = sBase.indexOf('/', ixEndDomain);
    ix = sRel.indexOf('//');
    if (ix === 0) {
      if ((ix = sBase.indexOf(BBUtils.C_SLASHSLASH)) >= 0) {
        sBase = sBase.substring(0, ix) + sRel;
      }
    } else {
      if (ix !== -1) {
        sBase = sRel;
      } else {
        if (sRel.length > 0 && sRel.charAt(0) === '/') {
          sBase = sBase.substring(0, ixEndDomain) + sRel;
        } else {
          bDone = false;
          while (!bDone && (sRel.length > 0) && (ix = sRel.indexOf('/')) >= 0) {
            if (sRel.substring(0, 2).equals(BBUtils.C_DOTSLASH)) {
              sRel = sRel.substring(2);
            } else {
              if (sRel.substring(0, 3).equals(BBUtils.C_DOTDOTSLASH)) {
                ix = sBase.lastIndexOf('/', sBase.length - 2);
                if (ix >= ixEndDomain) {
                  sBase = sBase.substring(0, ix + 1);
                }
                sRel = sRel.substring(3);
              } else {
                bDone = true;
              }
            }
          }
          if (sRel.length > 0) {
            sBase = sBase + sRel;
          }
        }
      }
    }
    return sBase;
  };
  BBUtils.IsSafeChar = (chIn) => {
    let ch = chIn.charCodeAt(0);
    if ((ch >= 48 && ch <= 57) || (ch >= 97 && ch <= 122) || (ch >= 65 && ch <= 90) || (ch === 45 || ch === 95 || ch === 46 || ch === 126 || ch === 64)) {
      return true;
    } else {
      return false;
    }
  };
  BBUtils.UrlEncode = (sIn) => {
    if (typeof(sIn) === 'undefined' || sIn == null || sIn.length == 0) {
      return "";
    }
    let i = 0;
    let ch = '';
    let lenAlloc = sIn.length;
    if (lenAlloc > 0) {
      lenAlloc += lenAlloc / 5;
    }
    let sb = new StringBuffer(lenAlloc);
    for (i = 0; i < sIn.length; i++) {
      ch = sIn.charAt(i);
      if (ch === ' ') {
        sb.append("+");
      } else {
        if (!BBUtils.IsSafeChar(ch)) {
          sb.append("%");
          sb.append(BBUtils.C_HexChars.charAt((ch.charCodeAt(0) >> 4) & 0x0f));
          sb.append(BBUtils.C_HexChars.charAt(ch.charCodeAt(0) & 0x0f));
        } else {
          sb.append(ch);
        }
      }
    }
    return sb.toString();
  };
  BBUtils.UTF8DecodeArrayBuffer = (arbuff) => {
    let data = new Uint8Array(arbuff);
    const extraByteMap = [ 1, 1, 1, 1, 2, 2, 3, 0 ];
    var count = data.byteLength;
    var str = "";
    
    for (var index = 0;index < count;)
    {
      var ch = data[index++];
      if (ch & 0x80)
      {
        var extra = extraByteMap[(ch >> 3) & 0x07];
        if (!(ch & 0x40) || !extra || ((index + extra) > count))
          return null;
        
        ch = ch & (0x3F >> extra);
        for (;extra > 0;extra -= 1)
        {
          var chx = data[index++];
          if ((chx & 0xC0) != 0x80)
            return null;
          
          ch = (ch << 6) | (chx & 0x3F);
        }
      }
      
      str += String.fromCharCode(ch);
    }
    
    return str;

  }
  BBUtils.Utf8Decode = (data) => {
      return utf8.decode(data);
      /*
    const extraByteMap = [ 1, 1, 1, 1, 2, 2, 3, 0 ];
    var count = data.length;
    var str = "";
    
    for (var index = 0;index < count;)
    {
      var ch = data[index++];
      if (ch & 0x80)
      {
        var extra = extraByteMap[(ch >> 3) & 0x07];
        if (!(ch & 0x40) || !extra || ((index + extra) > count))
          return null;
        
        ch = ch & (0x3F >> extra);
        for (;extra > 0;extra -= 1)
        {
          var chx = data[index++];
          if ((chx & 0xC0) != 0x80)
            return null;
          
          ch = (ch << 6) | (chx & 0x3F);
        }
      }
      
      str += String.fromCharCode(ch);
    }
    
    return str;
    */
  };
  BBUtils.Utf8Encode = (aBuffer) => {
      return utf8.encode(aBuffer);
      /*
    let sbuf = new StringBuffer();
    let ch = 0;
    let cval = 0;
    let i = 0;
    for (i = 0; i < aBuffer.length(); i++) {
      ch = aBuffer.charAt(i);
      if (65 <= ch && ch <= 90) {
        sbuf.append(ch);
      } else {
        if (97 <= ch && ch <= 122) {
          sbuf.append(ch);
        } else {
          if (48 <= ch && ch <= 57) {
            sbuf.append(ch);
          } else {
            if (ch === 32) {
              sbuf.append(32);
            } else {
              if (ch === 45 || ch === 95 || ch === 46 || ch === 33 || ch === 126 || ch === 42 || ch === '\'' || ch === 40 || ch === 41) {
                sbuf.append(ch);
              } else {
                if (ch <= 0x007) {
                  cval = ch;
                  sbuf.append(cval);
                } else {
                  if (ch <= 0x07F) {
                    cval = 0xc0 | (ch >> 6);
                    sbuf.append(cval);
                    cval = 0x80 | (ch & 0x3);
                    sbuf.append(cval);
                  } else {
                    cval = 0xe0 | (ch >> 12);
                    sbuf.append(cval);
                    cval = 0x80 | ((ch >> 6) & 0x3);
                    sbuf.append(cval);
                    cval = 0x80 | (ch & 0x3);
                    sbuf.append(cval);
                  }
                }
              }
            }
          }
        }
      }
    }
    return sbuf.toString();
    */
  };
  BBUtils.isBoopsieDomain = (aUrl) => {
    let bBoopsieDomain = false;
    let ixDblWhack = 0;
    ixDblWhack = aUrl.indexOf(BBUtils.C_SLASHSLASH);
    if (ixDblWhack !== -1) {
      let ixSlash = 0;
      ixSlash = aUrl.substring(ixDblWhack + 2).indexOf('/');
      if (ixSlash !== -1) {
        let ixDomain = 0;
        ixSlash += ixDblWhack + 2;
        ixDomain = aUrl.indexOf(BBUtils.C_BOOPSIE_DOT_COM);
        if (ixDomain !== -1) {
          if (ixDomain === ixSlash - BBUtils.C_BOOPSIE_DOT_COM.length) {
            if (aUrl.charCodeAt(ixDomain - 1) === 46) {
              bBoopsieDomain = true;
            } else {
              if (ixDblWhack === ixDomain - 2) {
                bBoopsieDomain = true;
              }
            }
          } else {
            ixDomain = -1;
          }
        }
        if (ixDomain === -1) {
          ixDomain = aUrl.indexOf(BBUtils.C_BOOPSIE_DOT_MOBI);
          if (ixDomain !== -1) {
            if (ixDomain === ixSlash - BBUtils.C_BOOPSIE_DOT_MOBI.length) {
              if (aUrl.charCodeAt(ixDomain - 1) === 46) {
                bBoopsieDomain = true;
              } else {
                if (ixDblWhack === ixDomain - 2) {
                  bBoopsieDomain = true;
                }
              }
            } else {
              ixDomain = -1;
            }
          }
          if (ixDomain === -1) {
            ixDomain = aUrl.indexOf(BBUtils.C_BOOPSIE_DOT_BREDIR_DOT_COM);
            if (ixDomain !== -1) {
              if (ixDomain === ixSlash - BBUtils.C_BOOPSIE_DOT_BREDIR_DOT_COM.length) {
                if (aUrl.charCodeAt(ixDomain - 1) === 46) {
                  bBoopsieDomain = true;
                } else {
                  if (ixDblWhack === ixDomain - 2) {
                    bBoopsieDomain = true;
                  }
                }
              }
            }
          }
        }
      }
    }
    return bBoopsieDomain;
  };
  BBUtils.isAndroidMarketLink = (aUrl) => {
    if (aUrl.toLowerCase().indexOf('http://market.android.com/details?id=') !== -1) {
      return true;
    } else {
      return false;
    }
  };
  BBUtils.isYouTubeWatch = (aUrl) => {
    if (aUrl.toLowerCase().indexOf('http://www.youtube.com/watch?v=') !== -1) {
      return true;
    } else {
      return false;
    }
  };
  BBUtils.isBitLyUrl = (aUrl) => {
    if (aUrl.toLowerCase().indexOf('http://bit.ly') !== -1) {
      return true;
    } else {
      return false;
    }
  };
 BBUtils.splitCheckEnd = (original, separator, bCheckEnd) => {
      let nodes = [];

      // Parse nodes into vector
      let index = original.indexOf(separator);
      //int oindex = index;
      while (index >= 0)
      {
          nodes.push(original.substring(0, index));
          original = original.substring(index + 1);
          index = original.indexOf(separator);
          //oindex = index;
          while (index == 0 && separator == ' ')      // skip spaces
          {
              original = original.substring(index + 1);
              index = original.indexOf(separator);
          }
      }
      // Get the last node
      //if (original.length() > 0)        // Jan 9, 2007
      nodes.push(original);

      // Create splitted string array
      let isize = nodes.length;
      if (bCheckEnd && isize > 0)
      {
        let sEnd = nodes[isize-1];
          if (sEnd.length == 0)
            isize--;
      }

      let result = [];
      if( isize>0 ) {
        for(let loop=0; loop<isize; loop++)
          result.push(nodes[loop]);
      }
      return result;
  }
  BBUtils.split = (original, separator) => {
    return original.split(separator);
  };

  BBUtils.URLdecode = (s) => {
    if (s !== null) {
      let tmp = new StringBuffer(s.length);
      let i = 0;
      while ((i = s.indexOf("%")) !== -1) {
        let a = s.substring(0, i);        // up to %
        let b = "0x" + s.substring(i + 1, i + 3);// 2 hex chars
        s = s.substring(i + 3, s.length); // after %xx
        tmp.append(a).append(String.fromCharCode(parseInt(Number(b))));
      }
      tmp.append(s);
      return tmp.toString().replaceAll("+", " ");
    } else {
      return null;
    }
  };
  BBUtils.parseInt = (s) => {
    let iVal = 0;
    try {
      iVal = parseInt(s);
    } catch (ex) {
      iVal = Number.MAX_SAFE_INTEGER;
    }
    return iVal;
  };
  BBUtils.parseDouble = (s) => {
    let iVal = 0;
    try {
      iVal = parseFloat(s);
    } catch (ex) {
      iVal = Number.MAX_VALUE;
    }
    return iVal;
  };
BBUtils.setAllArray = (ar, v) => {
  if (ar !== null && typeof ar === 'object') {
    var i, n = ar.length;
    for (i = 0; i < n; i++) {
      ar[i] = v;
    }
  }
}
// 6 digits = 4"
// 4 digits = 36'
// 3 digits = 365'
BBUtils.roundMapPrecision =(d) => {
  return BNumberUtils.parseDouble(d.toFixed(5));
}
