import BShape from './BShape';
import Fixed32 from './Fixed32';
import BStatus from './BStatus';
import BImageSpec from './BImageSpec';
import DecorNode from './DecorNode';
import BPColor from './BPColor';
import BGraphics from './BGraphics';
import BBUtils from '../view/BBUtils';
import Parser from './shunt';

export default class BEllipse extends BShape {
  constructor(iType) {
    super(iType);

    this.evaluated = false;

    this._maskSet = 0;
    this._cx = 0;
    this._cy = 0;
    this._rx = 0;
    this._ry = 0;

  }
  isValid() {
    switch (super.getType()) {
      case BShape.SHAPE_ELLIPSE:
      case BShape.SHAPE_CIRCLE:
          return ((this._maskSet & BEllipse.MASK_ELLIPSE) === BEllipse.MASK_ELLIPSE);

    }
    return false;
  }
  setCX(cx) {
    this._cx = cx;
    this._maskSet |= BEllipse.VAL_CX;
  }
  setCY(cy) {
    this._cy = cy;
    this._maskSet |= BEllipse.VAL_CY;
  }
  setRX(rx) {
    this._rx = rx;
    this._maskSet |= BEllipse.VAL_RX;
  }
  setRY(ry) {
    this._ry = ry;
    this._maskSet |= BEllipse.VAL_RY;
  }
  draw(ctx, g, mPaint, dnBase, x, y, width, height) {
    if (!this.evaluated) {
      if (typeof this._cx == 'object') {
        this._cx.pop();    // x
        var fdv = Parser.evalTokens(this._cx, width, height);
        this._cx = fdv;
      }
      if (typeof this._cy == 'object') {
        this._cy.pop();
        var fdv = Parser.evalTokens(this._cy, width, height);
        this._cy = fdv;
      }
      if (typeof this._rx == 'object') {
        this._rx.pop();
        var fdv = Parser.evalTokens(this._rx, width, height);
        this._rx = fdv;
      }
      if (typeof this._ry == 'object') {
        this._ry.pop();
        var fdv = Parser.evalTokens(this._ry, width, height);
        this._ry = fdv;
      }

      this.evaluated = true;
    }
    let myx = 0;
    let myy = 0;
    let mywidth = 0;
    let myheight = 0;
    let cx = 0;
    let cy = 0;
    if (this.getInset()) {
      let xlocal = Fixed32.toInt(this._cx);
      let ylocal = Fixed32.toInt(this._cy);
      myx = x + xlocal;
      myy = y + ylocal;
      mywidth = width - (Fixed32.toInt(this._rx) + xlocal);
      myheight = height - (Fixed32.toInt(this._ry) + ylocal);
      cx = myx + (mywidth / 2);
      cy = myy + (myheight / 2);
    } else {
      cx = Fixed32.toInt(this._cx);
      cy = Fixed32.toInt(this._cy);
      mywidth = Fixed32.toInt(this._rx);
      myheight = Fixed32.toInt(this._ry);
      myx = cx - mywidth;
      myy = cy - myheight;
      mywidth *= 2;
      myheight *= 2;
    }
    if (mywidth <= 0 || myheight <= 0) {
      return undefined;
    }
    g.save();
    BGraphics.ellipse(g, myx, myy, mywidth, myheight);
    g.clip();

    let pnFill = this.getFill();
    if (pnFill !== null) {
      let pnChild = null;
      pnChild = DecorNode.findChildNode$2(pnFill, DecorNode.C_lowercase_c);
      if (pnChild !== null) {
        let status = new BStatus(true);
        let iv = DecorNode.parseColorNode(pnChild, status);
        if (status.getStatus()) {
          let rgbOld = g.fillStyle;
          g.fillStyle = BPColor.intToHexRGBA(iv);
          BGraphics.ellipse(g, myx, myy, mywidth, myheight);
          g.fill();
          g.fillStyle = rgbOld;
        }
      } else {
        pnChild = DecorNode.findChildNode$2(pnFill, DecorNode.C_lowercase_url);
        if (pnChild !== null) {
          let pn = pnChild.firstChild();
          if (pn !== null) {
            let satt = pn.TID().toLowerCase();
            if (satt !== null) {
              if (satt.startsWith('#')) {
                let bg = dnBase.hasGradientId(satt);
                if (bg !== null) {
                  BGraphics.fillEllipseGradient(g, mPaint, bg, 0, myx, myy, mywidth, myheight);
                }
              } else {
                if (satt.startsWith(BBUtils.C_RESOURCE_PROTOCOL) || satt.startsWith(BBUtils.C_HTTP_COLON_WHACK_WHACK)) {
                  satt = BImageSpec.getRemoteImageUrl(satt);
                  let bimage = new BImageSpec(ctx, satt, pnFill, dnBase !== null ? dnBase._uiType : DecorNode.DECORNODE_TYPE_UNDEFINED);
                  let img = bimage.getImage();
                  if (img !== null) {
                    BGraphics.fillEllipseImage(g, mPaint, img, bimage.getAlpha(), DecorNode.findChildNode$2(pnFill, DecorNode.C_lowercase_tile) !== null, myx, myy, mywidth, myheight);
                  }
                }
              }
            }
          }
        }
      }
    }
    let pn = this.getStroke();
    if (pn !== null) {
      if (pn.TID().toLowerCase().equals(DecorNode.C_lowercase_c)) {
        let status = new BStatus(true);
        let iv = DecorNode.parseColorNode(pn, status);
        if (status.getStatus()) {
          let rgbOld = g.strokeStyle;
          g.strokeStyle = BPColor.intToHexRGBA(iv);
          BGraphics.ellipse(g, myx, myy, mywidth, myheight);
          g.stroke();
          g.strokeStyle = rgbOld;
        }
      }
    }
  }
}
BEllipse.VAL_CX = 0x00000001;
BEllipse.VAL_CY = 0x00000002;
BEllipse.VAL_RX = 0x00000010;
BEllipse.VAL_RY = 0x00000020;
BEllipse.MASK_ELLIPSE = BEllipse.VAL_CX | BEllipse.VAL_CY | BEllipse.VAL_RX | BEllipse.VAL_RY;
