import BShape from './BShape';
import BPolyline  from './BPolyline';
import Fixed32 from './Fixed32';
import BPColor from './BPColor';
import DecorNode from './DecorNode';
import BStatus from './BStatus';
import BBUtils from '../view/BBUtils';
import BImageSpec from './BImageSpec';
import BGraphics from './BGraphics';
import Parser from './shunt';

export default class BPolygon extends BPolyline {
  constructor(iType) {
    super(iType);
  }
  isValid() {
    switch (super.getType()) {
      case BShape.SHAPE_POLYGON:
        if (this._xValues !== null && this._xValues.length > 1) {
          return true;
        } else {
          return false;
        };
    }
    return false;
  }
  draw(ctx, g, mPaint, dnBase, xpos, ypos, width, height) {
    if (width <= 0 || height <= 0) {
      return undefined;
    }
    if (!this.evaluated) {
      let len = this._xValues.length;
      for (var i=0; i<len; i++) {
        if (typeof this._xValues[i] == 'object') {
          var fdv = Parser.evalTokens(this._xValues[i], width, height);
          this._xValues[i] = fdv;
        }
        if (typeof this._yValues[i] == 'object') {
          var fdv = Parser.evalTokens(this._yValues[i], width, height);
          this._yValues[i] = fdv;
        }
      }

      this.evaluated = true;
    }

    let alen = this._xValues.length;
    if (alen === 0) {
      return undefined;
    }
    let xpts = new Array(alen);
    let ypts = new Array(alen);
    for (let i = 0; i < alen; i++) {
      xpts[i] = Fixed32.toInt(this._xValues[i]);
      ypts[i] = Fixed32.toInt(this._yValues[i]);
    }
    let pnFill = this.getFill();
    if (pnFill !== null) {
      let pnChild = null;
      pnChild = DecorNode.findChildNode$2(pnFill, DecorNode.C_lowercase_c);
      if (pnChild !== null) {
        let status = new BStatus(true);
        let iv = DecorNode.parseColorNode(pnChild, status);
        if (status.getStatus()) {
          let rgbOld = g.fillStyle;
          g.fillStyle = BPColor.intToHexRGBA(iv);

          g.beginPath();
          g.moveTo(xpts[0] + xpos, ypts[0] + ypos);
          for (let i = 1; i < alen; i++) {
            g.lineTo(xpts[i] + xpos, ypts[i] + ypos);
          }
          g.closePath();
          g.fill();

          g.fillStyle = rgbOld;
        }
      } else {
        pnChild = DecorNode.findChildNode$2(pnFill, DecorNode.C_lowercase_url);
        if (pnChild !== null) {
          let pn = pnChild.firstChild();
          if (pn !== null) {
            let satt = pn.TID().toLowerCase();
            if (satt !== null) {
              if (satt.startsWith('#')) {
                let bg = dnBase.hasGradientId(satt);
                if (bg !== null) {
                  BGraphics.fillPolygonGradient(g, mPaint, bg, 0, xpts, ypts, xpos, ypos, width, height);
                }
              } else {
                if (satt.startsWith(BBUtils.C_RESOURCE_PROTOCOL) || satt.startsWith(BBUtils.C_HTTP_COLON_WHACK_WHACK)) {
                  satt = BImageSpec.getRemoteImageUrl(satt);
                  let bimage = new BImageSpec(ctx, satt, pnFill, dnBase !== null ? dnBase._uiType : DecorNode.DECORNODE_TYPE_UNDEFINED);
                  let img = bimage.getImage();
                  if (img !== null) {
                    BGraphics.fillPolygonWithImage(g, mPaint, img, bimage.getAlpha(), DecorNode.findChildNode$2(pnFill, DecorNode.C_lowercase_tile) !== null, xpts, ypts, xpos, ypos, width, height);
                  }
                }
              }
            }
          }
        }
      }
    }
    let pn = this.getStroke();
    if (pn !== null) {
      if (pn.TID().toLowerCase().equals(DecorNode.C_lowercase_c)) {
        let status = new BStatus(true);
        let iv = DecorNode.parseColorNode(pn, status);
        if (status.getStatus()) {
          let rgbOld = g.strokeStyle;
          g.strokeStyle = BPColor.intToHexRGBA(iv);
          g.beginPath();
          g.moveTo(xpts[0], ypts[0]);
          for (let i = 1; i < alen; i++) {
            g.lineTo(xpts[i] + xpos, ypts[i] + ypos);
          }
          g.closePath();
          g.stroke();
          g.strokeStyle = rgbOld;
        }
      }
    }
  }
}
