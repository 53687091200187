import Character from './Character';
import BNumberUtils from './BNumberUtils';
import DecorNode from './DecorNode';
import BBUtils from '../view/BBUtils';
import StringBuffer from './StringBuffer';
export default class BImageSpec {
  constructor(ctx, sUrl, pnFill, uiType) {
    this._alpha = 0;
    this._img = null;
    this._uiType = 0;

    this._img = null;
    this._alpha = 255;
    this._uiType = uiType;
    this._url = sUrl;

    if (sUrl === null) {
      return undefined;
    }
    let pnChild = DecorNode.findChildNode$2(pnFill, DecorNode.C_lowercase_alpha);
    if (pnChild !== null) {
      pnChild = pnChild.firstChild();
      if (pnChild !== null) {
        let alphaTemp = BNumberUtils.parseInt(pnChild.TID());
        if (alphaTemp !== Number.MAX_SAFE_INTEGER) {
          this._alpha = alphaTemp;
        }
      }
    }
    let fsCache = global.globalVars.getGlobalImageCache();
    if (fsCache !== null) {
      let aimg = fsCache.isImageCached(sUrl);
      if (aimg !== null) {
        this._img = aimg.image;
        return undefined;
      } else {
        if
         ((sUrl.startsWith(BBUtils.C_RESOURCE_PROTOCOL) || global.globalVars.isLocalResourceUrl(sUrl) !== null)) {
          aimg = fsCache.getImageForUrl(sUrl);
          if (aimg === null) {
            let resId = BImageSpec.resourceImageExists(ctx, sUrl);
            if (resId !== BImageSpec.RESOURCE_NOT_FOUND) {
              this._img = BitmapFactory.decodeResource(ctx.getResources(), resId);
              if (this._img !== null) {
                fsCache.addToCache(sUrl, this._img);
              }
            } else {
              sUrl = BImageSpec.resourceNameToRemote(ctx, sUrl);
              aimg = fsCache.isImageCached(sUrl);
              if (aimg !== null) {
                this._img = aimg.image;
                return undefined;
              } else {
                aimg = fsCache.getImageForUrl(sUrl);
                if (aimg === null) {
                  fsCache.addUrl(sUrl, uiType);
                } else {
                  this._img = aimg.image;
                }
              }
            }
          } else {
            fsCache.addToCache(sUrl, aimg.image);
            this._img = aimg.image;
          }
        } else {
          if (sUrl.startsWith('http://') || sUrl.startsWith('https://')) {
            aimg = fsCache.getImageForUrl(sUrl);
            if (aimg === null) {
              fsCache.addUrl(sUrl, uiType);
            } else {
              this._img = aimg.image;
            }
          }
        }
      }
    }
  }
  getUrl() {
    return this._url;
  }
  getImage() {
    return this._img;
  }
  getAlpha() {
    return this._alpha;
  }
}
BImageSpec.RESOURCE_NOT_FOUND = 0;
BImageSpec.assetNameToLocal = (sUrl) => {
  let sRes = null;
  if (sUrl.startsWith(BBUtils.C_RESOURCE_PROTOCOL)) {
    sRes = sUrl.substring(BBUtils.C_RESOURCE_PROTOCOL.length).toLowerCase();
  } else {
    sRes = sUrl.toLowerCase();
  }
  let ixDot = sRes.lastIndexOf('/');
  if (ixDot !== -1 && sRes.length > ixDot + 1) {
    sRes = sRes.substring(ixDot + 1);
  }
  let sb = new StringBuffer();
  let len = sRes.length;
  for (let i = 0; i < len; i++) {
    let c = sRes.charAt(i);
    let ccode = sRes.charCodeAt(i);
    if (Character.isDigit(c) || (ccode >= 97 && ccode <= 122)) {
      sb.append(c);
    } else {
      if (ccode === 95 || ccode === 46) {
        sb.append(c);
      }
    }
  }
  sRes = sb.toString();
  return sRes;
};
BImageSpec.resourceNameToLocal = (sUrl) => {
  let sRes = null;
  if (sUrl.startsWith(BBUtils.C_RESOURCE_PROTOCOL)) {
    sRes = sUrl.substring(BBUtils.C_RESOURCE_PROTOCOL.length).toLowerCase();
  } else {
    sRes = sUrl.toLowerCase();
  }
  let ixDot = sRes.lastIndexOf('.');
  if (ixDot !== -1) {
    sRes = sRes.substring(0, ixDot);
  }
  ixDot = sRes.lastIndexOf('/');
  if (ixDot !== -1 && sRes.length > ixDot + 1) {
    sRes = sRes.substring(ixDot + 1);
  }
  let sb = new StringBuffer();
  let len = sRes.length;
  for (let i = 0; i < len; i++) {
    let c = sRes.charAt(i);
    let ccode = sRes.charCodeAt(i);
    if (Character.isDigit(c) || (ccode >= 97 && ccode <= 122)) {
      sb.append(c);
    } else {
      if (ccode === 95 || ccode === 46) {
        sb.append(c);
      }
    }
  }
  sRes = sb.toString();
  return sRes;
};
BImageSpec.resourceNameToRemote = (ctx, sUrl) => {
  let sRes = sUrl.substring(BBUtils.C_RESOURCE_PROTOCOL.length).toLowerCase();
  let ixDot = sRes.lastIndexOf('/');
  if (ixDot !== -1 && sRes.length > ixDot + 1) {
    sRes = sRes.substring(ixDot + 1);
  }
  sRes = global.globalVars.getRemoteImagePath() + sRes;
  return sRes;
};
BImageSpec.getRemoteImageUrl = (sUrl) => {
  // convert r:/img/xxxx.png to https://<path>/xxxx.png
  if ((sUrl.startsWith(BBUtils.C_RESOURCE_PROTOCOL) || global.globalVars.isLocalResourceUrl(sUrl) !== null)) {
    return BImageSpec.resourceNameToRemote(/* ctx */ null, sUrl);
  } else {
    return sUrl;
  }
}
BImageSpec.resourceImageExists = (ctx, sUrl) => {
  let iRet = BImageSpec.RESOURCE_NOT_FOUND;
  try {
    let sRes = BImageSpec.resourceNameToLocal(sUrl);
    iRet = ctx.getResources().getIdentifier(sRes, 'drawable', ctx.getPackageName());
  } catch (rnf) {}
  return iRet;
};

// returns :  url, alpha, {none|fill|scale|tile}
BImageSpec.getImageSpecs = (dnThis, ctx, sWhich) => {
  if (dnThis.root !== null) {
    let children = dnThis.root.getChildren();
    if (children !== null) {
      for (let i = 0; i < children.size(); i++) {
        let pn = children.elementAt(i);
        if (pn !== null && pn.TID() !== null && pn.TID().toLowerCase().equals(sWhich)) {
          // bg( ...  )  look for url() none|fill|tile|scale  alpha(0-255)
          children = pn.getChildren();
          let retVal = {
            url: null,
            alpha: 255,
            treatment: "",
          };
          for (let j=0; j<children.size(); j++) {
            pn = children.elementAt(j);
            if (pn !== null && pn.TID() !== null) {
              if (pn.TID().toLowerCase().equals(DecorNode.C_lowercase_url)) {
                retVal.url = DecorNode.parseUrlNode(pn);
              } else if (pn.TID().toLowerCase().equals(DecorNode.C_lowercase_alpha)) {
                let pnChild = pn.firstChild();
                if (pnChild !== null) {
                  let alphaTemp = BNumberUtils.parseInt(pnChild.TID());
                  if (alphaTemp !== Number.MAX_SAFE_INTEGER) {
                    retVal.alpha = alphaTemp;
                  }
                }          
              } else if ((pn.TID().toLowerCase().equals(DecorNode.C_lowercase_tile)) ||
                         (pn.TID().toLowerCase().equals(DecorNode.C_lowercase_scale)) ||
                         (pn.TID().toLowerCase().equals(DecorNode.C_lowercase_fill)) ||
                         (pn.TID().toLowerCase().equals(DecorNode.C_lowercase_cover))) {
                  retVal.treatment = pn.TID().toLowerCase();
              } else if (pn.TID().toLowerCase().equals(DecorNode.c_lowercase_none)) {
                 retVal.treatment = DecorNode.c_lowercase_fill;
              }              
            }
          }
          if (retVal.url == null) {
            return null;
          } else {
            return retVal;
          }
        }
      }
    }
  }
  return null;

}

BImageSpec.hasResourceImage = (dnThis, ctx, sWhich) => {
  if (dnThis.root !== null) {
    let children = dnThis.root.getChildren();
    if (children !== null) {
      for (let i = 0; i < children.size(); i++) {
        let pn = children.elementAt(i);
        if (pn !== null && pn.TID() !== null && pn.TID().toLowerCase().equals(sWhich)) {
          let pAtt = pn.firstChild();
          if (pAtt !== null && pAtt.TID() !== null) {
            let satt = pAtt.TID().toLowerCase();
            if (satt.equals(DecorNode.C_lowercase_url)) {
              let sId = DecorNode.parseUrlNode(pAtt);
              if (sId !== null) {
                let bimage = new BImageSpec(ctx, sId, null, dnThis._uiType);
                let img = bimage.getImage();
                return img;
              }
            }
          }
        }
      }
    }
  }
  return null;
}