import React, { useEffect, useState, useReducer, useRef } from "react";
import {
    View,
    StyleSheet
} from "react-native";
import DecorNode from "./DecorNode";
import BGraphics from "./BGraphics";
import BPColor from "./BPColor";

// dnObj = {decorNodeString: , dnType: } or {decorNode:,  dnType}
export default function DecorBackground(props) {
    const {decorNodeString, dnType} = props;
    var [decorNode, setDecorNode] = useState(props.dNode);

    const [fg, setFg] = useState(0x000ffffff);
    const [mPaint, setMPaint] = useState(null);
    const canvasRef = useRef();
    const overlayRef = useRef();

    function setupDecorNode() {
        let dn = null;
        if (typeof props.decorNodeString !== 'undefined' && props.decorNodeString != null) {
            dn = DecorNode.FromAttributes(props.decorNodeString, props.dnType);
        } else if (typeof props.dNode !== 'undefined') {
            dn = props.dNode;
        }
        decorNode = dn;
//        setDecorNode(dn);
    }
    
    if (decorNode == null || typeof decorNode === 'undefined') {
        decorNode = DecorNode.FromAttributes(decorNodeString, dnType);
    }
    
/*
    if (typeof dNode !== 'undefined' && dNode !== null) {
        decorNode = dNode;
    } else {
        decorNode = DecorNode.FromAttributes(decorNodeString, dnType);
    }
*/

    useEffect(() => {
        setupDecorNode();

        // get size of View overlay, set size of canvas, draw, set display: ""
        canvasRef.current.width = overlayRef.current.clientWidth;
        canvasRef.current.height = overlayRef.current.clientHeight;
        canvasRef.current.style.display = null;
        drawCanvas(canvasRef.current);
    }, [props.decorNodeString, props.dNode, props.backcolor, props.refresh, props.width]);
    
    function drawCanvas(canvas) {
        let context = null;     // this was used for pulling from Android, leave it and don't use it..for now.

        var g = canvas.getContext('2d');
        // Draw stuff here...
        let width = canvas.width;
        let height = canvas.height;

        if (props.backcolor) {  // if props.backgrouind !== clearColor
            let iv = props.backcolor;
           if (!BGraphics.backgroundGradient(g, mPaint, decorNode, iv, 0, 0, width, height)) {
                g.fillStyle = BPColor.intToHexRGBA(iv);
                g.fillRect(0, 0, width, height);
            }
        }
        else {
            if (decorNode === null) {
                // make xparent
                g.fillStyle = BPColor.intToHexRGBA(0x00000000);
                g.fillRect(0, 0, width, height);
                return;
            }
            if (decorNode.hasBGColor()) {
                let argbFG = decorNode.getBGColor();
                g.fillStyle = BPColor.intToHexRGBA(argbFG);
                g.fillRect(0, 0, width, height);
            }
            else {
                let iv = fg;
                // Fill
                if (!BGraphics.backgroundGradient(g, mPaint, decorNode, iv, 0, 0, width, height)) {
                    if (!BGraphics.backgroundResourceImage(context, g, mPaint, decorNode, 0, 0, width, height)) {
                        g.fillStyle = BPColor.intToHexRGBA(fg);
                        g.fillRect(0, 0, width, height);
                    }
                }
            }
            if (decorNode.hasShape(DecorNode.C_lowercase_bg)) {
                BGraphics.drawShapes(context, g, mPaint, DecorNode.C_lowercase_bg, decorNode, 0, 0, width, height, false);
            }
        }
    }
    var subViewProps;
    if (Array.isArray(props.style)) {
        subViewProps = JSON.parse(JSON.stringify(props.style));
        for (var i=0; i<subViewProps.length; i++) {
            if (subViewProps[i] && typeof subViewProps[i] === 'object') {
                if (subViewProps[i].position && subViewProps[i].position == 'absolute') {
                    delete subViewProps[i].left;
                    delete subViewProps[i].top;
                }
            }
        }
    } else if (typeof props.style === 'object') {
        subViewProps = JSON.parse(JSON.stringify(props.style));
        if (typeof subViewProps === 'object') {
            if (subViewProps.position && subViewProps.position == 'absolute') {
                delete subViewProps.left;
                delete subViewProps.top;
            }
        }
    } else {
        subViewProps = props.style;
    }

    return (
        <View style={[styles.container, props.style]}>
            <canvas
                ref={canvasRef}
                style={
                    {
                    display: "none",
                    position: "absolute",
                    zIndex: 1,              } /* canvas with react-native-web can't use width and height in styles.gcanvas */
                }
            />
            <View style={[subViewProps, styles.overlay]} ref={overlayRef}>
                {props.children}
            </View>
        </View>
    );
}
/*
DecorBackground.propTypes = {
    decorNode: PropTypes.string,
    dnType: PropTypes.number
};
DecorBackground.defaultProps - {
    decorNode: "bg(c(#00000000))"      // xparent
};
*/

const styles = StyleSheet.create({
    overlay: {
      position: 'relative',
      margin: "0",
      zIndex: 2,
      height: "100%",
      // Debug - border causes canvas to shrink
//      border: "1px dotted red",
    },
    container: {
    },
  });
