import React from 'react';
import DelayInput from './DelayInput';

export default class CTextInput extends DelayInput {
    constructor(props) {
        super(props);
        this.state = { 
            value: props.value || ""
        };
        this.delayRef = React.createRef();
        this.update = this.update.bind(this);
    }
    focus = () => {
        if (typeof this.delayRef.current !== 'undefined' && this.delayRef.current != null) {
            this.delayRef.current.focus();
        }
    }
    isFocused = () => {
        if (typeof this.delayRef.current !== 'undefined' && this.delayRef.current != null) {
            return this.delayRef.current.isFocused();
        } else {
            return false;
        }
    }
    update = (txt) => {
        this.setState({ value: txt });
        this.props.notifyParentTextChanged(txt);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
    }
    componentDidMount() {
    }
    render() {
            return <DelayInput style={this.props.style}
                onChangeText={this.update}
                value={this.state.value}
                delayTimeout={400}
                placeholder={this.props.hintText}
                inputRef={this.delayRef}
            />
    }

}