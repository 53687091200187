export default class EConstants {
    constructor() {

    }
}

EConstants.Tree_Node_NavBar = "1";
EConstants.Tree_Node_NavBar_BackButton = "10";
EConstants.Tree_Node_NavBar_SearchTitle = "11";
EConstants.Tree_Node_NavBar_RightMenu = "12";
EConstants.Tree_Node_Screen = "2";
EConstants.Tree_Node_Screen_ListItem = "20";
EConstants.Tree_Node_Toolbar = "3";

EConstants.PropertyType_DecorBG = "DBG";
EConstants.PropertyType_Toolbar = "TB";