import React from "react";

export default class BColorSwatch extends React.Component {
    constructor(props) {
        // id={some id}
        // color = {some color}
        // handleColorClick = f()
        super(props);
    }

    render() {
        return(
            <div
            aria-describedby={this.props.id}
            style={{
                display: 'inline-block',
                backgroundColor: this.props.color,
                width: "28px",
                height: "28px",
                borderRadius: "8px",
                border: "3px solid #fff",
                boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
            }}
            onClick={this.props.handleColorClick}
        />

        )
    }
}