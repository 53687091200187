import React from 'react';
import { StyleSheet, Text, Image, View, TouchableOpacity } from 'react-native';
import DecorNode from '../graphics/DecorNode';
import ToolbarManager from './ToolbarManager';
import BPColor from '../graphics/BPColor';
import BGraphics from '../graphics/BGraphics';
import Color from '../graphics/Color';

export default class TBView extends React.Component {
    constructor(props) {
        // tbi={TBItem}
        // dnToolbar={DecorNode}
        super(props);

        this.state = {
            decorNodeString: "bg(c(#00ffffff))",
            fg: '#000',
            myFontSettings: null,
            refresh: props.refresh,
            width: global.globalVars.getToolbarButtonWidth(),
            height: global.globalVars.getToolbarButtonHeight(),
        }

    }

    static getDerivedStateFromProps(props, state) {

        if (props.decorToolbarString != state.decorNodeString || props.refresh != state.refresh) {

            let dn = DecorNode.FromAttributes(props.decorToolbarString, DecorNode.DECORNODE_TYPE_TOOLBAR);

            let fgColor = 0x0FFFFFFFF;
            if (dn !== null) {
                if (dn.hasFGColor()) {
                    fgColor = dn.getFGColor();
                }
            }
            if (!props.tbi.getEnabled()) {
                fgColor = BGraphics.tintColor(fgColor, Color.LTGRAY);
            }
            if (props.tbi.getActive()) {
                if (dn != null && dn.hasTintColor()) {
                    fgColor = dn.getTintColor();
                } else {
                    fgColor = 0x0FF007AFF;  // blueish
                }
            }
            let pnFont = null;
            if (dn !== null) {
                pnFont = dn.findChildNode(DecorNode.C_lowercase_font);
            }

            let fSettings = DecorNode.getFontSettings(pnFont, {
                fontSize: .86,   // 12pt
                fontWeight: 300,
            });

            let newState ={
                decorNodeString: props.decorToolbarString == null ? "bg(c(#00ffffff))" : props.decorToolbarString,
                fg: fgColor,
                myFontSettings: fSettings,
            }

            if (props.refresh != state.refresh) {
                newState.refresh = props.refresh;
                newState.width = global.globalVars.getToolbarButtonWidth();
                newState.height = global.globalVars.getToolbarButtonHeight();    
            }
            return newState;
        } else {
            return null;
        }

    }
    onPress = () => {
        let ii = this.props.tbi.getMenuId();
        global.globalVars.CMenu().dynSelect(ii);
    }

    componentDidUpdate(prevProps, prevstate, snapshot) {
        if (prevProps.decorToolbarString != this.props.decorToolbarString) {
//            this.updateToNewDecorString(this.props.decorToolbarString);
        }
    }

    // tbi.
    // tbi.getImageUrl();
    // toobar.getButtonType() - BTOOLBAR_BUTTON_TYPE_LABEL, etc.

    render() {
        var imgR = null;
        var txtR = null;
        switch (this.props.btnType) {
            case ToolbarManager.BTOOLBAR_BUTTON_TYPE_LABEL:
                txtR =
                    <View
                        style={{ justifyContent: 'center', height: '100%' }}
                    >
                        <Text
                            numberOfLines={1}
                            style={
                                {
                                    textAlign: 'center',
                                    color: BPColor.intToHexRGBA(this.state.fg),
                                    ...this.state.myFontSettings,
                                }
                            }>{this.props.tbi.getLabel()}</Text>
                    </View>
                break;
            case ToolbarManager.BTOOLBAR_BUTTON_TYPE_IMAGE:
                imgR =
                    <Image
                        source={global.globalVars.getConvertUrl(this.props.tbi.getRemoteImageUrl(), this.state.width, this.state.height)}
                        style={[{
                            width: this.state.width,
                            height: this.state.height,
                        },
                        styles.icon,
                        ]}
                    />
                break;
            case ToolbarManager.BTOOLBAR_BUTTON_TYPE_COMBO:
                txtR =
                    <Text
                        numberOfLines={1}
                        style={{
                            textAlign: 'center',
                            color: BPColor.intToHexRGBA(this.state.fg),
                            ...this.state.myFontSettings,
                        }}>{this.props.tbi.getLabel()}</Text>
                imgR =
                    <Image
                    source={global.globalVars.getConvertUrl(this.props.tbi.getRemoteImageUrl(), this.state.width, this.state.height)}
                    style={[{
                            width: this.state.width,
                            height: this.state.height,
                        },
                        styles.icon,
                        ]}
                    />
                break;
        }
        return (
            <View style={styles.container}
            >
                <TouchableOpacity
                    style={styles.iconHorizontal}
                    onPress={this.onPress}
                    disabled={!this.props.tbi.getEnabled()}
                >
                    {imgR}
                    {txtR}
                </TouchableOpacity>
            </View>
        )

    }
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    tab: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    label: {
        textAlign: 'center',
        backgroundColor: 'transparent',
    },
    iconHorizontal: {
        height: '100%',
        marginTop: 3,
    },
    icon: {
        alignSelf: 'center',
        // Workaround for react-native >= 0.54 layout bug
        //minWidth: 25, 
    }
  });