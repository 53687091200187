import React from 'react';
import { StyleSheet, View, VirtualizedList, TouchableOpacity, ActivityIndicator } from 'react-native';
import {Dimensions} from 'react-native';
import DecorNode from './components/graphics/DecorNode';
import DecorBackground from './components/graphics/DecorBackground';
import GlobalVars from './components/util/GlobalVars';
import StringBuffer from './components/graphics/StringBuffer';
import BBUtils from './components/view/BBUtils';
import CHistory from './components/view/CHistory';
import BPHttpTask from './components/view/BPHttpTask';
import BPSocket from './components/view/BPSocket';
import CTextInput from './components/view/CTextInput';
import CTitleView from './components/view/CTitleView';
import CSeparatorView from './components/view/CSeparatorView';
import ToolbarManager from './components/view/ToolbarManager';
import BMessageText from './components/view/BMessageText';
import DrawView from './components/view/DrawView';
import { MenuProvider } from 'react-native-popup-menu';
import ShowMenu from './components/view/ShowMenu';
import BPImage from './components/view/BPImage';
import CBackText from './components/view/CBackText';
import BBMaps from './components/view/BBMaps';
import BIFrame from './components/view/BIFrame';
import BPhoto from './components/view/BPhoto';
import UniqueId from './components/util/UniqueId';
import BStatus from './components/graphics/BStatus';
import BPColor from './components/graphics/BPColor';
import BImageSpec from './components/graphics/BImageSpec';
import {
  HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";

// Edit
import EComponentTree from './components/edit/EComponentTree';
import { TextField, Button } from '@mui/material';
import EProperties from './components/edit/EProperties';

// storage
import AsyncStorage from '@react-native-async-storage/async-storage';
import ELeftNav from './components/edit/ELeftNav';

const flavorRef = {
  'troparesheets':{title:'Troparé Sheets'},
  'strongorgfitness':{title:'Strong Org Fitness'},
}

String.prototype.equals = function(that) {
  return this === that;
}
  
//export default function App() {
class App extends React.Component {
    constructor(props) {
      super(props);

      let pin = "strongorgfitness-dev.boopsie";
      let flavor = 'strongorgfitness';
      const flavorRegex = /^\w+/;
      if (!location.href.startsWith("http://localhost")) {
        // https://foober.boopsie.tredir.com/ some more stuff...
        let ix = location.hostname.indexOf(".tredir.com");
        if (ix != -1) {
          pin = location.hostname.substring(0, ix);
          const flavorMatch = pin.match(flavorRegex);
          flavor = flavorMatch[0];
        }
      }
      if (flavorRef[flavor] && flavorRef[flavor].title) {
        document.title = flavorRef[flavor].title;
      } else {
        document.title = flavor;
      }
      global.globalVars = new GlobalVars(null,pin);

      this.state = {
        decorNodeString: App.C_DEFAULT_SCREEN_STRING,
        hfieldDecorString: App.C_DEFAULT_TITLE_STRING,
        separatorDecorString: App.C_DEFAULT_SEPARATOR_STRING,
        itemDecorString: "bg(c(#00000000))",
        toolbarDecorString: "bg(c(#000))",
        hfieldVisible: true,
        _tbitems: null,
        toolbarVisible: false,
        refresh: true,
        _dataIndexes: [],
        _msl: null,       // MenuSection Vector
        _mslIx: -1,   // cMenu or 0> list item
        rightButtonImageUrl: global.globalVars.getNavMenu(),
        titleSearchButtonImageUrl: null,
        leftButtonImageUrl: null, // 2022-08-18
        backButtonText: 'Back',
        backButtonColor: 'white',
        titleColor: 'black',
        loading: false,
        mainView: "main",
        mapInstructions: null,
        webUrl: null,
        photoUrl: null,

        titleText: '',
        titleVisible: false,
        inputVisible: true,
        inputHintText: 'search',

        titleSearchVisible: false,
        titleFontSettings: null,  // 2022-10-06


        srowhints: '',
        canEdit: false,
        toolbarText: [],
        editId: '',
        treeViewDisabled: true,

        titleRefresh: false,    // toggles to force display
        screenRefresh: false,    // toggles to force display
        cellRefresh: false,     // toggle to force display
        toolbarRefresh: false,
        rightButtonRefresh: false,
        leftButtonRefresh: false,
        titleSearchButtonRefresh: false,
        horizontalScrollersRefresh: false,

        adjustedWidth: 375,

      }
      this.ctx = null;    // we don't use context in JS

      this.canvasRef = React.createRef(),
        this.inputRef = React.createRef(),
        this.titleRef = React.createRef(),
        this.messageRef = React.createRef(),
        this.separatorRef = React.createRef(),
        this.listRef = React.createRef();
        
      this._bDelayRender = false;

     
      global.globalVars.setScreenRef(this);
      global.globalVars.setCanvasRef(this.canvasRef);

      this._sLastSent = "";
      this._tLastFieldChanged = 0;
      this._sLastSetValue = "";
      this._tLastTimeSet = 0;
      this._blockChange = 0;
      this.sIncrementalBaseUrl = global.globalVars.getHome();
      this._cHistory = new CHistory();
      this.scroll = 0;
      this.mGUID = "";  // "7f41f8ce-2884-11ec-b0c3-0bdd9ecd8d04";
      this.bpSocket = new BPSocket(this.ctx, this, this.mGUID);
      this._firstSearch = true;
      this._bCookie = null;

      this.lastTitleSearchChannel = null;
  
      this._initialHeight = 667;   // 667;  850
      this._initialWidth = 375;    // 375; 320
      this._currentSetHeight = this._initialHeight;
      this._currentSetWidth = this._initialWidth;

      this.myHeight = this._currentSetHeight;  // will change...
      this.myWidth = this._currentSetWidth;
      this.myLines = Math.floor(this.myHeight / GlobalVars.DEFAULT_FONT_SIZE);

      this._bSetListOffsets = false;  // used as a flag for results of next search() to scroll to prior position
      this._extraUIElements = {};
      this._bpReturnVars = {};

      this.state.adjustedWidth = this._currentSetWidth;

      this.renderItem = this.renderItem.bind(this);
      this.renderSeparator = this.renderSeparator.bind(this);
      this.menuPressed = this.menuPressed.bind(this);
      this.titleSearchPressed = this.titleSearchPressed.bind(this);
      this.fieldChanged = this.fieldChanged.bind(this);
      this.onListSizeChange = this.onListSizeChange.bind(this);
      this.shrink = this.shrink.bind(this);

      this._refreshTimer = null;

      this.lastHistoryRequest = Number.MAX_SAFE_INTEGER;
      this.lastLocation = 0.0.toFixed(6) + ';' + 0.0.toFixed(6);

      this.listHeight = 0;
    }
    // Data Access functions.  _dataIndexs[0] = 0, etc. up through length of setListData
    setDataIndexes =(di) => {
      const guid = UniqueId.guid({separator: '_'});

      for (let i=0; i<di.length; i++) {
        di[i].key = di[i].key + '_' + guid;
        di[i].ref = React.createRef();
      }
      this.setState({_dataIndexes : di});
    }
    // End Data Access
    updateRow = (ixRow) => {
      let item = this.state._dataIndexes.find( item => item.index == ixRow);
      if (typeof item !== 'undefined') {
        item.ref.current.forceUpdate();
      }
    }
    getGUID = () => {
      return this.mGUID;
    }
    setToolbarText(ar) {
      this.setState( {toolbarText: ar});
    }
    mapLocations = (dLatSearchCenter, dLonSearchCenter,
      sLabelSearchCenter, radarType,
      arMapItems) => {

      this.setState(
        {
          mainView: 'map',
          mapInstructions: [{
            cmd: 'mapLocations',
            dLatSearchCenter: dLatSearchCenter,
            dLonSearchCenter: dLonSearchCenter,
            sLabelSearchCenter: sLabelSearchCenter,
            radarType: radarType,
            arMapItems: arMapItems,
          }]
        }
      );
      location.hash = '/map';
    }

  addPhoto = (url) => {
    this.setState(
      {
        mainView: 'photo',
        photoUrl: url
      }
    )
    location.hash = '/photo';
  }

  addBoopsieParamsToUrl = (url) => {

    if ((url.startsWith(BBUtils.C_RESOURCE_PROTOCOL) || global.globalVars.isLocalResourceUrl(url) !== null)) {
      url = BImageSpec.resourceNameToRemote(/* ctx */ null, url);
    }


    let mBSID = this._bpReturnVars._BSID;
    let bAppendIt = false;
    let bHasQMark = url.indexOf('?') !== -1;

    let pin = global.globalVars.getBPIN();
    let guid = this.getGUID();
    let bBoopsieDomain = BBUtils.isBoopsieDomain(url);

    if (typeof mBSID !== 'undefined' && mBSID !== null && mBSID.length > 0) {
      if (bHasQMark) {
        url = url + '&';
      } else {
        url = url + '?';
        bHasQMark = true;
      }
      url = url + 'mofiid=' + mBSID;
    } else {
      if (bBoopsieDomain) {
        bAppendIt = true;
        if (bHasQMark) {
          url = url + '&';
        } else {
          url = url + '?';
          bHasQMark = true;
        }
        url = url + 'uid=' + guid;
        if (global.globalVars.getBlocation().hasLatLonBeenSet) {
          url = url + '&B-GPS=' + global.globalVars.getBlocation().getCommaFormattedLatLon();
        }
      }
    }

    if (bAppendIt) {
      if (pin !== null && pin.length > 0) {
        if (bHasQMark) {
          url = url + '&B-PIN=' + pin;
        } else {
          url = url + '?B-PIN=' + pin;
          bHasQMark = true
        }
      }
    }

    return url;
  }
  openWebView = (url) => {
    let newUrl = this.addBoopsieParamsToUrl(url);

    location.href = newUrl;
  }

    getDisplayWidth = () => {
      return this.myWidth;
    }
    getDisplayHeight = () => {
      return this.myHeight;
    }

    getUAPixels = () => {
      return this.myWidth.toString() + "x" + this.myHeight.toString() + " (" + this.myLines.toString() + " lines)";
    }
    startClientProgress = () => {
      this.setState({loading: true});
    }
    stopClientProgress = () => {
      this.setState({loading: false});
    }

    setTitleSearchButtonImageUrl = (sUrl) => {
      if (sUrl == null) {
        this.lastTitleSearchChannel = null;
      }

    this.setState({
        titleSearchVisible: sUrl == null ? false : true,
        titleSearchButtonImageUrl: sUrl,
        titleSearchButtonRefresh: !this.state.titleSearchButtonRefresh
      });
    }

    resetRightButtonImageUrl = () => {
      this.setState({
        rightButtonImageUrl: global.globalVars.getNavMenu(),
        rightButtonRefresh: !this.state.rightButtonRefresh
      });
    }

    setRightButtonImageUrl = (sUrl) => {
      this.setState({
        rightButtonImageUrl: sUrl,
        rightButtonRefresh: !this.state.rightButtonRefresh
      });
    }
    resetLeftButtonImageUrl = () => {
      this.setState({
        leftButtonImageUrl: null,
        leftButtonRefresh: !this.state.leftButtonRefresh
      });
    }

    setLeftButtonImageUrl = (sUrl) => {
      if (sUrl == null && this.state.leftButtonImageUrl == null) {
        return;
      }

      this.setState({
        leftButtonImageUrl: sUrl,
        leftButtonRefresh: !this.state.leftButtonRefresh
      });
    }

    setBackButtonText = (sTxt) => {
      this.setState({backButtonText: sTxt});
    }
    setBackButtonColor = (colr) => {
      this.setState({backButtonColor: colr});
    }
    setTitleColor = (colr) => {
      this.setState({titleColor: colr});
    }
    respondToBack = () => {
      let sUrl = this._cHistory.getBackHistory();
      window.history.back();

      /*
      if (typeof sUrl !== 'undefined' && sUrl !== null && sUrl.length > 0) {
        this.searchNoFocus(sUrl);
      } else {
        // if _returnToAppmenu - process here, else search()
        //this.search(home);
      }
      */
    }

    getListObject = () => {
      return this.listRef.current;
    }
    getListHeight = () => {
      return this.listHeight;
    }
    setMenuList = (mslIx, msl) => {
      this.setState({_mslIx: mslIx, _msl: msl});
    }
    getIncrementalBaseUrl() {
        return this.sIncrementalBaseUrl;
    }

    setIncrementalBaseUrl(s) {
      this.sIncrementalBaseUrl = s;
    }
    getAllHeaders() {
      return this._bpReturnVars._headers;
    }
    getAllHeadersStartsWith(sName) {

      return this._bpReturnVars._headers.filter(item => item.name.toLowerCase().startsWith(sName));
    }
    setExtraUI(dict) {
      this._extraUIElements = dict;
    }
    getHSList(ix) {
      if (this._extraUIElements != null) {  // Hashtable map  "HS-List"+ix  :  <header value>
        if (this._extraUIElements.hasOwnProperty("hs-list"+ix.toString())) {
          return this._extraUIElements["hs-list"+ix.toString()];
        }
      }
      return null;
    }

    setReturnVars = (bpvars) => { // BPSocketVariables
      //TODO: bpvars._stingd is source of response, match it with history
      this.matchHistory(bpvars._stingd);
      this._bpReturnVars = bpvars;
    }
    get_BSID = () => {
      return this._bpReturnVars._BSID;
    }
    getSuppressAccessoryColumn() {
      if (typeof this._bpReturnVars._ixSuppressAccessoryColumn !== 'undefined') {
        return this._bpReturnVars._ixSuppressAccessoryColumn;
      } else {
        return -1;
      }
    }
    showErrorMessage = (msg) => {
      this.messageRef.current.setState({message: msg, decorString: "fg(c(#fff)) bg(c(#e00))"});  // red
    }
    showMessage = (msg) => {
      this.messageRef.current.setState({message: msg, decorString: "bg(c(#555)) fg(c(#fff))"}); // dk gray
    }
    process302 = (slocation) => {
      // TODO: check i: or http:  etc...
      global.globalVars.CMenu().processAction(slocation, slocation.startsWith("http"));      
    }
    processX302 = (slocation) => {
      // TODO:
      
    }

    // iUpdateSeconds == -1, use default refresh rate
    startRefreshGPSL = (dMiles, iUpdateSeconds) => {
      if (!this.canEdit) {
        global.globalVars.getBlocation().NotifyClientGPSUseRequestL(true, dMiles, iUpdateSeconds);
      }
    }
    notifyClientDoNotUseGPS = () => {
      global.globalVars.getBlocation().NotifyClientGPSUseRequestL(false, 0.0, -1);
    }

    // Timer
    tick = () => {
      this.RefreshS();
    }

    startRefreshTimerL = (dval) => {
      if (!this.canEdit) {
        if (this._refreshTimer !== null) {
          clearInterval(this._refreshTimer);
          this._refreshTimer = null;
        }
        this._refreshTimer = setInterval(this.tick, dval);
      }
    }
    cancelRefreshTimer = () => {
      if (this._refreshTimer !== null) {
        clearInterval(this._refreshTimer);
        this._refreshTimer = null;
      }
    }
    refreshHorizontalScrollers = () => {
      this.setState({horizontalScrollersRefresh: !this.state.horizontalScrollersRefresh})

    }
    // End Timer
    setDelayRender = (bBuffer) => {
      this._bDelayRender = bBuffer;
      this.setState({refresh: !this._bDelayRender});
//      if (!bBuffer) {
//        this.forceUpdate();
//      }
    }
    setBCookie = (sval) => {
      this._bCookie = sval;
    }
    setHFieldVisible = (show) => {
      this.setState({hfieldVisible: show});
    }
    refreshSeparator = () => {
      this.separatorRef.current.refresh();
    }
    // called when an image is loaded async for screen background
    refreshScreen = () => {
      this.setState({screenRefresh: !this.state.screenRefresh})
    }
    // called when image is loaded async for cell backgrounds. calling forceUpdate() does not refresh DecorBackground images...
    refreshCells = () => {
      this.setState({cellRefresh: !this.state.cellRefresh})
    }
    refreshToolbar = () => {
      this.setState({toolbarRefresh: !this.state.toolbarRefresh});
    }
    refreshRightButton = () => {
      this.setState({rightButtonRefresh: !this.state.rightButtonRefresh})
    }
    refreshLeftButton = () => {
      this.setState({leftButtonRefresh: !this.state.leftButtonRefresh})
    }
    setDecorNodeToolbarString = (s) => {
      this.setState({
        toolbarDecorString: s,
        toolbarRefresh: !this.state.toolbarRefresh
      })
    }
    setDecorNodeItemString = (s) => {
      this.setState({
        itemDecorString: s,
        cellRefresh: !this.state.cellRefresh
      })
    }
    setDecorNodeSeparatorString = (s) => {
      if (typeof s === 'undefined' || s == null) {
        this.setState({
          separatorDecorString: App.C_DEFAULT_SEPARATOR_STRING
        })
      } else {
        this.setState({
          separatorDecorString: s
        })
      }
      /*
      if (this.separatorRef.current != null) {
        if (typeof s === 'undefined' || s === null) {
          this.separatorRef.current.setDecorNodeString(App.C_DEFAULT_SEPARATOR_STRING);
        } else {
          this.separatorRef.current.setDecorNodeString(s);
        }
      }
      */
    }
  setDecorNodeScreenString = (s) => {
    if (s === null) {
      s = App.C_DEFAULT_SCREEN_STRING;
    }
    this.setState({
      decorNodeString: s
    })
  }
  setDecorNodeTitleString = (sdnTitle) => {
    // NOTE: the back(fg(c(xxxx))) is part of B-Decor-Title, so get the fg component if any
    // otherwise, fg is white?
    let backSet = false;
    let titleSet = false;
    let pnFont = null;
    if (typeof sdnTitle !== 'undefined' && sdnTitle !== null) {
      let dnTitle = DecorNode.FromAttributes(sdnTitle, DecorNode.DECORNODE_TYPE_NAVBAR);
      if (dnTitle !== null) {
        let status = new BStatus(true);
        let icolor = dnTitle.getColorOfSubObject(DecorNode.C_lowercase_back, DecorNode.C_lowercase_fg, status);
        if (status.getStatus()) {
          this.setBackButtonColor(BPColor.intToHexRGBA(icolor));
          backSet = true;
        }
        if (dnTitle.hasFGColor()) {
          icolor = dnTitle.getFGColor(BPColor.intToHexRGBA(icolor));
          this.setTitleColor(BPColor.intToHexRGBA(icolor));
          titleSet = true;
        }
        pnFont = dnTitle.findChildNode(DecorNode.C_lowercase_font);
      }
    }
    let myFontSettings = DecorNode.getFontSettings(pnFont, {
      fontFactor: 1.43,        // 20pt
      fontWeight: 400,
    });

    this.setState({titleFontSettings: myFontSettings});


    if (!backSet) {
      this.setBackButtonColor('white');
    }
    if (!titleSet) {
      this.setTitleColor('black');
    }

    if (typeof sdnTitle === 'undefined' || sdnTitle == null) {
      this.setState({
        hfieldDecorString: App.C_DEFAULT_TITLE_STRING
      })
    } else {
      this.setState({
        hfieldDecorString: sdnTitle
      })
    }
  }
    refreshHField = () => {
      this.setState({
        hfieldDecorString: this.state.hfieldDecorString,
        titleRefresh: !this.state.titleRefresh
      })
    }

  getText = () => {
    return this._sLastSent;
//    return this.inputRef.current.state.value;
  }
  fieldChanged = (text) => {
    if (!this._sLastSent.equals(text)) {
      this._sLastSent = text;
      this._tLastFieldChanged = (new Date()).getTime();

      let iat = this.sIncrementalBaseUrl.indexOf('@');
      var sUrl;
      if (iat != -1) {
        sUrl = this.sIncrementalBaseUrl.substring(0,iat);        
      } else {
        sUrl = this.sIncrementalBaseUrl;
      }

			// 2011-08-07 - try to save position for 'back' restore.
			let indexTop = this.scroll;

			let ixHistory = this._cHistory.addHistory(sUrl, this._sLastSent, indexTop, 0); // last 0 is offset (can we get that?)
   
      location.hash = "/h/?c=" + BBUtils.UrlEncode(BBUtils.Utf8Encode(this._sLastSent)) + "&u=" + sUrl;

    }
  }
  checkIfRestoreScroll() {
    /*
    if (this.mRestoreScroll && this._cMenu !== null) {
      let index = this.mRestoreScrollIndexTop;
      let top = this.mRestoreScrollIndexOffset;
      if (this._cMenu.getCount() > index) {
        try {
          this._cMenu.setSelectionFromTop(index, top);
        } catch (ex) {}
      }
    }
    this.mRestoreScroll = false;
    */
  }

  getBackHistory() {
    return this._cHistory.getBackHistory();
  }

  matchHistory = (stingd) => {
    // to keep a hash navigation correct, we need to match the requests and responses
    // in the history.  this is called when we receive a response
    // e.g.
    // troparesheets-dev.boopsie.tredir.com/wwu?c=c&u=https://troparesheets-dev.boopsie.tredir.com/i/troparesheets_yours/
    // troparesheets-dev.boopsie.tredir.com/umenu?u=https%3a%2f%2ftroparesheets-dev.boopsie.tredir.com%2fi%2ftroparesheets_yours%2fu=https%3a%2f%2ftroparesheets-dev.boopsie.tredir.com%2fi%2ftroparesheets_yours%2f
    // cHistory.m_arUrlHistory = https://...
    // cHistory.m_arCharHistory = 
  }


  // called to see if the results we are now displaying are a result of hitting the back
	// key where we saved the 'last' scroll position.
  checkIfThisWasBack() {
    if (this._bSetListOffsets) {
      let index = this._cHistory.getSavedFirstChild();
      let top = this._cHistory.getSavedOffset();

      if (this.state._dataIndexes.length > index) {
        try {
          this.listRef.current.scrollTo({ x: 0, y: index, animated: false }); // a bit more to 'top' ?
        } catch (ex) { }
      }
    } else {
      try {
        this.listRef.current.scrollTo({ x: 0, y: 0, animated: true }); // a bit more to 'top' ?
      } catch (ex) { }
  }
    this._bSetListOffsets = false;
  }

  _cEditTextIsFocused = () => {
    if (this.state.inputVisible) {
      return this.inputRef.current.isFocused();
    } else {
      return false;
    }
  }

  _cEditTextRequestFocus = () => {
    if (this.state.inputVisible) {
      this.inputRef.current.focus();
    }
  }

  //TODO: refresh when /map is active? currently, the display changes from Map to list (fix).
  RefreshR = () => {
    this.lastHistoryRequest = "force";   // anything here...
    this.search(this.sIncrementalBaseUrl);
  }
  RefreshS = () => {
    let sText = "";
    let ix = this.sIncrementalBaseUrl.indexOf('@');
    if (ix != -1) {
      this.sIncrementalBaseUrl = this.sIncrementalBaseUrl.substring(0,ix+1) + sText;
    } else {
      this.sIncrementalBaseUrl = this.sIncrementalBaseUrl + '@' + sText;
    }
    this.RefreshR();
  }
  addWWU = (aBuffer, baseUrl) => {
    let indexTop = this.scroll;
    let top = 0;    // TODO: offset in top 'view'
    let sChars = "";

    if (aBuffer != null) {
      sChars = BBUtils.UrlEncode(BBUtils.Utf8Encode(aBuffer.replaceAll(' ', '+')));
    }

    let ix = baseUrl.length - 1;
    let notFound = true;
    while (notFound && ix >= 0) {
      if (baseUrl.charAt(ix) == '/') {
        notFound = false;
      } else {
        ix--;
      }
    }

    let baseTrim;
    if (notFound) {
      baseTrim = baseUrl;
    } else {
      baseTrim = baseUrl.substring(0,ix+1);
    }

    this._cHistory.addHistory(baseTrim, sChars, indexTop, top);

    // TODO: check location 'base' url vs. this baseTrim 'base' url. (take into consideration localhost as well...) mofi pin
    if (!location.href.startsWith("http://localhost")) {
      //baseTrim:  https://blah-dev.boopsie.tredir.com
      //location.href: https://foobar-dev.boopsie.tredir.com
      // So, see if same up to .tredir.com
      let ixCur = location.hostname.indexOf(".tredir.com");
      let ixTo = baseTrim.indexOf('.tredir.com');
      if (ixCur != -1 && ixTo != -1) {
        if (!location.hostname.substring(0,ixCur).equals(baseTrim.substring(0,ixTo))) {
          location.href = baseTrim.substring(0, ixTo+('.tredir.com').length) + '/#/h/?c=' + sChars + "&u=" + BBUtils.Utf8Encode(baseTrim);
          return;
        }
      }
    }

    location.hash = '/h/?c=' + sChars + "&u=" + BBUtils.Utf8Encode(baseTrim);

  }

  // It's possible this is called while a 'map' is showing
  searchMaybeFocus = (sUrl, bTextFocus) => {
    if (this.state.mainView === 'main') {
      if (this.inputRef.current !== null) {
        this.inputRef.current.setState({ value: "" });

        if (bTextFocus && !this._cEditTextIsFocused()) {
          this._cEditTextRequestFocus();
        }
      }
    }

    this.sIncrementalBaseUrl = sUrl;
    let ix = sUrl.indexOf('@');

    // 2011-08-07 - try to save position for 'back' restore.
    let indexTop = this.scroll;
    let top = 0;    // TODO: offset in top 'view'

    let sThisUrl;

    if (ix != -1) {
      // 2010-JUN-03 sIncrementalBaseUrl = sUrl.substring(0, ix);
      sThisUrl = sUrl.substring(0, ix);

      if (ix < sUrl.length - 1) {

        this.addWWU(sUrl.substring(ix + 1), sThisUrl);

      } else {
        this.addWWU(null, sThisUrl);
      }
    } else {
      this.addWWU(null, sUrl);
    }


    this.forceUpdate();

  }  
  old_searchMaybeFocus = (sUrl, bTextFocus) => {
    if (this.state.mainView === 'main') {
      if (this.inputRef.current !== null) {
        this.inputRef.current.setState({ value: "" });

        if (bTextFocus && !this._cEditTextIsFocused()) {
          this._cEditTextRequestFocus();
        }
      }
    }

    // 05/19/07 - see if a trailing @um+th If so, strip it and display in
    // _cEditText
    let ix = sUrl.indexOf('@');
    // 2010-Jun-02 - perhaps leave the @ on the sIncrementalBaseUrl so
    // RefreshR works correctly (like iPhone)
    this.sIncrementalBaseUrl = sUrl;
    // 2011-08-07 - try to save position for 'back' restore.
    let indexTop = this.scroll;
    let top = 0;    // TODO: offset in top 'view'

    let ixHistory;
    let sThisUrl;

    if (ix != -1) {
      // 2010-JUN-03 sIncrementalBaseUrl = sUrl.substring(0, ix);

      if (ix < sUrl.length - 1) {
        let sChars = BBUtils.URLdecode(sUrl.substring(ix + 1));
        // Need to UTF8 Decode now...Unfortunately, the bytes we
        // decoded are now in WCHARs. So, we have to UTF8 decode
        // the 'low order' bytes of each WCHAR

        sChars = BBUtils.Utf8Decode(sChars);

        sThisUrl = sUrl.substring(0, ix);
        ixHistory = this._cHistory.addHistory(sThisUrl, sChars, indexTop, top);

        ix = 0;
        if (sChars.charAt(ix) == '{') {
          ix++;
          while (ix < sChars.length && sChars.charAt(ix) != '}')
            ix++;
          if (ix < sChars.length)
            ix++;
        }

        sThisUrl = sUrl;    // for umenu

        this._sLastSent = sChars.substring(ix);
      } else {
        sThisUrl = sUrl.substring(0, ix);

        ixHistory = this._cHistory.addHistory(sThisUrl,
          BBUtils.C_EMPTY_STRING, indexTop, top);

        sThisUrl = sUrl;
        this._sLastSent = BBUtils.C_EMPTY_STRING;
      }
    } else {
      // 2010-JUN-03 sIncrementalBaseUrl = sUrl;
      sThisUrl = sUrl;
      ixHistory = this._cHistory.addHistory(sThisUrl, BBUtils.C_EMPTY_STRING, indexTop, top);
      this._sLastSent = BBUtils.C_EMPTY_STRING;
    }

    location.hash = '/h/?m=' + sThisUrl;

    this.forceUpdate();

  }

  setBTitle = (sVal) => {    // B-Title: Back; <title/ghost> [; Find]
    if (sVal !== null && sVal.length > 0) {
      let sSplits = BBUtils.split(sVal, ';');
      if (sSplits.length === 1) {
        this.setState( {
          backButtonText: sSplits[0].trim()
        });
      } else if (sSplits.length >= 2) {
        let stateVars = {
          backButtonText: sSplits[0].trim()
        }
        if (this.state.inputVisible) {
          stateVars.inputHintText = sSplits[1].trim();
        } else {
          stateVars.titleText = sSplits[1].trim();
        }
        this.setState( stateVars );
      }
    }
  }

  setTitleText(s) {
    this.setState({titleText: s});
//    this.titleRef.current.setText(s);
  }
  setTitleVisible(vis) {
    this.setState({titleVisible: vis});
//    this.titleRef.current.setState({visible: vis});
  }
  getTitleVisible = () => {
    return this.state.titleVisible;
//    return this.titleRef.current.state.visible;
  }
  getEditTextVisible = () => {
    return this.state.inputVisible;
//    return this.inputRef.current.getVisible();
  }
  getHFieldVisible = () => {
    return this.state.hfieldVisible;
  }
  setInputVisible(vis) {
    this.setState({inputVisible: vis});
//    this.inputRef.current.setState({visible: vis});
  }
  setHintText(stxt) {
    this.setState({inputHintText: stxt});
//    this.inputRef.current.setHintText(stxt);
  }
  search(sUrl) {
    this.searchMaybeFocus(sUrl, true);
  }
  searchNoFocus(sUrl) {
    this.searchMaybeFocus(sUrl, false);
  }
  setToolbarItems(itms) {
    if (itms !== null) {
      if (!this.state.toolbarVisible)
        this.setState({toolbarVisible: true});
    } else {
      if (this.state.toolbarVisible)
        this.setState({toolbarVisible: false});
    }
    this.setState({_tbitems: itms});
  }

  shouldComponentUpdate() {
    return !this._bDelayRender;
  }
  gotoHome = () => {
    this.search(global.globalVars.getHome());
  }
  hashChange = () => {
    this.forceUpdate();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    global.globalVars.RenderDone();
  }

  register = (sUrl, callback, err) => {
    var xhr;
    if(typeof XMLHttpRequest !== 'undefined') xhr = new XMLHttpRequest();
    else {
        var versions = ["MSXML2.XmlHttp.5.0", 
                         "MSXML2.XmlHttp.4.0",
                         "MSXML2.XmlHttp.3.0", 
                         "MSXML2.XmlHttp.2.0",
                         "Microsoft.XmlHttp"]

         for(var i = 0, len = versions.length; i < len; i++) {
             try {
                 xhr = new ActiveXObject(versions[i]);
                 break;
             }
             catch(e){}
         } // end for
    }
    
    xhr.onreadystatechange = ensureReadiness;

    function ensureReadiness() {
      if (xhr.readyState == XMLHttpRequest.HEADERS_RECEIVED) {
        return;
      }

        if(xhr.readyState < 4) {
            return;
        }
        
        if(xhr.status !== 200) {
            err(xhr.status);
            return;
        }

        // all is well	
        if(xhr.readyState === 4) {
            callback(xhr.response);
        }			
    }
    
//        xhr.responseType = 'arraybuffer';
    xhr.open('GET', sUrl, true);

    xhr.send();
  }

  storeGuid = async (sGuid) => {
    try {
      await AsyncStorage.setItem("myguid.txt", sGuid);
    } catch (ex) { }
  }

  getMyGuid = async() => {
    try {
      let myGuid = await AsyncStorage.getItem("myguid.txt");
      return myGuid;
    } catch (ex) {
      return null;
    }

  }
  async componentDidMount() {
    let that = this;
    global.globalVars.RenderDone();

    window.addEventListener("hashchange", this.hashChange, false);

    if (this._firstSearch && this.state.mainView === "main") {
      that.mGUID = await that.getMyGuid();
      if (typeof that.mGUID !== 'undefined' && that.mGUID !== null && that.mGUID.length === 36) {
        that.bpSocket.setGuid(that.mGUID);
      }
      if (location.hash.length == 0) {
        if (that.mGUID === null || that.mGUID.length == 0) {
          // service/register          
          this.register(global.globalVars.getRegisterUrl(), 
            function(myGuid) {

              if (myGuid.length == 42) {
                that.mGUID = myGuid.substring(5,41);
                that.storeGuid(that.mGUID);
                that.bpSocket.setGuid(that.mGUID);

                that._firstSearch = false;
                that.searchNoFocus(global.globalVars.getHome());
              }
    
            }, function(err) {
                console.log(err);
            });

        } else {

          this._firstSearch = false;
          this.searchNoFocus(global.globalVars.getHome());
        }

      } else {
        // parse hash to get c= and u=, save to history so 'refresh' works
        var sUrl;
        var sChars;
        if (location.hash.length > 4 && location.hash.substring(0, 5).equals("#/h/?")) {
          let sHistory = location.hash.substring(5);
          var plist = sHistory.split('&');
          let params = {};
          for (let i = 0; i < plist.length; i++) {
            for (let ix = 0; ix < plist[i].length; ix++) {
              if (plist[i].charAt(ix) == '=') {
                let sVal = '';
                if (plist[i].length > ix + 1) {
                  sVal = plist[i].substring(ix + 1);
                }
                params[plist[i].substring(0, ix)] = sVal;
                break;
              }
            }
          }

          if (params.hasOwnProperty('c') && params.hasOwnProperty('u')) {
            let curLoc = global.globalVars.getBlocation().getFormattedLatLon();
            this.doSearchCU(params['c'], params['u'], sHistory, curLoc);
            /*
            this.sIncrementalBaseUrl = params['u'];
            this._cHistory.addHistory(params['u'], BBUtils.Utf8Decode(BBUtils.URLdecode(params['c'])), 0, 0);
            this.forceUpdate();
            */
          }
        }

      }
      this._firstSearch = false;
    } else {
    }
  };
  componentWillUnmount() {
//    window.removeEventListener("hashchange", this.hashChange, false);
  }

  renderMenu = () => {
    /*
    if (this.state._msl === null) {
      return null;
    } else {
      return(
        <ShowMenu msl={this.state._msl} />
      )
    }
    */
  }

  menuPressed = () => {
    global.globalVars.CMenu().menuClicked();
  }
  titleSearchPressed = () => {
    this.lastTitleSearchChannel = this.sIncrementalBaseUrl;
    this.setState( {
      titleSearchVisible: false
    });
    global.globalVars.CMenu().resetTextEditToDefault();
  }

  _renderHField() {
    if (this.state.hfieldVisible) {
      var sText = "";
      if (this._tLastFieldChanged > this._tLastTimeSet) {
        sText = this._sLastSent;
      } else {
        sText = this._sLastSetValue;
      }


      return (
        <DecorBackground
          style={[
            {
              height: global.globalVars.getTabBarHeight(),
            },
            styles.hfieldDecorBackground
          ]}
          dnType={DecorNode.DECORNODE_TYPE_NAVBAR}
          decorNodeString={this.state.hfieldDecorString}
          refresh={this.state.titleRefresh}
        >
          {this.state.leftButtonImageUrl ?
            <TouchableOpacity
              onPress={() =>  {global.globalVars.appClass.respondToBack()}}
              style={styles.leftButton}
              >
              <BPImage
                width={global.globalVars.getMenuSize()}
                height={global.globalVars.getMenuSize()}
                uri={this.state.leftButtonImageUrl}
                refresh={this.state.leftButtonRefresh}
              />
            </TouchableOpacity>
          :
            <CBackText
              style={styles.backButton}
              value={this.state.backButtonText}
              color={this.state.backButtonColor}
              refresh={this.state.titleRefresh}
            >
            </CBackText>
          }
          {this.state.titleVisible &&
            <CTitleView
              ref={this.titleRef}
              style={this.state.titleFontSettings}
              color={this.state.titleColor}
              title={this.state.titleText}
            />
          }
          {this.state.inputVisible &&
            <CTextInput
              ref={this.inputRef}
              style={[
                {
                  height: global.globalVars.getInputHeight(),
                },
                styles.input]}
              notifyParentTextChanged={this.fieldChanged}
              placeholderTextColor='gray'
              hintText={this.state.inputHintText}
              value={sText}
              refresh={this.state.titleRefresh}
            />
          }
          {this.state.titleSearchVisible && this.state.titleSearchButtonImageUrl &&
            <TouchableOpacity
              onPress={() => this.titleSearchPressed()}
              style={styles.rightMenu}
            >
              <BPImage
                width={global.globalVars.getMenuSize()}
                height={global.globalVars.getMenuSize()}
                uri={this.state.titleSearchButtonImageUrl}
                refresh={this.state.titleSearchButtonRefresh}
              />
            </TouchableOpacity>
          }
          <TouchableOpacity
            onPress={() => this.menuPressed()}
            style={styles.rightMenu}
          >
            <BPImage
              width={global.globalVars.getMenuSize()}
              height={global.globalVars.getMenuSize()}
              uri={this.state.rightButtonImageUrl}
              refresh={this.state.rightButtonRefresh}
            />
          </TouchableOpacity>
          {this.state._msl !== null && this.state._mslIx == -1 &&
            <ShowMenu msl={this.state._msl} />
          }
        </DecorBackground>
      );
    } else {
      return null;
    }
  }
  renderSeparator = () => {
    return (
      <CSeparatorView ref={this.separatorRef} 
        decorNodeString={this.state.separatorDecorString}
      />
    );
  }

  onLayoutList = (event) => {
    const {x, y, height, width} = event.nativeEvent.layout;
    this.listHeight = height;
  }
  onListSizeChange = (wid, height) => {
    if (this.state.adjustedWidth != wid) {
      this.setState({adjustedWidth: wid});
//      this.forceUpdate();
    } else {
      this.state.adjustedWidth = wid;
    }
  }
  parseHash = (sHistory) => {
    // split on &, then split on = (well, that was naive...we just want the c= and u= params)
    /*
    var plist = sHistory.split('&');
    let params = {};
    for (let i = 0; i < plist.length; i++) {
      for (let ix = 0; ix < plist[i].length; ix++) {
        if (plist[i].charAt(ix) == '=') {
          let sVal = '';
          if (plist[i].length > ix + 1) {
            sVal = plist[i].substring(ix + 1);
          }
          params[plist[i].substring(0, ix)] = sVal;
          break;
        }
      }
    }
    */
   let myRegex = /^c=([^&]*)&u=(.*)/gm
   let matches = myRegex.exec(sHistory);
   let params = {}
    if (matches.length == 3) {
      params['c'] = matches[1];
      params['u'] = matches[2];
    }
    return params;    
  }

  doSearchCU = (c, u, sHistory, curLoc) => {
    let sb = new StringBuffer();
    let sUrl = u;
    let sChars = c;
    sb.append("/wwu?c=");
    sb.append(sChars);
    sb.append("&u=");
    sb.append(sUrl);

//            sb.append("/umenu?u="); // http specific umenu means URLEncoded
//            sb.append(sUrl);

    this.sIncrementalBaseUrl = u;   // incase refresh browser issue #2276 2022-03-09

    this.bpSocket.addHttpTask(BPHttpTask.HTTP_TASK_CLIENT, sb.toString(),
      BBUtils.C_EMPTY_STRING);

    //if (sChars.length > 0) 
    {
      this._tLastTimeSet = (new Date()).getTime();
      this._sLastSetValue = BBUtils.URLdecode(BBUtils.Utf8Decode(sChars));
      if (this.inputRef.current !== null) {
        let sTyped = "";
        let ix = 0;
        if (this._sLastSetValue.charAt(ix) == '{') {  // strip off any {detail...}
          ix++;
          while (ix < this._sLastSetValue.length && this._sLastSetValue.charAt(ix) != '}')
            ix++;
          if (ix < this._sLastSetValue.length)
            ix++;
          sTyped = this._sLastSetValue.substring(ix);
        } else {
          sTyped = this._sLastSetValue;
        }

        this.inputRef.current.setState({ value: sTyped });
      }
    }

    this.lastHistoryRequest = sHistory;
    this.lastLocation = curLoc;
  }

  checkHash = () => {
    if (this._firstSearch) return;

    let hash = location.hash; // /<number>
    if (hash.length > 4 && hash.substring(0,5).equals("#/h/?")) {
      let sHistory = hash.substring(5);
      let curLoc = global.globalVars.getBlocation().getFormattedLatLon();
      if (sHistory != this.lastHistoryRequest || !curLoc.equals(this.lastLocation)) { // TODO: check location too...it may have changed

        // c=<> & u=<>
        try {
          let params = this.parseHash(sHistory);

          if (params.hasOwnProperty('m')) {
            let sb = new StringBuffer();
            sb.append("/umenu?u="); // http specific umenu means URLEncoded
            sb.append(BBUtils.UrlEncode(params['m'])); // http specific
        
            this.bpSocket.addHttpTask(BPHttpTask.HTTP_TASK_CLIENT, sb.toString(),
                BBUtils.C_EMPTY_STRING);

            {
              this._tLastTimeSet = (new Date()).getTime();
              this._sLastSetValue = ""; // sChars
              if (this.inputRef.current !== null) {
                this.inputRef.current.setState({ value: this._sLastSetValue });
              }
            }
    
            this.lastHistoryRequest = sHistory;
            this.lastLocation = curLoc;
            
          }
          else if (params.hasOwnProperty('c') && params.hasOwnProperty('u')) {

            this.doSearchCU(params['c'], params['u'], sHistory, curLoc);
          }
        } catch (ex) {
console.log(ex.toString());
        }

      }
    }
    return(
      <>
      </>
    )
  }
  deleteRow = (rowIx) => {
    //const filteredData = this.state._dataIndexes.filter(item => item.index !== rowIx);
    var filteredData = [];
    for (let i=0; i<this.state._dataIndexes.length; i++) {
      if (i != rowIx) {
        const item = this.state._dataIndexes[i];
        if (i > rowIx) {
          item.index = item.index-1;
        }
        filteredData.push(item);
      }
    }
    this.setState({_dataIndexes : filteredData});
  }

  renderItem = ({item}) => {
    if (this.state._msl !== null && this.state._mslIx == item.index) {
      return (
        <>
          <ShowMenu msl={this.state._msl} />
          <DrawView ref={item.ref}
            refresh={this.state.cellRefresh}
            tkey={item.key}
            dnItemString={this.state.itemDecorString}
            item={item.index}
            width={this.state.adjustedWidth} />
        </>
      );
    } else {
      return (
        <DrawView ref={item.ref}
          refresh={this.state.cellRefresh}
          hrefresh={this.state.horizontalScrollersRefresh}
          tkey={item.key}
          dnItemString={this.state.itemDecorString}
          item={item.index}
          width={this.state.adjustedWidth} />
      );
    }
  }

  //Edit Start
  setTreeViewDisabled = (tf) => {
    this.setState({treeViewDisabled: tf});
  }

  setEditId(nodeId) {
    this.setState({editId: nodeId});
  }
  setEditModeTrue = () => {
    const windowWidth = Dimensions.get('window').width;
    if (windowWidth < 1100) {
      this.showErrorMessage('The screen is too small to use the editor. Try a desktop browser.');
    } else {
      this.setEditMode(true);
    }
  }

  saveEdit = () => {
    global.globalVars.getEditInfo().save();
  }
  cancelEdit = () => {
    this.setEditMode(false);
    this.RefreshR();
  }

  setEditMode = (tf) => {
    if (this.canEdit !== tf && tf) {
      // Stop refreshes while editing
      this.notifyClientDoNotUseGPS();
      this.cancelRefreshTimer();

      global.globalVars.newEdit();
      this.getEditInformation();
      this.setState( {
        canEdit: tf,
        treeViewDisabled: true,
      });
  
    } else {
      this.setState( {
        canEdit: tf,
      });  
    }
  }

  getEditInformation = () => {
    let sb = new StringBuffer();
    // https://troparesheets-dev.boopsie.tredir.com/host/troparesheets/edit/notesettings.pl?
    // channel=<url encode>
    // B-PIN=troparesheets-dev.boopsie
    // uid=7f41f8ce-2884-11ec-b0c3-0bdd9ecd8d04
    // B-GPS=<  >
    let hash = location.hash; // /<number>
    if (hash.length > 4 && hash.substring(0, 5).equals("#/h/?")) {
      let sHistory = hash.substring(5);
      // c=<> & u=<>
      try {
        let params = this.parseHash(sHistory);

        if (params.hasOwnProperty('u')) {
          // strip domain/i   leaving  /chann/elname/
          let sChannelUrl = params['u'];
          const sBaseServer = global.globalVars.getBaseServer() + '/i';  // https://....  (no trailing /)
          if (sChannelUrl.startsWith(sBaseServer)) {
            let sChannel = sChannelUrl.substring(sBaseServer.length);
            global.globalVars.getEditInfo().setEditChannel(sChannel);
            global.globalVars.getEditInfo().setEditDetail(null);
            sb.append("/host/troparesheets/edit/notesettings.pl?channel="); // http specific umenu means URLEncoded
            sb.append(BBUtils.UrlEncode(sChannel)); // http specifi
            if (params.hasOwnProperty('c')) {     // {detail=6|_ts_2715/troparesheets_detail}
              if (params['c'].startsWith("{detail=") && params['c'].endsWith("}")) {
                sb.append("&");
                let sDetail = params['c'].substring(1,params['c'].length-1);
                global.globalVars.getEditInfo().setEditDetail(sDetail);
                sb.append(sDetail);
              }
            }
            this.bpSocket.addEditTask(BPHttpTask.HTTP_TASK_CLIENT, sb.toString());
          }
        }
      } catch (ignore) {
      }
    }
  }

  editNotesReturn = (sNotes) => {
    try {
      let fullJson = JSON.parse(sNotes);
      if (fullJson.hasOwnProperty('noteSettings')) {
        let noteSettings = fullJson.noteSettings;
        let template = {};
        if (fullJson.hasOwnProperty('sheet_data')) {
          //.layout = "{NAME}|{ADDR}\t\t{LATLON} etc.."
          // .detailLanding = "\t\t{PHOTO}\t{NAME} etc..."
          if (fullJson.sheet_data.hasOwnProperty('layout')) {
            template['layout'] = fullJson.sheet_data.layout;
          }
          if (fullJson.sheet_data.hasOwnProperty('detailLanding')) {
            template['detailLanding'] = fullJson.sheet_data.detailLanding;
          }
          if (fullJson.sheet_data.hasOwnProperty('headerArray')) {  // available data
            template['headerArray'] = fullJson.sheet_data.headerArray;
          }
        }

        global.globalVars.getEditInfo().setNoteSettings(noteSettings, template, this._bpReturnVars._headers);
      }

    } catch (ignore) {
      this.showErrorMessage('Failed to retrieve current Comments for settings');
    }
  }
  setSRowHints(sRowHints) {
    this.setState({srowhints: sRowHints});
  }

  leftNavChange = (sel) => {    // data, style
    if (sel === 'data') {

    } else if (sel === 'style') {

    }

  }

  shrink =() => {
    this._currentSetHeight = 3 * this._currentSetHeight/4;
    this._currentSetWidth = 3 * this._currentSetWidth / 4;
    this.myHeight = this._currentSetHeight; // will change in render
    this.myWidth = this._currentSetWidth;

    global.globalVars.scaleUI( .75 );

    global.globalVars.CMenu().refreshRowHints();

    this.setState( {
      adjustedWidth: this.myWidth,
      toolbarRefresh: !this.state.toolbarRefresh,
      screenRefresh: !this.state.screenRefresh,
      titleRefresh: !this.state.titleRefresh,
      cellRefresh: !this.state.cellRefresh,
      rightButtonRefresh: !this.state.rightButtonRefresh,
      leftButtonRefresh: !this.state.leftButtonRefresh,
      horizontalScrollersRefresh: !this.state.horizontalScrollersRefresh,
    });
  }

  //Edit End
  render() {

    this.myHeight = (this._currentSetHeight - global.globalVars.getTabBarHeight() - (this.state.toolbarVisible ? global.globalVars.getTabBarHeight() : 0));
    let that = this;
    return (
      <View style={styles.fullPage}>
        {false && 
          <div style={{display: 'flex', flexDirection: "row", height: "3em", justifyContent: "center"}}>
            <Button variant="text"
              onClick={this.shrink}
            >Shrink</Button>
            </div>
        }
        {this.state.canEdit && 
          <div style={{display: 'flex', flexDirection: "row", height: "3em", justifyContent: "center"}}>
            <Button variant="text"
              onClick={this.saveEdit}
            >Save</Button>
            <Button variant="text"
              onClick={this.cancelEdit}
            >Cancel</Button>
          </div>
        }
        {this.state.canEdit &&
          <View style={styles.editTop}>
            <View style={styles.editHeaderBar}>
              <TextField 
                value="Troparé Sheets"
                variant="standard"
                inputProps={{style: {
                      color: 'white'
                }}} />
            </View>
            <View style={styles.editFileBar}>
            </View>
            <View style={styles.editIconBar}>
            </View>
          </View>
        }
        <View style={styles.horizMiddle}>
          {this.state.canEdit &&
            <>
            <ELeftNav 
              style={styles.leftIcons}
              onChange={this.leftNavChange}
            >
            </ELeftNav>
              <View style={styles.leftSelector}>
                <EComponentTree disableSelection={this.state.treeViewDisabled} toolbarText={this.state.toolbarText} />
              </View>
            </>
          }

          <MenuProvider style={styles.menuContext}>
            <Router>
              <Routes>
                <Route path="/h/"
                  element={
                    <View 
                      style={
                        styles.clientContainer
                        }
                      >
                      {this.checkHash()}
                      <View style={
                        [
                          {
                            width: this._currentSetWidth,
                            height: this._currentSetHeight+2,    // this.myHeight + global.globalVars.getTabBarHeight()*2,
                          }, 
                          styles.container]}
                      >
                        {this.renderMenu()}
                        <BMessageText ref={this.messageRef} />
                        {this._renderHField()}
                        <canvas
                          ref={this.canvasRef}
                          style={
                            {
                              display: "none",
                              position: "absolute",
                              width: 1000,
                              height: 1000,
                              zIndex: 1,
                            }
                          }
                        />
                        <DecorBackground style={{
                          height: this.myHeight,
                          width: this._currentSetWidth-2,
                        }} refresh={this.state.screenRefresh} dnType={DecorNode.DECORNODE_TYPE_TABLE} decorNodeString={this.state.decorNodeString}>
                          <VirtualizedList
                            ref={this.listRef}
                            onLayout={this.onLayoutList}
                            onContentSizeChange={this.onListSizeChange}
                            data={that.state._dataIndexes}
                            ItemSeparatorComponent={this.renderSeparator}
                            initialNumToRender={10}
                            renderItem={this.renderItem}
                            persistentScrollbar={true}
                            keyExtractor={(item, index) => {
                              return item.key;
                            }}
                            getItemCount={
                              (data) => that.state._dataIndexes.length
                            }
                            getItem={(data, index) => {
                              return data[index];
                            }}
                            onScroll={event => {
                              this.scroll = event.nativeEvent.contentOffset.y;
                            }}
                          />
                        </DecorBackground>
                        <ToolbarManager
                          ctx={this.ctx}
                          refresh={this.state.toolbarRefresh}
                          visible={this.state.toolbarVisible}
                          items={this.state._tbitems}
                          decorNodeString={this.state.toolbarDecorString}
                        />
                      </View>
                    </View>
                  } />
                <Route path="/map"
                  element={
                    <BBMaps style={styles.clientContainer}
                      instructions={this.state.mapInstructions}
                    >
                    </BBMaps>
                  } />
                <Route path="/web"
                  element={
                    <BIFrame style={styles.clientContainer}
                      url={this.state.webUrl}
                      pin={global.globalVars.getBPIN()}
                      uid={this.mGUID}
                    >
                    </BIFrame>
                  } />
                <Route path="/photo"
                  element={
                    <BPhoto style={styles.clientContainer}
                      url={this.state.photoUrl}
                      pin={global.globalVars.getBPIN()}
                      uid={this.mGUID}
                    >
                    </BPhoto>
                  }
                />                    
              </Routes>
              {this.state.loading &&
                <View style={styles.loading}>
                  <ActivityIndicator size="large" />
                </View>
              }
            </Router>
            {this.state.canEdit &&
              <View style={styles.editBottom}>

              </View>
            }
          </MenuProvider>

          {this.state.canEdit &&
            <View style={styles.rightProperties}>
              <EProperties id={this.state.editId}
              />
            </View>
          }
        </View>
      </View>
    );
  }
}
/*
              <EComponentSelector srowhints={this.state.srowhints}/>

*/
//{uri: `${base64Icon}`}
//http://img-troparesheets.prospectsforme.com/upload/troparesheets/000103
const myStyleInput = {
  white: {
    color: 'white',
  },
  fullPage: {
    flex: 1,
    flexDirection: 'column',
    //minWidth: "995px",
    width: '100%',
    maxWidth: '1200px',
  },
  editTop: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    height: 120,
  },
  horizMiddle: {
    flex: 1,
    flexDirection: 'row'
  },
  editHeaderBar: {
    width: "100%",
    height: 40,
    backgroundColor: "#243052",
    alignItems: 'center',
  },
  editFileBar: {
    width: "100%",
    height: 40,
  },
  editIconBar: {
    width: "100%",
    height: 40,
  },
  leftIcons: {
    flex: 1,
    flexGrow: 0,
    flexBasis: 'fit-content',
    flexDirection: 'column',
    width: "40px",
    borderWidth: 2,
    borderColor: 'lightgray',
  },
  leftSelector: {
    width: "300px",
    borderWidth: 2,
    borderColor: 'lightgray',
  },
  rightProperties: {
    width: "320px",
    borderWidth: 2,
    borderColor: 'lightgray',
  },
  container: {
    borderWidth: 1,
    borderColor: 'gray',
  },
  clientContainer: {
    flex: 1,
    backgroundColor: '#eee',
    alignItems: 'center',
//    justifyContent: 'center',
  },
  menuContext: {
    flex: 1,
//    alignItems: 'center',
//    justifyContent: 'center',
    backgroundColor: '#ecf0f1',
  },
  editBottom: {
    position: 'absolute',
    height: 40,
    width: '100%',
    bottom: 0,
    borderWidth: 1,
    borderColor: 'black',
    backgroundColor: 'white'
  },
  hfieldDecorBackground: {
    width: "100%",
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
},
backButton: {
  alignSelf: 'center',
  minWidth: '3em',
  maxWidth: '7em',
  padding: 10,
},
  ctitle: {
    flexGrow: 1,
    alignSelf: 'center',
    marginVertical: 10,
    marginHorizontal: 0,
  },
  input: {
    flexGrow: 1,
    alignSelf: 'center',
    marginVertical: 10,
    marginHorizontal: 0,
    borderWidth: 1,
    padding: 2,
    backgroundColor: '#ffffff',
    borderRadius: 8,
  },
  leftButton: {
    alisgnSelf: 'center',
    alignItems: 'center',
    padding: 10,
  },
  rightMenu: {
    alisgnSelf: 'center',
    alignItems: 'center',
    padding: 10,
  },
  item: {
    backgroundColor: '#f9c2ff88',
    height: 150,
    justifyContent: 'center',
    marginVertical: 8,
    marginHorizontal: 16,
    padding: 20,
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  }
}
const styles = StyleSheet.create(myStyleInput);
App.C_DEFAULT_SEPARATOR_STRING = "bg(c(#ddd))";
App.C_DEFAULT_TITLE_STRING = "bg(c(#777))";
App.C_DEFAULT_SCREEN_STRING= "bg(c(#fff))";

export default App;
