export default class ColumnHints {
    constructor(_item, _width, _font, dn) {
      this.widthPercent = 0;
      this.fontPercent = 0;
      this.item = null;
      this.dNode = null;
  
      this.item = _item;
      this.widthPercent = _width;
      this.fontPercent = _font;
      this.dNode = dn;
    }
  }
  