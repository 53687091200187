import React from 'react';
import { StyleSheet, Text, Image, View, TouchableOpacity } from 'react-native';
import DecorNode from '../graphics/DecorNode';
import ToolbarManager from './ToolbarManager';
import BPColor from '../graphics/BPColor';
import BGraphics from '../graphics/BGraphics';
import Color from '../graphics/Color';
import { ThirtyFpsTwoTone } from '@mui/icons-material';

export default class HSView extends React.Component {
    constructor(props) {
        // tbi={TBItem}
        // tbi.defaultSize = {widthType: 'matchHeight' | 'perc' | 'ratio' | 'units', width: <val>}
        // dnToolbar={DecorNode}
        super(props);

        this.state = {
            decorNodeString: "bg(c(#00ffffff))",
            fg: '#000',
            myFontSettings: null,
            refresh: props.refresh,
            hrefresh: props.hrefresh,
            imageWidth: 0,
            imageHeight: 0,
            containerHeight: 0,
            textHeight: 0,
            imageSet: false,
            containerSet: false,
            textSet: false,
            myWidth: 200,
            myHeight: '100%',
            mDecorNode: null,
            marginTop: 0,
            marginLeft: 0,
            marginBottom: 0,
            marginRight: 0,
            hAlign: 0,
        }
        this.resultingHeight = 100000;
        this.containerDone = this.containerDone.bind(this);
        this.textLayoutDone = this.textLayoutDone.bind(this);
        this.getMyUrl = this.getMyUrl.bind(this);

    }

    static getDerivedStateFromProps(props, state) {

        if (props.decorToolbarString != state.decorNodeString || props.refresh != state.refresh || props.hrefresh != state.hrefresh) {

            let dn = DecorNode.FromAttributes(props.decorToolbarString, DecorNode.DECORNODE_TYPE_TOOLBAR);

            let fgColor = 0x0FFFFFFFF;
            if (dn !== null) {
                if (dn.hasFGColor()) {
                    fgColor = dn.getFGColor();
                }
            }
            if (!props.tbi.getEnabled()) {
                fgColor = BGraphics.tintColor(fgColor, Color.LTGRAY);
            }
            if (props.tbi.getActive()) {
                if (dn != null && dn.hasTintColor()) {
                    fgColor = dn.getTintColor();
                } else {
                    fgColor = 0x0FF007AFF;  // blueish
                }
            }
            let pnFont = null;
            let hAlign = 0;
            if (dn !== null) {
                pnFont = dn.findChildNode(DecorNode.C_lowercase_font);
                hAlign = DecorNode.getHAlignVal(pnFont);    
            }

            let fSettings = DecorNode.getFontSettings(pnFont, {
                fontSize: .86,   // 12pt
                fontWeight: 300,
            });

            let newState ={
                decorNodeString: props.decorToolbarString == null ? "bg(c(#00ffffff))" : props.decorToolbarString,
                fg: fgColor,
                myFontSettings: fSettings,
                hAlign: hAlign,
            }


            // width calculation...
            switch (props.tbi.defaultSize.widthType) {
                case 'item':
                    break;
                case 'matchHeight':
                    break;
                case 'perc':
                    break;
                case 'ratio':
                    break;
                case 'units':
                    newState.myWidth = props.tbi.defaultSize.width;
                    break;
            }
            let marginTop = 0;
            let marginBottom = 0
            let marginLeft = 0;
            let marginRight = 0;
            if (dn !== null) {
                if (dn.hasMarginTop()) {
                        marginTop = dn.getMarginTop();
                }
                if (dn.hasMarginBottom()) {
                        marginBottom = dn.getMarginBottom();
                }
                if (dn.hasMarginLeft()) {
                        marginLeft = dn.getMarginLeft();
                }
                if (dn.hasMarginRight()) {
                        marginRight = dn.getMarginRight();
                }
            }
            //if (props.refresh != state.refresh)
             {
                newState.refresh = props.refresh;
                newState.hrefresh = props.hrefresh;
                newState.mDecorNode = dn;
                newState.marginRight = marginRight;     // these are already taken out by the surrounding View
                newState.marginLeft = marginLeft;
                newState.marginTop = marginTop;
                newState.marginBottom = marginBottom;

//                newState.width = global.globalVars.getToolbarButtonWidth();
//                newState.height = global.globalVars.getToolbarButtonHeight();    
            }
            return newState;
        } else {
            return null;
        }

    }
    onPress = () => {
        let ii = this.props.tbi.getMenuId();
        if (this.state.mDecorNode != null) {
            let pnI = this.state.mDecorNode.getRoot();
            let sAction = DecorNode.getAction(pnI);
            let processingType = 'I';
            let nextStep = 'N';
            let pnFindA = DecorNode.findChildNode$2(pnI, DecorNode.C_lowercase_actiontype);
            if (pnFindA != null) {
                let pAtt = pnFindA.firstChild();
                if (pAtt != null && pAtt.TID() != null && pAtt.TID().length == 3) {
                    processingType = pAtt.TID().charAt(1);
                    nextStep = pAtt.TID().charAt(2);
                }
            }
            if (processingType == 'I') {
                global.globalVars.CMenu().processAction(sAction, false);
            } else if (processingType == 'O') {
                global.globalVars.CMenu().processAction(sAction, true);
            } else if (processingType == 'V') {
                global.globalVars.CMenu().processExtendedAction(sAction, GGCACTION_EXTERNAL_WNAV);
            }

            if (processingType == 'I' || processingType == 'O' || processingType == 'V') {
                // R means refresh. S means refresh and send textbox data
                if (nextStep == 'R') {
                    global.globalVars.CMenu().processAction.RefreshR();
                } else if (nextStep == 'S') {
                    global.globalVars.CMenu().processAction.RefreshS();
                }
            }

        }

    }

    componentDidUpdate(prevProps, prevstate, snapshot) {
        if (prevProps.decorToolbarString != this.props.decorToolbarString) {
            console.log('update:', this.props.decorToolbarString);
        }
    }

    componentDidMount() {
        if ((this.props.btnType == ToolbarManager.BTOOLBAR_BUTTON_TYPE_COMBO || this.props.btnType == ToolbarManager.BTOOLBAR_BUTTON_TYPE_IMAGE) && 
            this.getMyUrl() != null) {
                Image.getSize(this.getMyUrl(), (w,h) => {
                    var stateProps = {
                        imageSet: true,
                        imageWidth: w,
                        imageHeight: h
                    }
                    if (this.state.containerSet && this.props.fitType == "sizetofit" && this.props.btnType == ToolbarManager.BTOOLBAR_BUTTON_TYPE_IMAGE) {
                        stateProps.myWidth = w * (this.resultingHeight / h);
                    }

                    if (this.props.loaded) {
                        this.props.loaded(this.props.index, w, h);
                    }
            
                    this.setState(stateProps);
                })
            }
    }
    getMyUrl = () => {
        return global.globalVars.getCacheableUrl( this.props.tbi.getRemoteImageUrl());
    }

    containerDone = (event) => {
        var {height, width} = event.nativeEvent.layout;
        if (height < this.resultingHeight)   // this prevents infinite redraw loop...when adding scrollbar, it reduces the height)
            this.resultingHeight = height;
            
        // if sizetofit myWidth becomes width (however, what about margins...)
        // width calculation...
        if (this.state.imageSet && this.props.fitType == 'sizetofit' && this.props.btnType == ToolbarManager.BTOOLBAR_BUTTON_TYPE_IMAGE) {
            var mw = (this.state.imageWidth * this.resultingHeight / this.state.imageHeight);
            this.setState({myWidth : mw});
        } else {
            switch (this.props.tbi.defaultSize.widthType) {
                case 'matchHeight':
                    // This matches the width of this (after margins) to the height of this (after margins)
                    this.setState({ myWidth: height });
                    break;
                case 'perc':
                    // This is different, it wants to take a percentage of the HorizScroller width and then subtract the margins
                    // so that the combined margins + percent will be the allocated area.  This would be used (for example) if you want
                    // 50 of the screen width dedicated to each item (including margins)
                    this.setState({ myWidth: (this.props.twidth * this.props.tbi.defaultSize.width / 100) - (this.state.marginRight + this.state.marginLeft) });
                    break;
                case 'ratio':
                    // This matches the width of this (after margins) to the height of this (after margins) to set a
                    // resulting ratio of width to height
                    this.setState({ myWidth: height * this.props.tbi.defaultSize.width })
                    break;
                case 'units':
                    break;
            }
        }
        
        if (this.getMyUrl() != null) {
            switch (this.props.btnType) {
                case ToolbarManager.BTOOLBAR_BUTTON_TYPE_COMBO:
                case ToolbarManager.BTOOLBAR_BUTTON_TYPE_IMAGE:
                    this.setState( {
                        containerSet: true,
                        containerHeight: height
                    })
                    break;
            }
        } 
    }
    textLayoutDone = (event) => {
        var {height} = event.nativeEvent.layout;
        if (this.getMyUrl() != null) {
            switch (this.props.btnType) {
                case ToolbarManager.BTOOLBAR_BUTTON_TYPE_COMBO:
                    this.setState( {
                        textSet: true,
                        textHeight: height
                    })
                    break;
            }
        }
    }

    // tbi.
    // tbi.getImageUrl();
    // toobar.getButtonType() - BTOOLBAR_BUTTON_TYPE_LABEL, etc.

    render() {
        var that = this;

        switch (this.props.btnType) {
            case ToolbarManager.BTOOLBAR_BUTTON_TYPE_LABEL:
                return (
                    <View
                        onLayout={that.containerDone}
                        style={[{
                            width: this.state.myWidth,
                        },
                        styles.container,
                        ]}
                    >
                        <TouchableOpacity
                            style={styles.iconHorizontal}
                            onPress={this.onPress}
                            disabled={!this.props.tbi.getEnabled()}
                        >
                            <View
                                style={{ justifyContent: 'center', height: '100%' }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={
                                        {
                                            textAlign: 'center',
                                            color: BPColor.intToHexRGBA(this.state.fg),
                                            ...this.state.myFontSettings,
                                        }
                                    }>{this.props.tbi.getLabel()}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                )
                break;
            case ToolbarManager.BTOOLBAR_BUTTON_TYPE_IMAGE:
                if (this.props.width && this.props.height) {
                    return (
                        <View
                            style={{
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <TouchableOpacity
                                style={{ height: '100%' }}
                                onPress={this.onPress}
                                disabled={!this.props.tbi.getEnabled()}
                            >
                                    <Image
                                        resizeMode='contain'
                                        source={this.getMyUrl()}
                                        style={[{
                                            width: this.props.width,
                                            height: this.props.height,
                                        },
                                        styles.icon,
                                        ]}
                                    />
                            </TouchableOpacity>
                        </View>
                    )
                } else {
                    return (
                        <View
                            onLayout={that.containerDone}
                            style={{
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: this.state.myWidth,
                                height: this.state.myHeight,
                            }}
                        >
                            <TouchableOpacity
                                style={{ height: '100%' }}
                                onPress={this.onPress}
                                disabled={!this.props.tbi.getEnabled()}
                            >
                                {this.state.imageSet && this.state.containerSet &&
                                    <Image
                                        resizeMode='contain'
                                        source={this.getMyUrl()}
                                        style={[{
                                            width: this.state.myWidth,
                                            height: this.state.myHeight,
                                        },
                                        styles.icon,
                                        ]}
                                    />
                                }
                            </TouchableOpacity>
                        </View>
                    )
                }
                break;
            case ToolbarManager.BTOOLBAR_BUTTON_TYPE_COMBO:
                // TODO: support 'fitheight' simila to IMAGE, but pass 'up' textLayoutDone height
                return (
                    <View
                        onLayout={that.containerDone}
                        style={[{
                            width: this.state.myWidth,
                        },
                        styles.container,
                        ]}
                    >
                        <TouchableOpacity
                            style={styles.iconHorizontal}
                            onPress={this.onPress}
                            disabled={!this.props.tbi.getEnabled()}
                        >
                            {this.state.imageSet && this.state.containerSet && this.state.textSet &&
                                <Image
                                    resizeMode='contain'
                                    source={this.getMyUrl()}
                                    style={[{
                                        width: this.state.myWidth,
                                        height: this.state.containerHeight - this.state.textHeight,
                                    },
                                    styles.icon,
                                    ]}
                                />
                            }
                            <Text
                                onLayout={that.textLayoutDone}
                                numberOfLines={1}
                                style={{
                                    textAlign: 'center',
                                    color: BPColor.intToHexRGBA(this.state.fg),
                                    ...this.state.myFontSettings,
                                }}>{this.props.tbi.getLabel()}</Text>
                        </TouchableOpacity>
                    </View>
                )
                break;
                default:
                    return null;
        }

    }
}
const styles = StyleSheet.create({
      container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    tab: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    label: {
        textAlign: 'center',
        backgroundColor: 'transparent',
    },
    iconHorizontal: {
        height: '100%',
		},
    icon: {
        alignSelf: 'center',
        // Workaround for react-native >= 0.54 layout bug
        //minWidth: 25, 
    }
  });