import DecorNode from "../graphics/DecorNode";
import ParenNode from "../graphics/ParenNode";
import BBUtils from "../view/BBUtils";
import BNumberUtils from "../graphics/BNumberUtils";
import RowHints from "../view/RowHints";
import ColumnHints from "../view/ColumnHints";

export default class CMenuAdapterUtils {
    constructor() {
    }
}

CMenuAdapterUtils._touchHeight = 44;    // (int) (((44.0 * _ydpi * 160.0) / 163.0) + 0.5f);

CMenuAdapterUtils.parseUnitsHeight = (sUnits) => {
    let height = 0;
    if (sUnits.equals('t')) {
        height = CMenuAdapterUtils._touchHeight;
    } else {
        let pn = ParenNode.NodeFromString(sUnits);
        if (pn !== null) {
            pn = pn.firstChild();
            if (pn !== null) {
                let dv = DecorNode.parseUnitsWithValue(pn);
                if (dv !== Number.MAX_VALUE) {
                    height = dv;
                } else {
                    height = 0;
                }
            }
            pn = null;
        }
    }
    return height;
};

CMenuAdapterUtils.setupRowHints = (rowHintsText) => {
    let rowHints = null;
    let bok = true;
    try {
        if (rowHintsText !== null && rowHintsText.length > 0) {
            let tokens = BBUtils.split(rowHintsText, ';');
            if (tokens.length >= 4) {
                let numTokens = tokens.length;
                let rowHeights = null;
                let rowLines = null;
                let rowWidths = null;
                let rowFonts = null;
                let rowDecors = null;
                for (let i = 0; i < numTokens; i++) {
                    switch (i) {
                        case 0:
                            rowHeights = BBUtils.split(tokens[i], '|');
                            break;
                        case 1:
                            rowLines = BBUtils.split(tokens[i], '|');
                            break;
                        case 2:
                            rowWidths = BBUtils.split(tokens[i], '|');
                            break;
                        case 3:
                            rowFonts = BBUtils.split(tokens[i], '|');
                            break;
                        case 4:
                            rowDecors = BBUtils.split(tokens[i], '|');
                            break;
                    }
                }
                if (rowHeights !== null && rowLines !== null && rowWidths !== null && rowFonts !== null && rowHeights.length === rowLines.length && rowLines.length === rowWidths.length && rowWidths.length === rowFonts.length) {
                    let line = null;
                    let width = null;
                    let font = null;
                    let decor = null;
                    for (let j = 0; bok && j < rowHeights.length; j++) {
                        let height = 0;
                        let sUnits = rowHeights[j].trim();
                        height = CMenuAdapterUtils.parseUnitsHeight(sUnits.toLowerCase());
                        line = BBUtils.split(rowLines[j].trim(), ',');
                        width = BBUtils.split(rowWidths[j].trim(), ',');
                        font = BBUtils.split(rowFonts[j].trim(), ',');
                        if (rowDecors !== null && rowDecors.length > j) {
                            decor = BBUtils.split(rowDecors[j].trim(), ',');
                        } else {
                            decor = null;
                        }
                        if (line !== null && width !== null && font !== null && line.length === width.length && width.length === font.length) {
                            if (rowHints === null) {
                                rowHints = new Array(rowHeights.length);
                            }
                            let hints = new RowHints(height, line.length);
                            for (let k = 0; k < line.length; k++) {
                                let cwidth = BNumberUtils.parseDouble(width[k].trim());
                                if (cwidth === Number.MAX_VALUE) {
                                    cwidth = 100.0;
                                }
                                let cfont = BNumberUtils.parseDouble(font[k].trim());
                                if (cfont === Number.MAX_VALUE) {
                                    cfont = 100.0;
                                }
                                let item = line[k].trim();
                                let dn = null;
                                if (decor !== null && decor.length > k) {
                                    dn = DecorNode.FromAttributes(decor[k], DecorNode.DECORNODE_TYPE_CELL);
                                }
                                let newColumn = new ColumnHints(item, cwidth, cfont, dn);
                                hints.addColumn(newColumn);
                            }
                            rowHints[j] = hints;
                        } else {
                            bok = false;
                        }
                    }
                }
            }
        }
    } catch (ex) {
        rowHints = null;
    }
    if (!bok) {
        rowHints = null;
    }
    return rowHints;

}
