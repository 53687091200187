import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Image, Button } from 'react-native';

import * as ImagePicker from 'expo-image-picker';


function BPhoto(props) {
    // The path of the picked image
    const [pickedImagePath, setPickedImagePath] = useState('');
    const [cameraPermission, setCameraPermission] = useState(null);
    const [galleryPermission, setGalleryPermission] = useState(null);

    const permisionFunction = async () => {
        // here is how you can get the camera permission
        const cameraPermission = await ImagePicker.requestCameraPermissionsAsync();

        setCameraPermission(cameraPermission.status === 'granted');

        const imagePermission = await ImagePicker.getMediaLibraryPermissionsAsync();
        console.log(imagePermission.status);

        setGalleryPermission(imagePermission.status === 'granted');

        if (
            imagePermission.status !== 'granted' &&
            cameraPermission.status !== 'granted'
        ) {
            alert('Permission for media access needed.');
        }
    };
    useEffect(() => {
        permisionFunction();
    }, []);

    // This function is triggered when the "Select an image" button pressed
    const showImagePicker = async () => {
        /*
        const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (permissionResult.granted === false) {
            alert("You've refused to allow this appp to access your photos!");
            return;
        }
        */
       if (!galleryPermission) return;

        const result = await ImagePicker.launchImageLibraryAsync();

        // Explore the result
        console.log(result);

        if (!result.cancelled) {
            setPickedImagePath(result.uri);
            console.log(result.uri);
        }
    }

    // This function is triggered when the "Open camera" button pressed
    const openCamera = async () => {
        /*
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();

        if (permissionResult.granted === false) {
            alert("You've refused to allow this appp to access your camera!");
            return;
        }
        */
       if (!cameraPermission) return;

        const result = await ImagePicker.launchCameraAsync();

        // Explore the result
        console.log(result);

        if (!result.cancelled) {
            setPickedImagePath(result.uri);
            console.log(result.uri);
        }
    }

    return (
        <View
            style={props.style}>
            <View style={styles.buttonContainer}>
                <Button onPress={showImagePicker} title="Select an image" />
                <Button onPress={openCamera} title="Open camera" />
            </View>

            <View style={styles.imageContainer}>
                {
                    pickedImagePath !== '' && <Image
                        source={{ uri: pickedImagePath }}
                        style={styles.image}
                    />
                }
            </View>
        </View>
    );

}
const styles = StyleSheet.create({
    screen: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonContainer: {
      width: 400,
      flexDirection: 'row',
      justifyContent: 'space-around'
    },
    imageContainer: {
      padding: 30
    },
    image: {
      width: 400,
      height: 300,
      resizeMode: 'cover'
    }
  });
  export default BPhoto;