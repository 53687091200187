import React from "react";
import { View } from 'react-native';
import '../../node_modules/leaflet/dist/leaflet.css';
import L from 'leaflet';
import BPColor from "../graphics/BPColor";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default class BBMaps extends React.Component{
    // style, instructions []
    constructor(props) {
        super(props);

        this.myMap = null;
        this.markerLayer = null;

        this.usedIcons = {};
        this.usedIcons["1F51FF"] =  this.makeIconWithColor("1F51FF");

    }
    makeIconWithColor = (sColor) => {
        return L.icon({
            iconUrl: global.globalVars.getMakePinUrl({
                in: sColor,
                out: "7DF9FF",
                outl: "000000",
                inl: "888888",
                width: 32,
                height: 32,
            }),
            iconSize: [32, 32],
            iconAnchor: [16, 31],
            popupAnchor: [0, -31],
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
            shadowSize: [41, 41],
            shadowAnchor: [12, 38],
        });
    }
/*
            cmd: 'mapLocations',
            dLatSearchCenter: dLatSearchCenter,
            dLonSearchCenter: dLonSearchCenter,
            sLabelSearchCenter: sLabelSearchCenter,
            radarType: radarType,
            arMapItems: arMapItems, // TropareMapItem

            BPColor.BPColor.intToHexRGBOnlyColor(itm.cColor) to makepin
*/
    processInstruction = (inst) => {
        switch (inst.cmd) {
            case 'mapLocations':
                if (this.markerLayer !== null) {
                    this.myMap.removeLayer(this.markerLayer);
                    this.markerLayer = null;
                }
                let arMarkers = [];
                inst.arMapItems.map(itm => {
                    let sColor = BPColor.intToHexRGBOnlyColor(itm.getColor());
                    if (typeof this.usedIcons[sColor] === 'undefined') {
                        this.usedIcons[sColor] = this.makeIconWithColor(sColor);
                    }
                    arMarkers.push(
                    L.marker([itm.getLat(), itm.getLon()], {icon: this.usedIcons[sColor]}).bindPopup(itm.getDescription())
                )})
                this.markerLayer = L.layerGroup(arMarkers);
                this.myMap.addLayer(this.markerLayer);
                this.myMap.panTo(new L.LatLng(inst.arMapItems[0].getLat(), inst.arMapItems[0].getLon()));

                break;
        }
    }
    updateMapInstructions = () => {
        if (typeof this.props.instructions !== 'undefined' && this.props.instructions !== null) {
            if (Object.prototype.toString.call(this.props.instructions) === '[object Array]') {
                this.props.instructions.map(inst => (this.processInstruction(inst)));
            } else {
                this.processInstruction(this.props.instructions);
            }

        } else {
            //TODO: clear map.  markers.
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (! JSON.stringify(prevProps.instructions).equals(JSON.stringify(this.props.instructions))) {
            // redraw w/ new mapInstructions
            updateMapInstrutions();
        }
    }

    componentDidMount = () => {
        this.myMap = L.map('mapid', {
            center: [37.7749, -122.4194],
            zoom: 13
          });
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                  maxZoom: 19,
                  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }).addTo(this.myMap);  

          this.updateMapInstructions();
    }

    backToMain() {
        global.globalVars.appClass.setState({mainView: "main"});
    }
    render() {
        return (
            <View style={this.props.style}>
                <View style={{
                    width: "80%",
                    height: "80%",
                    }}>
                <div id='mapid' style={{
                    width: "100%",
                    height: "100%",
                    borderWidth: "1px",
                    borderColor: "#888",
                }}>
                </div>
                </View>
            </View>
        );
    }
}

BBMaps.MapLocation = (lat, lon, sLabel, dLatSearchCenter, dLonSearchCenter, sLabelSearchCenter, sImgUrl, cColor) => {
    // lat, lon against Number.MAX_VALUE, etc.
    return false;
}
BBMaps.Supported = () => {
    return true;
}
BBMaps.MapRoute = (aLat, aLon, sLabel, sDescription,
    aLat2, aLon2, sLabel2, sDescription2) => {
    return false;
}