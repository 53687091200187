export default class BNumberUtils {
    constructor() {

    }
}

BNumberUtils.parseInt = (s) => {
let iVal = 0;
try {
    iVal = parseInt(s);
    if (isNaN(iVal)) {
        iVal = Number.MAX_SAFE_INTEGER;
    }
} catch (ex) {
    iVal = Number.MAX_SAFE_INTEGER;
}
return iVal;
};
BNumberUtils.parseDouble = (s) => {
let iVal = 0;
try {
    iVal = parseFloat(s);
    if (isNaN(iVal)) {
        iVal = Number.MAX_VALUE;
    }
} catch (ex) {
    iVal = Number.MAX_VALUE;
}
return iVal;
};