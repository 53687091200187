import StringBuffer from "../graphics/StringBuffer";

export default class CCookie {
    constructor(args) {  
      this.name = null;         // String
      this.value = null;        // String
      this.expires = null;      // String
      this.extra = null;        // String
      // derived
      this.dtExpires = null;

      if ('name' in args) {
        this.name = args.name;
      }
      if ('value' in args) {
        this.value = args.value;
      }
      if ('expires' in args) {
        this.expires = args.expires
        try {
          this.dtExpires = new Date(args.expires);
        } catch (ex) {
        }
      }
      if ('extra' in args) {
        this.extra = args.extra;
      }
    }
    toCookieString() {
      let sb = new StringBuffer();
      sb.append(this.name);
      sb.append('=');
      sb.append(this.value === null ? '' : this.value);
      if (this.dtExpire !== null) {      // valid date?
          if (Object.prototype.toString.call(this.dtExpire) === '[object Date]') {
              sb.append('; Expires=');
              sb.append(this.dtExpire.toUTCString());
          }
      }
      if (this.extra !== null) {
          sb.append('; ');
          sb.append(this.extra);
      }
      return sb.toString();
  }
    // dtNow: Date
    compareTo(dtNow) {
      if (Object.prototype.toString.call(dtNow) === '[object Date]') {
        if (Object.prototype.toString.call(this.dtExpires) === '[object Date]') {
          return this.dtExpires.getTime() - dtNow.getTime();
        } else {
          if (this.expires !== null) {
            try {
              this.dtExpires = new Date(this.expires);
              return this.dtExpires.getTime() - dtNow.getTime();              
            } catch (ex) {
              return -1;
            }
          } else {
            return -1;
          }
        }
      } else {
        if (dtNow === null) {
          return this.expires !== null ? 1 : 0;
        } else {
          try {
            let dtNowDate = new Date(dtNow);
            if (Object.prototype.toString.call(this.dtExpires) === '[object Date]') {
              return this.dtExpires.getTime() - dtNowDate.getTime();
            } else {
              return -1;
            }
          } catch (ex) {
            return this.expires !== null ? 1 : 0;
          }
        }

      }

    }
  }
  CCookie.serialVersionUID = 0;
  