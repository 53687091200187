import AsyncFullSizeImageCache from "../view/AsyncFullSizeImageCache";
import ImageLoaderWorker from "../ImageLoaderWorker";
import BBUtils from '../view/BBUtils';
import DecorNode from "../graphics/DecorNode";
import CCookieRepository from "../view/CCookieRepository";
import BLocation from './BLocation';
import BEditInfo from './BEditInfo';
import ToolbarManager from "../view/ToolbarManager";

// e.g. flavorSyndicator = "troparesheets.boopsie"
export default class GlobalVars {
    constructor(ctx, flavorSyndicator) {
        this.ctx = ctx;

        this.protocol = "https:"
        
        this.m_ubman = {};      // key: Url   value: localUrl
        this.asyncFullSizeImageCache = new AsyncFullSizeImageCache(this.ctx);
        this.imageLoader = new ImageLoaderWorker();
        CCookieRepository.sharedRepository().reload();

        this.setFlavorSyndicator(flavorSyndicator);

        this.appClass = null;
        this.canvasRef = null;
        this._font = {
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            fontSize: GlobalVars.DEFAULT_FONT_SIZE,       // this is in px (device indepednent units like dp on Android) work off base of 14
        }
        this._toolbarButtonWidth = GlobalVars.DEFAULT_TOOLBAR_BUTTON_SIZE;
        this._toolbarButtonHeight = GlobalVars.DEFAULT_TOOLBAR_BUTTON_SIZE;
        this._tabBarHeight = ToolbarManager.DEFAULT_TABBAR_HEIGHT;
        this._menuSize = GlobalVars.DEFAULT_MENU_SIZE;
        this._inputHeight = ToolbarManager.DEFAULT_TABBAR_HEIGHT * .8;

        this.cMenu = null;

        this.m_bLocation = new BLocation();

        this._editContext = null;
    }
    getFontSize = () => {
        return this._font.fontSize;
    }
    resetScale = () => {
        this._toolbarButtonHeight = GlobalVars.DEFAULT_TOOLBAR_BUTTON_SIZE;
        this._toolbarButtonWidth = GlobalVars.DEFAULT_TOOLBAR_BUTTON_SIZE;
        this._font.fontSize = GlobalVars.DEFAULT_FONT_SIZE;
        this._tabBarHeight = ToolbarManager.DEFAULT_TABBAR_HEIGHT;
        this._menuSize = GlobalVars.DEFAULT_MENU_SIZE;
        this._inputHeight = ToolbarManager.DEFAULT_TABBAR_HEIGHT * .8;
    }
    scaleUI = (perc) => {
        this._toolbarButtonHeight *= perc;
        this._toolbarButtonWidth *= perc;
        this._font.fontSize *= perc;
        this._tabBarHeight *= perc;
        this._menuSize *= perc;
        this._inputHeight *= perc;
    }
    getInputHeight() {
        return this._inputHeight;
    }
    getTabBarHeight() {
        return this._tabBarHeight;
    }
    getMenuSize() {
        return this._menuSize;
    }

    newEdit() {
        this._editContext = null;
        this._editContext = new BEditInfo();
    }

    getEditInfo() {
        return this._editContext;
    }

    selectMenuItem = (ix) => {
        this.cMenu.selectMenuItem(ix);
    }
    UseBBMaps() {
        return true;
    }
    setCMenu = (cm) => {
        this.cMenu = cm;
      }  
    CMenu() {
        return this.cMenu;
    }
    getFont() {
        return this._font;
    }
    getToolbarButtonWidth() {
        return this._toolbarButtonWidth;
    }
    getToolbarButtonHeight() {
        return this._toolbarButtonHeight;
    }

    setFlavorSyndicator(flavorSyndicator) {
        this.flavorSyndicator = flavorSyndicator;
        var fs = flavorSyndicator.split('.');
        this.flavor = fs[0];
        this.syndicator = fs[1];

    

        let socketServer = this.flavorSyndicator + ".tredir.com";

        this.hostHeader = socketServer;
        this.murl_baseServer = this.protocol + "//" + socketServer;
        this.m_httpServerUrl = this.murl_baseServer + ":443";
        this.murl_homeUrl = this.murl_baseServer + "/i/Home/";    // Home/";
        this.murl_syndicatorDomain = this.syndicator + ".tredir.com";
        this.murl_syndicatorService = this.murl_baseServer + "/service/";
        this.murl_register = this.murl_syndicatorService + "register/";
        this.murl_serviceConvert = this.murl_syndicatorService + "convert/";
        this.murl_serviceBatch = this.murl_syndicatorService + "convert/batch.pl"
        // https://....tredir.com/service/makepin/?in=00ff00&out=dddddd&outl=ff0000&inl=0000ff
        this.murl_serviceMakePinFormat = this.murl_syndicatorService + "makepin/?in={0}&out={1}&outl={2}&inl={3}";
//        this.remoteImagePath = this.protocol + "//img.prospectsforme.com/" + this.flavorSyndicator + "/";
//        this.murl_savePhoto = this.murl_baseServer + '/host/troparesheets/edit/savephoto.pl';
//        this.murl_saveNotes = this.murl_baseServer + '/host/troparesheets/edit/notesettings.pl';
        this.remoteImagePath = this.protocol + "//img." + this.flavorSyndicator + ".tredir.com/appimages/";
        this.murl_savePhoto = this.murl_baseServer + '/host/'+this.flavor+'/edit/savephoto.pl';
        this.murl_saveNotes = this.murl_baseServer + '/host/'+this.flavor+'/edit/notesettings.pl';
        this._lat = 0.0;
        this._lon = 0.0;

        this.m_chevronUrl = this.remoteImagePath + "chevron_ios.png";
        this.m_blueChevronUrl = this.remoteImagePath + "idisclosure.png";
        this.m_bulletWidth = 24;
        this.m_bulletHeight = 24;



    }

    setupFlavorSyndicatorUrls(sProtocol, pflavor) {
        let flavor = null;
        if (pflavor === null || pflavor.length === 0) {
          if (this.flavorSyndicator === null || this.flavorSyndicator.length === 0) {
            flavor = 'live.boopsie';
          } else {
            flavor = this.flavorSyndicator;
          }
        } else {
          if (pflavor.indexOf('.') === -1) {
            flavor = pflavor + '.boopsie';
          } else {
            flavor = pflavor;
          }
        }

        if (sProtocol.length > 0) {
            this.protocol = sProtocol
        } else {
            if (this.protocol.length === 0) {
                this.protocol = "https:";
            }
        }

        this.setFlavorSyndicator(flavor);

      }

    getRegisterUrl() {
        return this.murl_register;
    }
    getPostNotesUrl() {
        return this.murl_saveNotes;
    }
    getSavePhotoUrl() {
        return this.murl_savePhoto;
    }
    // Called when render of main interface completes
    RenderDone() {
    }

    // rrggbb: inside color, outside color, outside line, inside line
    getMakePinUrl(ps) {
        let arColors = ["0000FF", "0000DD", "000000", "888888"];
        let s = this.murl_serviceMakePinFormat;

        if (ps.hasOwnProperty('in')) {
            arColors[0] = ps.in;
        }
        if (ps.hasOwnProperty('out')) {
            arColors[1] = ps.out;
        }
        if (ps.hasOwnProperty('outl')) {
            arColors[2] = ps.outl;
        }
        if (ps.hasOwnProperty('inl')) {
            arColors[3] = ps.inl;
        }
        if (ps.hasOwnProperty("width")) {
            s = s + "&width=" + ps.width;
        }
        if (ps.hasOwnProperty("height")) {
            s = s + "&height=" + ps.height;
        }

        let ix=4;
        while (ix--) {
            s = s.replace(new RegExp('\\{' + ix + '\\}', 'gm'), arColors[ix]);
        }
        return s;
    }
    getBlocation() {
        return this.m_bLocation;
    }

    getNavMenu() {
        return this.getRemoteImagePath() + "white_nav_menu.png"
    }
    getChevronHeight() {
        return this.m_bulletHeight;
    }
    getChevronWidth() {
        return this.m_bulletWidth;
    }
    getChevronUrl() {
        return this.m_chevronUrl;
    }
    getBlueChevronUrl() {
        return this.m_blueChevronUrl;
    }
    getCacheableUrl(sUrl, wid, ht) {
        return this.murl_serviceConvert + encodeURI('-geometry 100%/' + sUrl);
    }
    getConvertUrl(sUrl, wid, ht) {
        var sCvtPerc = sUrl.replaceAll('%', '%25');
        return this.murl_serviceConvert + '-geometry%20' + wid.toString() + 'x' + ht.toString() + "/" + sCvtPerc;
    }
    HideImages() {
        return false;   // TODO
    }
    getLat() {
        return this._lat;
    }
    setLatLon(lat, lon) {
        this._lat = lat;
        this._lon = lon;
    }
    getLon() {
        return this._lon;
    }
    getLatLon() {
        return this._lat.toFixed(6) + ',' + this._lon.toFixed(6);
    }
    getFormattedLatLon() {
        return this._lat.toFixed(6) + ';' + this._lon.toFixed(6);
    }
    getBPIN() {
        return this.flavorSyndicator;
    }
    setbPIN(s) {
        this.flavorSyndicator = s;
    }

    getHostHeader() {
        return this.hostHeader;
    }
    getUAOS() {
        // window.navigator.userAgent
//        return "iPhone/Java - Version (14.1); Carrier (none); Boopsie - Version(" + + ")";
        return "Web/Java - Version (14.1); Carrier (none); Boopsie - Version(" + GlobalVars.VERSION_RELEASE + ")";
    }
    getHome() {
        return this.murl_homeUrl;
    }
    getServerUrl() {
        return this.m_httpServerUrl;
    }
    getBaseServer() {
        return this.murl_baseServer;
    }
    refreshViewType(iType) {
        switch (iType) {
            case DecorNode.DECORNODE_TYPE_TABLE:    // Screen
                this.appClass.refreshScreen();
                break;
            case DecorNode.DECORNODE_TYPE_SEPARATOR:
                this.appClass.refreshSeparator();
                break;                
            case DecorNode.DECORNODE_TYPE_CELL:
                this.appClass.refreshCells();
                break;
            case DecorNode.DECORNODE_TYPE_NAVBAR:
                this.appClass.refreshHField();
                break;
            case DecorNode.DECORNODE_TYPE_TOOLBAR:
                this.appClass.refreshToolbar();
                break;
            case DecorNode.DECORNODE_TYPE_RIGHTBUTTON:
                this.appClass.refreshRightButton();
                break;
            case DecorNode.DECORNODE_TYPE_LEFTBUTTON:
                this.appClass.refreshLeftButton();
                break;
            default:
                break;
        }
    }
    setScreenRef(ref) {
        this.appClass = ref;
    }
    setCanvasRef(ref) {
        this.canvasRef = ref;
    }
    getCanvas() {
        return this.canvasRef.current;
    }
    getImageLoader() {
        return this.imageLoader;
    }
    getRemoteImagePath() {
        return this.remoteImagePath;
    }
    getGlobalImageCache() {
        return this.asyncFullSizeImageCache;
    }
    getBatchService() {
        return this.murl_serviceBatch;
    }
    isLocalResourceUrl(surl) {
        if (surl.startsWith(BBUtils.C_RESOURCE_PROTOCOL)) {
            return surl;
        } else if (this.m_ubman[surl]) {
            return this.m_ubman[surl];
        }

        return null;
    }

}
GlobalVars.VERSION_RELEASE = "2.0.1";
GlobalVars.DEFAULT_FONT_SIZE = 14;
GlobalVars.DEFAULT_TOOLBAR_BUTTON_SIZE = 30;
GlobalVars.DEFAULT_MENU_SIZE = 32;
