
import ImageRow from "./ImageRow";
import XYRect from "./XYRect";

export default class HScrollRow extends ImageRow {
    constructor(sUrl, r, minHeight, vAlign, hAlign, itemNo) {
        super(sUrl, r, minHeight, vAlign, hAlign, itemNo);
        this.mState = '';
        this._sv = null;
        this.heightSet = false;
    }
    update(sUrl, newRect, minHeight, vAlign, hAlign) {
        this._imgUrl = sUrl;
        this._rect = new XYRect(newRect);
        this._minHeight = minHeight;
        this._valign = vAlign;
        this.halign = hAlign;
    }
    setHScrollView(sv) {
        this._sv = sv;
    }
    getHScrollView() {
        return this._sv;
    }
}
