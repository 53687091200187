import React from "react";
import { View } from 'react-native';

class BIFrame extends React.Component {
    constructor(props) {
        super(props)

        this.vContainerRef = React.createRef();
    }

    cloneAppend() {
        if (this.vContainerRef.current !== null) {
            if (this.vContainerRef.current.childElementCount == 0) {
                this.vContainerRef.current.appendChild(this.props.htmlrow.clone);
            } else {
                if (this.props.innerRef.current !== null) {
                    if (this.props.htmlrow.clone === null) {
                        this.props.htmlrow.clone = this.props.innerRef.current.cloneNode();
                    }
                }
            }
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.cloneAppend();
    }
    componentDidMount() {
        this.cloneAppend();
    }
    onLoad() {
    }
    render() {
        let myId = 'biframe_' + this.props.iframeid;
        return (
            <View
                ref={this.vContainerRef}
                style={this.props.style}>
                {this.props.innerRef.current == null && this.props.htmlrow.clone == null &&
                    <iframe
                        ref={this.props.innerRef}
                        id={myId} style={{
                            width: "100%",
                            height: "100%",
                            borderWidth: "0",
                            borderColor: "#888",
                        }}
                        scrolling="no"
                        src={this.props.url}
                        onLoad={this.onLoad}
                    >
                    </iframe>
                }
            </View>
        );
    }
}
export default React.forwardRef((props, ref) => <BIFrame
    innerRef={ref} {...props}
/>);
