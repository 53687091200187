import React from "react";
import { Text, TouchableOpacity } from 'react-native';
import DecorNode from "../graphics/DecorNode";

// .value, .color
export default class CBackText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            disabled: false,
            myFontSettings: null,
            refresh: props.refresh,
        }

    }
    static getDerivedStateFromProps(props, state) {
        if (props.refresh != state.refresh) {
            let fSettings = DecorNode.getFontSettings(null, {
                fontSize: 1,   // 12pt
                fontWeight: 300,
            });

            return {
                refresh: props.refresh,
                myFontSettings: fSettings
            }

        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }
    componentDidMount() {
    }
    componentWillUnmount() {
    }
    setText(s) {
        this.setState({message: s});
    }
    backPressed = () => {
        global.globalVars.appClass.respondToBack();
    }
    setDisabled = (tf) => {
        this.setState( {disabled: tf});
    }

    render() {
        if (this.state.visible) {

            return (
                <TouchableOpacity
                    style={this.props.style}
                    onPress={this.backPressed}
                    disabled={this.state.disabled}
                >
                    <Text style={
                        {
                            color: this.props.color,
                            ...this.state.myFontSettings
                        }
                    }
                    >
                        {this.props.value}
                    </Text>
                </TouchableOpacity>
            )
        } else {
            return null;
        }
    }
}
