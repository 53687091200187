import BPColor from "./BPColor";
import DecorNode from "./DecorNode";
import BStatus from './BStatus';
import BLinearGradient from "./BLinearGradient";
import BBUtils from '../view/BBUtils';
import BImageSpec from "./BImageSpec";
export default class BGraphics {
  constructor() {}
}
// g.globalAlpha compounds with fillStyle. So, fillStyle('#009933ff') and globalAlpha=.5 is same as fillStyle('#009933') globalAlpha=.5
// So, if you have  fillStyle('#00993388') globalAlpha(.5), the 88 AA values are compounded with .5 to effectively yield 44 as alpha
// new Image()
// ImageData.data is a 1 dim array of RGBA (8bits each)
BGraphics.tsf_kNone = 0;
BGraphics.tsf_kFill = 1;
BGraphics.tsf_kTile = 2;
BGraphics.tsf_kScale = 3;
BGraphics.tsf_kCover = 4;
BGraphics.tintColor = (iColor, iTint) => {
  let ia = (((iColor >> 24) & 0x0f) * ((iTint >> 24) & 0x0f)) / 255;
  let ir = (((iColor >> 16) & 0x0f) * ((iTint >> 16) & 0x0f)) / 255;
  let ig = (((iColor >> 8) & 0x0f) * ((iTint >> 8) & 0x0f)) / 255;
  let ib = (((iColor) & 0x0f) * ((iTint) & 0x0f)) / 255;
  return ((ia << 24) | (ir << 16) | (ig << 8) | ib) >>> 0;
};
BGraphics.gradientFromSingleColor = (iv) => {
  let blow = new BPColor(iv);
  let bhigh = new BPColor(0, 0, 0);
  if (blow._s === 0 && blow._h === 0) {
    let vlow = blow._v - 10;
    let vhigh = blow._v + 10;
    if (vlow < 0) {
      vlow = 0;
    }
    if (vhigh > 100) {
      vhigh = 100;
    }
    blow.setHSV(0, 0, vhigh);
    bhigh.setHSV(0, 0, vlow);
  } else {
    let slow = blow._s - 10;
    if (slow < 0) {
      slow = 0;
    }
    let shigh = blow._s + 10;
    if (shigh > 100) {
      shigh = 100;
    }
    bhigh.setHSV(blow._h, shigh, blow._v);
    blow.setHSV(blow._h, slow, blow._v);
  }
  let ivRet = new Array(2);
  ivRet[0] = blow.getColor();
  ivRet[1] = bhigh.getColor();
  return ivRet;
};

BGraphics.convertByte255ToReal = (bval) => {
  return parseFloat(bval).toFixed(2) / 255.0;
}

BGraphics.backgroundGradientBase = (sSel, g, pnt, decorNode, iv, xpos, ypos, width, height) => {
  if (decorNode === null) {
    return false;
  }
  let bg = decorNode.hasGradient(sSel);
  if (bg !== null) {
    let alphaSave = g.globalAlpha;

    g.globalAlpha = BGraphics.convertByte255ToReal(bg.getAlpha());
    BGraphics.fillRectGradient(g, pnt, bg, iv, xpos, ypos, width, height);

    g.globalAlpha = alphaSave;
    return true;
  }
  return false;
};
BGraphics.backgroundGradient = (g, pnt, decorNode, iv, xpos, ypos, width, height) => {
  return BGraphics.backgroundGradientBase(DecorNode.C_lowercase_bg, g, pnt, decorNode, iv, xpos, ypos, width, height);
};
BGraphics.selBackgroundGradient = (g, pnt, decorNode, iv, xpos, ypos, width, height) => {
  return BGraphics.backgroundGradientBase(DecorNode.C_lowercase_sbg, g, pnt, decorNode, iv, xpos, ypos, width, height);
};
BGraphics.drawShapes = (appContext, g, pnt, sTag, dn, x, y, width, height, bInsetOnly) => {
  if (dn === null || width <= 0 || height <= 0) {
    return undefined;
  }
  let shapes = dn.getShapesForTag(sTag);
  if (shapes !== null) {
    for (let i = 0; i < shapes.size(); i++) {
      let bs = shapes.elementAt(i);
      if (!(bInsetOnly && !bs.getInset())) {
        bs.draw(appContext, g, pnt, dn, x, y, width, height);
      }
    }
  }
};
BGraphics.fillRectGradient = (g, mPaint, bg, iv, xpos, ypos, width, height) => {
  if (width <= 0 || height <= 0) {
    return undefined;
  }
  let gColors = bg.getColors();
  let stops = bg.getStops();
  if (gColors === null) {
    let ivFromTo = BGraphics.gradientFromSingleColor(iv);
    gColors = new Array(2);
    gColors[0] = ivFromTo[0];
    gColors[1] = ivFromTo[1];
    stops = new Array(2);
    stops[0] = 0;
    stops[1] = 100;
  }
  if ((gColors.length !== stops.length) || gColors.length === 0) {
    return undefined;
  }
  let alen = gColors.length;
  if (alen === 1) {
    let alphaSave = g.globalAlpha;

    g.globalAlpha = BGraphics.convertByte255ToReal(bg.getAlpha());
    g.fillStyle = BPColor.intToHexRGB(gColors[0]);

    g.fillRect(xpos, ypos, width, height);

    g.globalAlpha = alphaSave;

  } else {
    let alpha = bg.getAlpha();
    let ptcolors = new Array(alen);
    let fstops = new Array(alen);
    for (let i = 0; i < alen; i++) {
      fstops[i] = (stops[i]) / 100.0;
      ptcolors[i] = ((alpha << 24) | (gColors[i] & 0xffffff)) >>> 0;
    }
    let mGradient = null;
    switch (bg.getType()) {
      case BLinearGradient.GRADIENT_TB:
        mGradient = g.createLinearGradient(xpos, ypos, xpos, ypos + height);
        break;
      case BLinearGradient.GRADIENT_LR:
        mGradient = g.createLinearGradient(xpos, ypos, xpos + width, ypos);
        break;
      case BLinearGradient.GRADIENT_RL:
        mGradient = g.createLinearGradient(xpos + width, ypos, xpos, ypos);
        break;
      case BLinearGradient.GRADIENT_BT:
      case undefined:
        mGradient = g.createLinearGradient(xpos, ypos + height, xpos, ypos);
        break;
    }
    for (let i=0; i<alen; i++) {
      mGradient.addColorStop(fstops[i], BPColor.intToHexRGBA(ptcolors[i]));
    }

    g.fillStyle = mGradient;
    g.fillRect(xpos, ypos, width, height);
  }
};
BGraphics.ellipse = (g, x, y, w,  h) => {
  var kappa = .5522848,
      ox = (w / 2) * kappa, // control point offset horizontal
      oy = (h / 2) * kappa, // control point offset vertical
      xe = x + w,           // x-end
      ye = y + h,           // y-end
      xm = x + w / 2,       // x-middle
      ym = y + h / 2;       // y-middle

  g.beginPath();
  g.moveTo(x, ym);
  g.bezierCurveTo(x, ym - oy, xm - ox, y, xm, y);
  g.bezierCurveTo(xm + ox, y, xe, ym - oy, xe, ym);
  g.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
  g.bezierCurveTo(xm - ox, ye, x, ym + oy, x, ym);
}
BGraphics.fillEllipse = (g, x, y, w, h) => {
  BGraphics.ellipse(g, x, y, w, h);
  g.fill();
};

BGraphics.fillEllipseGradient = (g, mPaint, bg, iv, xpos, ypos, width, height) => {
  if (width <= 0 || height <= 0) {
    return undefined;
  }
  let gColors = bg.getColors();
  let stops = bg.getStops();
  if (gColors === null) {
    let ivFromTo = BGraphics.gradientFromSingleColor(iv);
    gColors = new Array(2);
    gColors[0] = ivFromTo[0];
    gColors[1] = ivFromTo[1];
    stops = new Array(2);
    stops[0] = 0;
    stops[1] = 100;
  }
  if ((gColors.length !== stops.length) || gColors.length === 0) {
    return undefined;
  }
  let alen = gColors.length;
  if (alen === 1) {
    let alphaSave = g.globalAlpha;

    g.globalAlpha = BGraphics.convertByte255ToReal(bg.getAlpha());
    g.fillStyle = BPColor.intToHexRGB(gColors[0]);

    BGraphics.fillEllipse(xpos, ypos, width, height);

    g.globalAlpha = alphaSave;
  } else {
    let alpha = bp.getAlpha();
    let ptcolors = new Array(alen);
    let fstops = new Array(alen);
    for (let i = 0; i < alen; i++) {
      fstops[i] = (stops[i]) / 100.0;
      ptcolors[i] = ((alpha << 24) | (gColors[i] & 0xffffff)) >>> 0;
    }
    let mGradient = null;
    switch (bg.getType()) {
      case BLinearGradient.GRADIENT_TB:
        mGradient = g.createLinearGradient(xpos, ypos, xpos, ypos + height);
        break;
      case BLinearGradient.GRADIENT_LR:
        mGradient = g.createLinearGradient(xpos, ypos, xpos + width, ypos);
        break;
      case BLinearGradient.GRADIENT_RL:
        mGradient = g.createLinearGradient(xpos + width, ypos, xpos, ypos);
        break;
      case BLinearGradient.GRADIENT_BT:
      case undefined:
        mGradient = new g.createLinearGradient(xpos, ypos + height, xpos, ypos);
        break;
    }
    for (let i=0; i<alen; i++) {
      mGradient.addColorStop(fstops[i], BPColor.intToHexRGBA(ptcolors[i]));
    }

    g.fillStyl = mGradient;
    BGraphics.fillEllipse(xpos, ypos, width, height);
  }
};
BGraphics.fillPolygonGradient = (g, mPaint, bg, iv, xptsIn, yptsIn, xpos, ypos, width, height) => {
  if (xptsIn.length !== yptsIn.length || width <= 0 || height <= 0) {
    return undefined;
  }
  let alen = xptsIn.length;
  
  let gColors = bg.getColors();
  let stops = bg.getStops();
  if (gColors === null) {
    let ivFromTo = BGraphics.gradientFromSingleColor(iv);
    gColors = new Array(2);
    gColors[0] = ivFromTo[0];
    gColors[1] = ivFromTo[1];
    stops = new Array(2);
    stops[0] = 0;
    stops[1] = 100;
  }
  if ((gColors.length !== stops.length) || gColors.length === 0) {
    return undefined;
  }
  alen = gColors.length;
  if (alen === 1) {
    let alphaSave = g.globalAlpha;

    g.globalAlpha = BGraphics.convertByte255ToReal(bg.getAlpha());
    g.fillStyle = BPColor.intToHexRGB(gColors[0]);

    g.beginPath();
    g.moveTo(xptsIn[0] + xpos, yptsIn[0] + ypos);
    for (let i = 1; i < xptsIn.length; i++) {
      g.lineTo(xptsIn[i] + xpos, yptsIn[i] + ypos);
    }
    g.closePath();
    g.fill();

    g.globalAlpha = alphaSave;

  } else {
    let alpha = bg.getAlpha();

    let ptcolors = new Array(alen);
    let fstops = new Array(alen);
    for (let i = 0; i < alen; i++) {
      fstops[i] = (stops[i]) / 100.0;
      ptcolors[i] = ((alpha << 24) | (gColors[i] & 0xffffff)) >>> 0;
    }
    let mGradient = null;
    switch (bg.getType()) {
      case BLinearGradient.GRADIENT_TB:
        mGradient = g.createLinearGradient(xpos, ypos, xpos, ypos + height);
        break;
      case BLinearGradient.GRADIENT_LR:
        mGradient = g.createLinearGradient(xpos, ypos, xpos + width, ypos);
        break;
      case BLinearGradient.GRADIENT_RL:
        mGradient = g.createLinearGradient(xpos + width, ypos, xpos, ypos);
        break;
      case BLinearGradient.GRADIENT_BT:
      case undefined:
        mGradient = new g.createLinearGradient(xpos, ypos + height, xpos, ypos);
        break;
    }
    for (let i=0; i<alen; i++) {
      mGradient.addColorStop(fstops[i], BPColor.intToHexRGBA(ptcolors[i]));
    }

    let rgbOld = g.fillStyle;

    g.fillStyle = mGradient;

    g.beginPath();
    g.moveTo(xptsIn[0] + xpos, yptsIn[0] + ypos);
    for (let i = 1; i < xptsIn.length; i++) {
      g.lineTo(xptsIn[i] + xpos, yptsIn[i] + ypos);
    }
    g.closePath();
    g.fill();

    g.fillStyle = rgbOld;
  }
};

BGraphics.roundRect = (g, x, y, width, height, cx, cy, fill, stroke) => {
  if (typeof stroke == "undefined") {
      stroke = true;
  }
  if (typeof radius === "object") {
      for (var side in radius) {
          cornerRadius[side] = radius[side];
      }
  }

  g.beginPath();
  g.moveTo(x + cx, y);
  g.lineTo(x + width - cx, y);
  g.quadraticCurveTo(x + width, y, x + width, y + cy);
  g.lineTo(x + width, y + height - cy);
  g.quadraticCurveTo(x + width, y + height, x + width - cx, y + height);
  g.lineTo(x + cx, y + height);
  g.quadraticCurveTo(x, y + height, x, y + height - cy);
  g.lineTo(x, y + cy);
  g.quadraticCurveTo(x, y, x + cx, y);
  g.closePath();
  if (stroke) {
      g.stroke();
  }
  if (fill) {
      g.fill();
  }
} 
BGraphics.fillRoundRectGradient = (g, mPaint, bg, iv, xpos, ypos, width, height, cx, cy) => {
  if (width <= 0 || height <= 0) {
    return undefined;
  }
  let gColors = bg.getColors();
  let stops = bg.getStops();
  if (gColors === null) {
    let ivFromTo = BGraphics.gradientFromSingleColor(iv);
    gColors = new Array(2);
    gColors[0] = ivFromTo[0];
    gColors[1] = ivFromTo[1];
    stops = new Array(2);
    stops[0] = 0;
    stops[1] = 100;
  }
  if ((gColors.length !== stops.length) || gColors.length === 0) {
    return undefined;
  }
  let alen = gColors.length;
  if (alen === 1) {    
    g.save();
    BGraphics.roundRect(g, xpos, ypos, width, height, cx, cy, false, false);
    g.clip();
  
    g.globalAlpha = BGraphics.convertByte255ToReal(bg.getAlpha());
    g.fillStyle = BPColor.intToHexRGB(gColors[0]);

    g.fillRect(xpos, ypos, width, height);
    g.restore();

  } else {
    let alpha = bg.getAlpha();
    let ptcolors = new Array(alen);
    let fstops = new Array(alen);
    for (let i = 0; i < alen; i++) {
      fstops[i] = (stops[i]) / 100.0;
      ptcolors[i] = ((alpha << 24) | (gColors[i] & 0xffffff)) >>> 0;
    }
    let mGradient = null;
    switch (bg.getType()) {
      case BLinearGradient.GRADIENT_TB:
        mGradient = g.createLinearGradient(xpos, ypos, xpos, ypos + height);
        break;
      case BLinearGradient.GRADIENT_LR:
        mGradient = g.createLinearGradient(xpos, ypos, xpos + width, ypos);
        break;
      case BLinearGradient.GRADIENT_RL:
        mGradient = g.createLinearGradient(xpos + width, ypos, xpos, ypos);
        break;
      case BLinearGradient.GRADIENT_BT:
      case undefined:
        mGradient = new g.createLinearGradient(xpos, ypos + height, xpos, ypos);
        break;
    }
    for (let i=0; i<alen; i++) {
      mGradient.addColorStop(fstops[i], BPColor.intToHexRGBA(ptcolors[i]));
    }

    g.save();

    BGraphics.roundRect(g, xpos, ypos, width, height, cx, cy, false, false);
    g.clip();

    g.globalAlpha = BGraphics.convertByte255ToReal(bg.getAlpha());
    g.fillStyle = mGradient;

    g.fillRect(xpos, ypos, width, height);
    g.restore();
  }
};
BGraphics.drawRoundRect = (g, mPaint, xpos, ypos, width, height, cx, cy) => {
  if (width <= 0 || height <= 0) {
    return undefined;
  }
  BGraphics.roundRect(g, xpos, ypos, width, height, cx, cy, false, true);
};
BGraphics.fillEllipseImage = (g, mPaint, img, alpha, bTile, xpos, ypos, width, height) => {
  if (width <= 0 || height <= 0 || img === null) {
    return undefined;
  }
  g.save();

  BGraphics.ellipse(g, xpos, ypos, width, height);
  g.clip();

  g.globalAlpha = BGraphics.convertByte255ToReal(alpha);

  if (bTile) {
    let imgHeight = img.height;
    let imgWidth = img.width;
    let xend = xpos + width;
    let yend = ypos + height;
    for (let y = ypos; y < yend; y += imgHeight) {
      for (let x = xpos; x < xend; x += imgWidth) {
        g.drawImage(img.image, x, y);
      }
    }
  } else {
    g.drawImage(img.image, 0, 0, img.width, img.height, xpos, ypos, width, height);
  }

  g.restore();
};
BGraphics.fillRoundRectImage = (g, mPaint, img, alpha, pnBG, xpos, ypos, width, height, cx, cy) => {
  if (width <= 0 || height <= 0 || img === null) {
    return undefined;
  }
  let tsf = BGraphics.tsf_kNone;
  if (DecorNode.findChildNode$2(pnBG, DecorNode.C_lowercase_tile) !== null) {
    tsf = BGraphics.tsf_kTile;
  } else if (DecorNode.findChildNode$2(pnBG, DecorNode.C_lowercase_scale) !== null) {
    tsf = BGraphics.tsf_kScale;
  } else if (DecorNode.findChildNode$2(pnBG, DecorNode.C_lowercase_cover) !== null) {
    tsf = BGraphics.tsf_kCover;
  }

  g.save();
  BGraphics.roundRect(g, xpos, ypos, width, height, cx, cy, false, false);
  g.clip();

  g.globalAlpha = BGraphics.convertByte255ToReal(alpha);

  let pnFill = DecorNode.findChildNode$2(pnBG, DecorNode.C_lowercase_fill);
  if (pnFill !== null) {
    let pnChild = DecorNode.findChildNode$2(pnFill, DecorNode.C_lowercase_c);
    if (pnChild !== null) {
      let status = new BStatus(true);
      let iv = DecorNode.parseColorNode(pnChild, status);
      if (status.getStatus()) {
        g.fillStyle = BPColor.intToHexRGB(iv);
        g.fillRect(xpos, ypos, width, height);
      }
    }
  }
  if (tsf === BGraphics.tsf_kTile) {
    let imgHeight = img.height;
    let imgWidth = img.width;
    let xend = xpos + width;
    let yend = ypos + height;
    for (let y = ypos; y < yend; y += imgHeight) {
      for (let x = xpos; x < xend; x += imgWidth) {
        g.drawImage(img.image, x, y);
      }
    }
  } else if (tsf === BGraphics.tsf_kNone || tsf === BGraphics.tsf_kFill) {
    g.drawImage(img.image, 0, 0, img.width, img.height, xpos, ypos, width, height);
  } else if (tsf == BGraphics.tsf_kCover) {
    let imgHeight = img.height;
    let imgWidth = img.width;
    let scalew = 1.0,
      scaleh = 1.0,
      scale = 1;
      scalew = width / imgWidth;
      scaleh = height / imgHeight;
      if (scalew >= scaleh) {
        scale = scalew;
      } else {
        scale = scaleh;
      }
      let newDestWidth = imgWidth * scale;
      let newDestHeight = imgHeight * scale;
      let xoff = 0,
        yoff = 0,
        sx = 0,
        sy = 0,
        sheight = imgHeight,
        swidth = imgWidth;
      if (newDestWidth > width) {
        xoff = (newDestWidth - width) / 2;
        sx = xoff == 0 ? 0 : xoff /scale;
        swidth = width / scale;
      }
      if (newDestHeight > height) {
        yoff = (newDestHeight - height) / 2;
        sy = yoff == 0 ? 0 : yoff /scale;
        sheight = height /scale;
      }

      g.drawImage(img.image, sx, sy, swidth, sheight, xpos, ypos, width, height);

  } else if (tsf === BGraphics.tsf_kScale) {
    let imgHeight = img.height;
    let imgWidth = img.width;
    if (imgWidth < width && imgHeight < height) {
      if (imgWidth < width) {
        xpos = xpos + (width - imgWidth) / 2;
        width = imgWidth;
      }
      if (imgHeight < height) {
        ypos = ypos + (height - imgHeight) / 2;
        height = imgHeight;
      }
      g.drawImage(img.image, 0, 0, img.width, img.height, xpos, ypos, width, height);
    } else if (imgWidth / imgHeight < width / height) {
      let scale = height / imgHeight;
      let dWidth = scale * imgWidth;
      xpos = xpos + ((width - dWidth) / 2);
      width = dWidth;
      g.drawImage(img.image, 0, 0, img.width, img.height, xpos, ypos, width, height);
    } else {
      let scale = width / imgWidth;
      let dHeight = scale * imgHeight;
      ypos = ypos + ((height - dHeight) / 2);
      height = dHeight;
      g.drawImage(img.image, 0, 0, img.width, img.height, xpos, ypos, width, height);
    }
  }

  g.restore();
  return undefined;
};
BGraphics.fillPolygonWithImage = (g, mPaint, img, alpha, bTile, xptsIn, yptsIn, xpos, ypos, width, height) => {
  if (width <= 0 || height <= 0 || img === null || (xptsIn.length !== yptsIn.length)) {
    return undefined;
  }
  g.save();

  let alen = xptsIn.length;
  g.beginPath();
  g.moveTo(xptsIn[0] + xpos, yptsIn[0] + ypos);
  for (let i = 1; i < alen; i++) {
    g.lineTo(xptsIn[i] + xpos, yptsIn[i] + ypos);
  }
  g.closePath();
  g.clip();

  g.globalAlpha = BGraphics.convertByte255ToReal(alpha);

  if (bTile) {
    let imgHeight = img.height;
    let imgWidth = img.width;
    let xend = xpos + width;
    let yend = ypos + height;
    for (let y = ypos; y < yend; y += imgHeight) {
      for (let x = xpos; x < xend; x += imgWidth) {
        g.drawImage(img.image, x, y);
      }
    }
  } else {
    g.drawImage(img.image, 0, 0, img.width, img.height, xpos, ypos, width, height);
  }

  g.restore();
};
BGraphics.fillRectWithImage = (g, mPaint, img, alpha, pnBG, xpos, ypos, width, height) => {
  let bRet = false;
  let tsf = BGraphics.tsf_kNone;
  if (DecorNode.findChildNode$2(pnBG, DecorNode.C_lowercase_tile) !== null) {
    tsf = BGraphics.tsf_kTile;
  } else if (DecorNode.findChildNode$2(pnBG, DecorNode.C_lowercase_scale) !== null) {
    tsf = BGraphics.tsf_kScale;
  } else if (DecorNode.findChildNode$2(pnBG, DecorNode.C_lowercase_cover) !== null) {
    tsf = BGraphics.tsf_kCover;
  }

  g.save();
//  g.clipRect(xpos, ypos, xpos + width, ypos + height);
  g.beginPath();
  g.rect(xpos, ypos, xpos + width, ypos + height);
  g.clip();


  g.globalAlpha = BGraphics.convertByte255ToReal(alpha);

  let pnFill = DecorNode.findChildNode$2(pnBG, DecorNode.C_lowercase_fill);
  if (pnFill !== null) {
    let pnChild = DecorNode.findChildNode$2(pnFill, DecorNode.C_lowercase_c);
    if (pnChild !== null) {
      let status = new BStatus(true);
      let iv = DecorNode.parseColorNode(pnChild, status);
      if (status.getStatus()) {
        g.fillStyle = BPColor.intToHexRGBA(iv);
        g.fillRect(xpos, ypos, xpos + width, ypos + height);
      }
    }
  }
  if (tsf === BGraphics.tsf_kTile) {
    let imgHeight = img.height;
    let imgWidth = img.width;
    let xend = xpos + width;
    let yend = ypos + height;
    for (let y = ypos; y < yend; y += imgHeight) {
      for (let x = xpos; x < xend; x += imgWidth) {
        g.drawImage(img.image, x, y);
      }
    }
    bRet = true;
  } else if (tsf === BGraphics.tsf_kNone || tsf === BGraphics.tsf_kFill) {
    g.drawImage(img.image, 0, 0, img.width, img.height, xpos, ypos, width, height);
    bRet = true;
  } else if (tsf == BGraphics.tsf_kCover) {
    let imgHeight = img.height;
    let imgWidth = img.width;
    let scalew = 1.0,
      scaleh = 1.0,
      scale = 1;
      scalew = width / imgWidth;
      scaleh = height / imgHeight;
      if (scalew >= scaleh) {
        scale = scalew;
      } else {
        scale = scaleh;
      }
      let newDestWidth = imgWidth * scale;
      let newDestHeight = imgHeight * scale;
      let xoff = 0,
        yoff = 0,
        sx = 0,
        sy = 0,
        sheight = imgHeight,
        swidth = imgWidth;
      if (newDestWidth > width) {
        xoff = (newDestWidth - width) / 2;
        sx = xoff == 0 ? 0 : xoff /scale;
        swidth = width / scale;
      }
      if (newDestHeight > height) {
        yoff = (newDestHeight - height) / 2;
        sy = yoff == 0 ? 0 : yoff /scale;
        sheight = height /scale;
      }

      g.drawImage(img.image, sx, sy, swidth, sheight, xpos, ypos, width, height);
      bRet = true;
  } else if (tsf === BGraphics.tsf_kScale) {
    let imgHeight = img.height;
    let imgWidth = img.width;
    if (imgWidth < width && imgHeight < height) {
      if (imgWidth < width) {
        xpos = xpos + (width - imgWidth) / 2;
        width = imgWidth;
      }
      if (imgHeight < height) {
        ypos = ypos + (height - imgHeight) / 2;
        height = imgHeight;
      }
      g.drawImage(img.image, 0, 0, img.width, img.height, xpos, ypos, width, height);
      bRet = true;
    } else if (imgWidth / imgHeight < width / height) {
      let scale = height / imgHeight;
      let dWidth = scale * imgWidth;
      xpos = xpos + ((width - dWidth) / 2);
      width = dWidth;
      g.drawImage(img.image, 0, 0, img.width, img.height, xpos, ypos, width, height);
      bRet = true;
    } else {
      let scale = width / imgWidth;
      let dHeight = scale * imgHeight;
      ypos = ypos + ((height - dHeight) / 2);
      height = dHeight;
      g.drawImage(img.image, 0, 0, img.width, img.height, xpos, ypos, width, height);
      bRet = true;
    }
  }

  g.restore();
  return bRet;
};
BGraphics.backgroundResourceImageBase = (sSel, appContext, g, mPaint, dn, xpos, ypos, width, height) => {
  if (width <= 0 || height <= 0) {
    return false;
  }
  let img = BImageSpec.hasResourceImage(dn, appContext, sSel);
  if (img !== null) {
    let pnChild = dn.nodeHasTag(sSel, DecorNode.C_lowercase_alpha);
    let alpha = 255;
    if (pnChild !== null) {
      pnChild = pnChild.firstChild();
      if (pnChild !== null) {
        let alphaTemp = BBUtils.parseInt(pnChild.TID());
        if (alphaTemp !== Number.MAX_SAFE_INTEGER) {
          alpha = alphaTemp;
        }
      }
    }
    return BGraphics.fillRectWithImage(g, mPaint, img, alpha, dn.findChildNode(sSel), xpos, ypos, width, height);
  }
  return false;
};
BGraphics.backgroundResourceImage = (appContext, g, mPaint, dn, xpos, ypos, width, height) => {
  return BGraphics.backgroundResourceImageBase(DecorNode.C_lowercase_bg, appContext, g, mPaint, dn, xpos, ypos, width, height);
};
BGraphics.selBackgroundResourceImage = (appContext, g, mPaint, dn, xpos, ypos, width, height) => {
  return BGraphics.backgroundResourceImageBase(DecorNode.C_lowercase_sbg, appContext, g, mPaint, dn, xpos, ypos, width, height);
};
/*
BGraphics.sizeImage = (image, width, height) => {
  let result = null;
  if (image === null) {
    return null;
  }
  if (image.width === width && image.height === height) {
    return image;
  }
  try {
    result = createImageBitmap(image, {resizeWidth: width, resizeHeight: height, resizeQuality: high});
  } catch (ex) {
    result = null;
  }
  return result;
};
*/