export default class StringBuffer {

    constructor(...args$) {
        this.buffer = [];
        this.index = 0;
        /*
        if (typeof args$ !== 'undefined' && args$.length > 0) {
            if (typeof args$[0] === 'number') {
                // nothing to do.. pre-allocation
            } else {
                this.append(args$[0]);
            }
        }
        */
    }
    append = (s) => {
        this.buffer[this.index] = s;
        this.index += 1;
        return this;
    }
    length = () => {
        var lng = 0;
        for (let i=0; i<this.index; i++) {
            lng += this.buffer[i].length;
        }
        return lng;
    }
    toString = () => {
        return this.buffer.join('');
    }
};
