import DecorNode from "./DecorNode";

var operator = {
  "+": function (a, b) { return a + b; },
  "-": function (a, b) { return a - b; },
  "*": function (a, b) { return a * b; },
  "/": function (a, b) { return a / b; }
}

export default class Parser {
	constructor(table) {
		this.table = table;
	}


	parse = (input) => {
		var length = input.length,
			table = this.table,
			output = [],
			stack = [],
			index = 0;

		while (index < length) {
			var token = input[index++];

			switch (token) {
				case "(":
					stack.unshift(token);
					break;
				case ")":
					while (stack.length) {
						var token = stack.shift();
						if (token === "(") break;
						else output.push(token);
					}
					
					if (stack.length && /[a-zA-Z]+/.test(stack[0])) {
						output.push(stack.shift());
					}

					if (token !== "(")
						throw new Error("Mismatched parentheses.");
					break;
				default:
					if (table.hasOwnProperty(token)) {
						while (stack.length) {
							var punctuator = stack[0];

							if (punctuator === "(") break;

							var operator = table[token],
								precedence = operator.precedence,
								antecedence = table[punctuator].precedence;

							if (precedence > antecedence ||
								precedence === antecedence &&
								operator.associativity === "right") break;
							else output.push(stack.shift());
						}

						stack.unshift(token);
					} else {
							if (/[a-zA-Z]+/.test(token))
								stack.unshift(token);
							else
							  output.push(token);
					}
			}
		}

		while (stack.length) {
			var token = stack.shift();
			if (token !== "(") output.push(token);
			else throw new Error("Mismatched parentheses.");
		}

		return output;
	}
}

Parser.evalTokens = (toks, width, height) => {
	var stack = [];

	toks.forEach(function (c) {
		switch (c) {
			case "+":
			case "-":
			case "*":
			case "/":
				var b = stack.pop();
				var a = stack.pop();
				// is a or b an identifer like fw or fh
				if (typeof a != 'string' || /^[+-]?\d+(\.\d+)?$/.test(a)) {
					var aval = +a;
					if (typeof b != 'string' || /^[+-]?\d+(\.\d+)?$/.test(b)) {
						var bval = +b;
						stack.push(operator[c](aval, bval));
					} else {
						console.log('b is a function!');
					}
				} else {
					// a is function
					if (typeof b != 'string' || /^[+-]?\d+(\.\d+)?$/.test(b)) {
						var bval = +b;
						// x(200) or 
						// fw(3)

					} else {
						console.log('b is a function?');
					}

				}
				break;
			default:
				if (/[a-zA-Z]+/.test(c)) {
					var a =+ stack.pop();
					// evaluate c(a)
					stack.push(DecorNode.evalUnitsWithValue(c, a, width, height));
				} else {
					stack.push(c);
				}
				break;
		}
	})
	var result = stack.pop();
	return +result;
}

