import Worker from './HttpPostThread.worker.js';    

export default class ImageLoaderWorker {
    constructor() {
        this.requests = {};
        this.requestsCounter = 0;

        this.worker = new Worker();

        this.receive = this.receive.bind(this);
        this.send = this.send.bind(this);

        this.worker.onmessage = this.receive;
    }

    send(...args) {
        const id = `request-${this.requestsCounter++}`;
        var that = this;

        return new Promise((resolve, reject) => {
            that.requests[id] = ({data, error}) => {
                if (error) {
                    reject(error);
                    return;
                }

                resolve(data);
            };

            that.worker.postMessage({id, taskString: args[0].toString()})
        });
    }

    receive(event) {
        const {id, data, error} = event.data;

        if (typeof this.requests[id] === "function") {
            this.requests[id]({data, error});
            delete this.requests[id];
        }
    }
}
