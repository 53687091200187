import React, { Component, PropTypes } from "react";
import { Image } from "react-native";

export default class BPImage extends Component {
    constructor(props) {
        super(props);
        this.state = { source: { uri: this.props.uri } };
        this.firstTime = true;
    }

    resizeMe = () => {
        Image.getSize(this.props.uri, (width, height) => {
            var resWidth, resHeight;

            if (!this.props.width && !this.props.height) {
                resWidth = width;
                resHeight = height;
            } else if (this.props.width && !this.props.height) {
                resWidth = this.props.width;
                resHeight = height * (this.props.width / width);
            } else if (!this.props.width && this.props.height) {
                resWidth = width * (this.props.height / height);
                resHeight = this.props.height;
            } else {
                // fully scale into destination
                let scale = Math.min(this.props.width / width, this.props.height / height);
                resWidth = width * scale;
                resHeight = height * scale;
            }
            this.setState({                
                width: resWidth,
                height: resHeight
            });
            if (this.props.sizeSet) {
                this.props.sizeSet(
                    this.props.ssObj,
                    resWidth,
                    resHeight
                )
            }

        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.uri.equals(prevProps.uri)) {
            this.setState({source: { uri: this.props.uri }})
            this.resizeMe();
        } else if (this.props.refresh != prevProps.refresh) {
            this.resizeMe();
        }
      }
    
    componentDidMount() {
        if (this.firstTime) {
            this.firstTime = false;
            this.resizeMe();
        }
    }

    render() {
        return (
            <Image
                source={this.state.source}
                style={[
                    { 
                        height: this.state.height, 
                        width: this.state.width 
                    },
                    this.props.style                    
                ]}
            />
        );
    }
}
/*
BPImage.propTypes = {
    uri: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number
};
*/