import React from "react";
import {TextField, Grid, FormLabel} from "@mui/material"
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import EConstants from "./EConstants";
import BDecorBG from "./BDecorBG";
import EToolbar from "./EToolbar";
import EDefaultText from "./EDefaultText";

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
export default class EProperties extends React.Component {
    constructor(props) {
        // props.id == nodeId
        super(props);
        this.state = {
            value: "1",

            name: '',

        }
        this.curTreeItem = null;

        this.editInfo = global.globalVars.getEditInfo();

        this.updateToNewId();
    }

    /*
    shouldComponentUpdate() {

    }
    */

    componentDidUpdate(prevProps, prevstate, snapshot) {
        if (prevProps.id != this.props.id) {
            this.updateToNewId();
        }
    }
    componentDidMount() {
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    /*
    e.g. editProps = {
        id: nodeIds,
        name: myItem.label,
        properties: [
            {
                name: 'Background',
                type: EConstants.PropertyType_DecorBG
            }
        ],
        advanced: [

        ]
    };
    */

    updateToNewId() {
        this.curTreeItem = this.editInfo.editProps;
        if (this.curTreeItem !== null) {
            this.setState({ name: this.curTreeItem.label });
        }

    }

    renderProperties() {
        if (this.curTreeItem !== null && this.curTreeItem.properties.length > 0) {
            return (
                <>
                    {
                        this.curTreeItem.properties.map((itm, ix) => {
                            switch (itm.type) {
                                case EConstants.PropertyType_DecorBG:
                                    return (
                                        <BDecorBG
                                            key={ix}
                                            name={itm.name}
                                            original={itm.modified}
                                            dnType={itm.dnType}
                                            setProp={itm.setProp}
                                            enableFG={itm.enableFG}
                                        />
                                    )
                                    break;
                                case EConstants.PropertyType_Toolbar:
                                    return (
                                        <EToolbar
                                            key={ix}
                                            name={itm.name}
                                            original={itm.modified}
                                            setProp={itm.setProp}
                                        />
                                    )
                                    break;
                                case EConstants.PropertyType_Text:
                                    return (
                                        <EDefaultText
                                            key={ix}
                                            name={itm.name}
                                            original={itm.modified}
                                            setProp={itm.setProp}
                                            txtProps={itm.txtProps}
                                        />
                                    )
                                    break;
                            }

                        })
                    }
                </>
            )
        }
    }
    renderAdvanced() {
        if (this.curTreeItem !== null && this.curTreeItem.advanced.length > 0) {

        }
    }
    render() {
        return (
            <>
                <FormLabel style={{ padding: 5 }}>
                    : {this.state.name}
                </FormLabel>
                <TabContext value={this.state.value} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={this.handleChange} aria-label="property sheet">
                            <Tab label="Properties" value="1" {...a11yProps(0)} />
                            <Tab label="Advanced" value="2" {...a11yProps(1)} />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        {this.curTreeItem !== null &&
                            this.renderProperties()
                        }
                    </TabPanel>
                    <TabPanel value="2">
                        {this.curTreeItem !== null &&
                            this.renderAdvanced()
                        }
                    </TabPanel>
                </TabContext>
            </>
        )
    }
}