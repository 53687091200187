import BBUtils from "./BBUtils";
import BImageSpec from "../graphics/BImageSpec";

export default class TBItem {
    constructor(sImage, sLabel, iMenu, bEnabled) {
      this._button = null;
      this._imgbutton = null;
      this._imageSet = false;
  
      this._imageUrl = sImage;
      this._label = sLabel;
      this._menuId = iMenu;
      this._enabled = bEnabled;
      this._active = false;
    }
    getLabel() {
      return this._label;
    }
    getMenuId() {
      return this._menuId;
    }
    getEnabled() {
      return this._enabled;
    }
    getActive() {
      return this._active;
    }
    setActive(b) {
      this._active = b;
    }
    getImageUrl() {
      return this._imageUrl;
    }
    getRemoteImageUrl() {
      // convert r:/img/xxxx.png to https://<path>/xxxx.png
      if (!this._imageUrl) return null;
      
      if ((this._imageUrl.startsWith(BBUtils.C_RESOURCE_PROTOCOL) || global.globalVars.isLocalResourceUrl(this._imageUrl) !== null)) {
        return BImageSpec.resourceNameToRemote(/* ctx */ null, this._imageUrl);
      } else {
        return this._imageUrl;
      }
    }
    setButton(btn) {
      this._button = btn;
    }
    getImageButton() {
      return this._imgbutton;
    }
    setImageButton(btn) {
      this._imgbutton = btn;
    }
    getButton() {
      return this._button;
    }
    getImageSet() {
      return this._imageSet;
    }
    setImageSet(bSet) {
      this._imageSet = bSet;
    }
  }
  