export default class MofiHandler {

}
MofiHandler.ListModeNone = 0;
MofiHandler.ListModeRefreshR = 1;
MofiHandler.ListModeRefreshS = 2;

// _rightButtonType
MofiHandler.B_RIGHTBUTTON_REGULAR = 0;
//MofiHandler.B_RIGHTBUTTON_SLIDEOUT = 1;
MofiHandler.B_RIGHTBUTTON_SINGLE = 2;

