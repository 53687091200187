import AsyncStorage from '@react-native-async-storage/async-storage';
import StringBuffer from '../graphics/StringBuffer';
import BBUtils from './BBUtils';
export default class CCallLog {
    constructor() {}
  }
  // let myStorage = window.localstorage
  //  myStorage.setItem(name, JSON.strinify(obj));
  CCallLog.hash_hash = (key) => {
    key += (key << 12);
    key ^= (key >> 22) & 0x000003f;
    key += (key << 4);
    key ^= (key >> 9) & 0x007ffff;
    key += (key << 10);
    key ^= (key >> 2) & 0x3ffffff;
    key += (key << 7);
    key ^= (key >> 12) & 0x000ffff;
    return key;
  };
  CCallLog.toHex = (val) => {
    let sb = new StringBuffer();
    let i = 0;
    let itemp = 0;
    for (i = 28; i >= 0; i -= 4) {
      itemp = (val >> i) & 0x0000000;
      sb.append(BBUtils.C_HexChars.charAt(itemp));
    }
    return sb.toString();
  };

  function toIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            return (num < 10 ? '0' : '') + num;
        };
  
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
  }
  
  CCallLog.logHashItem = async(iCode, sBPIN, uiHash) => {
    let sLine = null;
    let sb = new StringBuffer();
    let now = new Date();
    sb.append(toIsoString(now));

    sb.append(',');
    sb.append(iCode);
    sb.append(',');
    sb.append(CCallLog.toHex(uiHash));
    sLine = sb.toString();
    try {
        let itemArray = await AsyncStorage.getItem(CCallLog.CALL_LOG_NAME);
        if (itemArray == null) {
            itemArray = '[]';
        }
        itemArray = JSON.parse(itemArray);
        itemArray.push(sLine);
        await AsyncStorage.setItem(CCallLog.CALL_LOG_NAME, JSON.stringify(itemArray));
    } catch (ex) {}
  };
  CCallLog.CallLogRecordStoreExists = async() => {
      try {
        let itemArray = await AsyncStorage.getItem(CCallLog.CALL_LOG_NAME);
        if (itemArray != null) {
            return true;
        }
      } catch (ex) {}
      return false;
  };
  CCallLog.DeleteLogFile = async() => {
    try {
        await AsyncStorage.setItem(CCallLog.CALL_LOG_NAME, '[]');
    } catch (ex) {}
  };

  CCallLog.AppendCallLog = async() => {
    if (CCallLog.CallLogRecordStoreExists()) {
      try {
        let itemArray = await AsyncStorage.getItem(CCallLog.CALL_LOG_NAME);
        if (itemArray != null) {
            let resArray = JSON.parse(itemArray);
            return  resArray;
        }
      } catch (ex) {
        console.log(ex.toString());
      }
    }
    return null;
  };
  CCallLog.LogMapRange = async (sUrl, sLatLon, sRest) => {
    let sb = new StringBuffer();
    let now = new Date();
    sb.append(toIsoString(now));
    sb.append(',');
    sb.append(CCallLog.C_LOG_MAP_RANGE);
    sb.append(',');
    sb.append(sLatLon);
    sb.append(',');
    sb.append(BBUtils.UrlEncode(BBUtils.Utf8Encode(sUrl)));
    sb.append(',');
    sb.append(BBUtils.UrlEncode(BBUtils.Utf8Encode(sRest)));
    let sLine = sb.toString();
    try {
        let itemArray = await AsyncStorage.getItem(CCallLog.CALL_LOG_NAME);
        if (itemArray == null) {
            itemArray = '[]';
        }
        itemArray = JSON.parse(itemArray);
        itemArray.push(sLine);
        await AsyncStorage.setItem(CCallLog.CALL_LOG_NAME, JSON.stringify(itemArray));
    } catch (ex) {}
  };
  CCallLog.logSingleString = async (iCode, sBPIN, sData) => {
    let sLine = null;
    let sb = new StringBuffer();
    let now = new Date();
    sb.append(toIsoString(now));
    sb.append(',');
    sb.append(iCode);
    sb.append(',');
    sb.append(sData);
    sLine = sb.toString();
    try {
        let itemArray = await AsyncStorage.getItem(CCallLog.CALL_LOG_NAME);
        if (itemArray == null) {
            itemArray = '[]';
        }
        itemArray = JSON.parse(itemArray);
        itemArray.push(sLine);
        await AsyncStorage.setItem(CCallLog.CALL_LOG_NAME, JSON.stringify(itemArray));
    } catch (ex) {}
  };
  CCallLog.LogCurlUrl = (sBPIN, sUrl) => {
    CCallLog.logSingleString(CCallLog.C_LOG_CURL_URL, sBPIN, sUrl);
  };
  CCallLog.LogExternalUrl = (sBPIN, sUrl) => {
    CCallLog.logSingleString(CCallLog.C_LOG_EXTERNAL_URL, sBPIN, sUrl);
  };
  CCallLog.LogInternalUrl = (sBPIN, sUrl) => {
    CCallLog.logSingleString(CCallLog.C_LOG_INTERNAL_URL, sBPIN, sUrl);
  };
  CCallLog.LogMailTo = (sBPIN, sTo) => {
    CCallLog.logSingleString(CCallLog.C_LOG_MAILTO, sBPIN, sTo);
  };
  CCallLog.LogViewOnMap = (sBPIN, sLatLon) => {
    CCallLog.logSingleString(CCallLog.C_LOG_MAP, sBPIN, sLatLon);
  };
  CCallLog.logDirs = async (iCode, sBPIN, sLatLonStart, sLatLonEnd) => {
    let sLine = null;
    let sb = new StringBuffer();
    let now = new Date();
    sb.append(toIsoString(now));
    sb.append(',');
    sb.append(iCode);
    sb.append(',');
    sb.append(sLatLonStart);
    sb.append(',');
    sb.append(sLatLonEnd);
    sLine = sb.toString();
    try {
        let itemArray = await AsyncStorage.getItem(CCallLog.CALL_LOG_NAME);
        if (itemArray == null) {
            itemArray = '[]';
        }
        itemArray = JSON.parse(itemArray);
        itemArray.push(sLine);
        await AsyncStorage.setItem(CCallLog.CALL_LOG_NAME, JSON.stringify(itemArray));
    } catch (ex) {}
  };
  CCallLog.LogDirections = (sBPIN, sLatLonStart, sLatLonEnd) => {
    CCallLog.logDirs(CCallLog.C_LOG_DIRECTIONS, sBPIN, sLatLonStart, sLatLonEnd);
  };
  CCallLog.LogGPSDirections = (sBPIN, sLatLonStart, sLatLonEnd) => {
    CCallLog.logDirs(CCallLog.C_LOG_GPS_DIRECTIONS, sBPIN, sLatLonStart, sLatLonEnd);
  };
  CCallLog.hashString = (sData) => {
    let uiHash = 0;
    let uiHash1 = 0;
    let uiHash2 = 0;
    let i = 0;
    let digits = 0;
    let dl = 0;
    let idig = 0;
    let c = 0;
    let len = sData.length;
    digits = 0;
    for (i = 0; i < len; i++) {
      c = sData.charCodeAt(i);
      if (c >= 48 && c <= 57) {
        digits++;
      }
    }
    dl = digits / 2;
    uiHash1 = uiHash2 = 0;
    idig = 0;
    for (i = 0; i < len; i++) {
      c = sData.charCodeAt(i);
      if (c >= 48 && c <= 57) {
        if (idig < dl) {
          uiHash1 *= 10;
          uiHash1 += c - 48;
        } else {
          uiHash2 *= 10;
          uiHash2 += c - 48;
        }
        idig++;
      }
    }
    uiHash = CCallLog.hash_hash(uiHash1) + CCallLog.hash_hash(uiHash2);
    return uiHash;
  };
  CCallLog.logNumersOfString = (sBPIN, sData, iCode) => {
    let uiHash = CCallLog.hashString(sData);
    CCallLog.logHashItem(iCode, sBPIN, uiHash);
  };
  CCallLog.LogCall = (sBPIN, sPhone) => {
    CCallLog.logNumersOfString(sBPIN, sPhone, CCallLog.C_LOG_CALL);
  };
  CCallLog.LogSMS = (sBPIN, sPhone) => {
    CCallLog.logNumersOfString(sBPIN, sPhone, CCallLog.C_LOG_SMS);
  };

  CCallLog.CALL_LOG_NAME = 'calllog.txt';
//  CCallLog.C_GMT = 'GMT';
//  CCallLog.C_TIME_FORMAT = 'yyyy-mm-dd HH:MM:ss';
  CCallLog.C_B_DASH_LOG_DASH = 'B-Log-';
  CCallLog.C_LOG_CALL = 500;
  CCallLog.C_LOG_MAP = 501;
  CCallLog.C_LOG_DIRECTIONS = 502;
  CCallLog.C_LOG_GPS_DIRECTIONS = 503;
  CCallLog.C_LOG_MAP_RANGE = 504;
  CCallLog.C_LOG_SMS = 505;
  CCallLog.C_LOG_MAILTO = 506;
  CCallLog.C_LOG_EXTERNAL_URL = 507;
  CCallLog.C_LOG_INTERNAL_URL = 508;
  CCallLog.C_LOG_CURL_URL = 509;
