import React from "react";
import { ScrollView, FlatList, StyleSheet, View, Text } from 'react-native';
import DecorNode from "../graphics/DecorNode";
import TBItem from "./TBItem";
import HSView from "./HSView";
import ToolbarManager from "./ToolbarManager";
import BStatus from "../graphics/BStatus";

export default class TropareHorizontalScrollView extends React.Component {
	constructor(props) {
		super(props);
		// props.decorString
		this.state = { 
			items: [],
			widthSet: false,
			twidth: 100,
			childDimensions: [],
			fitHeightMargins: {},
			fitHeightSizesSet: false,
		};
		this.marginTop = 0;
		this.marginLeft = 0;
		this.marginBottom = 0;
		this.marginRight = 0;
		this.fitType = "";			// sizetofit,  fitheight
		this.childLoadedFunction = null;
		this._childDims = [];

		this.scrollLayout = this.scrollLayout.bind(this);
		this.fitHeightLayout = this.fitHeightLayout.bind(this);

	}

	updateConfig() {
		this.dn = DecorNode.FromAttributes(this.props.decorString);
		if (this.dn == null) return;

		this._tbItems = [];
		this._pnNodesString = [];
		this._dnNodes = [];
		let iCheck = 0;
		let bFound = true;

		let sizeType = {
			width: -1,
			widthType: 'matchHeight'
		}

		let pnSizer = this.dn.findChildNode("sizetofit");
		if (pnSizer != null) {
			this.fitType = 'sizetofit';
		}
		pnSizer = this.dn.findChildNode("fitheight");
		if (pnSizer != null) {
			this.fitType = 'fitheight';
			this.childLoadedFunction = this.fitHeightLayout;
		}


		let pnW = this.dn.findChildNode(DecorNode.C_lowercase_width);
		if (pnW != null) {
			pnW = pnW.firstChild();
			if (pnW != null) {
				if (pnW.TID().toLowerCase().equals("perc")) {
					let status = new BStatus(true);
					let iPerc = this.dn.parseIntChild(pnW, status);
					if (status.getStatus()) {
						sizeType.width = iPerc;
						sizeType.widthType = 'perc';
					}

				} else if (pnW.TID().toLowerCase().equals("ratio")) {
					let status = new BStatus(true);
					let dPerc = this.dn.parseFloatChild(pnW, status);
					if (status.getStatus()) {
						sizeType.width = dPerc;
						sizeType.widthType = 'ratio';
					}

				} else if (pnW.TID().toLowerCase().equals("item")) {
					sizeType.widthType = 'item';	// width specified at item level
				} else {
					let dVal = DecorNode.parseUnitsWithValue(pnW);
					if (dVal != Number.MAX_VALUE) {
						sizeType.width = dVal;
						sizeType.widthType = 'units';
					}
				}
			}
		}
		if (this.dn.hasMarginTop()) {
			this.marginTop = this.dn.getMarginTop();
		}
		if (this.dn.hasMarginBottom()) {
			this.marginBottom = this.dn.getMarginBottom();
		}
		if (this.dn.hasMarginLeft()) {
			this.marginLeft = this.dn.getMarginLeft();
		}
		if (this.dn.hasMarginRight()) {
			this.marginRight = this.dn.getMarginRight();
		}

		while (bFound) {
			let sItem = "item" + iCheck.toString();
			let pn = this.dn.findChildNode(sItem);
			if (pn != null) {
				let sTitle = DecorNode.getTitle(pn);
				if (sTitle != null) {
					if (sTitle.equals('nil')) {
						sTitle = "";
					}
				}
				let sImgUrl = DecorNode.getImgUrl(pn);

				let bEnabled = true;
				let pnFind = DecorNode.findChildNode$2(pn, DecorNode.C_lowercase_disabled);
				if (pnFind != null) {
					bEnabled = false;
				}
				let tbi = new TBItem(sImgUrl, sTitle, iCheck, bEnabled);

				tbi.defaultSize = sizeType;

				let buttonType = ToolbarManager.BTOOLBAR_BUTTON_TYPE_LABEL;
				let pnType = DecorNode.findChildNode$2(pn, DecorNode.C_lowercase_type);
				if (pnType != null) {
					pnType = pnType.firstChild();
					if (pnType != null) {
						let satt = pnType.TID().toLowerCase();
						if (satt.equals(DecorNode.C_lowercase_label)) {
							buttonType = ToolbarManager.BTOOLBAR_BUTTON_TYPE_LABEL;
						} else if (satt.equals(DecorNode.C_lowercase_image)) {
							buttonType = ToolbarManager.BTOOLBAR_BUTTON_TYPE_IMAGE;
						} else if (satt.equals(DecorNode.C_lowercase_combo)) {
							buttonType = ToolbarManager.BTOOLBAR_BUTTON_TYPE_COMBO;
						}
					}
				}
				tbi.buttonType = buttonType;

				this._tbItems.push(tbi);
				let dnString = pn.toStringChildren();
				this._pnNodesString.push(dnString);
				this._dnNodes.push(DecorNode.FromAttributes(dnString, DecorNode.DECORNODE_TYPE_CELL));

				this._childDims.push({ set: false, width: 0, height: 0, computedWidth: 0, computedHeight: 0 });

				iCheck++;
			} else {
				bFound = false;
			}
		}


	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.decorString != this.props.decorString) {
			this.updateConfig();
		}
	}
	componentDidMount() {
		this.updateConfig();
		this.setState({
			items: this._tbItems,
			childDimensions: this._childDims
		})
	}
	componentWillUnmount() {
	}

	scrollLayout = (event) => {
		var { width } = event.nativeEvent.layout;
		this.setState({
			widthSet: true,
			twidth: width,
		})

	}

	// Used when fitting to height
	// 1. Scale all images to have matching heights (match to smallest height'd image)
	// 2. scale them to the props.height value.
	// 3. If the width is equal to or smaller than the this.state.twidth, then evenly space side padding
	// 4. If width is > twidth, then scale the height down to 'fit' twidth.
	fitHeightLayout(ix, w, h) {
		var newDims = [...this.state.childDimensions];

		newDims[ix].set = true;
		newDims[ix].width = w;
		newDims[ix].height = h;

		var allSet = newDims.every(elem => elem.set === true);
		if (allSet) {
			var usableHeight = this.props.height - (this.marginBottom + this.marginTop);

			var minHeight = newDims[0].height;
			for (var i=1; i<newDims.length; i++) {
				if (newDims[i].height < minHeight)
					minHeight = newDims[i].height;
			}
			var totalWidth = 0;
			for (var i=0; i<newDims.length; i++) {
				const scale = (minHeight / newDims[i].height);
				newDims[i].computedHeight = usableHeight;
				const newWidth = newDims[i].width * scale * (usableHeight / minHeight);
				newDims[i].computedWidth = newWidth;
				totalWidth += newWidth;
			}
			var usableWidth = this.state.twidth - (this.state.items.length * (this.marginLeft + this.marginRight));
			if (totalWidth > usableWidth) {	
				// We don't fit horizontally, so make us samller. Vertical align us in the are, as our height 
				// won't be tall enought. We added alignItems: 'center' on the ScrollView below to check this..
				var scale = usableWidth / totalWidth;
				for (var i=0; i<newDims.length; i++) {
					newDims[i].computedHeight *= scale;
					newDims[i].computedWidth *= scale;
				}
			} else {		// <=
				this.setState({fitHeightMargins: {marginLeft: 'auto', marginRight: 'auto'}});
			}
			this.setState({fitHeightSizesSet: true});
		}

		this.setState({ childDimensions: newDims });
	}


render() {
	let that = this;
	var bScroll = true;
	if (this.state.items.length > 0) {	// tbi.defaultSize = {width: widthType: } (all same...)
		// perc, ratio, units (item)
		if (this.state.items[0].defaultSize.widthType == 'perc') {
			if (this.state.items[0].defaultSize.width * this.state.items.length <= 100) {
				bScroll = false;
			}
		}		
	}
		return (
			<View
				onStartShouldSetResponder={() => true}
				style={{width: '100%', height: this.props.height}}
				>
			<ScrollView
				horizontal={true}
				style={{width: '100%', height:this.props.height}}
				contentContainerStyle={{alignItems: 'center', flex: 1}}
				onLayout={this.scrollLayout}
				showsHorizontalScrollIndicator={bScroll}
			>
				{this.state.widthSet && this.state.items.length > 0 && this.state.items.map( (item, index) => {
					let marginTop = that.marginTop;		// NOTE: we are taking the 'outer' margins specified, not item based
					let marginBottom = that.marginBottom;
					let marginLeft = that.marginLeft;
					let marginRight = that.marginRight;
					/* if inner margin on a per item basis...
					if (that._dnNodes[index] !== null) {
							if (that._dnNodes[index].hasMarginTop()) {
									marginTop += that._dnNodes[index].getMarginTop();
							}
							if (that._dnNodes[index].hasMarginBottom()) {
									marginBottom += that._dnNodes[index].getMarginBottom();
							}
							if (that._dnNodes[index].hasMarginLeft()) {
									marginLeft += that._dnNodes[index].getMarginLeft();
							}
							if (that._dnNodes[index].hasMarginRight()) {
									marginRight += that._dnNodes[index].getMarginRight();
							}
					}
					*/

					if (this.fitType == 'fitheight' && this.state.fitHeightSizesSet) {
						return (
							<View key={index}
								style={[
									{
										paddingTop: marginTop,
										paddingLeft: marginLeft,
										paddingBottom: marginBottom,
										paddingRight: marginRight,
										height: '100%',
									}, this.state.fitHeightMargins
								]
								}
							>
								<HSView
									index={index}
									style={styles.content}
									btnType={item.buttonType}
									tbi={item}
									fitType={that.fitType}
									decorToolbarString={that._pnNodesString[index]}
									refresh={this.props.refresh}
									hrefresh={this.props.hrefresh}
									width={this.state.childDimensions[index].computedWidth}
									height={this.state.childDimensions[index].computedHeight}
								/>
							</View>
						)

					} else {
						return (
							<View key={index}
								style={
									{
										paddingTop: marginTop,
										paddingLeft: marginLeft,
										paddingBottom: marginBottom,
										paddingRight: marginRight,
										height: '100%',
									}
								}
							>
								<HSView
									index={index}
									style={styles.content}
									btnType={item.buttonType}
									tbi={item}
									fitType={that.fitType}
									twidth={that.state.twidth}
									decorToolbarString={that._pnNodesString[index]}
									refresh={this.props.refresh}
									hrefresh={this.props.hrefresh}
									loaded={this.childLoadedFunction}
								/>
							</View>
						)
					}
				})}
				</ScrollView>
			</View>
		)
	}
}
const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
		height: '100%',
  }
});