import React from "react";
import ReactDOM from 'react-dom';
import { Container, Box } from "@mui/material";
import {TextField} from "@mui/material";

export default class BGradientStop extends React.Component {
    constructor(props) {
        // props.perc 0 to 100
        // props.color = #rgba
        super(props)        
        this.state = {
            foobar: null
        }
    }

    componentDidUpdate(prevProps, prevstate, snapshot) {
    }
    componentDidMount() {
    }

    render() {
        if (this.props.pref.current == null) {
            return (
                <>
                </>
            )
        } else {
            let dn = this.props.pref.current;
            let x = dn.offsetWidth * this.props.perc / 100;
            return (
                <Box
                    sx={{
                        position: "absolute",
                        width: "13px",
                        height: "31px",
                        boxSizing: "border-box",
                        display: "block",
                        cursor: "move",
                    }}
                >

                    <Box
                        sx={{
                            position: "absolute",
                            top: "0px",
                            left: "2px",
                            width: "11px",
                            height: "31px",
                            background: "rgba(0,0,0,0.2)",
                            borderRadius: "7px",
                            opacity: "0",
                            transition: "all 250ms ease-out",
                            boxSizing: "border-box",
                        }}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            top: "3px",
                            left: "4px",
                            width: "8px",
                            height: "28px",
                            background: "#fff",
                            border: "2px solid #1F2667",
                            borderRadius: "4px",
                            boxShadow: "0 1px 2px rgba(0,0,0,0.5)",
                            transition: "all 250ms ease-out",
                            boxSizing: 'border-box',
                            display: "block"
                        }}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            top: "4px",
                            left: "6px",
                            width: "3px",
                            height: "22px",
                            background: this.props.color,
                            borderRadius: "3px",
                            boxSizing: "border-box",
                            display: "block"
                        }}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            top: "38px",
                            left: "-4px",
                            width: "24px",
                            height: "16px",
                            boxSizing: "border-box",
                        }}
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                background: "#fff",
                                border: "1px solid #999",
                                borderRadius: "3px",
                                transition: "all 175ms ease-out",
                                boxSizing: "border-box",
                                display: "block"
                            }}
                        />
                        <Box
                            sx={{
                                position: "absolute",
                                top: '0px',
                                left: "0px",
                                background: "transparent",
                                border: "none",
                                width: "100%",
                                height: "100%",
                                textAlign: "center",
                                zIndex: "2",
                            }}
                        >
                            {this.props.perc}
                        </Box>
                    </Box>
                </Box>

            )
        }
    }
}
