export default class Character {
    constructor() {
      }      
}

Character.isDigit = (c) => {
    let ccode = c.charCodeAt(0);
    if (ccode >= 48 && ccode <= 57) {
        return true;
    } else {
        return false;
    }
}
Character.isHex = (c) => {
    let ccode = c.charCodeAt(0);
    if ((ccode >= 48 && ccode <= 57) ||
        (ccode >= 'a'.charCodeAt(0) && ccode <= 'f'.charCodeAt(0)) ||
        (ccode >= 'F'.charCodeAt(0) && ccode <= 'F'.charCodeAt(0)))
        return true;
    else
        return false;
}
Character.cvtHex = (c) => {
    let ccode = c.charCodeAt(0);
    if (ccode >= 48 && ccode <= 57) {
        return ccode - 48;
    } else if (ccode >= 'a'.charCodeAt(0) && ccode <= 'f'.charCodeAt(0)) {
        return ccode - 'a'.charCodeAt(0) + 10;
    } else if (ccode >= 'F'.charCodeAt(0) && ccode <= 'F'.charCodeAt(0)) {
        return ccode - 'A'.charCodeAt(0) + 10;
    } else {
        return 0;
    }
}