import StringBuffer from "../graphics/StringBuffer";
import CCookie from "./CCookie";
//import AsyncStorage from '@react-native-community/async-storage';

export default class CCookieRepository {
    constructor() {
        this._entries = {};
        this.ctx = null;
    }
    async reset() {
        this._entries = {};
        try {
            try {
                await AsyncStorage.setItem(CCookieRepository.COOKIE_STORAGE_NAME, '{}');
            } catch (ex) { }
        } catch (ex) { }
    }
    async reload() {
        this._entries = {};
        try {
            let sEntries = await AsyncStorage.getItem(CCookieRepository.COOKIE_STORAGE_NAME);
            if (sEntries == null) {
                sEntries = '{}';
            }
            let unParsed = JSON.parse(sEntries);
            for (const sKey in unParsed) {
                if (this._entries.hasOwnProperty(sKey)) {
                    this._entries[sKey] = new CCookie(this._entries[sKey]);
                }
            }

            for (const sKey in this._entries) {
                if (this._entries.hasOwnProperty(sKey)) {
                    let c = this._entries[sKey];
                    if (c.dtExpire === null) {
                        delete this._entries[c.name];
                    } else {
                        if (c.compareTo(new Date()) <= 0) {
                            delete this._entries[c.name];
                        }
                    }
                }
            }
        } catch (ex) {
        }
    }
    async flush() {
        try {
            await AsyncStorage.setItem(CCallLog.CALL_LOG_NAME, JSON.stringify(this._entries));
        } catch (ex) { }
    }
    removeModAuthState() {
        for (const sKey in this._entries) {
            if (this._entries.hasOwnProperty(sKey)) {
                if (sKey.startsWith('mod_auth_openidc_')) {
                    delete this._entries[sKey];
                }
            }
        }
    }
    itemCount() {
        return Object.keys(this._entries).length;
    }
    itemWithIdentifier(identifier) {
        return this._entries[identifier];
    }
    addItem(c) {
        this._entries[c.name] = c;
    }
    removeItem(c) {
        delete this._entries[c.name];
    }
    remoteItemWIthIdentifier(identifier) {
        delete this._entries[identifier];
    }
    asString() {
        let sb = new StringBuffer();
        for (const sKey in this._entries) {
            if (this._entries.hasOwnProperty(sKey)) {
                let c = this._entries[sKey];
                let bProcessed = false;
                if (c.dtExpire !== null) {
                    if (c.compareTo(new Date()) <= 0) {
                        delete this._entries[c.name];
                        bProcessed = true;
                    }
                }
                if (!bProcessed) {
                    if (sb.length() > 0) {
                        sb.append('; ');
                    }
                    sb.append(c.name);
                    sb.append('=');
                    sb.append(c.value === null ? '' : c.value);
                }
            }
        }
        return sb.toString();
    }
    asSetCookieString() {
        let sb = new StringBuffer();
        for (const sKey in this._entries) {
            if (this._entries.hasOwnProperty(sKey)) {
                let c = this._entries[sKey];
                let bProcessed = false;
                if (c.dtExpire !== null) {
                    if (c.dtExpire.compareTo(new Date()) <= 0) {
                        this._entries.remove(c.name);
                        bProcessed = true;
                    }
                }
                if (!bProcessed) {
                    if (sb.length() > 0) {
                        sb.append(';, ');
                    }
                    sb.append(c.name);
                    sb.append('=');
                    sb.append(c.value === null ? '' : c.value);
                    if (c.dtExpire !== null) {      // valid date?
                        if (Object.prototype.toString.call(c.dtExpire) === '[object Date]') {
                            sb.append('; Expires=');
                            sb.append(c.dtExpire.toUTCString());
                        }
                    }
                    if (c.extra !== null) {
                        sb.append('; ');
                        sb.append(c.extra);
                    }
                }
            }
        }
        return sb.toString();
    }
}
CCookieRepository.COOKIE_STORAGE_NAME = 'cookies.txt';
CCookieRepository.me = null;
CCookieRepository.sharedRepository = () => {
    if (CCookieRepository.me === null) {
        CCookieRepository.me = new CCookieRepository();
    }
    return CCookieRepository.me;
};
