import EConstants from "../edit/EConstants";
import DecorNode from "../graphics/DecorNode";
import ToolbarManager from "../view/ToolbarManager";

export default class BEditInfo {
    constructor() {
        this.channel = null;
        this.detail = null;
        this.noteSettings = null;
        this.template = null;
        this.headers = [];
        this.editProps = null,

            this.layoutNodes = [
                {
                    id: EConstants.Tree_Node_NavBar,
                    label: "Navigation Bar",
                    properties: [
                        {
                            name: 'Background',
                            type: EConstants.PropertyType_DecorBG,   // B-Decor-Title:
                            dnType: DecorNode.DECORNODE_TYPE_NAVBAR,
                            header: "B-Decor-Title",
                            remoteName: 'navbar-style',
                            original: null,
                            modified: null,
                            modifiedSet: false,
                            enableFG: {label: "Back Button Color", default: "#ffffffff", dnHasFx: "hasBackFG", dnGetFx: "getBackFG", wrap: "back"},
                            setProp: (sprop) => {
                                // Save for our use...
                                this.updateModified(EConstants.Tree_Node_NavBar, 0, sprop);
                                global.globalVars.appClass.setDecorNodeTitleString(sprop);
                            }
                        }
                    ],
                    advanced: [

                    ],
                    children: [
                        {
                            id: EConstants.Tree_Node_NavBar_BackButton,
                            label: "Back Button",
                            properties: [
                                {
                                    name: 'Back Button',
                                    type: EConstants.PropertyType_Text,
                                    header: "B-Title",
                                    remoteName: 'search-title',
                                    original: null,
                                    modified: null,
                                    modifiedSet: false,
                                    txtProps: {hasDefault: true, default: 'Back'},
                                    setProp: (sprop) => {
                                        // Save for our use...
                                        let sBCombo = this.updateBTitle(EConstants.Tree_Node_NavBar_BackButton, 0, sprop);
                                        global.globalVars.appClass.setBTitle(sBCombo);
                                    }
                                }
                            ],
                            advanced: [],
                        },
                        {
                            id: EConstants.Tree_Node_NavBar_SearchTitle,
                            label: "Search/Title",
                            properties: [
                                {
                                    name: 'Search/Title',
                                    type: EConstants.PropertyType_Text,
                                    header: "B-Title",
                                    remoteName: 'search-title',
                                    original: null,
                                    modified: null,
                                    modifiedSet: false,
                                    txtProps: {hasDefault: true, default: 'type here'},
                                    setProp: (sprop) => {
                                        // Save for our use...
                                        let sBCombo = this.updateBTitle(EConstants.Tree_Node_NavBar_SearchTitle, 1, sprop);
                                        global.globalVars.appClass.setBTitle(sBCombo);
                                    }
                                }
                            ],
                            advanced: [],
                        },
                        {
                            id: EConstants.Tree_Node_NavBar_RightMenu,
                            label: "Right Menu",
                            properties: [],
                            advanced: [],
                        }
                    ]
                },
                {
                    id: EConstants.Tree_Node_Screen,
                    label: "Screen",
                    properties: [
                        {
                            name: 'Background',
                            type: EConstants.PropertyType_DecorBG,   // B-Decor-Title:
                            dnType: DecorNode.DECORNODE_TYPE_TABLE,
                            header: "B-Decor-Screen",
                            remoteName: 'screen-style',
                            original: null,
                            modified: null,
                            modifiedSet: false,
                            setProp: (sprop) => {
                                // Save for our use...
                                this.updateModified(EConstants.Tree_Node_Screen, 0, sprop);
                                global.globalVars.appClass.setDecorNodeScreenString(sprop);
                            }
                        }
                    ],
                    advanced: [

                    ],

                    children: [
                        {
                            id: EConstants.Tree_Node_Screen_ListItem,
                            label: "List Item",
                            properties: [
                                {
                                    name: 'Background',
                                    type: EConstants.PropertyType_DecorBG,   // B-Decor-Title:
                                    dnType: DecorNode.DECORNODE_TYPE_CELL,
                                    header: "B-Decor-Item",
                                    remoteName: 'item-style',
                                    original: null,
                                    modified: null,
                                    modifiedSet: false,
                                    enableFG: {label: "Foreground", default: "#ff000000", dnHasFx: "hasFGColor", dnGetFx: "getFGColor"},
                                    setProp: (sprop) => {
                                        // Save for our use...
                                        this.updateModified(EConstants.Tree_Node_Screen_ListItem, 0, sprop);
                                        global.globalVars.appClass.setDecorNodeItemString(sprop);
                                    }
                                }
                            ],
                            advanced: [
        
                            ]
                                }
                    ]
                },
                {
                    id: EConstants.Tree_Node_Toolbar,
                    label: "Toolbar",
                    properties: [
                        {
                            name: 'Background',
                            type: EConstants.PropertyType_DecorBG,   // B-Decor-Title:
                            dnType: DecorNode.DECORNODE_TYPE_TOOLBAR,
                            header: "B-Toolbar",
                            remoteName: 'toolbar-style',
                            original: null,
                            modified: null,
                            modifiedSet: false,
                            enableFG: {label: "Foreground", default: "#ff000000", dnHasFx: "hasFGColor", dnGetFx: "getFGColor"},
                            setProp: (sprop) => {
                                // Save for our use...
                                let svCombined = this.combineModified(EConstants.Tree_Node_Toolbar, [0, 1], sprop);
                                global.globalVars.appClass.setDecorNodeToolbarString(svCombined);
                            }
                        },
                        {
                            name: 'Button Type',
                            type: EConstants.PropertyType_Toolbar,   // B-Decor-Title:
                            header: "B-Toolbar",
                            remoteName: 'toolbar-style',
                            original: null,
                            modified: null,
                            modifiedSet: false,
                            setProp: (sprop) => {
                                // Save for our use...
                                let svCombined = this.combineModified(EConstants.Tree_Node_Toolbar, [1, 0], sprop);
                                global.globalVars.appClass.setDecorNodeToolbarString(svCombined);
                            }
                        }

                    ],
                    advanced: [

                    ],
                    children: []
                }
            ];

            this.tnodes = this.layoutNodes;

    }

    updateModified = (id, index, sval) => {
        let myItem = this.findMyItem(id);
            myItem.properties[index].modified = sval;
            myItem.properties[index].modifiedSet = true;
    }

    // 1st one is modified, subsequent are ones to combine with it
    combineModified = (id, arIx, sval) => {
        let myItem = this.findMyItem(id);
        myItem.properties[arIx[0]].modified = sval;
        myItem.properties[arIx[0]].modifiedSet = true;

        let svCombined = sval;
        for (let i=1; i<arIx.length; i++) {
            if (myItem.properties[arIx[i]].modified !== null && myItem.properties[arIx[i]].modified.length > 0) {
                if (typeof svCombined === 'undefined' || svCombined == null) {
                    svCombined = '';
                }
                if (svCombined.length > 0) {
                    svCombined = svCombined + ' ';
                }
                svCombined = svCombined + myItem.properties[arIx[i]].modified;
            }
        }
        return svCombined;
    }
    // formulate:  B-Title: <v0>; <v1> [; Find]
    updateBTitle = (id, ixSplit, sval) => {
        let myItem = this.findMyItem(id);
        let myBackItem, myTextItem;
        if (id == EConstants.Tree_Node_NavBar_BackButton) {
            myBackItem = myItem;
            myTextItem = this.findMyItem(EConstants.Tree_Node_NavBar_SearchTitle);
        } else {    // SearchTitle
            myTextItem = myItem;
            myBackItem = this.findMyItem(EConstants.Tree_Node_NavBar_BackButton);
        }

        myItem.properties[0].modified = sval;
        myItem.properties[0].modifiedSet = true;

        let hasFind = this.getEditDetail() == null;
        let sBack = 'Back';
        let sTitle = 'type here';

        if (ixSplit == 0) {
            if (typeof sval === 'undefined' || sval == null || sval.length == 0) {
                sBack = 'Back';
            } else {
                sBack = sval;
            }

            sTitle = myTextItem.properties[0].modified;
            if (sTitle === null) {
                sTitle = myTextItem.properties[0].txtProps.default;
            }
        } else if (ixSplit == 1) {
            if (typeof sval === 'undefined' || sval == null) {
                sTitle = 'type here';
            } else {
                sTitle = sval;
            }
            sBack = myBackItem.properties[0].modified;
            if (sBack === null) {
                sBack = myBackItem.properties[0].txtProps.default;
            }

        }

        return sBack + '; ' + sTitle + (hasFind ? '; Find' : '');
    }

    getTreeNodes() {
        return this.tnodes;
    }

    setEditChannel = (s) => {
        this.channel = s;
    }
    setEditDetail = (s) => {
        this.detail = s;

    }
    getEditChannel = () => {
        return this.channel;
    }
    getEditDetail = () => {
        return this.detail;
    }

    /*
    e.g. for https://app.box.com/file/893690607644 e.g. Company Information Sheet - Demo.xlsx
    See Spreadsheet Notes Keyword Reference: https://docs.google.com/document/d/10hYZhtiDWfULxsQvQNMccxuqwu-FjxUNm5GssWiGyeo/edit#heading=h.1i05du6m1jf

    noteSettings : {
      screen-style : "bg(c(#0aa))"
      headerLength : 26,
      oLockId : "45e1c812d86a3609fc0249f2b92dc5165318f0f6302f22f4836505edcbcfcf85",
      columns : {
         Name : {
            comments : [
               {
                  "authorDisplayName" : "Greg Carpenter",
                  "dateCreated" : "2021-12-27T23:06:00.740Z",
                  "authorId" : "{EC9A29BF-48F2-4990-9467-FB9974D088EC}",
                  "authorUserId" : "",
                  "id" : "{452439F4-911F-4B0D-81F3-060F57B63DDD}",
                  "text" : "**t-settings-start**\nscreen-style: bg(c(#0aa))\n**t-settings-end**",
                  "parentId" : ""
               }
            ],
            index : 1,
            note : "This comment reflects a threaded comment in this cell, a feature that might be supported by newer versions of your spreadsheet program (for example later versions of Excel). Any edits will be overwritten if opened in a spreadsheet program that supports threaded comments.\n\nComment:\n**t-settings-start**\nscreen-style: bg(c(#0aa))\n**t-settings-end**\n"
         }
      },
   }
   template : {
       layout: "{NAME}|TITLE: {TITLE} |{ADDRESS} {CITY} {STATE} {ZIP}\t\t{lat_lon}\t{Cell}\t{Email}\t\t{Photo}\t{COMPANY_INFO_EMP_ID}\t\t\t\t\t\t\t\t{ADDRESS} {CITY} {STATE} {ZIP}\t\t\t\t{Ext}\t{Home}\t{Fax}\t{Cell}\t{Title}\t{Email}\t{Address}\t{City}\t{State}\t{Zip}\t{Company_Info_Emp_ID}\t{Name}\t{Ext}\t{Home}\t{Fax}\t{Title}\t{Address}\t{City}\t{State}\t{Zip}"
       detailLanding: "\t\t\t\t\t\t\t\t{PHOTO}\n{NAME}\t\t\t\t\t\t\t\t\tNAME:\n{TITLE}\t\t\t\t\t\t\t\t\tTITLE: \n{ADDRESS} {CITY} {STATE} {ZIP}\t\t\t\t\t\t\t\t\tAddress:\t\t\t\t\t\t{ADDRESS} {CITY} {STATE} {ZIP}\n{lat_lon}\t\t{lat_lon}\t\t\t\t\t\t\tLocation:\n{EXT}\t\t\t\t\t\t\t\t\tEXT.:\n{CELL}\t\t\t{CELL}\t\t\tr:/img/call_pg_multi.png\t\t\tCELL: \n{HOME}\t\t\t{HOME}\t\t\tr:/img/call_pg_multi.png\t\t\tHOME: \n{FAX}\t\t\t{FAX}\t\t\tr:/img/call_pg_multi.png\t\t\tFAX:\n{EMAIL}\t\t\t\t{EMAIL}\t\tr:/img/mail_pg_multi.png\t\t\tEMAIL:\n\n{Company_Info_Emp_ID}\t\t\t\t\t\t\t\t\tCompany_Info_Emp_ID:\t\t\t\t\t\t"
   }
    */
    setNoteSettings = (s, t, hdrs) => {
        this.noteSettings = s;
        this.template = t;
        this.headers = hdrs;
        // Override headers with noteSettings (this should have been updated by the system arleady...)
        Object.keys(s).forEach(key => {
            let lc = key.toLowerCase();
            let hdrMatch = this.headers.find( item => item.name == lc);
            if (typeof hdrMatch !== 'undefined') {
                hdrMatch.value = s[key];
            } else {
                this.headers.push({name: lc, value: s[key]});
            }
        })

        // Get rowhints
        let sRowHintsItem = hdrs.find( item => item.name.equals('b-row-hints'));
        if (sRowHintsItem != null) {
            global.globalVars.appClass.setSRowHints(sRowHintsItem.value);
        }
        // Get toolbar items
        let tbis = global.globalVars.CMenu().getTBItems();
        let arText = [];
        tbis.map((item, index) => {
            arText.push(item.getLabel());
        })
        global.globalVars.appClass.setToolbarText(arText);

        // Enable tree now...
        global.globalVars.appClass.setTreeViewDisabled(false);

    }
    findMyItem = (nodeId) => {
        let myItem = this.tnodes.find( itm => itm.id == nodeId);
        if (typeof myItem === 'undefined') {
            for (let i=0; i<this.tnodes.length; i++) {
                if (this.tnodes[i].hasOwnProperty('children')) {
                    myItem = this.tnodes[i].children.find( itm => itm.id == nodeId);
                    if (typeof myItem !== 'undefined') {
                        return myItem;
                    }
                }
            }
            return undefined;
        } else {
            return myItem;
        }
    }

    // UI
    treeNodeSelected(nodeIds) {
        // compare to 
        let myItem = null;
        let hdr = null;
        let myVal;
        switch (nodeIds) {
            case EConstants.Tree_Node_NavBar:
                myItem = this.findMyItem(nodeIds);
                hdr = this.headers.find( item => item.name.toLowerCase().equals(myItem.properties[0].header.toLowerCase()));
                myItem.properties[0].original = typeof hdr === 'undefined' ? null : hdr.value;
                if (!myItem.properties[0].modifiedSet)
                    myItem.properties[0].modified = myItem.properties[0].original;
                this.editProps = myItem;

                global.globalVars.appClass.setEditId(nodeIds);
                break;
            case EConstants.Tree_Node_NavBar_BackButton:
                myItem = this.findMyItem(nodeIds);
                hdr = this.headers.find( item => item.name.toLowerCase().equals(myItem.properties[0].header.toLowerCase()));
                myVal = null;
                if (typeof hdr !== 'undefined') {   // no B-Title: 
                    let arSplits = hdr.value.split(';');
                    if (arSplits.length > 0) {
                        myVal = arSplits[0].trim();
                    }
                    if (arSplits.length > 1) {
                        //Note: We set both so we can check it on change (unusual case since B-Title: has componenet info)
                        let myTextItem = this.findMyItem(EConstants.Tree_Node_NavBar_SearchTitle);
                        let myTVal = arSplits[1].trim();
                        myTextItem.properties[0].original = myTVal;
                        if (!myTextItem.properties[0].modifiedSet)
                            myTextItem.properties[0].modified = myTextItem.properties[0].original;
        
                    }
                }

                myItem.properties[0].original = myVal;
                if (!myItem.properties[0].modifiedSet)
                    myItem.properties[0].modified = myItem.properties[0].original;
                this.editProps = myItem;

                global.globalVars.appClass.setEditId(nodeIds);
                break;
            case EConstants.Tree_Node_NavBar_SearchTitle:
                myItem = this.findMyItem(nodeIds);
                hdr = this.headers.find( item => item.name.toLowerCase().equals(myItem.properties[0].header.toLowerCase()));
                myVal = null;
                if (typeof hdr !== 'undefined') {   // no B-Title: 
                    let arSplits = hdr.value.split(';');
                    if (arSplits.length > 0) {
                        //Note: We set both so we can check it on change
                        let myBackItem = this.findMyItem(EConstants.Tree_Node_NavBar_BackButton);
                        let myTVal = arSplits[0].trim();
                        myBackItem.properties[0].original = myTVal;
                        if (!myBackItem.properties[0].modifiedSet)
                            myBackItem.properties[0].modified = myBackItem.properties[0].original;
                    }
                    if (arSplits.length > 1) {
                        myVal = arSplits[1].trim();
                    }
                }

                myItem.properties[0].original = myVal;
                if (!myItem.properties[0].modifiedSet)
                    myItem.properties[0].modified = myItem.properties[0].original;
                this.editProps = myItem;

                global.globalVars.appClass.setEditId(nodeIds);
                break;
            case EConstants.Tree_Node_NavBar_RightMenu:
                break;
            case EConstants.Tree_Node_Screen:
                myItem = this.findMyItem(nodeIds);
                // Show navbar properties - DecorBG might be only one.
                hdr = this.headers.find( item => item.name.toLowerCase().equals(myItem.properties[0].header.toLowerCase()));
                myItem.properties[0].original = typeof hdr === 'undefined' ? null : hdr.value;
                if (!myItem.properties[0].modifiedSet)
                    myItem.properties[0].modified = myItem.properties[0].original;
                this.editProps = myItem;

                global.globalVars.appClass.setEditId(nodeIds);
                break;
            case EConstants.Tree_Node_Screen_ListItem:
                myItem = this.findMyItem(nodeIds);
                // Show navbar properties - DecorBG might be only one.
                hdr = this.headers.find( item => item.name.toLowerCase().equals(myItem.properties[0].header.toLowerCase()));
                myItem.properties[0].original = typeof hdr === 'undefined' ? null : hdr.value;
                if (!myItem.properties[0].modifiedSet)
                    myItem.properties[0].modified = myItem.properties[0].original;
                this.editProps = myItem;

                global.globalVars.appClass.setEditId(nodeIds);

                break;
            case EConstants.Tree_Node_Toolbar:
                myItem = this.findMyItem(nodeIds);

                // Show navbar properties - bg(...) fg(...) type(combo|image|label)
                hdr = this.headers.find( item => item.name.toLowerCase().equals(myItem.properties[0].header.toLowerCase()));
                let hdrValue = null;
                let dNode = null;
                if (typeof hdr !== 'undefined') {
                    hdrValue = hdr.value;
                    dNode = DecorNode.FromAttributes(hdrValue, 0);
                }
                // separate all from type(combo|image|label)  the type goes into [1], the rest go in [0]

                //strip off type(...) fit(height(true)) before setting original
                hdrValue = hdrValue.replace(/type\(([^\)]+)\)/, '').replace(/fit\(height\([^\)]+\)\)/, '');
                myItem.properties[0].original = hdrValue;
                if (!myItem.properties[0].modifiedSet) {
                    myItem.properties[0].modified = myItem.properties[0].original;
                }

                hdrValue = "";
                if (dNode !== null) {
                    let pn = dNode.findChildNode(DecorNode.C_lowercase_type);
                    if (pn !== null) {
                      pn = pn.firstChild();
                      if (pn !== null && pn.TID() !== null) {
                        let satt = pn.TID().toLowerCase();
                        if (satt.equals(DecorNode.C_lowercase_label)) {
                          hdrValue = "type(" + DecorNode.C_lowercase_label + ")";
                        } else {
                          if (satt.equals(DecorNode.C_lowercase_image)) {
                            hdrValue = "type(" + DecorNode.C_lowercase_image + ")";
                          } else {
                            if (satt.equals(DecorNode.C_lowercase_combo)) {
                                hdrValue = "type(" + DecorNode.C_lowercase_combo + ")";
                            }
                          }
                        }
                      }
                    }
                }
                if (hdrValue.length > 0) {
                    hdrValue = hdrValue + " ";
                }
                hdrValue = hdrValue + 'fit(height(true))';

                myItem.properties[1].original = hdrValue;
                if (!myItem.properties[1].modifiedSet)
                    myItem.properties[1].modified = myItem.properties[1].original;


                this.editProps = myItem;

                global.globalVars.appClass.setEditId(nodeIds);
                break;
            default:
                if (nodeIds.startsWith(EConstants.Tree_Node_Toolbar)) {
                    // Child of toolbar...
                }
                break;
        }
    }


    isSpecialChild = (item) => {
        if (item.id == EConstants.Tree_Node_NavBar_BackButton ||
            item.id == EConstants.Tree_Node_NavBar_SearchTitle) {
            return true;
        } else {
            return false;
        }
    }
    saveSpecialChild = (children, j, noteSettings) => {
        if (children[j].id == EConstants.Tree_Node_NavBar_BackButton) {
            // Aggregate BackButton and SearchTitle into B-Title, then noteSettings['search-title'] = Back; <text> [;Find]
            let backItem = children[j];
            let searchTitleItem = children.find( itm => itm.id == EConstants.Tree_Node_NavBar_SearchTitle);
            if (backItem.properties[0].modifiedSet || searchTitleItem.properties[0].modifiedSet) {
                noteSettings[backItem.properties[0].remoteName] = backItem.properties[0].modified + '; ' +
                    searchTitleItem.properties[0].modified + ((this.getEditDetail() == null) ? '; Find' : '');
            }
        }
    }

    // Save any edits back to the server...
    save = () => {
        global.globalVars.appClass.showMessage("Saving edits...");

        let noteSettings = {};
        var myItem;
        for (let i = 0; i < this.tnodes.length; i++) {
            myItem = this.tnodes[i];
            let visited = {}        // This logic combines same property across a single properties array, so if props are not in same tree node, this won't work..
            for (let k = 0; k < myItem.properties.length; k++) {
                if (!visited.hasOwnProperty(myItem.properties[k].remoteName)) {
                    let modifiedSet = myItem.properties[k].modifiedSet;
                    let myName = myItem.properties[k].remoteName;
                    let aggValue = myItem.properties[k].modified;
                    for (let j=k+1; j<myItem.properties.length; j++) {
                        if (myName.equals(myItem.properties[j].remoteName)) {
                            // combine
                            if (aggValue === null) {
                                if (myItem.properties[j].modified !== null) {
                                    aggValue = myItem.properties[j].modified;
                                }
                            } else {
                                if (myItem.properties[j].modified !== null) {
                                    aggValue = aggValue + ' ' + myItem.properties[j].modified;
                                }
                            }
                            // if t, set modifiedSet to true
                            if (myItem.properties[j].modifiedSet) {
                                modifiedSet = true;
                            }
                        }
                    }
                    visited[myName] = aggValue;
                    if (modifiedSet) {
                        noteSettings[myName] = aggValue;
                    }
                }
            }
            //TODO: advanced...

            if (this.tnodes[i].hasOwnProperty('children')) {
                for (let j = 0; j < this.tnodes[i].children.length; j++) {
                    myItem = this.tnodes[i].children[j];
                    if (!this.isSpecialChild(myItem)) {
                        for (let k = 0; k < myItem.properties.length; k++) {
                            if (myItem.properties[k].modifiedSet) {
                                noteSettings[myItem.properties[k].remoteName] = myItem.properties[k].modified;
                            }
                        }
                    } else {
                        this.saveSpecialChild(this.tnodes[i].children, j, noteSettings);
                    }
                    //TODO: advanced...
                }
            }
        }


        let sUrl = global.globalVars.getPostNotesUrl();
        let sdata = {
            channel: this.getEditChannel(),
            uid: global.globalVars.appClass.getGUID(),
            "B-PIN": global.globalVars.getBPIN(),
            notesettings: noteSettings
        }
        if (this.getEditDetail() !== null) {
            sdata.detail = this.getEditDetail();
        }
        global.globalVars.getEditInfo().post(sUrl, JSON.stringify(sdata), 
            function(xhr) {
                global.globalVars.appClass.showMessage("Edits saved!");
            }, function (err) {
                global.globalVars.appClass.showErrorMessage("Error saving edits: " + err.toString());
                console.log(err);

        })

    }

    post = (url, spdata, callback, err) => {
        var xhr;
        if(typeof XMLHttpRequest !== 'undefined') xhr = new XMLHttpRequest();
        else {
            var versions = ["MSXML2.XmlHttp.5.0", 
                             "MSXML2.XmlHttp.4.0",
                             "MSXML2.XmlHttp.3.0", 
                             "MSXML2.XmlHttp.2.0",
                             "Microsoft.XmlHttp"]
    
             for(var i = 0, len = versions.length; i < len; i++) {
                 try {
                     xhr = new ActiveXObject(versions[i]);
                     break;
                 }
                 catch(e){}
             } // end for
        }
        
        xhr.onreadystatechange = ensureReadiness;
    
    //    var headerMap = {};
    
        function ensureReadiness() {
          if (xhr.readyState == XMLHttpRequest.HEADERS_RECEIVED) {
              /*
            var headers = xhr.getAllResponseHeaders();
    
            var arr = headers.trim().split(/[\r\n]+/);
        
            // Create a map of header names to values
            arr.forEach(function (line) {
              var parts = line.split(': ');
              var header = parts.shift();
              var value = parts.join(': ');
              headerMap[header] = value;
            });
            */
            return;
          }
    
            if(xhr.readyState < 4) {
                return;
            }
            
            if(xhr.status !== 200) {
                err(xhr.status);
                return;
            }
    
            // all is well	
            if(xhr.readyState === 4) {
                callback(xhr /*, headerMap*/);
            }			
        }
        
//        xhr.responseType = 'arraybuffer';
        xhr.open('POST', url, true);

        xhr.setRequestHeader("Content-Type", "application/json");     
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

        xhr.send(spdata);
    }    
}

