import MofiHandler from "./MofiHandler";
import BBUtils from "./BBUtils";

export default class BPSocketVariables {
    constructor() {
      this._BSID = null;
      this._headers = [];
      this._ListMode = MofiHandler.ListModeNone;
      this._BSID = '';
      this._bFixedFont = false;
      this._bSkipEmptyLinks = false;
      this.skip_history = false;
      this._bSubmit = false;
      this._searchCenter = BBUtils.C_EMPTY_STRING;
      this._searchCenterLabel = BBUtils.C_EMPTY_STRING;
      this._imgUrlRightButton = null;
      this.rbType = 0;
      this._ixSuppressAccessoryColumn = -1;
      this._ixNoHighlightAccessoryColumn = -2;
      this._rightButtonType = MofiHandler.B_RIGHTBUTTON_REGULAR;
      this._stingd = '';
    }
  }
  