export default class BStatus {
  constructor(bstart) {
    this._result = false;

    this._result = bstart;
  }
  setStatus(bres) {
    this._result = bres;
  }
  getStatus() {
    return this._result;
  }
}
