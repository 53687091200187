import BPImage from "./BPImage";

// implements Serializable
export default class MenuSection {
  constructor$0() {
    ;
  }
  constructor$2(sText, ix) {
    this._text = sText;
    this._ix = ix;
  }
  constructor(...args$) {
    this._drawableUrl = null;
    switch (args$.length) {
      case 0:
        return this.constructor$0(...args$);
      case 2:
        return this.constructor$2(...args$);
    }
  }
  setIcon(sUrl) {
    this._drawableUrl = sUrl;
  }
  getIndex() {
    return this._ix;
  }
  getText() {
    return this._text;
  }
  getDrawable(ctx) {
    if (this._drawableUrl !== null) {
      return(
        <BPImage
          width={null}
          height={null}
          uri={this._drawableUrl}
        />
      )
    } else {
      return null;
    }
  }
}
MenuSection.serialVersionUID = -7667234719417351241;
