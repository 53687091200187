export default class BPSocketConst {
    constructor() {

    }
}
BPSocketConst.C_HOST = 'Host';
//BPSocketConst.C_CRLF = '\r\n';
BPSocketConst.C_REFRESH_EQUAL = 'refresh=';
BPSocketConst.C_MI = 'mi';
BPSocketConst.C_KM = 'km';
BPSocketConst.C_LIST = 'List';
BPSocketConst.C_ON = 'On';
BPSocketConst.C_B_MENU_ENTRY = 'b-menu-entry-';
BPSocketConst.C_CLICK = 'Click';
BPSocketConst.C_LOCATION = 'Location';
BPSocketConst.C_B_LOCATION = 'B-Location';
BPSocketConst.C_BXLOCATION = 'B-XLocation';
BPSocketConst.C_ROW_HINTS = 'B-Row-Hints';
BPSocketConst.C_B_COOKIE = 'B-Cookie';
BPSocketConst.C_B_COOKIE_COLON = 'B-Cookie: ';
BPSocketConst.C_B_TITLE = 'B-Title';
BPSocketConst.C_FIND = 'find';
BPSocketConst.C_B_MISCELLANEOUS = 'B-Miscellaneous';
BPSocketConst.C_B_DECOR_SEPARATOR = 'B-Decor-Separator';
BPSocketConst.C_TOOLBAR = 'B-Toolbar';
BPSocketConst.C_B_ACCESSORY = 'B-Accessory';
BPSocketConst.C_GUID = 'GUID';
BPSocketConst.C_UA_DASH_OS = 'UA-OS';
BPSocketConst.C_UA_DASH_PIXELS = 'UA-pixels';
BPSocketConst.C_GEO_DOT_POSITION = 'geo.position';
BPSocketConst.C_BPIN = "B-PIN";
BPSocketConst.C_SET_COOKIE = "Set-Cookie";
BPSocketConst.C_B_STINGD = 'B-stingd';

//BPSocketConst.C_COOKIE_COLON = 'Cookie: ';
//BPSocketConst.C_BLOCATION = 'B-Location';
