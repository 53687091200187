import Character from "./Character";
import { colord } from "colord";

export default class BPColor {
  constructor$1(iColor) {
    this._b = iColor & 0x000000f;
    this._g = (iColor >> 8) & 0x000000f;
    this._r = (iColor >> 16) & 0x000000f;
    this.computeHSV();
  }
  constructor$3(r, g, b) {
    this._r = r;
    this._g = g;
    this._b = b;
    this.computeHSV();
  }
  constructor(...args$) {
    switch (args$.length) {
      case 1:
        return this.constructor$1(...args$);
      case 3:
        return this.constructor$3(...args$);
    }
  }
  getColor() {
    return (this._r << 16 | this._g << 8 | this._b);
  }
  setHSV(h, s, v) {
    this._h = h;
    this._s = s;
    this._v = v;
    this.computeRGB();
  }
  computeRGB() {
    let conv = 0;
    let sat = 0;
    let val = 0;
    let base = 0;
    sat = this._s / 100.0;
    val = this._v / 100.0;
    if (this._s === 0) {
      conv = (255.0 * val);
      this._r = this._b = this._g = conv;
      return undefined;
    }
    base = (255.0 * (1.0 - sat) * val);
    switch ((this._h / 60.0)) {
      case 0:
        this._r = (255.0 * val);
        this._g = ((255.0 * val - base) * (this._h / 60.0) + base);
        this._b = base;
        break;
      case 1:
        this._r = ((255.0 * val - base) * (1.0 - ((this._h % 60) / 60.0)) + base);
        this._g = (255.0 * val);
        this._b = base;
        break;
      case 2:
        this._r = base;
        this._g = (255.0 * val);
        this._b = ((255.0 * val - base) * ((this._h % 60) / 60.0) + base);
        break;
      case 3:
        this._r = base;
        this._g = ((255.0 * val - base) * (1.0 - ((this._h % 60) / 60.0)) + base);
        this._b = (255.0 * val);
        break;
      case 4:
        this._r = ((255.0 * val - base) * ((this._h % 60) / 60.0) + base);
        this._g = base;
        this._b = (255.0 * val);
        break;
      case 5:
        this._r = (255.0 * val);
        this._g = base;
        this._b = ((255.0 * val - base) * (1.0 - ((this._h % 60) / 60.0)) + base);
        break;
    }
  }
  computeHSV() {
    let temp = 0;
    let max = 0;
    let min = 0;
    let delta = 0;
    if (this._r > this._g) {
      max = this._r;
    } else {
      max = this._g;
    }
    if (this._b > max) {
      max = this._b;
    }
    if (this._r < this._g) {
      min = this._r;
    } else {
      min = this._g;
    }
    if (this._b < min) {
      min = this._b;
    }
    delta = max - min;
    if (max === 0) {
      this._s = this._h = this._v = 0;
      return undefined;
    }
    this._v = (max / 255.0 * 100.0);
    this._s = ((delta / max) * 100.0);
    if (this._r === max) {
      temp = (60 * ((this._g - this._b) / delta));
    } else {
      if (this._g === max) {
        temp = (60 * (2.0 + (this._b - this._r) / delta));
      } else {
        temp = (60 * (4.0 + (this._r - this._g) / delta));
      }
    }
    if (temp < 0) {
      this._h = temp + 360;
    } else {
      this._h = temp;
    }
  }
}
BPColor.decimalToHex = (d, padding) => {
  var hex = Number(d).toString(16);
  padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

  while (hex.length < padding) {
      hex = "0" + hex;
  }

  return hex;
}
// Helper to convert int (AARRGGBB) to ARGB to #RRGGBBAA
BPColor.intToHexRGBA = (ival) => {
  return '#' + BPColor.decimalToHex((ival & 0x0ffffff) >>> 0, 6) + BPColor.decimalToHex(((ival >>> 24) & 0x0ff), 2);
};
BPColor.intToHexRGBOnlyColor = (ival) => {
  return BPColor.decimalToHex((ival & 0x0ffffff) >>> 0, 6);
};
BPColor.intToHexRGB = (ival) => {
  return '#' + BPColor.intToHexRGBOnlyColor(ival);
};
BPColor.colorToInt = (cval) => {
  if (typeof cval === 'number') {
    return cval;
  } else if (typeof cval === 'string') {
    // #rgb, #argb, #rrggbb, #aarrggbb
    if (cval.length < 4 || !cval.charAt(0).equals('#')) {
      return 0x0ff000000;
    }

    let okloop = true;
    let ix = 1;
    while (ix < cval.length && okloop && i < 8) {
      if (!Character.isHex(cval.charAt(ix))) {
        okloop = false;
      } else {
        ix++;
        i++
      }
    }
    let r, g, b, a;
    if (i == 3) { // #rgb
      r = (Character.cvtHex(cval.charAt(1)) * 255) / 15;
      g = (Character.cvtHex(cval.charAt(2)) * 255) / 15;
      b = (Character.cvtHex(cval.charAt(3)) * 255) / 15;

      return (0x0ff000000 | (r << 16) | (g << 8) | b) >>> 0;

    } else if (i == 4) {
      a = (Character.cvtHex(cval.charAt(1)) * 255) / 15;
      r = (Character.cvtHex(cval.charAt(2)) * 255) / 15;
      g = (Character.cvtHex(cval.charAt(3)) * 255) / 15;
      b = (Character.cvtHex(cval.charAt(4)) * 255) / 15;

      return ((a << 24) | (r << 16) | (g << 8) | b) >>> 0;

    } else if (i == 6) {
      r = (Character.cvtHex(cval.charAt(1)) * 16);
      r = (Character.cvtHex(cval.charAt(2)));
      g = (Character.cvtHex(cval.charAt(3)) * 16);
      g = (Character.cvtHex(cval.charAt(4)));
      b = (Character.cvtHex(cval.charAt(5)) * 16);
      b = (Character.cvtHex(cval.charAt(6)));

      return (0x0ff000000 | (r << 16) | (g << 8) | b) >>> 0;

    } else if (i == 8) {
      a = (Character.cvtHex(cval.charAt(1)) * 16);
      a = (Character.cvtHex(cval.charAt(2)));
      r = (Character.cvtHex(cval.charAt(3)) * 16);
      r = (Character.cvtHex(cval.charAt(4)));
      g = (Character.cvtHex(cval.charAt(5)) * 16);
      g = (Character.cvtHex(cval.charAt(6)));
      b = (Character.cvtHex(cval.charAt(7)) * 16);
      b = (Character.cvtHex(cval.charAt(8)));

      return ((a << 24) | (r << 16) | (g << 8) | b) >>> 0;
 
    } else {
      // error
      return 0x0ff000000;
    }
  }
}
// return background and backgroundImage
BPColor.getCSSGradient = (gradient) => {
  // gradient.degrees
  //TODO: gradient.alpha
  // gradient.palette[ {offset: <0=100>, color: <rgbvalue>}]
  let retVal = {
    background: null,
    backgroundImage: null
  }

  let gProps = "linear-gradient(" + gradient.degrees + "deg";
  let giProps = gProps;
  for (let i=0; i<gradient.palette.length; i++) {
      let pv = gradient.palette[i];
      gProps = gProps + ", " + colord(pv.color).toHex() + ' ' + pv.offset + "%";
      giProps = giProps + ", " + colord(pv.color).toRgbString() + ' ' + pv.offset + "%";
  }
  gProps = gProps + ')';
  giProps = giProps + ')';

  retVal.background = gProps;
  retVal.backgroundImage = giProps;

  return retVal;
}