export default class CmdParams {
    construtor() {

    }
}

CmdParams.isArray = (ar) => {
    if (typeof ar !== 'undefined' && ar !== null) {
        return Object.prototype.toString.call(ar) === '[object Array]';
    } else {
        return false;
    }
}
CmdParams.isString = (s) => {
    if (s !== null && typeof s === 'string') {
        return true;
    } else {
        return false;
    }
}
CmdParams.isNumber = (d) => {
    if (d !== null && typeof d === 'number') {
        return true;
    } else {
        return false;
    }
}
CmdParams.isObject = (o) => {
    if (o !== null && typeof o === 'object') {
        return true;
    } else {
        return false;
    }
}

CmdParams.CMDP_START_CLIENT_PROGRESS = "startClientProgress";

CmdParams.CMDP_STOP_CLIENT_PROGRESS = "stopClientProgress";

CmdParams.CMDP_GET_BACK_HISTORY = "getBackHistory";

CmdParams.CMDP_SET_COOKIE = "setCookie";
    CmdParams.CMDP_SET_COOKIE_p_ARCOOKIE = "arcookie";

CmdParams.CMDP_LOCATION = "Location";
    CmdParams.CMDP_LOCATION_p_LOCATION = "l";

CmdParams.CMDP_INCREMENTAL_SEARCH = "incrementalSearch";    // NOTE: thie xxx_p_xxx cannot duplicate under a command
    CmdParams.CMDP_INCREMENTAL_SEARCH_p_ARBMENU = "arbm";
    CmdParams.CMDP_INCREMENTAL_SEARCH_p_CONTENT = "cont";
    CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_ROW_HINTS = "brh";
    CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_FONT = "bfnt";
    CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_DECOR_ITEM = "bdi";
    CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_DECOR_SCREEN = "bds";
    CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_DECOR_TITLE = "bdt";
    CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_MISCELLANEOUS = "msc";
    CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_DECOR_SEPARATOR = "bdp";
    CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_TOOLBAR = "btb";

CmdParams.CMDP_SET_EXTRAUI = "setExtraUI";
    CmdParams.CMDP_SET_EXTRAUI_p_DICT = "dict";

CmdParams.CMDP_REFRESH_TIMER = "refreshTimer";
    CmdParams.CMDP_REFRESH_TIMER_p_TIME = "tm";

CmdParams.CMDP_ADD_TITLE_HISTORY = "addTitleHistory";
    CmdParams.CMDP_ADD_TITLE_HISTORY_p_BACK_TEXT = "bt";
    CmdParams.CMDP_ADD_TITLE_HISTORY_p_TITLE = "t";
    CmdParams.CMDP_ADD_TITLE_HISTORY_p_FIND = "f";

CmdParams.CMDP_START_REFRESH_GPSL = "startRefreshGPSL";
    CmdParams.CMDP_START_REFRESH_GPSL_p_DISTANCE = "dist";
    CmdParams.CMDP_START_REFRESH_GPSL_p_FLAG = "flg";

CmdParams.CMDP_DO_NOT_USE_GPS = "doNotUseGPS";

CmdParams.CMDP_SET_BCOOKIE = "setBcookie";
    CmdParams.CMDP_SET_BCOOKIE_p_COOKIE = "ck";

CmdParams.CMDP_CANCEL_REFRESH = "cancelRefresh";

CmdParams.CMDP_XLOCATION = "XLocation";
    CmdParams.CMDP_XLOCATION_p_LOCATION = "l";

CmdParams.CMDP_SET_STATUS = "setStatus";
    CmdParams.CMDP_SET_STATUS_p_TEXT = "txt";

CmdParams.CMDP_REFRESHS = "refreshS";

CmdParams.CMDP_REFRESHR = "refreshR";

CmdParams.CMDP_SET_BPSOCKET_VARIABLES = "setBPSocketVariables";
    CmdParams.CMDP_SET_BPSOCKET_VARIABLES_p_VARS = "vars";

CmdParams.CMDP_SHOW_ERROR_MESSAGE = "showErrorMessage";
CmdParams.CMDP_SHOW_ERROR_MESSAGE_p_MESSAGE = "msg";

CmdParams.CMDP_JSON_EDIT_RETURN = "jsonReturn";
CmdParams.CMDP_JSON_EDIT_RETURN_VALUE = "val";