import ParenNode from './ParenNode';
import BStatus from './BStatus';
import Vector from './Vector';
import BLinearGradient from './BLinearGradient';
import BShape from './BShape';
import BPolyline from './BPolyline';
import BPolygon from './BPolygon';
import BEllipse from './BEllipse';
import BLine from './BLine';
import BRect from './BRect';
import Character from './Character';
import BNumberUtils from './BNumberUtils';
import Lexer from 'lex';
import Parser from './shunt';

var lexer = new Lexer;
lexer.addRule(/\s+/, function () {
  /* skip whitespace */
});

lexer.addRule(/[a-zA-Z]+/, function (lexeme) {
  return lexeme; // symbols
});
lexer.addRule(/[0-9]+/, function (lexeme) {
  return lexeme; // numbers
});

lexer.addRule(/[\(\+\-\*\/\)]/, function (lexeme) {
  return lexeme; // punctuation (i.e. "(", "+", "-", "*", "/", ")")
});
var factor = {
  precedence: 2,
  associativity: "left"
};

var term = {
  precedence: 1,
  associativity: "left"
};

var parser = new Parser({
  "+": term,
  "-": term,
  "*": factor,
  "/": factor,
});

function parse(input) {
  lexer.setInput(input);
  var tokens = [], token;
  while (token = lexer.lex()) tokens.push(token);
  return parser.parse(tokens);
}

export default class DecorNode {
  constructor(pn) {
    this._attIntMask = 0;
    this._failedIntMask = 0;
    this._iVals = 0;
    this._attStringMask = 0;
    this._failedStringMask = 0;
    this._sVals = null;
    this._gradients = null;
    this._gradientSetup = false;
    this._htShapes = null;
    this._triedShape = null;
    this._shapes = null;
    this._shapeSetup = false;
    this.root = null;
    this._uiType = 0;

    this.root = pn;
    this._iVals = new Array(DecorNode.ATT_IVAL_SIZE);
    this._attIntMask = 0;
    this._failedIntMask = 0;
    this._sVals = new Array(DecorNode.ATT_STRING_SIZE);
    this._attStringMask = 0;
    this._failedStringMask = 0;
    this._gradientSetup = false;
    this._shapeSetup = false;
  }
  getRoot() {
    return this.root;
  }
  clear() {
    this._iVals = null;
    this._sVals = null;
    if (this._gradients !== null) {
      let len = this._gradients.size();
      for (let i = 0; i < len; i++) {
        let bl = this._gradients.elementAt(i);
        bl.clear();
      }
      this._gradients.removeAllElements();
      this._gradients = null;
    }
    if (this._triedShape !== null) {
      this._triedShape.removeAllElements();
      this._triedShape = null;
    }
    if (this._htShapes !== null) {
      this._htShapes = null;
    }
    if (this._shapes !== null) {
      let len = this._shapes.size();
      for (let i = 0; i < len; i++) {
        let sMe = this._shapes.elementAt(i);
        switch (sMe.getType()) {
          case BShape.SHAPE_OVAL_RECTANGLE:
          case BShape.SHAPE_RECTANGLE:
          case BShape.SHAPE_ROUND_RECTANGLE:
            {
            let br = sMe;
            br.clear();
            break;
            }
          case BShape.SHAPE_ELLIPSE:
          case BShape.SHAPE_CIRCLE:
            {
            let be = sMe;
            be.clear();
            break;
            }
          case BShape.SHAPE_LINE:
            {
            let bl = sMe;
            bl.clear();
            break;
            }
          case BShape.SHAPE_POLYGON:
            {
            let bp = sMe;
            bp.clear();
            break;
            }
          case BShape.SHAPE_POLYLINE:
            {
            let bp = sMe;
            bp.clear();
            break;
            }
        }
      }
      this._shapes.removeAllElements();
      this._shapes = null;
    }
  }
  hasFGColor() {
    let status = new BStatus(false);
    this.colorVal('fg', DecorNode.ATT_MASK_FG, status);
    return status.getStatus();
  }
  getFGColor() {
    let status = new BStatus(false);
    return this.colorVal('fg', DecorNode.ATT_MASK_FG, status);
  }
  hasBGColor() {
    let status = new BStatus(false);
    this.colorVal(DecorNode.C_lowercase_bg, DecorNode.ATT_MASK_BG, status);
    return status.getStatus();
  }
  getBGColor() {
    let status = new BStatus(false);
    return this.colorVal(DecorNode.C_lowercase_bg, DecorNode.ATT_MASK_BG, status);
  }
  hasTintColor() {
    let status = new BStatus(false);
    this.colorVal('tint', DecorNode.ATT_MASK_TINT, status);
    return status.getStatus();
  }
  getTintColor() {
    let status = new BStatus(false);
    return this.colorVal('tint', DecorNode.ATT_MASK_TINT, status);
  }
  getSelectedFGColor() {
    let status = new BStatus(false);
    return this.colorVal('sfg', DecorNode.ATT_MASK_SFG, status);
  }
  hasSelectedFGColor() {
    let status = new BStatus(false);
    this.colorVal('sfg', DecorNode.ATT_MASK_SFG, status);
    return status.getStatus();
  }
  hasSelectedBGColor() {
    let status = new BStatus(false);
    this.colorVal(DecorNode.C_lowercase_sbg, DecorNode.ATT_MASK_SBG, status);
    return status.getStatus();
  }
  getSelectedBGColor() {
    let status = new BStatus(false);
    return this.colorVal(DecorNode.C_lowercase_sbg, DecorNode.ATT_MASK_SBG, status);
  }
  hasGradient(sWhich) {
    let status = new BStatus(false);
    this.setupGradient(status);
    if (status.getStatus()) {
      if (this.root !== null) {
        let children = this.root.getChildren();
        if (children !== null) {
          for (let i = 0; i < children.size(); i++) {
            let pn = children.elementAt(i);
            if (pn !== null && pn.TID() !== null && pn.TID().toLowerCase().equals(sWhich)) {
              let pAtt = pn.firstChild();
              if (pAtt !== null && pAtt.TID() !== null) {
                let satt = pAtt.TID().toLowerCase();
                if (satt.equals(DecorNode.C_lowercase_url)) {
                  let sId = DecorNode.parseUrlNode(pAtt);
                  if (sId !== null && sId.startsWith('#')) {
                    return this.hasGradientId(sId);
                  }
                }
              }
            }
          }
        }
      }
    }
    return null;
  }
  findChildNode$1(sTagName) {
    if (this.root !== null) {
      let children = this.root.getChildren();
      if (children !== null) {
        for (let i = 0; i < children.size(); i++) {
          let pnC = children.elementAt(i);
          if (pnC !== null && pnC.TID() !== null && pnC.TID().toLowerCase().equals(sTagName)) {
            return pnC;
          }
        }
      }
    }
    return null;
  }
  findChildNode(...args$) {
    switch (args$.length) {
      case 1:
        return this.findChildNode$1(...args$);
      case 2:
        return DecorNode.findChildNode$2(...args$);
    }
  }
  widthInPixels() {
    let iRet = Number.MAX_SAFE_INTEGER;
    let pn = this.nodeHasTag(DecorNode.C_lowercase_layout, DecorNode.C_lowercase_width);
    if (pn !== null) {
      pn = pn.firstChild();
      if (pn !== null) {
        let fdv = DecorNode.parseUnitsWithValue(pn);
        if (fdv !== Number.MAX_VALUE) {
          iRet = fdv;
        }
      }
    }
    return iRet;
  }
  nodeHasTag(sWhich, sTagName) {
    if (this.root !== null) {
      let children = this.root.getChildren();
      if (children !== null) {
        for (let i = 0; i < children.size(); i++) {
          let pn = children.elementAt(i);
          if (pn !== null && pn.TID() !== null && pn.TID().toLowerCase().equals(sWhich)) {
            children = pn.getChildren();
            if (children !== null) {
              for (let j = 0; j < children.size(); j++) {
                pn = children.elementAt(j);
                if (pn !== null && pn.TID() !== null && pn.TID().toLowerCase().equals(sTagName)) {
                  return pn;
                }
              }
            }
            return null;
          }
        }
      }
    }
    return null;
  }
  hasGradientId(sId) {
    let status = new BStatus(false);
    this.setupGradient(status);
    if (this._gradients !== null) {
      for (let i = 0; i < this._gradients.size(); i++) {
        let lg = this._gradients.elementAt(i);
        if (lg !== null && lg.getId() !== null && lg.getId().toLowerCase().equals(sId.toLowerCase())) {
          return lg;
        }
      }
    }
    return null;
  }
  hasShape(sType) {
    let status = new BStatus(false);
    this.setupShapes(sType, status);
    return status.getStatus();
  }
  getShapesForTag(sTag) {
    if (this._htShapes !== null) {
        return this._htShapes[sTag];
    }
    return null;
  }
  hasMarginTop() {
    let status = new BStatus(false);
    this.marginVal(DecorNode.C_lowercase_t, DecorNode.ATT_MASK_MT, status);
    return status.getStatus();
  }
  getMarginTop() {
    let status = new BStatus(false);
    return this.marginVal(DecorNode.C_lowercase_t, DecorNode.ATT_MASK_MT, status);
  }
  hasMarginBottom() {
    let status = new BStatus(false);
    this.marginVal(DecorNode.C_lowercase_b, DecorNode.ATT_MASK_MB, status);
    return status.getStatus();
  }
  getMarginBottom() {
    let status = new BStatus(false);
    return this.marginVal(DecorNode.C_lowercase_b, DecorNode.ATT_MASK_MB, status);
  }
  hasMarginLeft() {
    let status = new BStatus(false);
    this.marginVal(DecorNode.C_lowercase_l, DecorNode.ATT_MASK_ML, status);
    return status.getStatus();
  }
  getMarginLeft() {
    let status = new BStatus(false);
    return this.marginVal(DecorNode.C_lowercase_l, DecorNode.ATT_MASK_ML, status);
  }
  hasMarginRight() {
    let status = new BStatus(false);
    this.marginVal(DecorNode.C_lowercase_r, DecorNode.ATT_MASK_MR, status);
    return status.getStatus();
  }
  getMarginRight() {
    let status = new BStatus(false);
    return this.marginVal(DecorNode.C_lowercase_r, DecorNode.ATT_MASK_MR, status);
  }
  getIntVal(maskBit) {
    let mtest = 0x00000001;
    for (let i = 0; i < DecorNode.ATT_IVAL_SIZE; i++) {
      if ((mtest & maskBit) === maskBit) {
        return this._iVals[i];
      }
      mtest <<= 1;
    }
    return 0;
  }
  setIntVal(maskBit, ivalue) {
    let mtest = 0x00000001;
    for (let i = 0; i < DecorNode.ATT_IVAL_SIZE; i++) {
      if ((mtest & maskBit) === maskBit) {
        this._attIntMask |= maskBit;
        this._iVals[i] = ivalue;
        return ivalue;
      }
      mtest <<= 1;
    }
    return 0;
  }
  colorVal(att, maskBit, status) {
    if ((this._attIntMask & maskBit) === maskBit) {
      status.setStatus(true);
      return this.getIntVal(maskBit);
    } else {
      if ((this._failedIntMask & maskBit) === maskBit) {
        status.setStatus(false);
        return 0;
      } else {
        if (this.root !== null) {
          let children = this.root.getChildren();
          if (children !== null) {
            for (let i = 0; i < children.size(); i++) {
              let pn = children.elementAt(i);
              if (pn !== null && pn.TID() !== null && pn.TID().toLowerCase().equals(att)) {
                let pAtt = pn.firstChild();
                if (pAtt !== null && pAtt.TID() !== null) {
                  let satt = pAtt.TID().toLowerCase();
                  if (satt.equals(DecorNode.C_lowercase_c)) {
                    let icolor = DecorNode.parseColorNode(pAtt, status);
                    if (status.getStatus()) {
                      return this.setIntVal(maskBit, icolor);
                    } else {
                      this._failedIntMask |= maskBit;
                      return 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    status.setStatus(false);
    this._failedIntMask |= maskBit;
    return 0;
  }
  addShapeToTag(sTag, bs) {
    if (this._htShapes === null) {
      this._htShapes = {};
    }
    let vshapes = null;
    if (this._htShapes[sTag]) {
      vshapes = this._htShapes[sTag];
    } else {
      vshapes = new Vector();
      this._htShapes[sTag] = vshapes;
    }
    vshapes.addElement(bs);
  }
  arraysContains(arS, sVal) {
    let len = arS.length;
    for (let i = 0; i < len; i++) {
      if (arS[i].equals(sVal)) {
        return true;
      }
    }
    return false;
  }
  setupShapes(sTag, status) {
    if (this._triedShape === null) {
      this._triedShape = new Vector();
    }
    if (this._triedShape.contains(sTag)) {
      if (this._htShapes !== null && this._htShapes[sTag]) {
        status.setStatus(true);
      } else {
        status.setStatus(false);
      }
      return undefined;
    } else {
      this._triedShape.addElement(sTag);
      let bAdded = false;
      status.setStatus(false);
      if (this.root !== null) {
        let children = this.root.getChildren();
        if (children !== null) {
          if (sTag.length !== 0) {
            let bNewKids = false;
            for (let i = 0; i < children.size(); i++) {
              let pn = children.elementAt(i);
              if (pn !== null && pn.TID() !== null && pn.TID().toLowerCase().equals(sTag)) {
                children = pn.getChildren();
                if (children !== null) {
                  bNewKids = true;
                }
              }
            }
            if (!bNewKids) {
              return undefined;
            }
          }
          for (let i = 0; i < children.size(); i++) {
            let pn = children.elementAt(i);
            let sShapeName = pn.TID().toLowerCase();
            if (pn !== null && this.arraysContains(BShape.AllShapes, sShapeName)) {
              status.setStatus(true);
              if (sShapeName.equals(BShape.C_RECT)) {
                let br = new BRect(BShape.SHAPE_RECTANGLE);
                this.parseXYWidthHeight(pn, br, status);
                if (br.isValid()) {
                  this.addShapeToTag(sTag, br);
                  bAdded = true;
                }
              } else if (sShapeName.equals(BShape.C_ORECT)) {
                let br = new BRect(BShape.SHAPE_OVAL_RECTANGLE);
                this.parseXYWidthHeight(pn, br, status);
                if (br.isValid()) {
                  this.addShapeToTag(sTag, br);
                  bAdded = true;
                }
              } else if (sShapeName.equals(BShape.C_RRECT)) {
                let br = new BRect(BShape.SHAPE_ROUND_RECTANGLE);
                this.parseXYWidthHeight(pn, br, status);
                if (br.isValid()) {
                  this.addShapeToTag(sTag, br);
                  bAdded = true;
                }
              } else if (sShapeName.equals(BShape.C_ELLIPSE)) {
                let be = new BEllipse(BShape.SHAPE_ELLIPSE);
                this.parseEllipse(pn, be, status);
                if (be.isValid()) {
                  this.addShapeToTag(sTag, be);
                  bAdded = true;
                }
              } else if (sShapeName.equals(BShape.C_CIRCLE)) {
                let be = new BEllipse(BShape.SHAPE_CIRCLE);
                this.parseCircle(pn, be, status);
                if (be.isValid()) {
                  this.addShapeToTag(sTag, be);
                  bAdded = true;
                }
              } else if (sShapeName.equals(BShape.C_LINE)) {
                let bl = new BLine(BShape.SHAPE_LINE);
                this.parseLine(pn, bl, status);
                if (bl.isValid()) {
                  this.addShapeToTag(sTag, bl);
                  bAdded = true;
                }
              } else if (sShapeName.equals(BShape.C_POLYGON)) {
                let bp = new BPolygon(BShape.SHAPE_POLYGON);
                this.parsePoints(pn, bp, status);
                if (bp.isValid()) {
                  this.addShapeToTag(sTag, bp);
                  bAdded = true;
                }
              } else if (sShapeName.equals(BShape.C_POLYLINE)) {
                let bp = new BPolyline(BShape.SHAPE_POLYLINE);
                this.parsePoints(pn, bp, status);
                if (bp.isValid()) {
                  this.addShapeToTag(sTag, bp);
                  bAdded = true;
                }
              }
            }
          }
        }
      }
      if (bAdded) {
        status.setStatus(true);
      }
    }
    return undefined;
  }
  parseXYWidthHeight(pn, br, status) {
    if (pn !== null) {
      let kids = pn.getChildren();
      if (kids !== null) {
        for (let i = 0; i < kids.size(); i++) {
          let pAtt = kids.elementAt(i);
          if (pAtt !== null && pAtt.TID() !== null) {
            let sAtt = pAtt.TID().toLowerCase();
            if (sAtt.equals(DecorNode.C_lowercase_inset)) {
              br.setInset(true);
            } else if (sAtt.equals(DecorNode.C_lowercase_fill)) {
              br.setFill(pAtt);
            } else if (sAtt.equals(DecorNode.C_lowercase_stroke)) {
              br.setStroke(pAtt.firstChild());
            } else {
              let pVal = pAtt.firstChild();
              if (pVal !== null) {
                var toks = parse(pAtt.toString());  // includes x(....) or width(....), need to strip off..
                // br.setX ( toks vs. fdv ) then put this eval into draw...
                //let fdv = DecorNode.parseUnitsWithValue(pVal);
                //if (fdv !== Number.MAX_VALUE)
                {
                  if (sAtt.equals(DecorNode.C_lowercase_x)) {
//                    br.setX(fdv);
                    br.setX(toks);
                  } else if (sAtt.equals(DecorNode.C_lowercase_y)) {
//                    br.setY(fdv);
                    br.setY(toks);
                  } else if (sAtt.equals(DecorNode.C_lowercase_width)) {
//                    br.setWidth(fdv);
                    br.setWidth(toks);
                  } else if (sAtt.equals(DecorNode.C_lowercase_height)) {
//                    br.setHeight(fdv);
                    br.setHeight(toks);
                  } else if (sAtt.equals(DecorNode.C_lowercase_rx)) {
//                    br.setRX(fdv);
                    br.setRX(toks);
                  } else if (sAtt.equals(DecorNode.C_lowercase_ry)) {
//                    br.setRY(fdv);
                    br.setRY(toks);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  parseEllipse(pn, br, status) {
    if (pn !== null) {
      let kids = pn.getChildren();
      if (kids !== null) {
        for (let i = 0; i < kids.size(); i++) {
          let pAtt = kids.elementAt(i);
          if (pAtt !== null && pAtt.TID() !== null) {
            let sAtt = pAtt.TID().toLowerCase();
            if (sAtt.equals(DecorNode.C_lowercase_inset)) {
              br.setInset(true);
            } else if (sAtt.equals(DecorNode.C_lowercase_fill)) {
              br.setFill(pAtt);
            } else if (sAtt.equals(DecorNode.C_lowercase_stroke)) {
              br.setStroke(pAtt.firstChild());
            } else {
              let pVal = pAtt.firstChild();
              if (pVal !== null) {
                var toks = parse(pAtt.toString());
//                let dVal = DecorNode.parseUnitsWithValue(pVal);
//                if (dVal !== Number.MAX_VALUE)
                {
                  if (sAtt.equals(DecorNode.C_lowercase_cx)) {
//                    br.setCX(dVal);
                    br.setCX(toks);
                  } else if (sAtt.equals(DecorNode.C_lowercase_cy)) {
//                    br.setCY(dVal);
                    br.setCY(toks);
                  } else if (sAtt.equals(DecorNode.C_lowercase_rx)) {
//                    br.setRX(dVal);
                    br.setRX(toks);
                  } else if (sAtt.equals(DecorNode.C_lowercase_ry)) {
//                    br.setRY(dVal);
                    br.setRY(toks);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  parseCircle(pn, br, status) {
    if (pn !== null) {
      let kids = pn.getChildren();
      if (kids !== null) {
        for (let i = 0; i < kids.size(); i++) {
          let pAtt = kids.elementAt(i);
          if (pAtt !== null && pAtt.TID() !== null) {
            let sAtt = pAtt.TID().toLowerCase();
            if (sAtt.equals(DecorNode.C_lowercase_inset)) {
              br.setInset(true);
            } else if (sAtt.equals(DecorNode.C_lowercase_fill)) {
              br.setFill(pAtt);
            } else if (sAtt.equals(DecorNode.C_lowercase_stroke)) {
              br.setStroke(pAtt.firstChild());
            } else {
              let pVal = pAtt.firstChild();
              if (pVal !== null) {
                var toks = parse(pAtt.toString());  // includes x(....) or width(....), need to strip off..
//                let dVal = DecorNode.parseUnitsWithValue(pVal);
//                if (dVal !== Number.MAX_VALUE)
                {
                  if (sAtt.equals(DecorNode.C_lowercase_cx)) {
//                    br.setCX(dVal);
                    br.setCX(toks);
                  } else if (sAtt.equals(DecorNode.C_lowercase_cy)) {
//                    br.setCY(dVal);
                    br.setCY(toks);
                  } else {
                    if (sAtt.equals(DecorNode.C_lowercase_r)) {
//                      br.setRX(dVal);
                      var toksCp = [...toks];
                      br.setRX(toks);
//                      br.setRY(dVal);
                      br.setRY(toksCp);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  parsePoints(pn, br, status) {
    if (pn !== null) {
      let kids = pn.getChildren();
      if (kids !== null) {
        for (let i = 0; i < kids.size(); i++) {
          let pAtt = kids.elementAt(i);
          if (pAtt !== null && pAtt.TID() !== null) {
            let sAtt = pAtt.TID().toLowerCase();
            if (sAtt.equals(DecorNode.C_lowercase_fill)) {
              br.setFill(pAtt);
            } else if (sAtt.equals(DecorNode.C_lowercase_stroke)) {
              br.setStroke(pAtt.firstChild());
            } else if (sAtt.equals(DecorNode.C_lowercase_point) || sAtt.equals(DecorNode.C_lowercase_pt)) {
              let pts = pAtt.getChildren();
              if (pts !== null && pts.size() === 2) {
                let pn1 = pts.elementAt(0);
                let pn2 = pts.elementAt(1);
                if (pn1 !== null && pn2 !== null) {
                  var toks1 = parse(pn1.toString());
                  var toks2 = parse(pn2.toString());
//                  let dVal1 = DecorNode.parseUnitsWithValue(pn1);
//                  let dVal2 = DecorNode.parseUnitsWithValue(pn2);
//                  if (dVal1 !== Number.MAX_VALUE && dVal2 !== Number.MAX_VALUE)
                  {
//                    br.addPoint(dVal1, dVal2);
                    br.addPoint(toks1, toks2);;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  parseLine(pn, br, status) {
    if (pn !== null) {
      let kids = pn.getChildren();
      if (kids !== null) {
        for (let i = 0; i < kids.size(); i++) {
          let pAtt = kids.elementAt(i);
          if (pAtt !== null && pAtt.TID() !== null) {
            let sAtt = pAtt.TID().toLowerCase();
            if (sAtt.equals(DecorNode.C_lowercase_inset)) {
              br.setInset(true);
            } else if (sAtt.equals(DecorNode.C_lowercase_fill)) {
              br.setFill(pAtt);
            } else if (sAtt.equals(DecorNode.C_lowercase_stroke)) {
              br.setStroke(pAtt.firstChild());
            } else {
              let pVal = pAtt.firstChild();
              if (pVal !== null) {
                if (sAtt.equals(DecorNode.C_lowercase_x1) || sAtt.equals(DecorNode.C_lowercase_y1) || sAtt.equals(DecorNode.C_lowercase_x2) || sAtt.equals(DecorNode.C_lowercase_y2)) {
                  var toks = parse(pAtt.toString());  // includes x(....) or width(....), need to strip off..

//                  let fdv = DecorNode.parseUnitsWithValue(pVal);
//                  if (fdv !== Number.MAX_VALUE)
                  {
                    if (sAtt.equals(DecorNode.C_lowercase_x1)) {
                      br.setX1(toks); // fdv);
                    } else if (sAtt.equals(DecorNode.C_lowercase_y1)) {
                      br.setY1(toks);
                    } else if (sAtt.equals(DecorNode.C_lowercase_x2)) {
                      br.setX2(toks);
                    } else if (sAtt.equals(DecorNode.C_lowercase_y2)) {
                      br.setY2(toks);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  marginVal(att, maskBit, status) {
    if ((this._attIntMask & maskBit) === maskBit) {
      status.setStatus(true);
      return this.getIntVal(maskBit);
    } else {
      if ((this._failedIntMask & maskBit) === maskBit) {
        status.setStatus(false);
        return 0;
      } else {
        if (this.root !== null) {
          let children = this.root.getChildren();
          if (children !== null) {
            for (let i = 0; i < children.size(); i++) {
              let pn = children.elementAt(i);
              if (pn !== null && pn.TID() !== null && pn.TID().toLowerCase().equals(DecorNode.C_lowercase_margin)) {
                children = pn.getChildren();
                if (children !== null) {
                  let iPixels = 0;
                  for (let j = 0; j < children.size(); j++) {
                    pn = children.elementAt(j);
                    let sMargin = pn.TID().toLowerCase();
                    if (pn !== null) {
                      if (sMargin.equals(DecorNode.C_lowercase_t)) {
                        iPixels = this.parseIntNode(pn, status);
                        if (status.getStatus()) {
                          this.setIntVal(DecorNode.ATT_MASK_MT, iPixels);
                        } else {
                          this._failedIntMask |= DecorNode.ATT_MASK_MT;
                        }
                      } else if (sMargin.equals(DecorNode.C_lowercase_l)) {
                        iPixels = this.parseIntNode(pn, status);
                        if (status.getStatus()) {
                          this.setIntVal(DecorNode.ATT_MASK_ML, iPixels);
                        } else {
                          this._failedIntMask |= DecorNode.ATT_MASK_ML;
                        }
                      } else if (sMargin.equals(DecorNode.C_lowercase_b)) {
                        iPixels = this.parseIntNode(pn, status);
                        if (status.getStatus()) {
                          this.setIntVal(DecorNode.ATT_MASK_MB, iPixels);
                        } else {
                          this._failedIntMask |= DecorNode.ATT_MASK_MB;
                        }
                      } else if (sMargin.equals(DecorNode.C_lowercase_r)) {
                        iPixels = this.parseIntNode(pn, status);
                        if (status.getStatus()) {
                          this.setIntVal(DecorNode.ATT_MASK_MR, iPixels);
                        } else {
                          this._failedIntMask |= DecorNode.ATT_MASK_MR;
                        }
                      }
                    }
                  }
                }
                if ((this._attIntMask & maskBit) === maskBit) {
                  status.setStatus(true);
                  return this.getIntVal(maskBit);
                } else {
                  status.setStatus(false);
                  return 0;
                }
              }
            }
          }
        }
      }
    }
    status.setStatus(false);
    this._failedIntMask |= maskBit;
    return 0;
  }
  setupGradient(status) {
    if (this._gradientSetup) {
      status.setStatus(true);
      return undefined;
    } else {
      this._gradientSetup = true;
      if (this.root !== null) {
        let children = this.root.getChildren();
        if (children !== null) {
          for (let i = 0; i < children.size(); i++) {
            let pn = children.elementAt(i);
            if (pn !== null && pn.TID() !== null && pn.TID().toLowerCase().equals('defs')) {
              let defsKids = pn.getChildren();
              for (let k = 0; k < defsKids.size(); k++) {
                pn = defsKids.elementAt(k);
                if (pn !== null && pn.TID() !== null && pn.TID().toLowerCase().equals('lg')) {
                  let lg = this.parseLinearGradient(pn);
                  if (lg.isValid()) {
                    if (this._gradients === null) {
                      this._gradients = new Vector();
                    }
                    this._gradients.addElement(lg);
                  } else {
                    lg.clear();
                    lg = null;
                  }
                }
              }
            }
          }
        }
      }
    }
    status.setStatus(this._gradientSetup);
    return undefined;
  }
  parseLinearGradient(pnIn) {
    let lg = new BLinearGradient();
    let gChildren = pnIn.getChildren();
    if (gChildren !== null) {
      let nColors = 0;
      for (let j = 0; j < gChildren.size(); j++) {
        let pn = gChildren.elementAt(j);
        if (pn !== null && pn.TID() !== null) {
          let sSub = pn.TID().toLowerCase();
          if (sSub.equals('gd')) {
            let pgd = pn.firstChild();
            if (pgd !== null && pgd.TID() !== null) {
              let sDir = pgd.TID().toLowerCase();
              if (sDir !== null) {
                if (sDir.equals('tb')) {
                  lg.setType(BLinearGradient.GRADIENT_TB);
                } else {
                  if (sDir.equals('bt')) {
                    lg.setType(BLinearGradient.GRADIENT_BT);
                  } else {
                    if (sDir.equals('lr')) {
                      lg.setType(BLinearGradient.GRADIENT_LR);
                    } else {
                      if (sDir.equals('rl')) {
                        lg.setType(BLinearGradient.GRADIENT_RL);
                      }
                    }
                  }
                }
              }
            }
          } else {
            if (sSub.equals('stop')) {
              nColors++;
            } else {
              if (sSub.equals(DecorNode.C_lowercase_c)) {
                nColors++;
              } else {
                if (sSub.equals('id')) {
                  let pgd = pn.firstChild();
                  if (pgd !== null && pgd.TID() !== null) {
                    lg.setId(pgd.TID().toLowerCase());
                  }
                } else {
                  if (sSub.equals(DecorNode.C_lowercase_alpha)) {
                    let pgd = pn.firstChild();
                    if (pgd !== null) {
                      let alpha = BNumberUtils.parseInt(pgd.TID());
                      if (alpha !== Number.MAX_SAFE_INTEGER) {
                        lg.setAlpha(alpha);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (nColors > 0) {
        let gc = new Array(nColors);
        let stops = new Array(nColors);
        nColors = 0;
        for (let j = 0; j < gChildren.size(); j++) {
          let pn = gChildren.elementAt(j);
          if (pn !== null && pn.TID() !== null) {
            let sSub = pn.TID().toLowerCase();
            if (sSub.equals(DecorNode.C_lowercase_c)) {
              let status = new BStatus(true);
              let icolor = DecorNode.parseColorNode(pn, status);
              if (status.getStatus()) {
                gc[nColors] = icolor;
                stops[nColors] = -1;
                nColors++;
              }
            } else {
              if (sSub.equals('stop')) {
                let status = new BStatus(true);
                let stopKids = pn.getChildren();
                if (stopKids !== null) {
                  let offset = 0;
                  let color = 0;
                  let bits = 0;
                  for (let k = 0; k < stopKids.size(); k++) {
                    let pk = stopKids.elementAt(k);
                    if (pk !== null && pk.TID() !== null) {
                      let ska = pk.TID().toLowerCase();
                      if (ska.equals('off')) {
                        status.setStatus(true);
                        offset = this.parseIntChild(pk, status);
                        if (status.getStatus()) {
                          bits |= 0x00000001;
                        }
                      } else {
                        if (ska.equals(DecorNode.C_lowercase_c)) {
                          color = DecorNode.parseColorNode(pk, status);
                          if (status.getStatus()) {
                            bits |= 0x00000002;
                          }
                        }
                      }
                    }
                  }
                  if ((bits & 0x000000003) === 0x00000003) {
                    gc[nColors] = color;
                    stops[nColors] = offset;
                    nColors++;
                  }
                }
              }
            }
          }
        }
        lg.setColorsAndStops(gc, stops, nColors);
      }
    }
    return lg;
  }
  getChildNodeIntValue(sChild) {
    let iRet = Number.MAX_SAFE_INTEGER;
    let pn = this.findChildNode(sChild);
    if (pn !== null) {
      let status = new BStatus(false);
      iRet = this.parseIntChild(pn, status);
      if (!status.getStatus()) {
        iRet = Number.MAX_SAFE_INTEGER;
      }
    }
    return iRet;
  }
  parseIntNode(pC, status) {
    if (pC !== null) {
      let pn = pC.firstChild();
      if (pn !== null) {
        let fdv = DecorNode.parseUnitsWithValue(pn);
        if (fdv !== Number.MAX_VALUE) {
          status.setStatus(true);
          return fdv;
        }
      }
    }
    status.setStatus(false);
    return 0;
  }
  parseIntChild = (pn, status) => {
    let pAtt = null;
    if (pn === null) {
      status.setStatus(false);
      return 0;
    }
    pAtt = pn.firstChild();
    if (pAtt !== null) {
      let sIVal = pAtt.TID();
      let iVal = BNumberUtils.parseInt(sIVal);
      if (iVal !== Number.MAX_SAFE_INTEGER) {
        status.setStatus(true);
        return iVal;
      }
    }
    status.setStatus(false);
    return 0;
  }  
  parseFloatChild = (pn, status) => {
    let pAtt = null;
    if (pn === null) {
      status.setStatus(false);
      return 0;
    }
    pAtt = pn.firstChild();
    if (pAtt !== null) {
      let sIVal = pAtt.TID();
      let dVal = BNumberUtils.parseDouble(sIVal);
      if (dVal !== Number.MAX_VALUE) {
        status.setStatus(true);
        return dVal;
      }
    }
    status.setStatus(false);
    return 0;
  }  

  hasBackFG = () => {
    let status = new BStatus(false);
    this.getColorOfSubObject(DecorNode.C_lowercase_back, DecorNode.C_lowercase_fg, status);
    return status.getStatus();
  }
  getBackFG = () => {
    let status = new BStatus(false);
    let ival = this.getColorOfSubObject(DecorNode.C_lowercase_back, DecorNode.C_lowercase_fg, status);
    if (status.getStatus()) {
      return ival;
    } else {
      return 0xffffffff;    // white
    }
  }
  getColorOfSubObject = (sTag, sAtt, status) => {
    let pnTag = this.findChildNode(sTag);
    if (pnTag != null) {
      let children = pnTag.getChildren();
      if (children !== null) {
        for (let i = 0; i < children.size(); i++) {
          let pnC = children.elementAt(i);
          if (pnC !== null && pnC.TID() !== null && pnC.TID().toLowerCase().equals(sAtt)) {
            let pAtt = pnC.firstChild();
            if (pAtt !== null) {
              if (pAtt.TID() !== null) {
                let satt = pAtt.TID().toLowerCase();
                if (satt.equals(DecorNode.C_lowercase_c)) {
                  let icolor = DecorNode.parseColorNode(pAtt, status);
                  if (status.getStatus()) {
                    return icolor;
                  }
                }
              }
            }
          }
        }
      }
    }
    status.setStatus(false);
    return 0;
    }
}
DecorNode.C_lowercase_action = 'action';
DecorNode.C_lowercase_actiontype = 'actiontype';
DecorNode.C_lowercase_alpha = 'alpha';
DecorNode.C_lowercase_bg = 'bg';
DecorNode.C_lowercase_sbg = 'sbg';
DecorNode.C_lowercase_bold = 'bold';
DecorNode.C_lowercase_c = 'c';
DecorNode.C_lowercase_col = 'col';
DecorNode.C_lowercase_content = 'content';
DecorNode.C_lowercase_default = 'default';
DecorNode.C_lowercase_disabled = 'disabled';
DecorNode.C_lowercase_font = 'font';
DecorNode.C_lowercase_h = 'h';
DecorNode.C_lowercase_id = 'id';
DecorNode.C_lowercase_italic = 'italic';
DecorNode.C_lowercase_none = 'none';
DecorNode.C_lowercase_tile = 'tile';
DecorNode.C_lowercase_titlesearch = 'titlesearch';
DecorNode.C_lowercase_cover = 'cover';
DecorNode.C_lowercase_scale = 'scale';
DecorNode.C_lowercase_title = 'title';
DecorNode.C_lowercase_underline = 'underline';
DecorNode.C_lowercase_w = 'w';
DecorNode.C_lowercase_map = 'map';
DecorNode.C_lowercase_normal = 'normal';
DecorNode.C_lowercase_url = 'url';
DecorNode.C_lowercase_rburl = 'rburl';
DecorNode.C_lowercase_lburl = 'lburl';
DecorNode.C_lowercase_height = 'height';
DecorNode.C_lowercase_layout = 'layout';
DecorNode.C_lowercase_chevron = 'chevron';
DecorNode.C_lowercase_fit = 'fit';
DecorNode.C_lowercase_true = 'true';
DecorNode.C_lowercase_nocompress = 'nocompress';
DecorNode.C_lowercase_nohighlight = 'nohighlight';
DecorNode.C_lowercase_pixels = 'pixels';
DecorNode.C_lowercase_px = 'px';
DecorNode.C_lowercase_in = 'in';
DecorNode.C_lowercase_cm = 'cm';
DecorNode.C_lowercase_fh = 'fh';
DecorNode.C_lowercase_fw = 'fw';
DecorNode.C_lowercase_tint = 'tint';
DecorNode.C_lowercase_type = 'type';
DecorNode.C_lowercase_label = 'label';
DecorNode.C_lowercase_image = 'image';
DecorNode.C_lowercase_combo = 'combo';
DecorNode.C_lowercase_mult = 'mult';
DecorNode.ALIGN_MASK_NONE = 0x00000000;
DecorNode.ALIGN_MASK_LEFT = 0x00000001;
DecorNode.ALIGN_MASK_HCENTER = 0x00000002;
DecorNode.ALIGN_MASK_RIGHT = 0x00000004;
DecorNode.ALIGN_MASK_TOP = 0x00000008;
DecorNode.ALIGN_MASK_VCENTER = 0x00000010;
DecorNode.ALIGN_MASK_BOTTOM = 0x00000020;
DecorNode.FONT_MASK_STYLE_BOLD = 0x00000040;
DecorNode.FONT_MASK_STYLE_ITALIC = 0x00000080;
DecorNode.FONT_MASK_STYLE_UNDERLINED = 0x00000100;
DecorNode.FONT_MASK_STYLE_PLAIN = 0x00000200;
DecorNode.DECORNODE_TYPE_UNDEFINED = 0;
DecorNode.DECORNODE_TYPE_NAVBAR = 1;
DecorNode.DECORNODE_TYPE_TABLE = 2;
DecorNode.DECORNODE_TYPE_TOOLBAR = 3;
DecorNode.DECORNODE_TYPE_CELL = 4;
DecorNode.DECORNODE_TYPE_TOOLBAR_BUTTON = 5;
DecorNode.DECORNODE_TYPE_SEPARATOR = 6;
DecorNode.DECORNODE_TYPE_RIGHTBUTTON = 7;
DecorNode.DECORNODE_TYPE_MESSAGE = 8;
DecorNode.DECORNODE_TYPE_LEFTBUTTON = 9;
DecorNode.DECORNODE_TYPE_TITLESEARCHBUTTON = 10;
DecorNode.DECORNODE_TYPE_FIRSTAVAILABLE = 11;
DecorNode.C_lowercase_fg = 'fg';
DecorNode.C_lowercase_back = 'back';
DecorNode.C_lowercase_t = 't';
DecorNode.C_lowercase_l = 'l';
DecorNode.C_lowercase_b = 'b';
DecorNode.C_lowercase_r = 'r';
DecorNode.C_lowercase_x = 'x';
DecorNode.C_lowercase_y = 'y';
DecorNode.C_lowercase_x1 = 'x1';
DecorNode.C_lowercase_y1 = 'y1';
DecorNode.C_lowercase_x2 = 'x2';
DecorNode.C_lowercase_y2 = 'y2';
DecorNode.C_lowercase_width = 'width';
DecorNode.C_lowercase_cx = 'cx';
DecorNode.C_lowercase_cy = 'cy';
DecorNode.C_lowercase_rx = 'rx';
DecorNode.C_lowercase_ry = 'ry';
DecorNode.C_lowercase_point = 'point';
DecorNode.C_lowercase_pt = 'pt';
DecorNode.C_lowercase_inset = 'inset';
DecorNode.C_lowercase_fill = 'fill';
DecorNode.C_lowercase_stroke = 'stroke';
DecorNode.C_lowercase_margin = 'margin';
DecorNode.C_lowercase_halign = 'halign';
DecorNode.C_lowercase_valign = 'valign';
DecorNode.ATT_MASK_FG = 0x00000001;
DecorNode.ATT_MASK_BG = 0x00000002;
DecorNode.ATT_MASK_SFG = 0x00000004;
DecorNode.ATT_MASK_SBG = 0x00000008;
DecorNode.ATT_MASK_MT = 0x00000010;
DecorNode.ATT_MASK_MB = 0x00000020;
DecorNode.ATT_MASK_MR = 0x00000040;
DecorNode.ATT_MASK_ML = 0x00000080;
DecorNode.ATT_MASK_TINT = 0x00000100;
DecorNode.ATT_IVAL_SIZE = 9;
DecorNode._density = 160;
DecorNode._mPaint = null;
//DecorNode._m_fw = 18;
//DecorNode._m_fh = 18;
DecorNode._firstFreeType = DecorNode.DECORNODE_TYPE_FIRSTAVAILABLE;
DecorNode.ATT_STRING_SIZE = 2;
DecorNode.resetNodeTypes = () => {
  DecorNode._firstFreeType = DecorNode.DECORNODE_TYPE_FIRSTAVAILABLE;
};
DecorNode.getFreeType = () => {
  let iRet = DecorNode._firstFreeType;
  DecorNode._firstFreeType++;
  return iRet;
};
/* NOT USED
DecorNode.setDensity = (density, tf) => {
  DecorNode._density = density;
  if (this._mPaint === null) {
    this._mPaint = new Paint();
  }
  this._mPaint.setTextSize((16.0 * DecorNode._density + 0.5));
  this._mPaint.setAntiAlias(true);
  this._mPaint.setTypeface(tf);
  let r = new Rect();
  this._mPaint.getTextBounds('m', 0, 'm'.length, r);
  DecorNode._m_fw = r.right;
  DecorNode._m_fh = (this._mPaint.descent() - this._mPaint.ascent());
};
*/
DecorNode.FromAttributes = (sParenString, uiType) => {
  if (sParenString === null || typeof sParenString === 'undefined') {
    return null;
  }
  let pn = ParenNode.NodeFromString(sParenString);
  if (pn !== null) {
    pn._tid = sParenString;
  }
  let dn = new DecorNode(pn);
  dn._uiType = uiType;
  return dn;
};
DecorNode.getHAlignVal = (pn) => {
  if (pn !== null) {
    let children = pn.getChildren();
    if (children !== null) {
      for (let i = 0; i < children.size(); i++) {
        let pnC = children.elementAt(i);
        if (pnC !== null && pnC.TID() !== null && pnC.TID().toLowerCase().equals(DecorNode.C_lowercase_halign)) {
          pnC = pnC.firstChild();
          if (pnC !== null) {
            if (pnC.TID() !== null) {
              let sv = pnC.TID().toLowerCase();
              if (sv.equals(DecorNode.C_lowercase_l)) {
                return DecorNode.ALIGN_MASK_LEFT;
              } else {
                if (sv.equals(DecorNode.C_lowercase_r)) {
                  return DecorNode.ALIGN_MASK_RIGHT;
                } else {
                  if (sv.equals(DecorNode.C_lowercase_c)) {
                    return DecorNode.ALIGN_MASK_HCENTER;
                  }
                }
              }
            }
          }
          return 0;
        }
      }
    }
  }
  return 0;
};
DecorNode.getVAlignVal = (pn) => {
  if (pn !== null) {
    let children = pn.getChildren();
    if (children !== null) {
      for (let i = 0; i < children.size(); i++) {
        let pnC = children.elementAt(i);
        if (pnC !== null && pnC.TID() !== null && pnC.TID().toLowerCase().equals(DecorNode.C_lowercase_valign)) {
          pnC = pnC.firstChild();
          if (pnC !== null) {
            if (pnC.TID() !== null) {
              let sv = pnC.TID().toLowerCase();
              if (sv.equals(DecorNode.C_lowercase_t)) {
                return DecorNode.ALIGN_MASK_TOP;
              } else {
                if (sv.equals(DecorNode.C_lowercase_b)) {
                  return DecorNode.ALIGN_MASK_BOTTOM;
                } else {
                  if (sv.equals(DecorNode.C_lowercase_c)) {
                    return DecorNode.ALIGN_MASK_VCENTER;
                  }
                }
              }
            }
          }
          return 0;
        }
      }
    }
  }
  return 0;
};
DecorNode.getReactAlignTextValues = (h, v) => {
  // h=0, 2, 4
  // v=8, 16, 32
  return {
    textAlign: (h == 0 ? 'auto' : (h == DecorNode.ALIGN_MASK_LEFT ? 'left' : (h == DecorNode.ALIGN_MASK_HCENTER ? 'center' : (h == DecorNode.ALIGN_MASK_RIGHT ? 'right' : 'auto')))),
    textAlignVertical: (v == 0 ? 'auto' : (v == DecorNode.ALIGN_MASK_TOP ? 'top' : (v == DecorNode.ALIGN_MASK_VCENTER ? 'center' : (v == DecorNode.ALIGN_MASK_BOTTOM ? 'bottom' : 'auto'))))
  }
}
// these are for arbitrary containers.
DecorNode.getReactAlignValues = (h, v) => {
  // horizontal center:   flexDirection: column
  // left:  flexDirection: column, justifyContent: flex-start
  // vertical center:  flexDirection: row
  let hv = {};
  hv.flexDirection = 'column';
  if (h == DecorNode.ALIGN_MASK_LEFT) {
    hv.alignItems = 'flex-start';
  } else if (h == DecorNode.ALIGN_MASK_HCENTER) {
    hv.alignItems = 'center';
  } else if (h == DecorNode.ALIGN_MASK_RIGHT) {
    hv.alignItems = 'flex-end';
  } else {  // 0 or bad value
    hv.alignItems = 'flex-start';
  }
  if (v == DecorNode.ALIGN_MASK_TOP) {
    hv.flexDirection = 'column';
    hv.justifyContent = 'flex-start';
  } else if (v == DecorNode.ALIGN_MASK_VCENTER) {
    hv.flexDirection = 'column';
    hv.justifyContent = 'center';
  } else if (v == DecorNode.ALIGN_MASK_BOTTOM) {
    hv.flexDirection = 'column';
    hv.justifyContent = 'flex-end';
  } else {
    hv.flexDirection = 'column';
    hv.justifyContent = 'flex-start';
  }
  return hv;
}
DecorNode.findChildNode$2 = (pn, sTagName) => {
  if (pn !== null) {
    let children = pn.getChildren();
    if (children !== null) {
      for (let i = 0; i < children.size(); i++) {
        let pnC = children.elementAt(i);
        if (pnC !== null && pnC.TID() !== null && pnC.TID().toLowerCase().equals(sTagName)) {
          return pnC;
        }
      }
    }
  }
  return null;
};
DecorNode.parseUrlNode = (pn) => {
  let pnChild = pn.firstChild();
  if (pnChild !== null) {
    return pnChild.TID();
  }
  return null;
};
DecorNode.isHex = (chStr) => {
  let ch = chStr.charCodeAt(0);
  if (ch >= 48 && ch <= 57) {
    return true;
  } else {
    if (ch >= 97 && ch <= 102) {
      return true;
    } else {
      if (ch >= 65 && ch <= 70) {
        return true;
      } else {
        return false;
      }
    }
  }
}
DecorNode.cvtHex = (chStr) => {
  let ch = chStr.charCodeAt(0);

  if (ch >= 48 && ch <= 57) {
    return (ch - 48);
  } else {
    if (ch >= 97 && ch <= 102) {
      return (ch - 97 + 10);
    } else {
      if (ch >= 65 && ch <= 70) {
        return (ch - 65 + 10);
      } else {
        return 0;
      }
    }
  }
};

DecorNode.parseColorNode = (pC, status) => {
  let pAtt = null;
  pAtt = pC.firstChild();
  if (pAtt !== null) {
    let sColor = pAtt.TID();
    let myStat = new BStatus(true);
    let cColor = DecorNode.parseColorString(sColor, myStat);
    status.setStatus(myStat.getStatus());
    if (myStat.getStatus()) {
      return cColor;
    } else {
      return 0;
    }
  }
  status.setStatus(false);
  return 0;
};

DecorNode.parseColorString = (sColor, status) => {
  let iColorLen = sColor.length;
  if (iColorLen > 0) {
    if (sColor.charCodeAt(0) === 35 && iColorLen > 3) {
      let a = 0;
      let r = 0;
      let g = 0;
      let b = 0;
      if (iColorLen === 4) {
        r = DecorNode.cvtHex(sColor.charAt(1));
        g = DecorNode.cvtHex(sColor.charAt(2));
        b = DecorNode.cvtHex(sColor.charAt(3));
        r = (r * 255) / 15;
        g = (g * 255) / 15;
        b = (b * 255) / 15;
        status.setStatus(true);
        return ((0xff000000 | (r << 16) | (g << 8) | b)) >>> 0;
      } else {
        if (iColorLen === 5) {
          a = DecorNode.cvtHex(sColor.charAt(1));
          r = DecorNode.cvtHex(sColor.charAt(2));
          g = DecorNode.cvtHex(sColor.charAt(3));
          b = DecorNode.cvtHex(sColor.charAt(4));
          a = (a * 255) / 15;
          r = (r * 255) / 15;
          g = (g * 255) / 15;
          b = (b * 255) / 15;
          status.setStatus(true);
          return ((a << 24) | (r << 16) | (g << 8) | b);
        } else {
          if (iColorLen === 7) {
            r = DecorNode.cvtHex(sColor.charAt(1)) * 16;
            r += DecorNode.cvtHex(sColor.charAt(2));
            g = DecorNode.cvtHex(sColor.charAt(3)) * 16;
            g += DecorNode.cvtHex(sColor.charAt(4));
            b = DecorNode.cvtHex(sColor.charAt(5)) * 16;
            b += DecorNode.cvtHex(sColor.charAt(6));
            status.setStatus(true);
            return ((0xff000000 | (r << 16) | (g << 8) | b)) >>> 0;
          } else {
            if (iColorLen === 9) {
              a = DecorNode.cvtHex(sColor.charAt(1)) * 16;
              a += DecorNode.cvtHex(sColor.charAt(2));
              r = DecorNode.cvtHex(sColor.charAt(3)) * 16;
              r += DecorNode.cvtHex(sColor.charAt(4));
              g = DecorNode.cvtHex(sColor.charAt(5)) * 16;
              g += DecorNode.cvtHex(sColor.charAt(6));
              b = DecorNode.cvtHex(sColor.charAt(7)) * 16;
              b += DecorNode.cvtHex(sColor.charAt(8));
              status.setStatus(true);
              return ((a << 24) | (r << 16) | (g << 8) | b);
            }
          }
        }
      }
    } else {
      if (Character.isDigit(sColor.charAt(0))) {
        let iTemp = 0;
        for (let j = 0; j < iColorLen; j++) {
          if (!Character.isDigit(sColor.charAt(j))) {
            return 0;
          }
          iTemp = iTemp * 10;
          iTemp += sColor.charAt(j) - 48;
        }
        status.setStatus(true);
        return iTemp;
      }
    }
  }
  status.setStatus(false);
  return 0;
};

DecorNode.evalUnitsWithValue = (sFunc, fdv, width, height) => {
  if (sFunc.equals(DecorNode.C_lowercase_px)) {
    ;
  } else if (sFunc.equals(DecorNode.C_lowercase_in)) {
    fdv = fdv * DecorNode._density * 160.0;
  } else if (sFunc.equals(DecorNode.C_lowercase_cm)) {
    fdv = (fdv * DecorNode._density * 160.0) / 2.54;
  } else if (sFunc.equals(DecorNode.C_lowercase_fw)) {
    fdv = fdv * global.globalVars.getFontSize() / 100.0;
  } else if (sFunc.equals(DecorNode.C_lowercase_fh)) {
    fdv = fdv * global.globalVars.getFontSize() / 100.0;
  } else if (sFunc.equals(DecorNode.C_lowercase_t)) {
    fdv = (fdv * (((44.0 * DecorNode._density * 160.0) / 163.0) + 0.5)) / 100.0;
  } else if (sFunc.equals(DecorNode.C_lowercase_w)) { // width
    fdv = fdv * width / 100.0
  } else if (sFunc.equals(DecorNode.C_lowercase_h)) { // height
    fdv = fdv * height / 100.0
  }
  return fdv;
}

// fh(100) or cm(.4) or in(<n>) px(<n>) t(<n>)
DecorNode.parseUnitsWithValue = (pVal) => {
  let sVal = pVal.TID();
  if (sVal === null || sVal.length === 0) {
    return 0;
  }
  let fdv = 0;
  let len = sVal.length;
  let i = 0;
  let bDigits = true;
  while (bDigits && i < len) {
    if (!Character.isDigit(sVal.charAt(i))) {
      bDigits = false;
    }
    i++;
  }
  if (bDigits) {
    fdv = parseFloat(sVal);
  } else {
    let sUnits = sVal.toLowerCase();
    let pVal1 = pVal.firstChild();
    if (pVal1 !== null) {
      sVal = pVal1.TID();
      fdv = parseFloat(sVal);
      if (sUnits.equals(DecorNode.C_lowercase_px)) {
        ;
      } else if (sUnits.equals(DecorNode.C_lowercase_in)) {
        fdv = fdv * DecorNode._density * 160.0;
      } else if (sUnits.equals(DecorNode.C_lowercase_cm)) {
        fdv = (fdv * DecorNode._density * 160.0) / 2.54;
      } else if (sUnits.equals(DecorNode.C_lowercase_fw)) {
        fdv = fdv * global.globalVars.getFontSize() / 100.0;
      } else if (sUnits.equals(DecorNode.C_lowercase_fh)) {
        fdv = fdv * global.globalVars.getFontSize() / 100.0;
      } else if (sUnits.equals(DecorNode.C_lowercase_t)) {
        fdv = (fdv * (((44.0 * DecorNode._density * 160.0) / 163.0) + 0.5)) / 100.0;
      }
    }
  }
  return fdv;
}  

DecorNode.getTitle = (pnIn) => {
  let sTitle = null;

  let pnFind = DecorNode.findChildNode$2(pnIn, DecorNode.C_lowercase_title);
  if (pnFind != null) {
    let children = pnFind.getChildren();
    if (children != null) {
      for (let iChild=0; iChild<children.size(); iChild++) {
          let pn = children.elementAt(iChild);
          let sMe = pn.TID(); 
          if (sMe != null) {
            if (sTitle == null) {
              sTitle = sMe;
            } else {
              sTitle = sTitle + " " + sMe;
            }
          }
      }
    }
  }

  return sTitle;
}
DecorNode.getImgUrl = (pnIn) => {
  let sValue = null;

  let pnFind = DecorNode.findChildNode$2(pnIn, DecorNode.C_lowercase_image);
  if (pnFind != null) {
    pnFind = DecorNode.findChildNode$2(pnFind, DecorNode.C_lowercase_url);
    if (pnFind != null) {
      let pAtt = pnFind.firstChild();
      if (pAtt != null) {
        sValue = pAtt.TID();
      }
    }
  }

  return sValue;
}
DecorNode.getAction = (pnIn) => {
  let sValue = null;

  let pnFind = DecorNode.findChildNode$2(pnIn, DecorNode.C_lowercase_action);
  if (pnFind != null) {
    pnFind = DecorNode.findChildNode$2(pnFind, DecorNode.C_lowercase_url);
    if (pnFind != null) {
      let pAtt = pnFind.firstChild();
      if (pAtt != null) {
        sValue = pAtt.TID();
      }
    }
  }

  return sValue;
}

// input defAttribs are this items default attributes (if any). We combine with global attributes,
// then apply decornode adjustments.  fontFactor is a number 0.0 to 1.0 that determines the 'base' scale of the font
// our base font is 14 pts, so if we pass 1.0, use 14.0 pt. If we pass .5 use 7 pt, etc.
// 
DecorNode.getFontSettings = (pnFont, defAttribs) => {
  // defAttribs:  {fontWeight, fontStyle, fontSize}
  let gFont = global.globalVars.getFont();

  let fontFactor;
  if (defAttribs !== null && typeof defAttribs.fontFactor !== 'undefined') {
    fontFactor = defAttribs.fontFactor;
  } else {
    fontFactor = 1.0
  }

  let mySettings = {
    // normal, bold, 100, 200, ... 900.
    fontWeight: (defAttribs !== null && typeof defAttribs.fontWeight !== 'undefined' && defAttribs.fontWeight !== null ? defAttribs.fontWeight : gFont.fontWeight),
    // normal, italic
    fontStyle: (defAttribs !== null && typeof defAttribs.fontStyle !== 'undefined' && defAttribs.fontStyle !== null ? defAttribs.fontStyle : gFont.fontStyle),
    // 'none', 'underline', 'line-through', 'underline line-through'
    textDecorationLine: (defAttribs !== null && typeof defAttribs.textDecorationLine !== 'undefined' && defAttribs.textDecorationLine !== null ? defAttribs.textDecorationLine : gFont.textDecorationLine),
    // Typically do not pass fontSize, pass fontFactor unless you want a very explicity override
    fontSize: (defAttribs !== null && typeof defAttribs.fontSize !== 'undefined' && defAttribs.fontSize !== null ? defAttribs.fontSize : gFont.fontSize),
  }

  let fontPercent = 100.0;

  if (pnFont !== null) {
    if (DecorNode.findChildNode$2(pnFont, DecorNode.C_lowercase_bold) !== null) {
      mySettings.fontWeight = 'bold';
    }
    if (DecorNode.findChildNode$2(pnFont, DecorNode.C_lowercase_italic) !== null) {
      mySettings.fontStyle = 'italic';
    }
    if (DecorNode.findChildNode$2(pnFont, DecorNode.C_lowercase_underline) !== null) {
      mySettings.textDecorationLine = 'underline';
    }
    if (DecorNode.findChildNode$2(pnFont, DecorNode.C_lowercase_normal) !== null) {
      mySettings.fontWeight = 'normal';
    }
    let pnChild = DecorNode.findChildNode$2(pnFont, DecorNode.C_lowercase_mult);
    if (pnChild !== null) {
      pnChild = pnChild.firstChild();
      if (pnChild !== null) {
        let dVal = BNumberUtils.parseDouble(pnChild.TID());
        if (dVal !== Number.MAX_VALUE) {
          fontPercent = dVal;
        }
      }

    }
  }

  let tsize = (gFont.fontSize * fontFactor * fontPercent) / 100.0;
  mySettings.fontSize = Math.round(tsize);

  return mySettings;
}
