import Vector from "../graphics/Vector";

// _bm is actually {width: <width>, height: <height>, image: <ImageData>}
export default class AsyncImage {
    constructor$2(_url, _bm) {
      this.constructor$4(_url, _bm, AsyncImage.UNKNOWN_SIZE, AsyncImage.UNKNOWN_SIZE);
    }
    constructor$4(_url, _bm, reqWidth, reqHeight) {
      this.vUrls = new Vector();
      this.vUrls.addElement(_url);
      this.image = _bm;
      this.requestedWidth = reqWidth;
      this.requestedHeight = reqHeight;
    }
    constructor(...args$) {
      switch (args$.length) {
        case 2:
          return this.constructor$2(...args$);
        case 4:
          return this.constructor$4(...args$);
      }
    }
    getWorkInProgress() {
      return false;
    }
    addUrl(sUrl) {
      this.vUrls.addElement(sUrl);
    }
    containsUrl(sUrl) {
      if (this.vUrls != null) {
        for (let i=0; i<this.vUrls.size(); i++) {
          let s = this.vUrls.elementAt(i);
          if (s.equals(sUrl)) {
            return true;
          }
        }
      }
      return false;
    }
    contains(ai) {
      if (ai.vUrls !== null) {
        for (let i = 0; i < ai.vUrls.size(); i++) {
          let s = ai.vUrls.elementAt(i);
          if (!this.containsUrl(s)) {
            return false;
          }
        }
      }
      return true;
    }
  }
  AsyncImage.UNKNOWN_SIZE = -1;
  