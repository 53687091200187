import React from "react";
import { StyleSheet, Text, Image, View, PixelRatio, TouchableOpacity } from 'react-native';
import DecorBackground from "../graphics/DecorBackground";
import DecorNode from "../graphics/DecorNode";
import BBUtils from "./BBUtils";
import XYRect from './XYRect';
import Character from "../graphics/Character";
import ImageRow from './ImageRow';
import HTMLRow from './HTMLRow';
import HScrollRow from './HScrollRow';
import BImageSpec from "../graphics/BImageSpec";
import BPImage from "./BPImage";
import BIFrame from "./BIFrame";
import BPColor from "../graphics/BPColor";
import TropareHorizontalScrollView from "./TropareHorizontalScrollView";
import zIndex from "@mui/material/styles/zIndex";

export default class DrawView extends React.Component {
    constructor(props) {
        super(props);
        // props.item = index
        // props.width = width of area (e.g. if container is 320 and scroll bar, then we are 305)
        this.state = {
            imageAndOverlaySizes: null            // [rowIx][rowImgIx][ixImagOv]  {height:<>, wasSet: false}
        };
        this.cMenu = global.globalVars.CMenu();
        this._touchHeight = 44;// (((44.0 * PixelRatio.get() * 160.0) / 163.0) + 0.5);
        this.m_bOverrideFont = false;
        this.nRenderCalls = 0;      // used as a guard to do things on 1st iteration only.
        this.setMyImageHeight = this.setMyImageHeight.bind(this);

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.item != prevProps.item) {
            console.log('item change ' + prevProps.item.toString() + ' to ' + this.props.item.toString());
        }
        if (this.props.dnItemString != prevProps.dnItemString) {
            this.cMenu.m_decorNodeItems[this.props.item] = null;
            this.cMenu.m_decorNodeItemsString[this.props.item] = null;
        }
    }
    componentDidMount() {
    }
    componentWillUnmount() {
    }
    //TODO: Need to also check if this is an 'image' async update...
    /*
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.tkey != this.props.tkey) {
            return true;
        } else {
            return false;
        }
    }
    */
    computeDynamicDecorNode(arColumns, decorNode) {
        if (decorNode !== null && decorNode.getRoot() !== null && decorNode.getRoot().TID() !== null) {
            var sTid = decorNode.getRoot().TID();
            var sReplaced = this.cMenu.parseDollarArgsOffset(arColumns, sTid, false, 1);
            if (!sReplaced.toLowerCase().equals(sTid.toLowerCase())) {
                decorNode = DecorNode.FromAttributes(sReplaced, DecorNode.DECORNODE_TYPE_CELL);
            }
        }
        return decorNode;
    }
    getRowWidthMultiplier(_rowHint, _ixRow, _maxWidth) {
        let midlet = this.cMenu;

        let rowWidthTotal = 0.0;
        let fixedWidthTotal = 0;
        for (let i = 0; i < _rowHint.columns.length; i++) {
            let col = _rowHint.columns[i];
            let decorNode = col.dNode;
            decorNode = this.computeDynamicDecorNode(midlet.m_arColumns[_ixRow], decorNode);
            let bHoldWidth = false;
            let myPotentialFixedWidth = 0;
            if (decorNode !== null) {
                if (decorNode.nodeHasTag(DecorNode.C_lowercase_layout, DecorNode.C_lowercase_nocompress) !== null) {
                    bHoldWidth = true;
                }
                let myFixedPixels = decorNode.widthInPixels();
                if (myFixedPixels !== Number.MAX_SAFE_INTEGER) {
                    myPotentialFixedWidth = myFixedPixels;
                }
            }
            if (col.widthPercent > 0.0) {
                let itemId = col.item;
                let k = itemId.indexOf('I');
                if (k !== -1) {
                    let itemNo = 0;
                    k++;
                    while (k < itemId.length && Character.isDigit(itemId.charAt(k))) {
                        itemNo *= 10;
                        itemNo += itemId.charCodeAt(k) - '0'.charCodeAt(0);
                        k++;
                    }
                    let imgUrl = midlet.getImageUrl(itemNo, midlet.m_arColumns[_ixRow]);
                    if ((imgUrl !== null && imgUrl.length > 0) || bHoldWidth) {
                        if ((!global.globalVars.HideImages()) || bHoldWidth) {
                            fixedWidthTotal += myPotentialFixedWidth;
                            rowWidthTotal += col.widthPercent;
                        }
                    }
                } else {
                    fixedWidthTotal += myPotentialFixedWidth;
                    rowWidthTotal += col.widthPercent;
                }
            }
        }
        if (rowWidthTotal === 0.0 && fixedWidthTotal === 0) {
            return 0;
        } else {
            if (_maxWidth === fixedWidthTotal) {
                return 1.0;
            } else {
                return (_maxWidth - fixedWidthTotal) / ((((rowWidthTotal * _maxWidth) / 100.0) - fixedWidthTotal));
            }
        }
    }
    getLineHeight(_mainIx, psz, _ix, _fontPercent, pnFont) {
        let bBold = this.cMenu.m_bOverrideFont ? false : _ix === 0;
        return this.sizeWithFont(_mainIx, bBold, psz, _fontPercent, _xpos, _ypos, _width, pnFont, ixMarginOffset);
    }
    getFitTextheight(_mainIx, rowText, _fontPercent, pnFont) {
        let height = 0;
        let totalHeight = 0;
        let lines = BBUtils.split(rowText, '|');
        let ix = 0;
        while (ix < lines.length) {
            height = this.getLineHeight(_mainIx, lines[ix], ix, _xpos, _ypos, _width, _fontPercent, pnFont, ixMarginOffset);
            if (height === 0 && ix > 0) {
                height = this.getLineHeight(_mainIx, ' ', ix, _xpos, _ypos, _width, _fontPercent, pnFont, ixMarginOffset);
            }
            _ypos += height;
            totalHeight += height;
            ix++;
        }
        return totalHeight;
    }
/*
    drawImageRow(g, ir, _xpos, _ypos) {
        let minHeight = ir.getMinHeight();
        let iSubLine = ir.getSubLine();
        let vAlign = ir.getVAlign();
        let hAlign = ir.getHAlign();
        let rowHeight = m_arSubLines[iSubLine];
        let thisDest = ir.copyRect();
        if (thisDest.width <= 0 || thisDest.height <= 0) {
            return undefined;
        }
        thisDest.x += _xpos;
        thisDest.y += _ypos;
        let imgUrl = ir.getUrl();
        if (isLocalResourceUrl(imgUrl) === null) {
            imgUrl = BBUtils.C_GEOMETRY_PLAIN + thisDest.width + 'x' + thisDest.height + '/' + imgUrl;
        }
        let aImage = getImageForUrl(imgUrl, thisDest.width, thisDest.height);
        if (aImage === null || aImage.getWorkInProgress()) {
            if ((vAlign & DecorNode.ALIGN_MASK_VCENTER) === DecorNode.ALIGN_MASK_VCENTER) {
                if (rowHeight > minHeight) {
                    thisDest.y += (rowHeight - minHeight) / 2;
                }
            } else {
                if ((vAlign & DecorNode.ALIGN_MASK_BOTTOM) === DecorNode.ALIGN_MASK_BOTTOM) {
                    if (rowHeight > minHeight) {
                        thisDest.y += (rowHeight - minHeight);
                    }
                }
            }
            this.showImageNoImage(g, thisDest);
        } else {
            if (aImage.image !== null) {
                let imgHeight = aImage.image.getHeight();
                let imgWidth = aImage.image.getWidth();
                if ((vAlign & DecorNode.ALIGN_MASK_VCENTER) === DecorNode.ALIGN_MASK_VCENTER) {
                    if (minHeight < rowHeight) {
                        thisDest.y += (rowHeight - minHeight) / 2;
                    }
                    if (imgHeight < thisDest.height) {
                        thisDest.y += (thisDest.height - imgHeight) / 2;
                    }
                } else {
                    if ((vAlign & DecorNode.ALIGN_MASK_BOTTOM) === DecorNode.ALIGN_MASK_BOTTOM) {
                        if (minHeight < rowHeight) {
                            thisDest.y += (rowHeight - minHeight);
                        }
                        if (imgHeight < thisDest.height) {
                            thisDest.y += (thisDest.height - imgHeight);
                        }
                    }
                }
                if ((hAlign & DecorNode.ALIGN_MASK_HCENTER) === DecorNode.ALIGN_MASK_HCENTER) { } else {
                    if ((hAlign & DecorNode.ALIGN_MASK_RIGHT) === DecorNode.ALIGN_MASK_RIGHT) {
                        if (imgWidth < thisDest.width) {
                            thisDest.x += (thisDest.width - imgWidth);
                            thisDest.width = imgWidth;
                        }
                    } else {
                        if ((hAlign & DecorNode.ALIGN_MASK_LEFT) === DecorNode.ALIGN_MASK_LEFT) {
                            if (imgWidth < thisDest.width) {
                                thisDest.width = imgWidth;
                            }
                        }
                    }
                }
                let itemNo = 1;
                let nColor = getTintedColor('image', itemNo);
                if (nColor !== null) {
                    let colorFilter = this.mPaintCurrent.getColorFilter();
                    let mode = PorterDuff.Mode.SRC_ATOP;
                    let cf = new PorterDuffColorFilter(nColor, mode);
                    this.mPaintCurrent.setColorFilter(cf);
                    this.showImage(g, aImage.image, thisDest);
                    this.mPaintCurrent.setColorFilter(colorFilter);
                } else {
                    this.showImage(g, aImage.image, thisDest);
                }
            }
        }
    }
    */

    // {row: _ixRow, arIndex: iix, imgIx: ix}
    setMyImageHeight(ssObj, width, height) {
        var newIO = {...this.state.imageAndOverlaySizes};
        newIO[ssObj.row][ssObj.arIndex][ssObj.imgIx].height = height;
        newIO[ssObj.row][ssObj.arIndex][ssObj.imgIx].width = width;
        newIO[ssObj.row][ssObj.arIndex][ssObj.imgIx].wasSet = true;
        //TODO: delay for a bit so don't stack these up...
        this.setState( {
            imageAndOverlaySizes: newIO
        })
    }

//    sizeImage = (e) => {
//        Image.getSize(e.url, (width, height) => {this.setState({width, height})});
//∂    }
    // NOTE: the _maxWidth is the area to draw in. The scroll bar is 'outside' this area, so we don't compensate for it
    // _margins is the margin for the entire cell, so we pass it here to inject it if we have any content
    getThisRowHeight(_rowHint, _ixRow, _maxWidth, _margins, _ixRowHint) {
        let midlet = this.cMenu;

        let height = 0;
        let width = 0;
        let xoffset = 0;
        let fullWidth = 0;
        let fontMultiplier = 0;
        let thisDest = new XYRect();
        let imgRow = null;
        let htmlRow = null;
        let hscrollRow = null;
        let bHasAccessory = midlet.HasAccessory(_ixRow);
        let myMaxWidth = 0;
        let maxHeight = 0;
        let rowWidthMultiplier = this.getRowWidthMultiplier(_rowHint, _ixRow, _maxWidth);

        let arRender = [];  // array of objects to render, need width.
        let oLastEmpty = [];

        for (let i = 0; i < _rowHint.columns.length; i++) {
            let marginTop = 0;
            let marginBottom = 0;
            let marginLeft = 0;
            let marginRight = 0;
            myMaxWidth = _maxWidth;
            marginTop = 0;
            marginLeft = 0;
            marginBottom = 0;
            marginRight = 0;
            height = 0;
            fullWidth = 0;
            let col = _rowHint.columns[i];
            let decorNode = col.dNode;
            decorNode = this.computeDynamicDecorNode(midlet.m_arColumns[_ixRow], decorNode);
            let fontNode = null;
            let bHoldWidth = false;
            if (decorNode !== null) {
                if (!bHasAccessory) {
                    let pnLayout = decorNode.nodeHasTag(DecorNode.C_lowercase_layout, DecorNode.C_lowercase_chevron);
                    if (pnLayout !== null) {
                        myMaxWidth = _maxWidth - (global.globalVars.getChevronWidth() /*+ DrawView.C_SCROLLBAR_WIDTH*/);
                    }
                }
                fontNode = decorNode.findChildNode(DecorNode.C_lowercase_font);
                if (decorNode.nodeHasTag(DecorNode.C_lowercase_layout, DecorNode.C_lowercase_nocompress) !== null) {
                    bHoldWidth = true;
                }
            } else {
                fontNode = null;
            }
            let vAlign = DecorNode.getVAlignVal(fontNode);
            let hAlign = DecorNode.getHAlignVal(fontNode);
            fontMultiplier = col.fontPercent;
            if (fontMultiplier === 0.0) {
                fontMultiplier = 100.0;
            }
            // myMaxWidth is in px units.
            width = (myMaxWidth * col.widthPercent * rowWidthMultiplier);
            if (width > 0) {
                let myPotentialFixedWidth = 0;
                width = ((myMaxWidth * col.widthPercent * rowWidthMultiplier) / 100.0);
                if (decorNode !== null) {
                    let myFixedPixels = decorNode.widthInPixels();
                    if (myFixedPixels !== Number.MAX_SAFE_INTEGER) {
                        myPotentialFixedWidth = myFixedPixels;
                    }
                }
                fullWidth = width;
                thisDest.x = 0; // _xpos;
                thisDest.y = 0; // _ypos;
                thisDest.x += xoffset;      // if thisDest.x is non-zero and we have text, we need to leave horizontal space
                thisDest.width = width;
                let itemId = col.item;
                let isImage = itemId.indexOf('I') !== -1;
                let isHTML = itemId.indexOf('H') !== -1;
                let isHScroll = itemId.indexOf('Z') !== -1;
                if (isImage || isHTML || isHScroll) {
                    let itemNo = 0;
                    let k = 1;
                    while (k < itemId.length && Character.isDigit(itemId.charAt(k))) {
                        itemNo *= 10;
                        itemNo += itemId.charCodeAt(k) - '0'.charCodeAt(0);
                        k++;
                    }
                    let imgUrl = null;
                    if (isImage) {
                        imgUrl = midlet.getImageUrl(itemNo, midlet.m_arColumns[_ixRow]);
                        if (imgUrl != null) {
                            imgUrl = BImageSpec.getRemoteImageUrl(imgUrl);
                        }
                    } else if (isHTML) {
                        imgUrl = midlet.getHTMLUrl(itemNo, midlet.m_arColumns[_ixRow]);
                    } else if (isHScroll) {
                        imgUrl = midlet.getHScrollDecor(itemNo, midlet.m_arColumns[_ixRow]);
                    }
                    if ((imgUrl !== null && imgUrl.length > 0) || bHoldWidth) {
                        if (isHScroll || isHTML || (isImage && !global.globalVars.HideImages()) || bHoldWidth) {
                            if (myPotentialFixedWidth > 0) {
                                width = myPotentialFixedWidth;
                                fullWidth = myPotentialFixedWidth;
                                thisDest.width = width;
                            }
                            thisDest.height = _rowHint.minHeight;
                            let outerHeight = thisDest.height + (isHScroll ? 18: 0);
                            let outerWidth = thisDest.width;
                            if (decorNode !== null) {
                                // NOTE: in CSS terms, our Margin is actually Padding
                                if (decorNode.hasMarginTop()) {
                                    marginTop = decorNode.getMarginTop();
                                }
                                if (decorNode.hasMarginBottom()) {
                                    marginBottom = decorNode.getMarginBottom();
                                }
                                if (decorNode.hasMarginLeft()) {
                                    marginLeft = decorNode.getMarginLeft();
                                }
                                if (decorNode.hasMarginRight()) {
                                    marginRight = decorNode.getMarginRight();
                                }
                                if (thisDest.width > marginLeft + marginRight) {
                                    thisDest.width -= (marginLeft + marginRight);
                                    thisDest.x += marginLeft;
                                }
                                if (thisDest.height > marginTop + marginBottom) {
                                    thisDest.height -= (marginTop + marginBottom);
                                    thisDest.y += marginTop;
                                }
                            }

                            let minHeight = _rowHint.minHeight;
                            if (imgUrl !== null && imgUrl.length > 0) {
                                if (isImage) {
                                    // https://troparesheets-dev.boopsie.tredir.com/service/convert/-geometry%20<wid>x<ht>/<imgUrl>
                                    imgUrl = global.globalVars.getConvertUrl(imgUrl, thisDest.width, thisDest.height);
                                    // For overlay images, look for Overlay; 1; 0  ... Overlay; 1; 1, etc.
                                    // like getImageUrl but with checking getSecondExtra, then put array as 1st param of new ImageRow
                                    var imgUrls = [{url: imgUrl, scale: true}];
                                    var overlayUrl = "foo";
                                    var ixO = 0;
                                    while (overlayUrl != null) {
                                        var retObj = {};
                                        overlayUrl = midlet.getOverlayUrl(itemNo, midlet.m_arColumns[_ixRow], ixO, thisDest.width, thisDest.height, retObj);
                                        // We 'half' got scaling to work, the problem is we can pull it and not scale, but on display, the image gets stretched if
                                        // smaller than the destination because we use flex/column vs. absolute position.  If we used absolute, we could put top,left at 0,0
                                        if (overlayUrl != null) {
                                            imgUrls.push({url: overlayUrl, scale: retObj.scale});
                                            ixO++;
                                        }
                                    }

                                    imgRow = new ImageRow(imgUrls, thisDest, _rowHint.minHeight, vAlign, hAlign, itemNo);
//                                    midlet.m_arImageRows.get(_ixRow).addObj(imgRow);
                                } else if (isHTML) {
                                    htmlRow = midlet.findHTMLItemNo(midlet.m_arHTMLRows.get(_ixRow), itemNo);
                                    if (htmlRow !== null) {
                                        var newRect = thisDest;
                                        if (htmlRow.heightSet) {
                                            minHeight = htmlRow.getHeight();
                                            newRect.height = minHeight;
                                            outerHeight  = minHeight;   // testing... 2021-12-22
                                        }
                                        htmlRow.update(imgUrl, newRect, _rowHint.minHeight, vAlign, hAlign);
                                    } else {
                                        htmlRow = new HTMLRow(imgUrl, thisDest, _rowHint.minHeight, vAlign, hAlign, itemNo);
                                        htmlRow.setWebView(React.createRef());
                                        var myV = midlet.m_arHTMLRows.get(_ixRow);
                                        myV.addObj(htmlRow);
                                    }
                                } else if (isHScroll) {
                                    hscrollRow = midlet.findHScrollItemNo(midlet.m_arHScrollRows.get(_ixRow), itemNo);
                                    if (hscrollRow !== null) {
                                        var newRect = thisDest;
                                        if (hscrollRow.heightSet) {
                                            minHeight = hscrollRow.getHeight();
                                            newRect.height = minHeight;
                                            outerHeight  = minHeight;   // testing... 2021-12-22
                                        }
                                        hscrollRow.update(imgUrl, newRect, _rowHint.minHeight, vAlign, hAlign);
                                    } else {
                                        hscrollRow = new HScrollRow(imgUrl, thisDest, _rowHint.minHeight, vAlign, hAlign, itemNo);
                                        hscrollRow.setHScrollView(React.createRef());
                                        var myV = midlet.m_arHScrollRows.get(_ixRow);
                                        myV.addObj(hscrollRow);
                                    }
                                }
                                var dnHZ;
                                if (!isImage)
                                    dnHZ = DecorNode.FromAttributes(imgUrl);
                                else
                                    dnHZ = null;
                                arRender.push(
                                    {
                                        ih: isImage ? imgRow : (isHTML ? htmlRow : hscrollRow),
                                        ihType: isImage ? 'i' : isHTML ? 'h' : 'z',
                                        margin: {
                                            top: marginTop,
                                            left: marginLeft,
                                            bottom: marginBottom,
                                            right: marginRight
                                        },
                                        decorNode: decorNode,
                                        decorNodeHZ: dnHZ,
                                        height: thisDest.height,
                                        width: thisDest.width,
                                        outerHeight: outerHeight,
                                        outerWidth: outerWidth,
                                        valign: vAlign,
                                        halign: hAlign,
                                    }
                                );
                                oLastEmpty = [];
                            } // TODO: the else of this is bHoldWidth (push an 'empty' cell?)
                            height = minHeight;
                        } else {
                            fullWidth = 0;
                            height = 0;
                        }
                    } else {
                        fullWidth = 0;
                        height = 0;
                    }
                } else {    // not image or html..
                    if (myPotentialFixedWidth > 0) {
                        width = myPotentialFixedWidth;
                        fullWidth = myPotentialFixedWidth;
                        thisDest.width = width;
                    }
                    marginTop = DrawView.TOP_OFFSET_HINT;
                    marginBottom = DrawView.TOP_OFFSET_HINT;
                    marginLeft = DrawView.LEFT_OFFSET_HINT;
                    marginRight = DrawView.LEFT_OFFSET_HINT;
                    if (decorNode !== null) {
                        if (decorNode.hasMarginTop()) {
                            marginTop = decorNode.getMarginTop();
                        }
                        if (decorNode.hasMarginBottom()) {
                            marginBottom = decorNode.getMarginBottom();
                        }
                        if (decorNode.hasMarginLeft()) {
                            marginLeft = decorNode.getMarginLeft();
                        }
                        if (decorNode.hasMarginRight()) {
                            marginRight = decorNode.getMarginRight();
                        }
                    }

                    if (itemId.length >= 2) {
                        if (itemId.charAt(0) === '$') {
                            let itemNo = 0;
                            let k = 1;
                            while (k < itemId.length && Character.isDigit(itemId.charAt(k))) {
                                itemNo *= 10;
                                itemNo += itemId.charCodeAt(k) - '0'.charCodeAt(0);
                                k++;
                            }
                            if (k < itemId.length) {
                                if (itemId.charAt(k) === '[') {
                                    let ino = 0;
                                    k++;
                                    while (k < itemId.length && Character.isDigit(itemId.charAt(k))) {
                                        ino *= 10;
                                        ino += itemId.charCodeAt(k) - '0'.charCodeAt(0);
                                        k++;
                                    }
                                    if (k < itemId.length) {
                                        if (itemId.charAt(k) === ']') {
                                            // tempHeight = this.getSubLineHeightWithSubIndex(_ixRow, itemNo - 1, ino, thisDest.x, thisDest.y, thisDest.width, fontMultiplier, fontNode, ixMarginOffset);
                                            if (_ixRow >= 0 && _ixRow < midlet.m_arKeyPhrases.length) {
                                                let ixColumn = itemNo - 1;
                                                if (ixColumn >= 0 && ixColumn < midlet.m_arColumns[_ixRow].length) {
                                                    let lines = BBUtils.split(midlet.m + _arColumns[_ixRow], '|');
                                                    // lines[ixSub]
                                                    if (ixSub < lines.length) {
                                                        if (oLastEmpty.length > 0) {
                                                            oLastEmpty.forEach( elem => arRender.push(elem) );
                                                        }
                                                        arRender.push(
                                                            {
                                                                text: lines.slice(ixSub, ixSub + 1),   // replace | with {"\n"}
                                                                decorNode: decorNode,
                                                                fontNode: fontNode,
                                                                fontMultiplier: fontMultiplier,
                                                                margin: {
                                                                    top: marginTop,
                                                                    left: marginLeft,
                                                                    bottom: marginBottom,
                                                                    right: marginRight
                                                                },
                                                                //height: minHeight,
                                                                width: thisDest.width,
                                                                valign: vAlign,
                                                                halign: hAlign,
                                                            }
                                                        );
                                                        oLastEmpty = [];
                                                        height = this._touchHeight;
                                                    }

                                                }
                                            }
                                        } else {
                                            if (itemId.charAt(k) === '*') {
                                                k++;
                                                if (k < itemId.length) {
                                                    if (itemId.charAt(k) === ']') {
                                                        //tempHeight = this.getSubLineHeightFromSubIndex(_ixRow, itemNo - 1, ino, fontMultiplier, fontNode);
                                                        if (_ixRow >= 0 && _ixRow < midlet.m_arKeyPhrases.length) {
                                                            let ixColumn = itemNo - 1;
                                                            if (ixColumn >= 0 && ixColumn < midlet.m_arColumns[_ixRow].length) {
                                                                let lines = BBUtils.split(midlet.m + _arColumns[_ixRow], '|');
                                                                // lines[ixSub]
                                                                if (ixSub < lines.length) {
                                                                    if (oLastEmpty.length > 0) {
                                                                        oLastEmpty.forEach( elem => arRender.push(elem) );
                                                                    }            
                                                                    arRender.push(
                                                                        {
                                                                            text: lines.slice(ixSub),   // replace | with {"\n"}
                                                                            decorNode: decorNode,
                                                                            fontNode: fontNode,
                                                                            fontMultiplier: fontMultiplier,
                                                                            margin: {
                                                                                top: marginTop,
                                                                                left: marginLeft,
                                                                                bottom: marginBottom,
                                                                                right: marginRight
                                                                            },
                                                                            width: thisDest.width,
                                                                            valign: vAlign,
                                                                            halign: hAlign,
                                                                        }
                                                                    );
                                                                    oLastEmpty = [];
                                                                    height = this._touchHeight;
                                                                }

                                                            }
                                                        }


                                                    }
                                                }
                                            } else { }
                                        }
                                    }
                                }
                            } else {
                                if (itemNo > 0) {
                                    itemNo--;
                                    if (midlet.m_arColumns[_ixRow].length > itemNo) {
                                        if (midlet.m_arColumns[_ixRow][itemNo] !== null &&
                                            midlet.m_arColumns[_ixRow][itemNo].length > 0) {
                                            // getFitLineHeight(  midlet.m_arColumns[_ixRow][itemNo], fontMultiplier, fontNode)
                                            if (oLastEmpty.length > 0) {
                                                oLastEmpty.forEach( elem => arRender.push(elem) );
                                            }
                                            arRender.push(
                                                {
                                                    text: BBUtils.split(midlet.m_arColumns[_ixRow][itemNo], '|'),   // replace | with {"\n"}
                                                    decorNode: decorNode,
                                                    fontNode: fontNode,
                                                    fontMultiplier: fontMultiplier,
                                                    margin: {
                                                        top: marginTop,
                                                        left: marginLeft,
                                                        bottom: marginBottom,
                                                        right: marginRight
                                                    },
                                                    //height: minHeight,
                                                    width: thisDest.width,
                                                    valign: vAlign,
                                                    halign: hAlign,
                                                }
                                            );
                                            oLastEmpty = [];
                                            height = this._touchHeight;
                                        } else {
                                            oLastEmpty.push( {
                                                text: [" "],
                                                decorNode: decorNode,
                                                fontNode: fontNode,
                                                fontMultiplier: fontMultiplier,
                                                margin: {
                                                    top: marginTop,
                                                    left: marginLeft,
                                                    bottom: marginBottom,
                                                    right: marginRight
                                                },
                                                //height: minHeight,
                                                width: thisDest.width,
                                                valign: vAlign,
                                                halign: hAlign,
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
                if (height > maxHeight) {
                    maxHeight = height;
                }
            }
            xoffset += fullWidth;
        }

        if (arRender.length > 0) {  // could also check maxHeight > 0
            let mh = maxHeight > this._touchHeight ? maxHeight : this._touchHeight;
            mh = maxHeight;

            var newIO;
            var didChange = false;
            if (this.state.imageAndOverlaySizes == null) {
                newIO = {};
                didChange = true;
            } else {
                newIO = {...this.state.imageAndOverlaySizes};
            }
            if (!newIO.hasOwnProperty(_ixRowHint)) {
                newIO[_ixRowHint] = {};
                didChange = true;
            }
            arRender.map((item, iix) => {
                if (item.hasOwnProperty('ih') && item.ihType === 'i') {
                    if (!newIO[_ixRowHint].hasOwnProperty(`${iix}`)) {
                        newIO[_ixRowHint][iix] = {};
                        didChange = true;
                    }
                    var imagesAndOverlays = item.ih.getUrl();
                    imagesAndOverlays.map((imgU, ixIO) => {
                        if (!newIO[_ixRowHint][`${iix}`].hasOwnProperty(`${ixIO}`)) {
                            newIO[_ixRowHint][`${iix}`][`${ixIO}`] = {};
                            didChange = true;
                        }
                        // {height:<>, wasSet: false}
                        if (!newIO[_ixRowHint][`${iix}`][`${ixIO}`].hasOwnProperty('height')) {
                            newIO[_ixRowHint][`${iix}`][`${ixIO}`] = { height: item.height, width: item.width, wasSet: false };
                            didChange = true;
                        } else {
                            if (!newIO[_ixRowHint][`${iix}`][`${ixIO}`].wasSet) {
                                if (newIO[_ixRowHint][`${iix}`][`${ixIO}`].height != item.height) {
                                    newIO[_ixRowHint][`${iix}`][`${ixIO}`].height = item.height;
                                    didChange = true;
                                }
                                if (newIO[_ixRowHint][`${iix}`][`${ixIO}`].width != item.width) {
                                    newIO[_ixRowHint][`${iix}`][`${ixIO}`].width = item.width;
                                    didChange = true;
                                }
                            }
                        }
                    })
                }
            })
            if (didChange) {
                this.setState({
                    imageAndOverlaySizes: newIO
                })
            }




            // Three cases for rendering Image, HTML, Text
            /*
            Image/HTML/HScroll
            {
                ih: isImage ? imgRow : isHTML? htmlRow: hscrollRow,
                margin: {
                    top: marginTop,
                    left: marginLeft,
                    bottom: marginBottom,
                    right: marginRight
                },
                height: minHeight,
                width: thisDest.width,
                valign: vAlign,
                halign: hAlign,
            }

            Text
            {
                text: lines.slice(ixSub),   // put {"\n"} at end of each line (text: [])
                fontNode: fontNode,
                fontMultiplier: fontMultiplier,
                margin: {
                    top: marginTop,
                    left: marginLeft,
                    bottom: marginBottom,
                    right: marginRight
                },
                //height: minHeight,
                width: thisDest.width,
                valign: vAlign,
                halign: hAlign,
            }
            */
           let that = this;
            return (
                <View style={
                    {
                        paddingTop: _margins.y,
                        paddingLeft: _margins.x,
                        paddingBottom: _margins.height,
                        paddingRight: _margins.width,
                        minHeight: mh,
                        display: 'flex',
                        flexDirection: 'row',
                    }
                }>
                    {
                        arRender.map((item, iix) => {
                            if (item.hasOwnProperty('text')) {
                                let align = DecorNode.getReactAlignValues(item.halign, item.valign);
                                return (
                                    <DecorBackground
                                    key={iix}
                                    dNode={item.decorNode} 
                                    dnType={DecorNode.DECORNODE_TYPE_CELL}
                                    width={item.width}
                                    >
                                    <View style={
                                        {
                                            paddingTop: item.margin.top,
                                            paddingLeft: item.margin.left,
                                            paddingBottom: item.margin.bottom,
                                            paddingRight: item.margin.right,
                                            width: item.width,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            ...align,
                                            minHeight: mh,
                                        }
                                    }
                                    >
                                        {item.text.map((txt, ix) => {
                                            // basically if we have || = we should output an empty line, so perhaps
                                            // TODO: if txt.length == 0, change to " " if not 0 ?
                                            if (txt.length == 0 && ix == 0) {
                                                return null;
                                            } else
                                            {
                                                let bBold = this.m_bOverrideFont ? false : ix == 0;
                                                let fw;
                                                if (bBold) {
                                                    fw = 'bold';
                                                } else {
                                                    fw = 'normal';
                                                }
                                                let fontSettings = DecorNode.getFontSettings(item.fontNode, {
                                                    fontWeight: fw
                                                });
                                                // Order matches iOS and Android
                                                fontSettings.fontSize = fontSettings.fontSize * item.fontMultiplier / 100.0;
                                                if (item.decorNode !== null && item.decorNode.hasFGColor()) {
                                                    fontSettings.color = BPColor.intToHexRGBA(item.decorNode.getFGColor());
                                                } else if (midlet.HasForeColor(_ixRow)) {
                                                    fontSettings.color = BPColor.intToHexRGBA(midlet.GetForeColor(_ixRow));
                                                } else if (midlet.m_decorNodeItems[_ixRow] != null && midlet.m_decorNodeItems[_ixRow].hasFGColor()) {
                                                    fontSettings.color = BPColor.intToHexRGBA(midlet.m_decorNodeItems[_ixRow].getFGColor())
                                                }

                                                return (
                                                    <Text key={ix} 
                                                        style={
                                                        {
                                                            minHeight: fontSettings.fontSize,
                                                            fontWeight: fw,
                                                            ...fontSettings,
                                                        }
                                                    }>
                                                        {txt}
                                                    </Text>
                                                )
                                            }
                                        })}
                                    </View>
                                    </DecorBackground>
                                );
                            } else if (item.hasOwnProperty('ih')) {
                                var align = DecorNode.getReactAlignValues(item.halign, item.valign);
                                var imagesAndOverlays = item.ih.getUrl();   // array...
                                var heightOffset = 0;
                                var toAdd = 0;
                                if (item.ihType === 'i') {
                                    if (!that.state.imageAndOverlaySizes ||
                                        !that.state.imageAndOverlaySizes[_ixRowHint]) {
                                        return null;
                                    } else  {
                                    return (
                                        <DecorBackground 
                                        key={iix}
                                        dNode={item.decorNode} 
                                        dnType={DecorNode.DECORNODE_TYPE_CELL}
                                        width={item.width}

                                        >
    
                                            <View style={
                                                {
                                                    paddingTop: item.margin.top,
                                                    paddingLeft: item.margin.left,
                                                    paddingBottom: item.margin.bottom,
                                                    paddingRight: item.margin.right,
                                                    width: item.outerWidth,
                                                    height: item.outerHeight,
                                                    maxHeight: item.outerHeight,
                                                    ...align,
                                                }
                                            }
                                            >
                                                <View>
                                                {imagesAndOverlays.map((imgU, ix) => {
                                                    heightOffset += toAdd;
                                                    toAdd = that.state.imageAndOverlaySizes[_ixRowHint][iix][ix].height; // item.height;
                                                    if (ix == 0) {
                                                        return (
                                                            <BPImage
                                                                width={that.state.imageAndOverlaySizes[_ixRowHint][iix][ix].width}
                                                                height={that.state.imageAndOverlaySizes[_ixRowHint][iix][ix].height}
                                                                uri={imgU.url}
                                                                refresh={that.props.refresh}
                                                                style={{
                                                                    zIndex: ix + 1
                                                                }}
                                                                ssObj={{row: _ixRowHint, arIndex: iix, imgIx: ix}}
                                                                sizeSet={that.setMyImageHeight}
                                                            />
                                                        )
                                                    } else {
                                                        // We don't know the height of the below until after it loads, so adjusting
                                                        // our position here won't work unless we use absolute position...
                                                        if (imgU.scale) {
                                                            return (
                                                                <BPImage
                                                                    width={that.state.imageAndOverlaySizes[_ixRowHint][iix][ix].width}
                                                                    height={that.state.imageAndOverlaySizes[_ixRowHint][iix][ix].height}
                                                                    uri={imgU.url}
                                                                    refresh={that.props.refresh}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        zIndex: ix + 1
                                                                    }}
                                                                    ssObj={{row: _ixRowHint, arIndex: iix, imgIx: ix}}
                                                                    sizeSet={that.setMyImageHeight}
                                                                    />
                                                            )
                                                        } else {
                                                            return (
                                                                <BPImage
                                                                    width={null}
                                                                    height={null}
                                                                    uri={imgU.url}
                                                                    refresh={that.props.refresh}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        zIndex: ix + 1
                                                                    }}
                                                                    ssObj={{row: _ixRowHint, arIndex: iix, imgIx: ix}}
                                                                    sizeSet={that.setMyImageHeight}
                                                                    />
                                                            )
                                                        }

                                                    }
                                                })}
                                                </View>
                                            </View>
                                        </DecorBackground>
                                    )
                                            }
                                } else if (item.ihType === 'h') {
                                    return (
                                        <DecorBackground
                                            key={iix}
                                            dNode={item.decorNode}
                                            dnType={DecorNode.DECORNODE_TYPE_CELL}
                                        >

                                        <DecorBackground
                                            key={iix}
                                            dNode={item.decorNodeHZ}
                                            dnType={DecorNode.DECORNODE_TYPE_CELL}
                                        >
                                            <BIFrame style={
                                                {
                                                    paddingTop: item.margin.top,
                                                    paddingLeft: item.margin.left,
                                                    paddingBottom: item.margin.bottom,
                                                    paddingRight: item.margin.right,
                                                    width: item.outerWidth,
                                                    height: item.outerHeight,
                                                    ...align,
                                                }
                                            }
                                                ref={item.ih.getWebView()}
                                                htmlrow={item.ih}
                                                url={global.globalVars.appClass.addBoopsieParamsToUrl(item.ih.getUrl())}
                                                iframeid={item.ih.getId()}
                                            >

                                            </BIFrame>
                                        </DecorBackground>
                                        </DecorBackground>
                                    )
                                } else if (item.ihType === 'z') {
                                    return (
                                        <DecorBackground
                                            key={iix}
                                            dNode={item.decorNode}
                                            dnType={DecorNode.DECORNODE_TYPE_CELL}
                                        >
                                        <DecorBackground
                                            key={iix}
                                            dNode={item.decorNodeHZ}
                                            dnType={DecorNode.DECORNODE_TYPE_CELL}
                                        >
                                            <View style={
                                                {
                                                    paddingTop: item.margin.top,
                                                    paddingLeft: item.margin.left,
                                                    paddingBottom: item.margin.bottom,
                                                    paddingRight: item.margin.right,
                                                    width: item.outerWidth,
                                                    height: item.outerHeight,
                                                    ...align,
                                                }
                                            }
                                            >

                                                <TropareHorizontalScrollView
                                                    ref={item.ih.getHScrollView()}
                                                    hscrollrow={item.ih}
                                                    decorString={item.ih.getUrl()}
                                                    iframeid={item.ih.getId()}
                                                    refresh={that.props.refresh}
                                                    hrefresh={that.props.hrefresh}
                                                    height={item.outerHeight - (item.margin.left + item.margin.right)}
                                                >

                                                </TropareHorizontalScrollView>
                                            </View>
                                        </DecorBackground>
                                        </DecorBackground>
                                    )
                                } else {
                                    return null;
                                }
                            } else {
                                return null;
                            }
                        })
                    }
                </View>
            );
        } else {
            return null;
        }
    }

    // Return the necessary <> notation for our 'row'. Do this by creating an array of columns. If a column doesn't 
    // compute to be rendered, don't output it. If all columns are 'empty', then don't output a row, return < />
    // Keep an array of columns to map at the end.
    calcLine = (maxWidth) => {
        let iposition = this.props.item;
        let midlet = this.cMenu;

        let margins = new XYRect(DrawView.LEFT_OFFSET_NO_HINT, DrawView.TOP_OFFSET_NO_HINT, DrawView.LEFT_OFFSET_NO_HINT, DrawView.TOP_OFFSET_NO_HINT);
        if (midlet.m_decorNodeItems[iposition] !== null) {
            if (midlet.m_decorNodeItems[iposition].hasMarginTop()) {
                margins.y = midlet.m_decorNodeItems[iposition].getMarginTop();
            }
            if (midlet.m_decorNodeItems[iposition].hasMarginBottom()) {
                margins.height = midlet.m_decorNodeItems[iposition].getMarginBottom();
            }
            if (midlet.m_decorNodeItems[iposition].hasMarginLeft()) {
                margins.x = midlet.m_decorNodeItems[iposition].getMarginLeft();
            }
            if (midlet.m_decorNodeItems[iposition].hasMarginRight()) {
                margins.width = midlet.m_decorNodeItems[iposition].getMarginRight();
            }
        }

        this.m_bOverrideFont = false;
        if (midlet.rowHints !== null && !global.globalVars.HideImages()) {
            return (
                <>
                    {
                        midlet.rowHints.map((item, ixx) => (
                            <React.Fragment key={ixx}>
                                {this.getThisRowHeight(item, iposition, maxWidth, margins, ixx)}
                            </React.Fragment>
                        ))
                    }
                </>
            );
        } else {
            let rowText = midlet.m_arKeyPhrases[iposition];
            this.m_bOverrideFont = !midlet.m_bPipes;
            return (
                <>
                    {this.getThisRowHeight("$1", iposition, maxWidth, margins, 0)}
                </>
            )
        }

    }
 

    renderItems(width) {
        return (
            <>
                {this.calcLine(width)}
            </>
        )
    }

    chevronPressed = (ipos) => {
        this.cMenu.SelectItem(ipos);
    }
    blueChevronPressed = (ipos) => {
        this.cMenu.accessoryTapped(ipos, false, false);
    }

    // add prop:  backcolor={this.cMenu.}
    render() {
        let iposition = this.props.item;
        let midlet = this.cMenu;

        this.nRenderCalls++;

        // TODO: as an optimization, we could mark that we checked this already, as it will recompute each
        // time we render without having a decorNodeItemS
        if (midlet.m_decorNodeItems[iposition] == null) {
            if (this.props.dnItemString !== null && this.props.dnItemString.length > 0) {
                let arColumns = new Array(midlet.m_arColumns[iposition].length + 1);
                arColumns[0] = global.globalVars.appClass.getIncrementalBaseUrl();
                for (let j = 0; j < midlet.m_arColumns[iposition].length; j++) {
                    arColumns[j + 1] = midlet.m_arColumns[iposition][j];
                }
                let sDecor = midlet.parseDollarArgs(arColumns, this.props.dnItemString, true);
                for (let j = 0; j < midlet.m_arColumns[iposition].length; j++) {
                    arColumns[j] = null;
                }
                arColumns = null;
                if (sDecor !== null && sDecor.length > 0) {
                    midlet.m_decorNodeItemsString[iposition] = sDecor;
                    midlet.m_decorNodeItems[iposition] = DecorNode.FromAttributes(sDecor, DecorNode.DECORNODE_TYPE_CELL);
                }
            }
        }


        let bc = null;
        if (midlet.HasBackColor(iposition)) {
            bc = midlet.GetBackColor(iposition);
        }

        let maxWidth;
        let accessory;
        if (midlet.HasAccessory(iposition)) {
            maxWidth = this.props.width - global.globalVars.getChevronWidth();
            if (midlet.HasChevron(iposition)) {
                accessory = <TouchableOpacity
                    style={
                        {
                            flexDirection: 'row',
                            alignItems: 'center',
                        }
                    }
                    onPress={() => this.chevronPressed(iposition)}
                >
                    <BPImage
                        width={global.globalVars.getChevronWidth()}
                        height={global.globalVars.getChevronHeight()}
                        uri={global.globalVars.getChevronUrl()}
                        refresh={this.props.refresh}
                    />
                </TouchableOpacity>;
            } else if (midlet.HasBlueChevron(iposition)) {
                accessory = <TouchableOpacity
                    style={
                        {
                            flexDirection: 'row',
                            alignItems: 'center',
                        }                        
                    }
                    onPress={() => this.blueChevronPressed(iposition)}
                >
                    <BPImage
                        width={global.globalVars.getChevronWidth()}
                        height={global.globalVars.getChevronHeight()}
                        uri={global.globalVars.getBlueChevronUrl()}
                        refresh={this.props.refresh}
                    />
                </TouchableOpacity>;
            } else {
                accessory = null;
            }
        } else {
            maxWidth = this.props.width;
            accessory = null;
        }

        return (
            <DecorBackground 
                key={this.props.tkey} 
                style={styles.background}
                dnType={DecorNode.DECORNODE_TYPE_CELL}
                decorNodeString={midlet.m_decorNodeItemsString[iposition]}
                refresh={this.props.refresh}
                backcolor={bc}
            >
                <TouchableOpacity
                    onPress={() => this.chevronPressed(iposition)}
                >
                    {this.renderItems(maxWidth)}
                </TouchableOpacity>
                {accessory}
            </DecorBackground>

        )
    }
}
const styles = StyleSheet.create({
    background: {
        height: '100%',
        width: '100%',
        flexDirection: 'row'
    },
});
DrawView.TOP_OFFSET_NO_HINT = 4;
DrawView.LEFT_OFFSET_NO_HINT = 8;
DrawView.TOP_OFFSET_HINT = 4;
DrawView.LEFT_OFFSET_HINT = 8;
DrawView.C_SCROLLBAR_WIDTH = 6;
