import React from "react";
import { StyleSheet, Text, View } from 'react-native';
import DecorBackground from "../graphics/DecorBackground";
import DecorNode from "../graphics/DecorNode";
import BPColor from "../graphics/BPColor";

export default class BMessageText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            decorString: 'bg(c(#444)) fg(c(#ededed))',
            message: '',
            timer: null,            
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.message.length > 0 && this.state.timer == null) {
            let timer = setInterval(this.tick, 4000);
            this.setState({ timer: timer });
        }
    }
    componentDidMount() {
        let timer = setInterval(this.tick, 4000);
        this.setState({ timer: timer });
    }
    tick = () => {
        clearInterval(this.state.timer);
        this.setState({ timer: null, message: '' });
    }

    componentWillUnmount() {
        clearInterval(this.state.timer);
        this.setState({ timer: null });
    }
    render() {
        if (this.state.message.length > 0) {
    
            let dn = DecorNode.FromAttributes(this.state.decorString, DecorNode.DECORNODE_TYPE_MESSAGE);
            let pnFont = null;
            if (dn !== null) {
                pnFont = dn.findChildNode(DecorNode.C_lowercase_font);
            }
            this.myFontSettings = DecorNode.getFontSettings(pnFont, {
                fontFactor: 1.0,        // .86 = 12pt
                fontWeight: 400,
            });

            let fg = 0x0ff000000;
            if (dn !== null && dn.hasFGColor()) {
                fg = dn.getFGColor();
            }

            return (
                <DecorBackground style={[styles.outer, {
                    position: "absolute",
                    left: '10%',
                    top: '10%',
                }]} dnType={DecorNode.DECORNODE_TYPE_MESSAGE} decorNodeString={this.state.decorString} >
                    <View style={{
                        padding: 20,
                    }}
                    >
                        <Text style={[
                            styles.msg,
                            {
                                color: BPColor.intToHexRGBA(fg),
                                ...this.myFontSettings,
                            }
                        ]}
                        >
                            {this.state.message}

                        </Text>
                    </View>
                </DecorBackground>
            )
        } else {
            return null;
        }
    }
}
const styles = StyleSheet.create({
    outer: {
        display: 'flex',
        width: '80%',
        maxHeight: '80%',
        minHeight: 80,
        zIndex: 3,
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    msg: {
        padding: 10,
    }
});