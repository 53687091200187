import CTitleHistory from "./CTitleHistory";
import BBUtils from "./BBUtils";

export default class CHistory {
    constructor() {
      this.aUrlHistory = null;
      this.aCharHistory = null;
      this.aTitleHistory = null;
      this.aIndexFirstChild = null;
      this.aOffset = null;
      this.aSavedFirstChild = 0;
      this.aSavedOffset = 0;
      this.iHistoryIx = 0;
      this.iHistoryTotal = 0;
      this._skip_history = false;
  
      this.aUrlHistory = new Array(CHistory.CI_HISTORY_LENGTH);
      this.aCharHistory = new Array(CHistory.CI_HISTORY_LENGTH);
      this.aTitleHistory = new Array(CHistory.CI_HISTORY_LENGTH);
      this.aIndexFirstChild = new Array(CHistory.CI_HISTORY_LENGTH);
      this.aOffset = new Array(CHistory.CI_HISTORY_LENGTH);

      this.noHistUrl = '';
      this.noHistChar = '';
      this.noHistIndex = 0;
      this.noHistOffset = 0;

      for (let i=0; i<CHistory.CI_HISTORY_LENGTH; i++) {
        this.aUrlHistory[i] = null;
        this.aCharHistory[i] = null;
        this.aTitleHistory[i] = null;
        this.aIndexFirstChild[i] = null;
        this.aOffset[i] = null;
      }
    }
    clearHistory() {
      /*
      Since this is in the browser, we can't stop someone from going 'forward' via the nav
      buttons.  so, we don't clear history and hope an app never goes deeper than 
      CI_HISTORY_LENGTH levels...

      for (let i = 0; i < CHistory.CI_HISTORY_LENGTH; i++) {
        this.aUrlHistory[i] = null;
        this.aCharHistory[i] = null;
        this.aTitleHistory[i] = null;
        this.aIndexFirstChild[i] = 0;
        this.aOffset[i] = 0;
      }
      this.iHistoryIx = 0;
      this.iHistoryTotal = 0;
      */
    }
    replacePreviousBackChars(sChars) {
      let ix = this.iHistoryIx;
      if (this.iHistoryTotal > 0) {
        ix--;
        if (ix < 0) {
          ix = CHistory.CI_HISTORY_LENGTH - 1;
        }
        this.aCharHistory[ix] = sChars;
      }
    }
    getRequest = (ix) => {
      let sChars, sUrl;

      if (ix == -1) {
        return {
          url: this.noHistUrl,
          chars: this.noHistChar
        }
      } else if (ix < CHistory.CI_HISTORY_LENGTH) {
        return {
          url: this.aUrlHistory[ix],
          chars: this.aCharHistory[ix]
        }
      } else {
        return null;
      }
    }
    addHistory(sBase, sChars, ixTop, iOffset) {
      if (this._skip_history) {
        this.noHistUrl = sBase;
        this.noHistChar = sChars;
        this.noHistIndex = ixTop;
        this.noHistOffset = iOffset;
        return -1;
      }
      if (sBase !== null) {

        const uri = new URL(sBase);
        if (uri !== null) {
          if (uri.pathname.equals('/i/Home/') && sChars.length === 0) {
            this.clearHistory();
          }
        }
      }
      let sPrevBase = this.getPreviousBase();
      let sPrevChar = this.getPreviousChars();
      let ixRetval = this.iHistoryIx -1;

      if (!sPrevBase.equals(sBase)) {
        ixRetval = this.iHistoryIx;
        this.aUrlHistory[this.iHistoryIx] = sBase;
        this.aCharHistory[this.iHistoryIx] = sChars;
        this.aIndexFirstChild[this.iHistoryIx] = ixTop;
        this.aOffset[this.iHistoryIx] = iOffset;
        this.iHistoryIx++;
        this.iHistoryIx %= CHistory.CI_HISTORY_LENGTH;
        if (this.iHistoryTotal < CHistory.CI_HISTORY_LENGTH) {
          this.iHistoryTotal++;
        }
      } else {
        if (!sChars.equals(sPrevChar)) {
          if (sChars.indexOf("{") !== -1 || sPrevChar.indexOf("{") !== -1) {
            ixRetval = this.iHistoryIx;
            this.aUrlHistory[this.iHistoryIx] = sBase;
            this.aCharHistory[this.iHistoryIx] = sChars;
            this.aIndexFirstChild[this.iHistoryIx] = ixTop;
            this.aOffset[this.iHistoryIx] = iOffset;
            this.iHistoryIx++;
            this.iHistoryIx %= CHistory.CI_HISTORY_LENGTH;
            if (this.iHistoryTotal < CHistory.CI_HISTORY_LENGTH) {
              this.iHistoryTotal++;
            }
          } else {
            this.replacePreviousBackChars(sChars);
          }
        }
      }
      return ixRetval;
    }
    getPreviousBase() {
      let sRet = null;
      let ix = this.iHistoryIx;
      if (this.iHistoryTotal > 0) {
        ix--;
        if (ix < 0) {
          ix = CHistory.CI_HISTORY_LENGTH - 1;
        }
        sRet = this.aUrlHistory[ix];
      } else {
        sRet = '';
      }
      return sRet;
    }
    getPreviousChars() {
      let sRet = null;
      let ix = this.iHistoryIx;
      if (this.iHistoryTotal > 0) {
        ix--;
        if (ix < 0) {
          ix = CHistory.CI_HISTORY_LENGTH - 1;
        }
        sRet = this.aCharHistory[ix];
      } else {
        sRet = BBUtils.C_EMPTY_STRING;
      }
      return sRet;
    }
    getSavedFirstChild() {
      return this.aSavedFirstChild;
    }
    getSavedOffset() {
      return this.aSavedOffset;
    }
    getBackHistory() {
      let ix = 0;
      let sRet = BBUtils.C_EMPTY_STRING;
      if (this.iHistoryTotal > 0) {
        this.iHistoryIx--;
        this.iHistoryTotal--;
        if (this.iHistoryTotal > 0) {
          if (this.iHistoryIx < 0) {
            this.iHistoryIx = CHistory.CI_HISTORY_LENGTH - 1;
          }
          ix = this.iHistoryIx;
          this.aSavedFirstChild = this.aIndexFirstChild[ix];
          this.aSavedOffset = this.aOffset[ix];
          ix--;
          if (ix < 0) {
            ix = CHistory.CI_HISTORY_LENGTH - 1;
          }
          if (this.aCharHistory[ix].length > 0) {
            sRet = this.aUrlHistory[ix] + '@' + this.aCharHistory[ix];
          } else {
            sRet = this.aUrlHistory[ix];
          }
        } else {
          this.aSavedFirstChild = this.aIndexFirstChild[this.iHistoryIx];
          this.aSavedOffset = this.aOffset[this.iHistoryIx];
          this.iHistoryIx++;
          this.iHistoryTotal++;
        }
      }
      return sRet;
    }
    addTitleHistory(sBackText, sTitle, bFind) {
      let ix = this.iHistoryIx;
      ix--;
      if (ix < 0) {
        ix = CHistory.CI_HISTORY_LENGTH - 1;
      }
      this.aTitleHistory[ix] = null;
      let th = new CTitleHistory(sBackText, sTitle, bFind);
      this.aTitleHistory[ix] = th;
    }
    getLastTitle() {
      let ix = this.iHistoryIx;
      ix--;
      if (ix < 0) {
        ix = CHistory.CI_HISTORY_LENGTH - 1;
      }
      return this.aTitleHistory[ix];
    }
    getLastBackButton() {
      let ix = this.iHistoryIx;
      ix--;
      if (ix < 0) {
        ix = CHistory.CI_HISTORY_LENGTH - 1;
      }
      ix--;
      if (ix < 0) {
        ix = CHistory.CI_HISTORY_LENGTH - 1;
      }
      if (this.aTitleHistory[ix] !== null) {
        return this.aTitleHistory[ix].backText;
      } else {
        return null;
      }
    }
  }
  CHistory.serialVersionUID = 62590468044755261;
  CHistory.CI_HISTORY_LENGTH = 20;
  