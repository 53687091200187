import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';


export default class EToolbar extends React.Component {
    constructor(props) {
        // props.original type(combo, image, label)
        // 
        super(props);
        this.state = {
            barType: props.original,
        }

        if (typeof props.original !== 'undefined') {    // type(combo|image|label)
            var myRegex = /type\(([^\)]+)\)/gm;
            var match = myRegex.exec(props.original);
            this.state.barType = match[1];
        }

        this.propChange = this.propChange.bind(this);
    }

    handleTypeChange = (event) => {
        this.state.barType = event.currentTarget.value;
        this.setState({barType: event.currentTarget.value});
        this.propChange();
    }  

    propChange = () => {
        let sval = 'type(' + this.state.barType + ')';

        this.props.setProp(sval);
    }

    render() {
        return (
            <>
                <FormLabel component="legend">{this.props.name}</FormLabel>
                <RadioGroup aria-label={this.props.name}
                    onChange={this.handleTypeChange}
                    value={this.state.barType}
                    name="rb_tbt" row>
                    <FormControlLabel value="combo" control={<Radio />} label="Combo" />
                    <FormControlLabel value="image" control={<Radio />} label="Image" />
                    <FormControlLabel value="label" control={<Radio />} label="Label" />
                </RadioGroup>
            </>
        )
    }
}