export default class Vector {
    constructor() {
        this._arr = null;
    }
    firstChild = () => {
        if (this._arr == null) {
            return null;
        } else {
            return this._arr[0];
        }

    }
    elementAt = (ix) => {
        if (this._arr == null) {
            return null;
        } else if (ix < this._arr.length) {
            return this._arr[ix];
        } else {
            return null;
        }
    }
    add = (elem) => {
        this.addElement(elem);
    }
    addElement = (elem) => {
        if (this._arr == null) {
            this._arr = [];
        }
        this._arr.push(elem);
    }
    insertElementAt = (elem, ix) => {
        if (this._arr == null) {
            this._arr = [];
        }
        this._arr.splice(ix, 0, elem);
    }
    removeElementAt = (ix) => {
        if (ix < this._arr.length)
            this._arr.splice(ix, 1);
    }
    size = () => {
        if (this._arr == null) {
            return 0;
        } else {
            return this._arr.length;
        }
    }
    removeElement = (elem) => {
        if (this._arr != null) {
            for (let i = 0; i < this._arr.length; i++) {
                if (elem == this._arr[i]) {
                    this._arr.splice(i, 1);
                    return;
                }
            }
        }
    }
    removeAllElements = () => {
        this._arr = [];
    }
    contains = (elem) => {
        if (this._arr != null) {
            for (let i = 0; i < this._arr.length; i++) {
                if (elem == this._arr[i]) {
                    return true;
                }
            }
        }
        return false;
    }

    map(callback /*, args */) {
        var T, A, k;
        var len = this.size();
        if (typeof callback !== 'function') {
            throw new TypeError(callback + ' is not a funtion');
        }

        var O = Object(this);
    
        // 4. If IsCallable(callback) is false, throw a TypeError exception.
        // See: https://es5.github.com/#x9.11
        if (typeof callback !== 'function') {
          throw new TypeError(callback + ' is not a function');
        }
    
        // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 1) {
          T = arguments[1];
        }
    
        // 6. Let A be a new array created as if by the expression new Array(len)
        //    where Array is the standard built-in constructor with that name and
        //    len is the value of len.
        A = new Array(len);
    
        // 7. Let k be 0
        k = 0;
    
        // 8. Repeat, while k < len
        while (k < len) {
    
          var kValue, mappedValue;
    
          // a. Let Pk be ToString(k).
          //   This is implicit for LHS operands of the in operator
          // b. Let kPresent be the result of calling the HasProperty internal
          //    method of O with argument Pk.
          //   This step can be combined with c
          // c. If kPresent is true, then
          kValue = O.elementAt(k);

        // ii. Let mappedValue be the result of calling the Call internal
        //     method of callback with T as the this value and argument
        //     list containing kValue, k, and O.
        mappedValue = callback.call(T, kValue, k, O);

        // iii. Call the DefineOwnProperty internal method of A with arguments
        // Pk, Property Descriptor
        // { Value: mappedValue,
        //   Writable: true,
        //   Enumerable: true,
        //   Configurable: true },
        // and false.

        // In browsers that support Object.defineProperty, use the following:
        // Object.defineProperty(A, k, {
        //   value: mappedValue,
        //   writable: true,
        //   enumerable: true,
        //   configurable: true
        // });

        // For best browser support, use the following:
        A[k] = mappedValue;

          // d. Increase k by 1.
          k++;
        }
    
        // 9. return A
        return A;
      }
}
