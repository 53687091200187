import BPSocketVariables from './BPSocketVariables';
import BBUtils from './BBUtils';
import Worker from './BPSocket.worker.js'
import CCallLog from './CCallLog';
import CCookie from './CCookie';
import BPTask from './BPTask';
import BPHttpTask from './BPHttpTask';
import BPSocketConst from './BPSocketConst';
import CmdParams from './CmdParams';
import CMenuAdapter from './CMenuAdapter';

export default class BPSocket {
    constructor(ctx, appClass, sGuid) {
      this.ctx = ctx,
      this.appClass = appClass;   // App (for callbacks to set data, etc.)

      this.GUID = sGuid;

      this.requestsCounter = 0;
      this.worker = new Worker();

      this.worker.onmessage = this.receive;

      this.cMenu = new CMenuAdapter(ctx, this.appClass);
      
      global.globalVars.setCMenu(this.cMenu);

      // if web...

      this.procMessage = this.procMessage.bind(this);

      this.addEvent(window, 'message', this.procMessage);
    }
    setGuid = (sG) => {
      this.GUID = sG;
    }
    procMessage(event) {
      this.cMenu.processWebMessage(event);
    }
  addEvent(obj, type, fn) {
    if (obj.addEventListener) {
      obj.addEventListener(type, fn, false);
    }
    else if (obj.attachEvent) {
      obj["e" + type + fn] = fn;
      obj[type + fn] = function () { obj["e" + type + fn](window.event); }
      obj.attachEvent("on" + type, obj[type + fn]);
    }
    else {
      obj["on" + type] = obj["e" + type + fn];
    }
  }

    dumpCmdParamas(sName, oParams) {
      console.log("cmd: " + sName);
      if (typeof oParams !== 'undefined' && oParams !== null) {
        for (const sKey in oParams) {          
          if (Object.prototype.toString.call(oParams[sKey]) === '[object Array]') {
            console.log("    " + (typeof oParams[sKey]) + ": " + sKey + ": ");
            for (let k=0; k<oParams[sKey].length; k++) {
              console.log("        " + oParams[sKey][k]);
            }

          } else {
            console.log("    " + (typeof oParams[sKey]) + ": " + sKey + ": " + oParams[sKey]);
          }
        }
      }
    }
    processCommands = (arCmds) => {
      if (arCmds != null && arCmds.length > 0) {
        for (let i=0; i<arCmds.length; i++) {
          let sName = arCmds[i].cmd;
          let oParams = arCmds[i].params;
          switch (sName) {
            case CmdParams.CMDP_SET_COOKIE: // arParams are cookies
            {
              if (CmdParams.isArray(oParams[CmdParams.CMDP_SET_COOKIE_p_ARCOOKIE])) {
                let arFrom = oParams[CmdParams.CMDP_SET_COOKIE_p_ARCOOKIE];
                for (let i=0; i<arFrom.length; i++) {
                  try {
                    let kookie = new CCookie(arFrom[i]);
                    document.cookie = kookie.toCookieString();
                  } catch (ex) {
                    console.log('Set cookie failed: ' + ex.toString());
                  }
                }
              }
              break;
            }
            case CmdParams.CMDP_SET_BCOOKIE:
              {
                if (CmdParams.isString(oParams[CmdParams.CMDP_SET_BCOOKIE_p_COOKIE])) {
                  this.appClass.setBCookie(oParams[CmdParams.CMDP_SET_BCOOKIE_p_COOKIE]);
                }
              }
              break;
            case CmdParams.CMDP_CANCEL_REFRESH:
              this.appClass.cancelRefreshTimer();
              break;
            case CmdParams.CMDP_REFRESH_TIMER:
              if (CmdParams.isNumber(oParams[CmdParams.CMDP_REFRESH_TIMER_p_TIME])) {
                this.appClass.startRefreshTimerL(oParams[CmdParams.CMDP_REFRESH_TIMER_p_TIME]);
              }
              break;
            case CmdParams.CMDP_ADD_TITLE_HISTORY:
              this.appClass._cHistory.addTitleHistory(
                oParams[CmdParams.CMDP_ADD_TITLE_HISTORY_p_BACK_TEXT],
                oParams[CmdParams.CMDP_ADD_TITLE_HISTORY_p_TITLE],
                oParams[CmdParams.CMDP_ADD_TITLE_HISTORY_p_FIND]
                );
              break;
            case CmdParams.CMDP_START_REFRESH_GPSL:
              if (CmdParams.isNumber(oParams[CmdParams.CMDP_START_REFRESH_GPSL_p_DISTANCE]) && 
                  CmdParams.isNumber(oParams[CmdParams.CMDP_START_REFRESH_GPSL_p_FLAG])) {
                    this.appClass.startRefreshGPSL(oParams[CmdParams.CMDP_START_REFRESH_GPSL_p_DISTANCE], oParams[CmdParams.CMDP_START_REFRESH_GPSL_p_FLAG]);
                  }
              break;
            case CmdParams.CMDP_DO_NOT_USE_GPS:
              this.appClass.notifyClientDoNotUseGPS();
              break;
            case CmdParams.CMDP_SET_EXTRAUI:
              if (typeof oParams !== 'undefined' && oParams != null) {
                this.appClass.setExtraUI(oParams[CmdParams.CMDP_SET_EXTRAUI_p_DICT]);
              }
              break;
            case CmdParams.CMDP_INCREMENTAL_SEARCH:   
              if (typeof oParams !== 'undefined' && oParams != null) {
                // Check types? 
                let arBMenu = oParams[CmdParams.CMDP_INCREMENTAL_SEARCH_p_ARBMENU];
                let sContent = oParams[CmdParams.CMDP_INCREMENTAL_SEARCH_p_CONTENT];
                let sRowHints = oParams[CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_ROW_HINTS];
                let sFont = oParams[CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_FONT];
                let sDecorItem = oParams[CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_DECOR_ITEM];
                let sDecorScreen = oParams[CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_DECOR_SCREEN];
                let sDecorTitle = oParams[CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_DECOR_TITLE];
                let sMisc = oParams[CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_MISCELLANEOUS];
                let sSeparator = oParams[CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_DECOR_SEPARATOR];
                let sToolBar = oParams[CmdParams.CMDP_INCREMENTAL_SEARCH_p_B_TOOLBAR];

                if (sRowHints === null || sRowHints.length == 0) {
                  sRowHints = "fh(100); $1; 100; 100";
                }
                this.cMenu.setListData(
                  arBMenu,
                  sContent,
                  sRowHints,
                  sFont,
                  sDecorItem,
                  sDecorScreen,
                  sDecorTitle,
                  sMisc,
                  sSeparator,
                  sToolBar,
                  );
              }

              break;
            case CmdParams.CMDP_LOCATION:
              if (CmdParams.isString(oParams[CmdParams.CMDP_LOCATION_p_LOCATION])) {
                this.appClass.process302(oParams[CmdParams.CMDP_LOCATION_p_LOCATION]);
              }
              break;
            case CmdParams.CMDP_XLOCATION:
              if (CmdParams.isString(oParams[CmdParams.CMDP_XLOCATION_p_LOCATION])) {
                this.appClass.processX302(oParams[CmdParams.CMDP_XLOCATION_p_LOCATION]);
              }
              break;
            case CmdParams.CMDP_SET_STATUS:
              if (CmdParams.isString(oParams[CmdParams.CMDP_SET_STATUS_p_TEXT])) {
                this.appClass.showMessage(oParams[CmdParams.CMDP_SET_STATUS_p_TEXT]);
              }
              break;
            case CmdParams.CMDP_REFRESHS:
              this.appClass.RefreshS();
              break;
            case CmdParams.CMDP_REFRESHR:
              this.appClass.RefreshR();
              break;
            case CmdParams.CMDP_SET_BPSOCKET_VARIABLES:
              if (CmdParams.isObject(oParams[CmdParams.CMDP_SET_BPSOCKET_VARIABLES_p_VARS])) {
                this.appClass.setReturnVars(oParams[CmdParams.CMDP_SET_BPSOCKET_VARIABLES_p_VARS]);
              }
              break;
            case CmdParams.CMDP_SHOW_ERROR_MESSAGE:
              if (CmdParams.isString(oParams[CmdParams.CMDP_SHOW_ERROR_MESSAGE_p_MESSAGE])) {
                this.appClass.showErrorMessage(oParams[CmdParams.CMDP_SHOW_ERROR_MESSAGE_p_MESSAGE]);
              }
              break;
            case CmdParams.CMDP_GET_BACK_HISTORY:
              this.dumpCmdParamas(sName, oParams);
              break;
            case CmdParams.CMDP_START_CLIENT_PROGRESS:
              this.appClass.startClientProgress();
              break;
            case CmdParams.CMDP_STOP_CLIENT_PROGRESS:
              this.appClass.stopClientProgress();
                break;
            case CmdParams.CMDP_JSON_EDIT_RETURN:
              if (CmdParams.isString(oParams[CmdParams.CMDP_JSON_EDIT_RETURN_VALUE])) {
                this.appClass.editNotesReturn(oParams[CmdParams.CMDP_JSON_EDIT_RETURN_VALUE]);
              }
                break;
            default:
              console.log("Unknown cmd: " + sName + " params: " + (oParams !== null && typeof oParams !== 'undefined' ? oParams.toString() : ""));
              break;
          }

        }
      }
    }
    receive = (event) => {
      const {id, arCmds, error} = event.data;

      if (error != null) {
        // showError(error.toString());
      } else if (arCmds.length > 0) {
        this.processCommands(arCmds);
      }
    }

    async addEditTask(iType, sHeader) {
      const id = `request-${this.requestsCounter++}`;

      let bpHttpTask = new BPHttpTask({type: iType, header: sHeader, content: ''});
      // Pass 'global' variables we need on the network call
      bpHttpTask.setServerUrl(global.globalVars.getServerUrl());
      bpHttpTask.setProperty("uid", this.GUID);
      bpHttpTask.setProperty(BPSocketConst.C_BPIN, global.globalVars.getBPIN());
      if (global.globalVars.getBlocation().getLat() !== 0.0 && global.globalVars.getBlocation().getLon() !== 0.0) {
        bpHttpTask.setProperty("B-GPS", global.globalVars.getBlocation().getCommaFormattedLatLon());
      }
      bpHttpTask.setBcookie("");
      bpHttpTask.setCookie(document.cookie);

      let bpTask = new BPTask(BPTask.TASK_HTTPEDITTASK, bpHttpTask);
      this.worker.postMessage({id, taskString: JSON.stringify(bpTask)})
    }

    async addHttpTask(iType, sHeader, sContent) {
      const id = `request-${this.requestsCounter++}`;

      let bpHttpTask = new BPHttpTask({type: iType, header: sHeader, content: sContent});
      // Pass 'global' variables we need on the network call
      bpHttpTask.setServerUrl(global.globalVars.getServerUrl());
      bpHttpTask.setProperty(BPSocketConst.C_GUID, this.GUID);
      bpHttpTask.setProperty(BBUtils.C_UAOS, global.globalVars.getUAOS());
      bpHttpTask.setProperty(BBUtils.C_UAPIXELS, global.globalVars.appClass.getUAPixels());
      bpHttpTask.setProperty(BBUtils.C_LATLON, global.globalVars.getBlocation().getFormattedLatLon());
      bpHttpTask.setProperty(BPSocketConst.C_BPIN, global.globalVars.getBPIN());
//dev throws error      bpHttpTask.setProperty(BPSocketConst.C_HOST, global.globalVars.getHostHeader());
      bpHttpTask.setBcookie("");
      bpHttpTask.setCookie(document.cookie);
      var arCallLog = await CCallLog.AppendCallLog();
      if (arCallLog != null && arCallLog.length > 0) {
        bpHttpTask.appendCallLog(arCallLog);
        CCallLog.DeleteLogFile();   // no way to know whether we succeed in transfer, so might as well delete it
      }

      let bpTask = new BPTask(BPTask.TASK_HTTPTASK, bpHttpTask);
      this.worker.postMessage({id, taskString: JSON.stringify(bpTask)})

    }
  }


  