export default class UniqueId {
    constructor() {

    }
}

UniqueId.guid = (args) => {
    var s;
    if(typeof args !== 'undefined' && args.hasOwnProperty('separator')) {
      s = args.separator;
    } else {
      s = "-";
    }
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }

        return s4() + s4() + s + s4() + s + s4() + s + s4() + s + s4() + s4() + s4();

    };
