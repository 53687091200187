import XYRect from "./XYRect";
import UniqueId from "../util/UniqueId";

export default class ImageRow {
    constructor(sImgUrl, r, minHeight, vAlign, hAlign, itemNo) {
      this._id = UniqueId.guid({'separator': '_'});
      this._imgUrl = sImgUrl;
      this._rect = new XYRect(r);
      this._minHeight = minHeight;
      this._valign = vAlign;
      this._halign = hAlign;
      this._itemNo = itemNo;
    }

    getItemNo() {
      return this._itemNo;
    }
    getUrl() {
      return this._imgUrl;
    }
    getId() {
      return this._id;
    }
    copyRect() {
      return new XYRect(this._rect);
    }
    getMinHeight() {
      return this._minHeight;
    }
    getVAlign() {
      return this._valign;
    }
    getHAlign() {
      return this._halign;
    }
    getHeight() {
      return this._rect.height;
    }
  }
  