import React from "react";
import { StyleSheet } from "react-native";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import {TextField} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EConstants from "./EConstants";

export default class EComponentTree extends React.Component  {
    constructor(props) {
        super(props);

    }

    onNodeSelect = (event, nodeIds) => {
            // 1 - show properties Nav Bar
            // 2 - show properties Screen
            // etc.
            global.globalVars.getEditInfo().treeNodeSelected(nodeIds);
    }

    componentDidUpdate(prevProps, prevstate, snapshot) {
    }
    componentDidMount() {
    }

    render() {
        let color = this.props.disableSelection ? 'lightgray' : null;
            return (
                <TreeView
                    aria-label="user interface styling"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    disableSelection={this.props.disableSelection}
                    sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                    onNodeSelect={this.onNodeSelect}
                    expanded={["1", "2", "3"]}
                >
                    {global.globalVars.getEditInfo().getTreeNodes().map((item, index) => {
                        return (
                            <TreeItem
                                key={index}
                                nodeId={item.id}
                                label={item.label}
                                sx={{
                                    color: color
                                }}
                            >
                                {item.children.map((citem, cindex) => {
                                    return (
                                        <TreeItem
                                            key={cindex}
                                            nodeId={citem.id}
                                            label={citem.label}
                                            sx={{
                                                color: color
                                            }}
                                        />
                                    )
                                })}
                                {item.id === EConstants.Tree_Node_Toolbar &&
                                    this.props.toolbarText.map((item, index) => {
                                        return (
                                            <TreeItem
                                                key={index}
                                                nodeId={EConstants.Tree_Node_Toolbar + index.toString()}
                                                label={this.props.toolbarText[index]}
                                                sx={{
                                                    color: color
                                                }}

                                            />
                                        )

                                    })
                                }
                            </TreeItem>
                        )
                    })
                    }
                </TreeView>
        )
    }
}
const styles = StyleSheet.create({
    label: {
        fontSize: "1rem",
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    },
});
